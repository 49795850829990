import { MasterPage } from "../../_ui-kit/MasterPage";
import { PageTransition } from "../../_ui-kit/PageTransition";

function UserProfile() {
  return (
    <MasterPage heading="User profile">
      <PageTransition>
        <div id="content" className="px-6 py-10 xl:max-w-5xl xl:mx-auto"></div>
      </PageTransition>
    </MasterPage>
  );
}

export default UserProfile;
