import { ClientPricingPlan } from "../../../models/Client";
import { getFormattedDate } from "../../../utils/common";

interface PricingPlansListRowProps {
  pricingPlan: ClientPricingPlan;
  editPricingPlanClickHandler: (param: ClientPricingPlan) => void;
  deletePricingPlanClickHandler: (param: ClientPricingPlan) => void;
}

export default function PricingPlansListRow({
  pricingPlan,
  editPricingPlanClickHandler,
  deletePricingPlanClickHandler,
}: PricingPlansListRowProps) {
  return (
    <tr>
      <td className="whitespace-nowrap py-4 pr-3 text-sm pl-6 font-medium">
        {pricingPlan.planType}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {pricingPlan.duration}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {`$${pricingPlan.price.toLocaleString()}`}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {getFormattedDate(pricingPlan.dateCreated)}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {pricingPlan.createdBy}
      </td>
      <td className="relative whitespace-nowrap py-4 pl-3 text-right text-sm font-medium pr-6">
        <div className="flex justify-end space-x-4">
          <button
            className="px-1 text-primary-700 rounded-sm hover:text-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            onClick={() => editPricingPlanClickHandler(pricingPlan)}
          >
            Edit
            <span className="sr-only">, {pricingPlan.planType}</span>
          </button>
          <button
            className="px-1 text-danger-700 rounded-sm hover:text-danger-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-danger-500"
            onClick={() => deletePricingPlanClickHandler(pricingPlan)}
          >
            Delete
            <span className="sr-only">, {pricingPlan.planType}</span>
          </button>
        </div>
      </td>
    </tr>
  );
}

export function PricingPlansListRowSkeleton() {
  return (
    <tr>
      <td className="py-4 pr-3 pl-6 leading-5">
        <span className="inline-block rounded bg-gray-200 w-40 h-4 align-middle animate-pulse" />
      </td>
      <td className="px-3 py-4 leading-5">
        <span className="inline-block rounded bg-gray-200 w-20 h-4 align-middle animate-pulse" />
      </td>
      <td className="px-3 py-4 leading-5">
        <span className="inline-block rounded bg-gray-200 w-20 h-4 align-middle animate-pulse" />
      </td>
      <td className="px-3 py-4 leading-5">
        <span className="inline-block rounded bg-gray-200 w-20 h-4 align-middle animate-pulse" />
      </td>
      <td className="px-3 py-4 leading-5">
        <span className="inline-block rounded bg-gray-200 w-36 h-4 align-middle animate-pulse" />
      </td>
      <td className="py-4 pl-3 pr-6 text-right leading-5">
        <div className="flex justify-end space-x-4">
          <span className="inline-block rounded bg-gray-200 w-8 h-4 align-middle animate-pulse" />
          <span className="inline-block rounded bg-gray-200 w-8 h-4 align-middle animate-pulse" />
        </div>
      </td>
    </tr>
  );
}
