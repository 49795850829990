import { RadioSwitchProps } from "./RadioSwitch.types";
import { RadioGroup } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function RadioSwitch({
  className,
  options,
  selectedOption,
  onChange,
  label,
  disabled = false,
}: RadioSwitchProps) {
  return (
    <RadioGroup
      value={selectedOption}
      onChange={onChange}
      className={`${className} w-full grid gap-0.5 grid-cols-${
        options.length
      } ${
        disabled
          ? "pointer-events-none"
          : "hover:bg-gray-300 hover:text-gray-900"
      } justify-items-stretch bg-gray-200 text-gray-700 p-0.5 rounded-lg font-medium text-sm`}
      disabled={disabled}
    >
      <RadioGroup.Label className="sr-only">{label}</RadioGroup.Label>
      {options.map((option) => {
        return (
          <RadioGroup.Option
            className={({ checked }) =>
              `${
                checked ? "bg-white text-gray-900 shadow-sm" : "bg-transparent"
              } ${
                option.disabled ? "text-gray-400" : "cursor-pointer"
              } group px-3 py-2 rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-200`
            }
            value={option}
            key={option.id}
            disabled={option.disabled}
          >
            {({ checked }) => (
              <RadioGroup.Label
                as="span"
                className="flex items-center justify-center"
              >
                {option.icon && (
                  <FontAwesomeIcon
                    icon={option.icon}
                    className={`${
                      checked
                        ? "text-primary-600"
                        : "text-gray-500 group-hover:text-gray-600"
                    } w-4 h-4 mr-2`}
                    aria-hidden={true}
                  />
                )}
                {option.name}
              </RadioGroup.Label>
            )}
          </RadioGroup.Option>
        );
      })}
    </RadioGroup>
  );
}
