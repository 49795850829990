import { Navigate } from "react-router-dom";
import { getStoredAuthToken } from "../../utils/user-storage";

interface PublicRouteProps {
  children: JSX.Element;
  force?: boolean;
}

function PublicRoute({ children, force = false }: PublicRouteProps) {
  const authToken = getStoredAuthToken();

  if (authToken && force) {
    return <Navigate to="/" replace />;
  }

  return children;
}

export default PublicRoute;
