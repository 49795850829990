import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AuthorizationApi from "../../api/AuthorizationApi";
import { Button } from "../../_ui-kit";
import { encode } from "base-64";
import Alert from "../../_ui-kit/Alert/Alert";
import { AuthRouteLocationState } from "../../routes/components/AuthorizationRoute";
import { MasterPage } from "../../_ui-kit/MasterPage";
import InputGroup, {
  InputGroupErrorDescription,
} from "../../_ui-kit/InputGroup/InputGroup";
import Input from "../../_ui-kit/Input/Input";
import { userNameBlurHandler } from "./SignIn";
import RuledPasswordInput from "./components/RuledPasswordInput";
import { sendPassword } from "./ForgotPassword";

interface SingInProps {
  authApi: AuthorizationApi;
}

export default function ResetPassword({ authApi }: SingInProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as AuthRouteLocationState;
  const isReset = location.pathname.includes("/reset-password");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [info, setInfo] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const { code } = useParams();
  const [emailAddressRequiredError, setEmailAddressRequiredError] =
    useState(false);
  const [emailAddressFormatError, setEmailAddressFormatError] = useState(false);
  const [passwordHasErrors, setPasswordHasErrors] = useState(false);
  const [isVerifyingEmail, setIsVerifyingEmail] = useState(false);
  const [validCode, setValidCode] = useState(true);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    if (!userEmail) {
      const email = formData.get("auth-username") as string;
      let validCode;
      const verifyCode = async () => {
        try {
          const response = await authApi.verifyResetCodeValidation(email);
          if (response.ok) {
            const isValid = await response.text();
            validCode = isValid === "true";
            setValidCode(validCode);
            setUserEmail(email);
            setIsVerifyingEmail(false);
          } else {
            setIsVerifyingEmail(false);
            setError("Error verifying code. Please try again later.");
          }
        } catch (ex: any) {
          setIsVerifyingEmail(false);
          setError(ex);
        }
      };
      setIsVerifyingEmail(true);
      verifyCode();
      return;
    }

    setIsSubmitting(true);
    setError("");
    setInfo("");

    const newPassword = formData.get("auth-new-password") as string;

    const setPassword = async () => {
      try {
        await authApi.setPassword(userEmail, code || "", encode(newPassword));
        navigate("/sign-in", {
          state: {
            from: { info: "You've set up your password successfully." },
          },
        });
      } catch (ex: any) {
        setIsSubmitting(false);
        setError(ex);
      }
    };
    setPassword();
  }

  function sendPasswordLink(): void {
    sendPassword(userEmail, setIsSubmitting, setError, navigate, authApi);
  }

  useEffect(() => {
    setError(locationState?.from?.error || "");
    setInfo(locationState?.from?.info || "");
    window.history.replaceState(null, "");
  }, [locationState]);

  return (
    <MasterPage type="signin" heading="Set Up Password">
      {error && (
        <Alert variant="danger" className="mb-8" show={!!error} dismissible>
          <p className="text-sm font-medium">{error}</p>
        </Alert>
      )}
      {info && (
        <Alert className="mb-8" show={!!info} dismissible>
          <p className="text-sm font-medium">{info}</p>
        </Alert>
      )}
      <h2 className="mb-1 text-4xl font-bold text-gray-900">
        {isReset ? "Reset your password" : "Setup your password"}
      </h2>
      {!validCode && (
        <div className="mt-2 flex flex-col space-y-8">
          <p className="text-center text-gray-600">
            It looks like your password authorization link has run out of time.
            No worries, just ask for a new one, and we'll get you back on track.
          </p>
          <Button
            label="Send authorization link"
            size="lg"
            onClick={sendPasswordLink}
            isLoading={isSubmitting}
          />
        </div>
      )}
      {validCode && (
        <>
          <p className="mb-6 text-sm text-gray-600">
            {userEmail
              ? "You can set up your new password below."
              : "Enter your email to continue."}
          </p>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col space-y-8 text-left"
          >
            {!userEmail && (
              <>
                <InputGroup label="Email address" inputId="auth-username">
                  <Input
                    type="email"
                    id="auth-username"
                    name="auth-username"
                    size="lg"
                    autoComplete="username"
                    isInvalid={
                      emailAddressRequiredError || emailAddressFormatError
                    }
                    onBlur={(e) =>
                      userNameBlurHandler(
                        e,
                        setEmailAddressRequiredError,
                        setEmailAddressFormatError
                      )
                    }
                    ariaDescribedby={
                      emailAddressRequiredError
                        ? "signin-username-required-error"
                        : emailAddressFormatError
                        ? "signin-username-format-error"
                        : undefined
                    }
                    required
                  />
                  {emailAddressRequiredError && (
                    <InputGroupErrorDescription
                      id="signin-username-required-error"
                      text="Your email address is required."
                    />
                  )}
                  {emailAddressFormatError && (
                    <InputGroupErrorDescription
                      id="signin-username-format-error"
                      text="Your email address is invalid."
                    />
                  )}
                </InputGroup>
                <Button
                  type="submit"
                  label="Create password"
                  size="lg"
                  isLoading={isVerifyingEmail}
                />
              </>
            )}
            {userEmail && (
              <>
                <RuledPasswordInput
                  id="auth-new-password"
                  name="auth-new-password"
                  label="Password"
                  passwordHasErrors={passwordHasErrors}
                  setPasswordHasErrors={setPasswordHasErrors}
                />
                <div className="flex flex-col space-y-2">
                  <Button
                    label={isReset ? "Reset password" : "Set password"}
                    size="lg"
                    type="submit"
                    isLoading={isSubmitting}
                  />
                  <Button
                    label="Back"
                    size="lg"
                    variant="gray"
                    onClick={() => {
                      setUserEmail("");
                    }}
                    soft
                    underline
                  />
                </div>
              </>
            )}
          </form>
        </>
      )}
    </MasterPage>
  );
}
