import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageLoaderSpinnerProps } from "./PlageLoaderSpinner.types";

export function PageLoaderSpinner({
  loadingText = "Loading...",
}: PageLoaderSpinnerProps) {
  return (
    <div className="flex justify-center py-10">
      <FontAwesomeIcon
        icon={duotone("spinner-third")}
        className="w-10 h-10 text-primary-600 animate-spin"
      />
      <span className="sr-only">{loadingText}</span>
    </div>
  );
}
