import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { SlideOverProps } from "./SlideOver.types";
import CloseButton from "../CloseButton/CloseButton";

export function SlideOver({
  show = false,
  scroll = true,
  title = "Panel title",
  size = "md",
  description,
  onClose,
  children,
}: SlideOverProps) {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-hidden"
        onClose={onClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 flex max-w-full">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div
                className={`w-screen ${
                  size === "sm"
                    ? "max-w-sm pl-8"
                    : size === "lg"
                    ? "max-w-3xl pl-6"
                    : size === "xl"
                    ? "max-w-4xl pl-6"
                    : "max-w-xl pl-6"
                }`}
              >
                <div
                  className={`flex flex-col h-full bg-gray-100 shadow-xl ${
                    scroll ? "overflow-y-scroll" : ""
                  }`}
                >
                  <div className="flex items-start justify-between bg-white px-6 py-5">
                    <Dialog.Title className="text-lg font-medium text-gray-700">
                      {title}
                    </Dialog.Title>
                    {description && (
                      <Dialog.Description className="sr-only">
                        {description}
                      </Dialog.Description>
                    )}
                    <div className="ml-3 flex h-7 items-center">
                      <CloseButton onClose={onClose} />
                    </div>
                  </div>
                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
