import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactFlow, {
  Background,
  Controls,
  EdgeProps,
  getBezierPath,
  Handle,
  MiniMap,
  NodeProps,
  Position,
  Node,
  Edge,
} from "react-flow-renderer";
import { VendorAsset } from "../VendorCard";
import {
  FlowData,
  LineageLevel,
  LineageProps,
  LineageResource,
} from "./Lineage.types";
import { faTable as fasTable } from "@fortawesome/pro-solid-svg-icons";
import { faMemoPad as farMemoPad } from "@fortawesome/pro-regular-svg-icons";
import { faBarsProgress as fasBarsProgress } from "@fortawesome/pro-solid-svg-icons";
import { faDiagramCells as fasDiagramCells } from "@fortawesome/pro-solid-svg-icons";
import { faDiagramSuccessor as fasDiagramSuccessor } from "@fortawesome/pro-solid-svg-icons";
import { faDiagramSubtask as fasDiagramSubtask } from "@fortawesome/pro-solid-svg-icons";
import { faDiamondExclamation as fasDiamondExclamation } from "@fortawesome/pro-solid-svg-icons";

const nodeTypes = {
  "lineage-node": LineageNode,
};

const edgeTypes = {
  "lineage-edge": LineageEdge,
};

function truncateMiddle(str: string) {
  return str.length >= 22
    ? str.substring(0, 9) + "..." + str.substring(str.length - 9, str.length)
    : str;
}

function getTranslatedLineageData(
  data: LineageLevel[],
  ignoreIsNew?: boolean
): FlowData {
  const nodes: Node[] = [];
  const edges: Edge[] = [];
  const width = 224;
  const height = 80;
  const maxGapX = 480;
  const gapX = 96;
  const gapY = 40;
  const maxItemLevel = 25;
  const animatedEdges = !data.some(
    (item) => item.resources.length > maxItemLevel
  );
  let x = 0;

  for (let index = 0; index < data.length; index++) {
    const item = data[index];
    const resourcesCount = item.resources.length;
    let y = 0;
    if (resourcesCount % 2 === 0) {
      y = height / 2 + gapY / 2 + ((height + gapY) * (resourcesCount - 2)) / 2;
    } else {
      y = ((height + gapY) * (resourcesCount - 1)) / 2;
    }
    for (let resource of item.resources) {
      const sinksCount = resource.sinks ? resource.sinks.length : 0;

      nodes.push({    
        id: `node-${item.level}--${resource.id}`,
        type: "lineage-node",
        data: {
          label: resource.name,
          entrypoint: item.level === 1,
          hasSinks: sinksCount > 0,
          details: resource,
          ignoreIsNew: ignoreIsNew,
        },
        position: { x, y },
      });

      if (resource.sinks && sinksCount > 0) {
        edges.push(
          ...resource.sinks.map((sink: any) => {
            return {
              id: `edge-${item.level}--${resource.id}--${sink}`,
              type: "lineage-edge",
              source: `node-${item.level}--${resource.id}`,
              target: `node-${item.level + 1}--${sink}`,
              animated: animatedEdges,
            };
          })
        );
      }
      y -= height + gapY;
    }
    if (index + 1 < data.length) {
      const maxCount = Math.max(
        resourcesCount,
        data[index + 1].resources.length
      );
      let calcGapX = Math.min(maxGapX, Math.round((gapX * maxCount) / 5));
      if (calcGapX < gapX) calcGapX = gapX;
      x += width + calcGapX;
    }
  }

  return {
    nodes,
    edges,
  };
}

function getSubLineage(id: string, lineage: LineageLevel[]): LineageLevel[] {
  let foundResource: LineageResource | null = null;
  lineage.forEach((lineage) => {
    let resource = lineage.resources.find((resource) => resource.id === id);
    if (resource) foundResource = resource;
  });
  let subLineage: LineageLevel[] = [];

  if (foundResource) {
    subLineage.push({
      level: 1,
      resources: [foundResource],
    });

    for (let lineageLevel of subLineage) {
      let nextLevel = lineageLevel.level + 1;
      let resourceList = [];
      for (let lineageResource of lineageLevel.resources) {
        if (lineageResource.sinks) {
          for (let resId of lineageResource.sinks) {
            if (resourceList.filter((item) => item.id === resId).length < 1)
              resourceList.push(
                ...lineage
                  .filter((lin) => lin.level === nextLevel)[0]
                  .resources.filter((res) => res.id === resId)
              );
          }
        }
      }
      if (resourceList.length > 0)
        subLineage.push({
          level: nextLevel,
          resources: resourceList,
        });
    }
  }

  return subLineage;
}

function Lineage({
  nodes,
  edges,
  onNodesChange,
  onEdgesChange,
  onConnect,
  ...props
}: LineageProps) {
  const defaultZoom = 1;

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      nodeTypes={nodeTypes}
      onEdgesChange={onEdgesChange}
      edgeTypes={edgeTypes}
      onConnect={onConnect}
      attributionPosition="top-right"
      defaultZoom={defaultZoom}
      nodesConnectable={false}
      elementsSelectable={false}
      fitViewOptions={{ minZoom: defaultZoom, maxZoom: defaultZoom }}
      fitView
    >
      {props.children}
      <Controls className="!left-5 !bottom-5" />
      <MiniMap nodeStrokeWidth={2} className="w-40 h-32 !right-5 !bottom-5" />
      <Background />
    </ReactFlow>
  );
}

function LineageNode({ data }: NodeProps) {
  const handleClassNames =
    "!w-1 !h-1 !bg-white !border-none ring-2 ring-gray-300";
  let nodeIcon;

  if (data.details.type === "job") {
    if (data.details.subType) {
      nodeIcon =
        data.details.subType === "parallel"
          ? fasDiagramCells
          : fasDiagramSuccessor;
    } else nodeIcon = fasBarsProgress;
  } else if (data.details.type === "table") {
    nodeIcon = fasTable;
  } else if (data.details.type === "file") {
    nodeIcon = farMemoPad;
  } else nodeIcon = fasDiagramSubtask;

  return (
    <div className="relative">
      {!data.entrypoint && (
        <Handle
          type="target"
          className={`!-left-0.5 ${handleClassNames}`}
          position={Position.Left}
        />
      )}
      <div
        className="flex flex-col w-56 h-20 border border-gray-300 bg-white shadow-sm rounded-lg font-mono"
        title={data.details.name}
      >
        {!data.ignoreIsNew && data.details.isNew && (
          <div className="absolute -top-2 -left-5 h-5 px-2 border border-success-500 bg-success-100 rounded-full shadow-sm text-success-700 font-medium text-xs leading-4">
            new
          </div>
        )}
        <div className="flex items-center justify-between h-10 px-4 border-b border-gray-300">
          <div className="flex items-center">
            <FontAwesomeIcon
              icon={nodeIcon}
              className="text-gray-500"
              aria-hidden={true}
            />
            <span className="ml-2 text-xs font-medium uppercase tracking-wide">
              {data.details.type === "job" && (
                <>
                  <span className="mr-1.5">{data.details.subType}</span>
                  {data.details.type}
                </>
              )}
              {data.details.type !== "job" && data.details.type}
            </span>
          </div>
          {(data.details.type === "table" || data.details.type === "file") &&
            data.details.subType && (
              <span className="ml-auto mr-2 pt-px text-xs text-gray-500 font-sans capitalize italic">
                * {data.details.subType}
              </span>
            )}
          <div className="flex items-center">
            {data.details.external && (
              <div className="mr-2">
                <FontAwesomeIcon
                  icon={fasDiamondExclamation}
                  className="w-4 h-4 text-warning-400"
                  aria-hidden={true}
                />
                <span className="sr-only">Outside application</span>
              </div>
            )}
            {data.details.platform && (
              <VendorAsset platform={data.details.platform} type="icon" />
            )}
          </div>
        </div>
        <div className="flex items-center grow px-4 text-sm font-bold tracking-wide">
          <span className="truncate">
            {data.details.name ? truncateMiddle(data.details.name) : "Unknown"}
          </span>
        </div>
      </div>
      {data.hasSinks && (
        <Handle
          type="source"
          className={`!-right-0.5 ${handleClassNames}`}
          position={Position.Right}
        />
      )}
    </div>
  );
}

function LineageEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  animated,
}: EdgeProps) {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <path
        id={id}
        style={style}
        className="fill-transparent stroke-[1.5] stroke-gray-300"
        d={edgePath}
        strokeDasharray={animated ? undefined : "5"}
        markerEnd={markerEnd}
      />
    </>
  );
}

export {
  getTranslatedLineageData,
  getSubLineage,
  Lineage,
  LineageNode,
  LineageEdge,
};
