import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import _ from "lodash";
import { Fragment, useEffect } from "react";
import { ChangeEvent, DragEvent, useRef, useState } from "react";
import { useContext } from "react";
import ConfigureApi from "../../api/ConfigureApi";
import { Button } from "../../_ui-kit";
import { PageTransition } from "../../_ui-kit/PageTransition";
import { UploadType } from "../../models/Project";
import { ProjectContext } from "../../contexts/ProjectContext";
import { DataUploadContext } from "../../contexts/DataUploadContext";
import UploadItem from "./components/UploadItem";
import UploadItemSummaryList from "./components/UploadItemSummaryList";
import Modal, { ModalContent, ModalFooter } from "../../_ui-kit/Modal/Modal";
import { Platform } from "../../_ui-kit/SelectPlatform/SelectPlatform.types";
import SelectPlatform from "../../_ui-kit/SelectPlatform/SelectPlatform";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ClientContext } from "../../contexts/ClientContext";
import { ETLPlatform } from "../../contexts/DiscoverContext";
import DiscoverApi from "../../api/DiscoverApi";
import InputGroup from "../../_ui-kit/InputGroup/InputGroup";
import Input from "../../_ui-kit/Input/Input";

interface UploadListItem {
  file: File;
  type: UploadType;
  error?: string;
}

interface SelectedImportCollection {
  collectionId: string;
  type: UploadType;
}

function DataUpload() {
  const discoverApi = new DiscoverApi();
  const configureApi = new ConfigureApi();
  const { currentClient } = useContext(ClientContext);
  const { currentProject } = useContext(ProjectContext);
  const { projectUploadInProgress, projectCollectionsInProgress } =
    useContext(DataUploadContext);
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState<UploadListItem[]>([]);
  const [openUploadFilesSummary, setOpenUploadFilesSummary] = useState(false);
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [selectedEtlPlatform, setSelectedEtlPlatform] = useState<Platform>();
  const [dataPlatforms, setDataPlatforms] = useState<Platform[]>([]);
  const [selectedImportCollection, setSelectedImportCollection] =
    useState<SelectedImportCollection | null>(null);
  const [selectedDataPlatform, setSelectedDataPlatform] = useState<Platform>();
  const [isImportingData, setIsImportingData] = useState(false);
  const [showProgressPanel, setShowProgressPanel] = useState(false);
  const queryClient = useQueryClient();
  const { data: etlList } = useQuery(
    [
      `etl-list${!currentClient || !currentProject ? "-invalid" : ""}`,
      {
        clientId: currentClient?.id,
        projectId: currentProject?.uuid,
      },
    ],
    async () => {
      const clientId = currentClient?.id || "";
      const projectId = currentProject?.uuid || "";
      const response = await discoverApi.getETLPlatforms(clientId, projectId);
      const etls: ETLPlatform[] = await response.json();
      return etls;
    },
    {
      enabled: !!currentClient && !!currentProject,
      select: (data) =>
        data.map((etl) => {
          return {
            id: etl.etlplatformId,
            name: etl.etlPlatformType,
            description: etl.etlPlatformType,
            assignedName: etl.name,
          };
        }),
      onSuccess: () => {
        queryClient.removeQueries(["etl-list-invalid"]);
      },
    }
  );

  function getFilesToUpload(files: FileList): UploadListItem[] {
    const items: UploadListItem[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (file) {
        const invalidFormat = file.type !== "text/csv";
        const fileTooBig = file.size > 1048576000; // 100MB
        const hasError = invalidFormat || fileTooBig;

        items.push({
          file: file,
          type: hasError ? "error" : "missing",
          error: hasError
            ? invalidFormat
              ? "Invalid file format"
              : "File size is too big"
            : undefined,
        });
      }
    }
    return items;
  }

  function fileUploadClickHandler() {
    const input = fileUploadRef.current;

    if (input) {
      input.value = "";
      input.click();
    }
  }

  function fileUploadChangeHandler(e: ChangeEvent<HTMLInputElement>) {
    const filesList = e.target.files;
    if (filesList) setFilesToUpload(getFilesToUpload(filesList));
  }

  function fileUploadOnDragEnterHandler(e: DragEvent<HTMLDivElement>) {
    e.stopPropagation();
    e.preventDefault();

    if (
      !e.relatedTarget ||
      (e.relatedTarget && !e.currentTarget.contains(e.relatedTarget as Node))
    ) {
      setIsDragging(true);
    }
  }

  function fileUploadOnDragLeaveHandler(e: DragEvent<HTMLDivElement>) {
    e.stopPropagation();
    e.preventDefault();

    if (
      !e.relatedTarget ||
      (e.relatedTarget && !e.currentTarget.contains(e.relatedTarget as Node))
    ) {
      setIsDragging(false);
    }
  }

  function fileUploadOnDragOverHandler(e: DragEvent<HTMLDivElement>) {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  }

  function fileUploadOnDropHandler(e: DragEvent<HTMLDivElement>) {
    e.stopPropagation();
    e.preventDefault();

    const fileList = e.dataTransfer.files;
    setFilesToUpload(getFilesToUpload(fileList));
    setIsDragging(false);
  }

  function closeFileUploadSummary(close: boolean): void {
    setOpenUploadFilesSummary(close);
    _.delay(() => {
      setFilesToUpload([]);
    }, 300);
  }

  function submitUploadFilesClickHandler(): void {
    const submitUploadFiles = async (
      projectId: string,
      filesToUpload: UploadListItem[]
    ): Promise<void> => {
      try {
        const responses = await Promise.all(
          filesToUpload.map((fileUpload) =>
            configureApi.uploadCsvFile(
              projectId,
              fileUpload.file,
              fileUpload.type
            )
          )
        );
        responses.forEach((response) => {
          if (!response.ok) {
            console.log(response.status, response.statusText);
          }
        });

        _.delay(() => {
          setIsUploadingFiles(false);
          setOpenUploadFilesSummary(false);
        }, 1000);
      } catch (ex) {
        console.log(ex);
      }
    };

    if (currentProject && filesToUpload.length > 0) {
      setIsUploadingFiles(true);
      submitUploadFiles(currentProject.uuid, filesToUpload);
    }
  }

  function importDataSubmitHandler(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    setIsImportingData(true);

    async function importFileUpload(
      projectId: string,
      collectionId: string,
      collectionType: string
    ) {
      const formData = new FormData(e.currentTarget);
      const etlId = formData.get("import-etl-platform[id]") as string;
      const dataPlatformId = formData.get("import-data-platform[id]") as string;
      const defaultSchema =
        (formData.get("import-default-schema") as string) || "DBO";
      const response =
        collectionType === "etl"
          ? await configureApi.importETLData(
              projectId,
              collectionId,
              etlId,
              dataPlatformId
            )
          : collectionType === "dependency"
          ? await configureApi.importDependencyData(
              projectId,
              collectionId,
              dataPlatformId,
              defaultSchema
            )
          : await configureApi.importDDLData(
              projectId,
              collectionId,
              dataPlatformId,
              defaultSchema
            );
      setIsImportingData(false);
      setOpenImportDialog(false);
      console.log(response);
    }

    if (currentProject && selectedImportCollection && selectedDataPlatform) {
      setIsImportingData(true);
      importFileUpload(
        currentProject.uuid,
        selectedImportCollection.collectionId,
        selectedImportCollection.type
      );
    }
  }

  function isUploadBtnDisabled() {
    return (
      filesToUpload.every((file) => file.type === "error") ||
      filesToUpload.some((file) => file.type === "missing")
    );
  }

  function closeImportDialog(close: boolean): void {
    setOpenImportDialog(close);
  }

  useEffect(() => {
    async function getDataPlatforms() {
      const result = await configureApi.getDataPlatforms();
      const dataPlatforms: Platform[] = await result.json();
      setDataPlatforms(dataPlatforms);
    }

    getDataPlatforms();

    queryClient.invalidateQueries(["etl-list"]);
    queryClient.invalidateQueries(["etl-summaries"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filesToUpload && filesToUpload.length > 0) {
      setOpenUploadFilesSummary(true);
    }
  }, [filesToUpload]);

  useEffect(() => {
    setShowProgressPanel(projectUploadInProgress);
  }, [projectUploadInProgress]);

  return (
    <PageTransition>
      <div id="content" className="h-full px-6 py-8">
        <div className="h-full">
          <div
            onDragEnter={fileUploadOnDragEnterHandler}
            onDragLeave={fileUploadOnDragLeaveHandler}
            onDragOver={fileUploadOnDragOverHandler}
            onDrop={fileUploadOnDropHandler}
            className={`min-h-full flex flex-col items-center justify-center grow ${
              showProgressPanel ? "mr-[22.5rem]" : ""
            } px-20 pt-32 pb-20 border-2 border-dashed ${
              isDragging
                ? "border-primary-500"
                : "border-gray-300 focus-within:border-primary-500"
            } rounded-lg`}
          >
            <input
              ref={fileUploadRef}
              onChange={fileUploadChangeHandler}
              id="fileUpload"
              type="file"
              accept=".csv"
              multiple
              hidden
            />
            <div className="group relative mb-4">
              <span
                className={`transition-transform ease-in-out duration-300 flex absolute left-0 ${
                  isDragging
                    ? "-translate-x-10 -translate-y-4"
                    : "-translate-x-12 -translate-y-7 group-hover:-translate-x-10 group-hover:-translate-y-4"
                } -rotate-45 text-base text-gray-900 opacity-25`}
              >
                <FontAwesomeIcon
                  icon={regular("file-alt")}
                  size="3x"
                  aria-hidden={true}
                />
              </span>
              <span
                className={`transition-transform ease-in-out duration-300 flex absolute translate-x-11 ${
                  isDragging
                    ? "-translate-y-12"
                    : "-translate-y-14 group-hover:-translate-y-12"
                } text-gray-900 opacity-20`}
              >
                <FontAwesomeIcon
                  icon={regular("file-csv")}
                  className="h-10"
                  aria-hidden={true}
                />
              </span>
              <span
                className={`transition-transform ease-in-out duration-300 flex absolute -right-4 ${
                  isDragging
                    ? "translate-x-10 -translate-y-7"
                    : "translate-x-12 -translate-y-10 group-hover:translate-x-10 group-hover:-translate-y-7"
                } rotate-45 text-gray-900 opacity-30`}
              >
                <FontAwesomeIcon
                  icon={regular("file-code")}
                  size="4x"
                  aria-hidden={true}
                />
              </span>
              <button
                onClick={fileUploadClickHandler}
                className={`transition-colors duration-300 ${
                  isDragging
                    ? "text-primary-700"
                    : "text-gray-500 hover:!text-primary-600 focus:!text-primary-700"
                } focus:outline-none`}
              >
                <FontAwesomeIcon
                  icon={solid("cloud-arrow-up")}
                  size="6x"
                  aria-hidden={true}
                />
                <span className="sr-only">Select files</span>
              </button>
            </div>
            <h2 className="mb-1 text-gray-900 font-medium">
              Drop files here or click to upload
            </h2>
            <p className="w-80 text-sm text-center text-gray-600">
              Upload your{" "}
              <abbr
                className="transition-colors tracking-wider font-medium text-primary-700 cursor-default"
                title="Comma-separated values"
              >
                .csv
              </abbr>{" "}
              files to import your data into the system for your current
              project.
            </p>
          </div>
          <Transition
            as={Fragment}
            show={showProgressPanel}
            enter="transform transition ease-in-out duration-500"
            enterFrom="opaciti-0 translate-x-full"
            enterTo="opacity-100 translate-x-0"
            leave="transform transition ease-in-out duration-300"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-full"
          >
            <div className="fixed right-8 top-0 bottom-0 w-80 flex flex-col">
              <div className="space-y-4 px-8 -mx-8 pb-8 overflow-y-scroll">
                <h2 className="sticky top-0 z-10 -mx-0.5 -mb-2 pt-8 pb-3 bg-gray-100/90 backdrop-blur border-x-2 border-x-gray-100 shadow-md shadow-gray-100 text-2xl font-medium text-gray-900">
                  Data load progress...
                </h2>
                {projectCollectionsInProgress.map((collection) => {
                  return (
                    <UploadItem
                      key={collection.collectionId}
                      data={collection}
                      currentProject={currentProject}
                      setSelectedImportCollection={setSelectedImportCollection}
                      setOpenImportDialog={setOpenImportDialog}
                      show={!collection.imported}
                    />
                  );
                })}
              </div>
            </div>
          </Transition>
        </div>
        <Modal
          title="File upload"
          size="xl"
          show={openUploadFilesSummary}
          onClose={isUploadingFiles ? () => {} : closeFileUploadSummary}
        >
          <ModalContent>
            {filesToUpload && (
              <div className="min-h-[6rem] py-1">
                <UploadItemSummaryList
                  filesToUpload={filesToUpload}
                  setFilesToUpload={setFilesToUpload}
                />
              </div>
            )}
          </ModalContent>
          <ModalFooter>
            <div className="flex space-x-4">
              <Button
                label="Upload"
                icon={solid("cloud-arrow-up")}
                onClick={submitUploadFilesClickHandler}
                isLoading={isUploadingFiles}
                disabled={isUploadBtnDisabled()}
              />
              <Button
                label="Cancel"
                icon={solid("xmark-circle")}
                variant="white"
                onClick={() => closeFileUploadSummary(false)}
                disabled={isUploadingFiles}
              />
            </div>
          </ModalFooter>
        </Modal>
        <Modal
          title="File import"
          show={openImportDialog}
          onClose={isImportingData ? () => {} : closeImportDialog}
        >
          <form onSubmit={importDataSubmitHandler}>
            <ModalContent>
              <div className="flex flex-col space-y-5">
                {selectedImportCollection?.type === "etl" && etlList && (
                  <div>
                    <label className="block font-medium text-gray-700">
                      ETL platform
                    </label>
                    <div className="mt-2">
                      <SelectPlatform
                        name="import-etl-platform"
                        platforms={etlList}
                        selectedPlatform={selectedEtlPlatform}
                        setSelectedPlatform={setSelectedEtlPlatform}
                      />
                    </div>
                  </div>
                )}
                <div>
                  <label className="block font-medium text-gray-700">
                    Default data platform
                  </label>
                  <div className="mt-2">
                    <SelectPlatform
                      name="import-data-platform"
                      platforms={dataPlatforms}
                      selectedPlatform={selectedDataPlatform}
                      setSelectedPlatform={setSelectedDataPlatform}
                    />
                  </div>
                </div>
                {(selectedImportCollection?.type === "dependency" ||
                  selectedImportCollection?.type === "ddl") && (
                  <InputGroup
                    label="Platform schema"
                    inputId="import-default-schema"
                    className="space-y-2"
                  >
                    <Input
                      type="text"
                      id="import-default-schema"
                      name="import-default-schema"
                      size="lg"
                      maxLength={30}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                      placeholder="DBO (default)"
                      autoComplete="off"
                    />
                  </InputGroup>
                )}
              </div>
            </ModalContent>
            <ModalFooter>
              <div className="flex space-x-4">
                <Button
                  type="submit"
                  label="Import"
                  icon={solid("file-import")}
                  isLoading={isImportingData}
                />
                <Button
                  label="Cancel"
                  icon={solid("xmark-circle")}
                  variant="white"
                  disabled={isImportingData}
                  onClick={() => closeImportDialog(false)}
                />
              </div>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    </PageTransition>
  );
}

export type { UploadListItem, SelectedImportCollection };
export default DataUpload;
