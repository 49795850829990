import { RingChartProps } from "./RingChart.types";

export function RingChart({
  size,
  progress,
  title,
  children,
  trackWidth = 10,
  indicatorWidth = 10,
  indicatorCap = "round",
  variant = "primary",
}: RingChartProps) {
  const center = size / 2,
    width = trackWidth > indicatorWidth ? trackWidth : indicatorWidth,
    radius = center - width + width / 2,
    dashArray = 2 * Math.PI * radius,
    dashOffset = dashArray * ((100 - progress) / 100);

  function getIndicatorStrokeColor() {
    switch (variant) {
      case "primary":
        return "stroke-primary-600";
      case "secondary":
        return "stroke-secondary-600";
      case "success":
        return "stroke-success-600";
      case "warning":
        return "stroke-warning-600";
      case "danger":
        return "stroke-danger-600";
      case "info":
        return "stroke-info-600";
      default:
        return "stroke-gray-600";
    }
  }

  return (
    <div className="relative" style={{ width: size, height: size }}>
      <svg style={{ width: size, height: size, rotate: "-90deg" }} role="img">
        {title && <title>{title}</title>}
        <circle
          className="stroke-gray-200"
          fill="transparent"
          strokeWidth={trackWidth}
          cx={center}
          cy={center}
          r={radius}
        />
        <circle
          className={getIndicatorStrokeColor()}
          fill="transparent"
          strokeWidth={indicatorWidth}
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
          strokeLinecap={indicatorCap}
          cx={center}
          cy={center}
          r={radius}
        />
      </svg>
      {children}
    </div>
  );
}
