import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { UploadType } from "../../../models/Project";
import { UploadListItem } from "../DataUpload";
import { uploadTypes } from "./UploadItem";

interface UploadItemTypeSelectProps {
  index: number;
  filesToUpload: UploadListItem[];
  setFilesToUpload: (param: UploadListItem[]) => void;
}

function UploadItemTypeSelect({
  index,
  filesToUpload,
  setFilesToUpload,
}: UploadItemTypeSelectProps) {
  const [selectedType, setSelectedType] = useState(uploadTypes[0]);

  useEffect(() => {
    if (
      filesToUpload[index].type !== "missing" &&
      filesToUpload[index].type !== "error"
    ) {
      setSelectedType(
        uploadTypes.filter((type) => type.name === filesToUpload[index].type)[0]
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedType.key !== "missing") {
      const files = [...filesToUpload];
      files[index].type = selectedType.key as UploadType;
      setFilesToUpload(files);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType]);

  return (
    <Listbox value={selectedType} onChange={setSelectedType}>
      <div className="relative">
        <Listbox.Button className="relative inline-flex items-center w-full pl-3.5 pr-8 py-1.5 border border-gray-300 shadow-sm rounded-full text-xs text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:border-primary-500 focus:ring-1 focus:ring-primary-500">
          <span className="font-medium truncate">{selectedType.name}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex flex-col justify-center items-center pr-3.5 text-gray-500">
            <FontAwesomeIcon
              icon={solid("sort-up")}
              className="w-2 h-2"
              aria-hidden={true}
            />
            <FontAwesomeIcon
              icon={solid("sort-down")}
              className="w-2 h-2 -mt-1.5"
              aria-hidden={true}
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Listbox.Options className="origin-top-right absolute right-0 mt-2 py-1 max-w-xs flex flex-col rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-40 focus:outline-none">
            {uploadTypes.map((type) => {
              if (type.key !== "missing")
                return (
                  <Listbox.Option
                    key={type.key}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-4 pr-12 text-sm ${
                        active
                          ? "bg-primary-100 text-primary-700"
                          : "bg-white text-gray-900"
                      }`
                    }
                    value={type}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`whitespace-nowrap ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {type.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 right-0 flex items-center pr-4 text-base text-primary-600`}
                          >
                            <span className="sr-only">Selected</span>
                            <FontAwesomeIcon
                              icon={solid("check")}
                              aria-hidden={true}
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                );
              return null;
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

export default UploadItemTypeSelect;
