import { PageTransition } from "../../_ui-kit/PageTransition";
import { RadioSwitch } from "../../_ui-kit/RadioSwitch";
import { faBrowser as fasBrowser } from "@fortawesome/pro-solid-svg-icons";
import { faTable as fasTable } from "@fortawesome/pro-solid-svg-icons";
import { faListOl as fasListOl } from "@fortawesome/pro-solid-svg-icons";
import { faWater as fasWater } from "@fortawesome/pro-solid-svg-icons";
import { Fragment, useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../_ui-kit";
import { Combobox, Transition } from "@headlessui/react";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { LineageResourceSummary } from "../../_ui-kit/Lineage/Lineage.types";
import { ProjectContext } from "../../contexts/ProjectContext";
import { SlideOver } from "../../_ui-kit/SlideOver";
import AnalyzeApi from "../../api/AnalyzeApi";
import { Project } from "../../models/Project";
import { UserContext } from "../../contexts/UserContext";

interface WavePlanSummary {
  addedApplications: number;
  addedJobs: number;
  addedSchedulers: number;
  addedTables: number;
  clientId: string;
  createdBy: string;
  dateCreated: string;
  lastUpdated: string;
  name: string;
  totalApplications: number;
  totalJobs: number;
  totalScedulers: number;
  totalTables: number;
  totalWaves: number;
  uuid: string;
}

export interface WaveDetails {
  createdBy: string;
  dateCreated: string;
  lastUpdated: string;
  name: string;
  newApplications: number;
  newJobs: number;
  newSchedulers: number;
  newTables: number;
  objectId: string;
  projectId: string;
  totalApplications: number;
  totalJobs: number;
  totalSchedulers: number;
  totalTables: number;
  type: number;
  uuid: string;
}

interface NewWaveSummary {
  resourceType?: number;
  resourceId?: string;
  resourceName?: string;
  waveId?: string;
  addedApplications: number;
  totalJobs: number;
  addedJobs: number;
  addedSchedulers: number;
  totalTables: number;
  addedTables: number;
  projectId: string;
}

interface WaveStat {
  id: number;
  name: string;
  value: string;
}

interface WaveListItemProps {
  navigate: NavigateFunction;
  waves: WaveDetails[];
  wave: WaveDetails;
  index: number;
  project: Project;
  downloadWaves: (
    projectId: string,
    fileName: string,
    waveId: string
  ) => Promise<void>;
  getWavePlanSummary: (project: string) => void;
  getWavePlanSummaryDetails: (project: string) => void;
}

const contextOptions = [
  {
    id: 0,
    name: "Applications",
    icon: fasBrowser,
  },
  {
    id: 3,
    name: "Tables",
    icon: fasTable,
  },
];

export async function addResourceToWavePlan(
  projectId: string,
  resourceId: string,
  isApplication: boolean
): Promise<NewWaveSummary> {
  const analyzeApi = new AnalyzeApi();
  const response = isApplication
    ? await analyzeApi.addApplicationToWavePlan(projectId, resourceId)
    : await analyzeApi.addTableToWavePlan(projectId, resourceId);
  const wave: NewWaveSummary = await response.json();
  console.log(wave);
  return wave;
}

export async function downloadWaves(
  projectId: string,
  fileName: string,
  waveId?: string,
  onFinish?: () => void
): Promise<void> {
  const analyzeApi = new AnalyzeApi();
  const response = await analyzeApi.downloadWaves(projectId, waveId);
  const result = await response.text();
  let url = URL.createObjectURL(new Blob([result], { type: "text/csv" }));
  let anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = fileName;
  anchor.style.display = "none";
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  URL.revokeObjectURL(url);
  onFinish?.();
}

function WaveListItem({
  navigate,
  waves,
  wave,
  index,
  project,
  downloadWaves,
  getWavePlanSummary,
  getWavePlanSummaryDetails,
}: WaveListItemProps) {
  const { authUser } = useContext(UserContext);
  const analyzeApi = new AnalyzeApi();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  function waveItemClickHandler(): void {
    navigate(
      `/analyze/wave-lineage/${wave.uuid}/${wave.type}/${wave.objectId}`
    );
  }

  function downloadClickHandler(e?: React.MouseEvent<HTMLButtonElement>): void {
    e?.stopPropagation();

    async function downloadWave(projectId: string) {
      await downloadWaves(
        projectId,
        `wave-[${wave.name.replace(" ", "_")}].csv`,
        wave.uuid
      );
      setIsDownloading(false);
    }

    if (project) {
      setIsDownloading(true);
      downloadWave(project.uuid);
    }
  }

  function removeClickHandler(e?: React.MouseEvent<HTMLButtonElement>): void {
    e?.stopPropagation();

    async function removeLastWave(projectId: string): Promise<void> {
      const response = await analyzeApi.removeLastWave(projectId);
      const result = response.status;
      getWavePlanSummary(projectId);
      getWavePlanSummaryDetails(projectId);
      setIsRemoving(false);
      console.log(result);
    }

    if (project) {
      setIsRemoving(true);
      removeLastWave(project.uuid);
    }
  }

  return (
    <div
      key={wave.uuid}
      className="group transition duration-300 relative flex px-4 pt-3 pb-5 bg-white hover:bg-gray-100 rounded-md cursor-pointer"
      onClick={waveItemClickHandler}
    >
      <span className="relative z-10 w-10 h-10 flex shrink-0 items-center justify-center bg-primary-700 rounded-full font-medium text-lg text-white">
        {index + 1}
      </span>
      <div className="transition-transform duration-300 ease-out grow min-w-0 ml-3 group-hover:translate-x-1.5">
        <div className="mt-2.5 mb-4 font-medium text-sm group-hover:text-gray-900 truncate">
          {wave.name}
        </div>
        <ul className="space-y-1.5">
          <li className="flex items-center space-x-3">
            <FontAwesomeIcon
              icon={wave.type === 0 ? fasBrowser : fasTable}
              className="text-gray-400"
              aria-hidden={true}
            />
            <span className="text-sm text-gray-500">
              {wave.type === 0 ? "Application" : "Table"}
            </span>
          </li>
          <li className="flex items-center space-x-3">
            <FontAwesomeIcon
              icon={solid("diagram-cells")}
              className="text-gray-400"
              aria-hidden={true}
            />
            <span className="text-sm text-gray-500">
              {wave.newJobs} New jobs
            </span>
          </li>
          <li className="flex items-center space-x-3">
            <FontAwesomeIcon
              icon={solid("table-cells")}
              className="text-gray-400"
              aria-hidden={true}
            />
            <span className="text-sm text-gray-500">
              {wave.newTables} New tables
            </span>
          </li>
        </ul>
      </div>
      <div className="invisible group-hover:visible absolute z-10 inset-y-0 w-10 mt-[3.75rem] flex flex-col space-y-2">
        <Button
          label="Download wave"
          variant="white"
          hideLabel
          icon={solid("file-arrow-down")}
          size="sm"
          onClick={(e?: React.MouseEvent<HTMLButtonElement>) =>
            downloadClickHandler(e)
          }
          isLoading={isDownloading}
          radius="circular"
        />
        {index === waves.length - 1 && !authUser?.isReviewer() && (
          <Button
            label="Delete wave"
            variant="white"
            hideLabel
            icon={solid("trash-xmark")}
            size="sm"
            onClick={(e?: React.MouseEvent<HTMLButtonElement>) =>
              removeClickHandler(e)
            }
            isLoading={isRemoving}
            radius="circular"
          />
        )}
      </div>
      <div
        className={`${
          index < waves.length - 1 || authUser?.isReviewer()
            ? "group-hover:mb-16"
            : "invisible group-hover:visible mb-8"
        } absolute z-0 inset-y-0 w-0.5 group-hover:mt-12 mt-16 left-[2.1875rem] bg-gray-200 group-hover:bg-gray-300`}
        role="presentation"
        aria-hidden={true}
      ></div>
    </div>
  );
}

function WavePlanner() {
  const { authUser } = useContext(UserContext);
  const analyzeApi = new AnalyzeApi();
  const navigate = useNavigate();
  const [selectedContext, setSelectedContext] = useState(contextOptions[0]);
  const { currentProject } = useContext(ProjectContext);
  const [applicationsList, setApplicationsList] = useState<
    LineageResourceSummary[]
  >([]);
  const [selectedApplication, setSelectedApplication] =
    useState<LineageResourceSummary | null>(null);
  const [tablesList, setTablesList] = useState<LineageResourceSummary[]>([]);
  const [selectedTable, setSelectedTable] =
    useState<LineageResourceSummary | null>(null);
  const [resourcesListQuery, setResourcesListQuery] = useState("");
  const filteredResourcesList =
    resourcesListQuery === ""
      ? selectedContext.id === 0
        ? applicationsList
        : tablesList
      : selectedContext.id === 0
      ? getFilteredResources(applicationsList)
      : getFilteredResources(tablesList);
  const [newWaveSummary, setNewWaveSummary] = useState<NewWaveSummary | null>(
    null
  );
  const [isCreatingWave, setIsCreatingWave] = useState(false);
  const [isAddingWave, setIsAddingWave] = useState(false);
  const [isDownloadingAllWaves, setIsDownloadingAllWaves] = useState(false);
  const [isRemovingAllWaves, setIsRemovingAllWaves] = useState(false);
  const [waveStats, setWaveStats] = useState<WaveStat[]>([]);
  const [wavePlanSummary, setWavePlanSummary] =
    useState<WavePlanSummary | null>(null);
  const [wavePlanSummaryDetails, setWavePlanSummaryDetails] = useState<
    WaveDetails[]
  >([]);
  const [openSlideOver, setOpenSlideOver] = useState(false);

  async function getWavePlanSummary(projectId: string): Promise<void> {
    const result = await analyzeApi.getWavePlanSummary(projectId);
    const summary = await result.json();
    setWavePlanSummary(summary);
  }

  async function getWavePlanSummaryDetails(projectId: string): Promise<void> {
    const result = await analyzeApi.getWavePlanSummaryDetails(projectId);
    const summaryDetails = await result.json();
    setWavePlanSummaryDetails(summaryDetails);
  }

  function getFilteredResources(resourceList: LineageResourceSummary[]) {
    return resourceList.filter((item) => {
      return item.name.toLowerCase().includes(resourcesListQuery.toLowerCase());
    });
  }

  function createWaveClickHandler(): void {
    async function getApplicationWavePreview(
      projectId: string,
      applicationId: string
    ): Promise<void> {
      const result = await analyzeApi.getApplicationWavePreview(
        projectId,
        applicationId
      );
      const newWave: NewWaveSummary = await result.json();
      newWave.resourceType = 0;
      newWave.resourceId = selectedApplication?.id;
      newWave.resourceName = selectedApplication?.name;
      setNewWaveSummary(newWave);
      setIsCreatingWave(false);
    }
    async function getTableWavePreview(
      projectId: string,
      tableId: string
    ): Promise<void> {
      const result = await analyzeApi.getTableWavePreview(projectId, tableId);
      const newWave: NewWaveSummary = await result.json();
      newWave.resourceType = 3;
      newWave.resourceId = selectedTable?.id;
      newWave.resourceName = selectedTable?.name;
      setNewWaveSummary(newWave);
      setIsCreatingWave(false);
    }
    if (currentProject) {
      if (selectedContext.id === 0) {
        if (selectedApplication) {
          setIsCreatingWave(true);
          getApplicationWavePreview(
            currentProject.uuid,
            selectedApplication.id
          );
        }
      } else {
        if (selectedTable) {
          setIsCreatingWave(true);
          getTableWavePreview(currentProject.uuid, selectedTable.id);
        }
      }
    }
  }

  function addResourceToWavePlanClickHandler(): void {
    async function addResource(projectId: string, resourceId: string) {
      await addResourceToWavePlan(
        projectId,
        resourceId,
        selectedContext.id === 0
      );
      if (selectedContext.id === 0) setSelectedApplication(null);
      else setSelectedTable(null);
      await getWavePlanSummary(projectId);
      await getWavePlanSummaryDetails(projectId);
      setNewWaveSummary(null);
      setIsAddingWave(false);
    }

    if (currentProject && newWaveSummary && newWaveSummary.resourceId) {
      setIsAddingWave(true);
      addResource(currentProject.uuid, newWaveSummary.resourceId);
    }
  }

  function removeAllWavesClickHandler(): void {
    async function removeAllWaves(projectId: string): Promise<void> {
      const response = await analyzeApi.removeAllWaves(projectId);
      const result = response.status;
      await getWavePlanSummary(projectId);
      await getWavePlanSummaryDetails(projectId);
      setIsRemovingAllWaves(false);
      console.log(result);
    }

    if (currentProject) {
      setIsRemovingAllWaves(true);
      removeAllWaves(currentProject.uuid);
    }
  }

  function downloadAllWavesClickHandler(): void {
    if (currentProject) {
      setIsDownloadingAllWaves(true);
      downloadWaves(
        currentProject.uuid,
        `wave-plan-[${currentProject?.name.replace(" ", "_")}].csv`,
        undefined,
        () => {
          setIsDownloadingAllWaves(false);
        }
      );
    }
  }

  function showWavePlanDetailsClickHandler(): void {
    setOpenSlideOver(true);
  }

  useEffect(() => {
    async function getApplications(projectId: string): Promise<void> {
      const result = await analyzeApi.getJobLineageSummary(projectId, 0);
      const applications: LineageResourceSummary[] = await result.json();
      setApplicationsList(applications);
    }

    async function getTables(projectId: string): Promise<void> {
      const result = await analyzeApi.getJobLineageSummary(projectId, 3);
      const tables: LineageResourceSummary[] = await result.json();
      setTablesList(tables);
    }

    if (currentProject) {
      getWavePlanSummary(currentProject.uuid);
      getWavePlanSummaryDetails(currentProject.uuid);
      getApplications(currentProject.uuid);
      getTables(currentProject.uuid);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  useEffect(() => {
    if (newWaveSummary) {
      setWaveStats(
        Object.entries(newWaveSummary).reduce(
          (result: WaveStat[], prop, index) => {
            let stat: WaveStat = {
              id: index,
              name: "",
              value: prop[1],
            };
            if (prop[0] === "totalJobs") {
              stat.name = "Total jobs";
              result.push(stat);
            } else if (prop[0] === "addedJobs") {
              stat.name = "New jobs";
              result.push(stat);
            } else if (prop[0] === "totalTables") {
              stat.name = "Total tables";
              result.push(stat);
            } else if (prop[0] === "addedTables") {
              stat.name = "New tables";
              result.push(stat);
            }
            return result;
          },
          []
        )
      );
    }
  }, [newWaveSummary]);

  return (
    <PageTransition>
      <div id="content" className="p-6 2xl:max-w-7xl 2xl:mx-auto 2xl:px-0">
        <div className="flex items-start space-x-8 pr-2">
          <div className="grow">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-lg font-medium">
                Create waves of jobs to plan your migration
              </h2>
              <div className="w-[16.5rem] ml-auto">
                <RadioSwitch
                  label="Wave context"
                  options={contextOptions}
                  selectedOption={selectedContext}
                  onChange={setSelectedContext}
                />
              </div>
            </div>
            <div className="mb-6 p-5 bg-white rounded-lg shadow">
              <div className="flex items-center">
                <div className="relative grow mr-4">
                  <Combobox
                    value={
                      selectedContext.id === 0
                        ? selectedApplication
                        : selectedTable
                    }
                    onChange={
                      selectedContext.id === 0
                        ? setSelectedApplication
                        : setSelectedTable
                    }
                  >
                    <div className="relative w-full">
                      <Combobox.Input
                        className="w-full rounded-md border border-gray-300 bg-white py-2 pl-4 pr-12 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                        displayValue={(resource: LineageResourceSummary) =>
                          resource?.name
                        }
                        onChange={(event) =>
                          setResourcesListQuery(event.target.value)
                        }
                        placeholder={
                          selectedContext.id === 0
                            ? "Application to migrate"
                            : "Table to migrate"
                        }
                      />
                      <Combobox.Button
                        className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                        aria-label="Toggle ptions"
                      >
                        <span className="pointer-events-none relative inset-y-0 right-0 flex flex-col justify-center items-center px-2 text-gray-500">
                          <FontAwesomeIcon
                            icon={solid("sort-up")}
                            className="w-2.5 h-2.5"
                            aria-hidden={true}
                          />
                          <FontAwesomeIcon
                            icon={solid("sort-down")}
                            className="w-2.5 h-2.5 -mt-1.5"
                            aria-hidden={true}
                          />
                        </span>
                      </Combobox.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      afterLeave={() => setResourcesListQuery("")}
                    >
                      <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {filteredResourcesList.length === 0 &&
                        resourcesListQuery !== "" ? (
                          <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                            Nothing found.
                          </div>
                        ) : (
                          filteredResourcesList.slice(0, 50).map((resource) => {
                            const disabled = !wavePlanSummaryDetails.every(
                              (plan) => plan.objectId !== resource.id
                            );
                            return (
                              <Combobox.Option
                                key={resource.id}
                                className={({ active, selected }) =>
                                  `relative cursor-default select-none flex py-2 pl-4 pr-9 text-sm ${
                                    selected && !active
                                      ? "bg-primary-100 text-primary-700"
                                      : active
                                      ? "bg-primary-50 text-primary-700"
                                      : "bg-white text-gray-900"
                                  }`
                                }
                                value={resource}
                                disabled={disabled}
                              >
                                {({ active, selected }) => (
                                  <>
                                    <span
                                      className={`block truncate mr-3 ${
                                        disabled ? "text-gray-400" : ""
                                      } ${
                                        selected ? "font-medium" : "font-normal"
                                      }`}
                                    >
                                      {resource.name}
                                    </span>
                                    {!disabled && (
                                      <span
                                        className={`whitespace-nowrap ml-auto mr-3 ${
                                          active || selected
                                            ? "text-primary-600"
                                            : "text-gray-500"
                                        }`}
                                      >
                                        {resource.totalJobs} Jobs
                                      </span>
                                    )}
                                    {disabled && (
                                      <span className="ml-auto mr-3 text-gray-300">
                                        In a wave
                                      </span>
                                    )}
                                    {selected && (
                                      <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary-600">
                                        <span className="text-base">
                                          <span className="sr-only">
                                            Selected
                                          </span>
                                          <FontAwesomeIcon
                                            icon={solid("check")}
                                            aria-hidden={true}
                                          />
                                        </span>
                                      </span>
                                    )}
                                  </>
                                )}
                              </Combobox.Option>
                            );
                          })
                        )}
                      </Combobox.Options>
                    </Transition>
                  </Combobox>
                </div>
                <Button
                  label="New wave"
                  variant="primary"
                  icon={fasWater}
                  onClick={isCreatingWave ? undefined : createWaveClickHandler}
                  size="lg"
                  isLoading={isCreatingWave}
                  outline
                />
              </div>
            </div>
            {newWaveSummary && (
              <div className="flex items-center mb-6 rounded-lg divide-x divide-gray-200 bg-white shadow">
                {waveStats.map((item) => {
                  return (
                    <div key={item.id} className="w-full">
                      <div className="flex flex-col items-center justify-center py-6">
                        <div className="text-2xl font-bold">
                          {item.value.toLocaleString()}
                        </div>
                        <div className="text-gray-500">{item.name}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div className="flex flex-col items-center justify-center h-80 p-6 bg-white rounded-lg shadow">
              {!newWaveSummary && wavePlanSummary && (
                <>
                  <div className="w-80 text-center">
                    {wavePlanSummary.totalJobs - wavePlanSummary.addedJobs >
                      0 && (
                      <>
                        <span className="inline-flex p-4 my-3 rounded-lg bg-primary-100">
                          <FontAwesomeIcon
                            icon={regular("water-arrow-up")}
                            size="3x"
                            className="text-primary-600"
                            aria-hidden={true}
                          />
                        </span>
                        <h3 className="font-medium leading-8">
                          {`${(
                            wavePlanSummary.totalJobs -
                            wavePlanSummary.addedJobs
                          ).toLocaleString()} jobs still unassigned`}
                        </h3>
                        <p className="text-sm text-gray-500">
                          Create a new wave for an application or table from the
                          migration menu at the top.
                        </p>
                      </>
                    )}
                    {wavePlanSummary.totalJobs - wavePlanSummary.addedJobs ===
                      0 && (
                      <>
                        <span className="inline-flex p-4 my-3 rounded-lg bg-success-100">
                          <FontAwesomeIcon
                            icon={regular("circle-check")}
                            size="3x"
                            className="text-success-600"
                            aria-hidden={true}
                          />
                        </span>
                        <h3 className="font-medium leading-8">
                          All jobs assigned
                        </h3>
                        <p className="text-sm text-gray-500">
                          Your migration plan is 100% complete.
                        </p>
                      </>
                    )}
                  </div>
                </>
              )}
              {newWaveSummary && (
                <div className="flex flex-col items-center justify-center min-w-full">
                  <div className="flex items-center justify-center w-[4.5rem] h-[4.5rem] p-2 rounded-full bg-secondary-500 text-white">
                    <FontAwesomeIcon
                      icon={
                        newWaveSummary.resourceType === 0
                          ? fasBrowser
                          : fasTable
                      }
                      size="2x"
                      aria-hidden={true}
                    />
                  </div>
                  <div className="mt-2 mb-4 text-center">
                    <div className="leading-8 font-medium">
                      {newWaveSummary.resourceName}
                    </div>
                    <div className="text-sm text-gray-500">
                      {newWaveSummary.resourceType === 0
                        ? "Application"
                        : "Table"}
                    </div>
                  </div>
                  <div
                    className={
                      "grid " +
                      (authUser?.isReviewer() ? "grid-cols-1" : "grid-cols-2") +
                      " gap-x-4"
                    }
                  >
                    <Button
                      label="View dependencies"
                      icon={solid("arrow-up-right")}
                      variant="white"
                      radius="pill"
                      href={`/analyze/wave-lineage/${
                        newWaveSummary.resourceType
                      }/${
                        newWaveSummary.resourceType === 0
                          ? selectedApplication?.id
                          : selectedTable?.id
                      }`}
                      trailingIcon
                      fullWidth
                    />
                    {!authUser?.isReviewer() && (
                      <Button
                        label="Add to wave plan"
                        icon={solid("plus")}
                        variant="white"
                        radius="pill"
                        onClick={
                          isAddingWave
                            ? undefined
                            : addResourceToWavePlanClickHandler
                        }
                        isLoading={isAddingWave}
                        trailingIcon
                        fullWidth
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {!wavePlanSummary && (
            <div className="w-80 animate-pulse">
              <div className="w-64 h-10 mb-6 rounded bg-gray-200"></div>
              <div className="w-16 h-8 mb-2 rounded bg-gray-200"></div>
              <div className="h-2.5 mb-2 rounded-full bg-gray-200"></div>
              <div className="w-40 h-4 mb-8 rounded bg-gray-200"></div>
              <div className="h-9 mb-1 rounded bg-gray-200"></div>
              <div className="h-9 mb-1 rounded bg-gray-200"></div>
              <div className="h-9 mb-6 rounded bg-gray-200"></div>
              <div className="h-11 rounded bg-gray-200"></div>
            </div>
          )}
          {wavePlanSummary && (
            <div className="w-80 pt-1">
              <h2 className="text-2xl font-medium mb-6">Wave plan summary</h2>
              <h3 className="text-4xl text-primary-700 font-bold mb-3">
                {`${Math.round(
                  (wavePlanSummary.addedJobs / wavePlanSummary.totalJobs) * 100
                )}%`}
              </h3>
              <div className="h-2 mb-3 rounded-full bg-gray-200 overflow-hidden">
                <div
                  className={`${
                    wavePlanSummary.addedJobs < 1 ? "opacity-0" : "h-full"
                  } bg-primary-700 ring-2 ring-gray-100 rounded-full`}
                  style={{
                    width: `${Math.round(
                      (wavePlanSummary.addedJobs / wavePlanSummary.totalJobs) *
                        100
                    )}%`,
                  }}
                ></div>
              </div>
              <div className="mb-3 text-sm text-gray-600">
                {`${wavePlanSummary.addedJobs.toLocaleString()} / ${wavePlanSummary.totalJobs.toLocaleString()} Jobs assigned`}
              </div>
              <dl className="mt-2 mb-3 divide-y divide-gray-200">
                <div className="py-3 flex justify-between">
                  <dt className="text-gray-600 font-mdium">Waves</dt>
                  <dd className="font-bold">
                    {wavePlanSummary.totalWaves.toLocaleString()}
                  </dd>
                </div>
                <div className="py-3 flex justify-between ">
                  <dt className="text-gray-600 fontmedium"> New jobs</dt>
                  <dd className="font-bold">
                    {wavePlanSummary.addedJobs.toLocaleString()}
                  </dd>
                </div>
                <div className="py-3 flex justify-between">
                  <dt className="text-gray-600 fon-medium"> New tables</dt>
                  <dd className="font-bold">
                    {wavePlanSummary.addedTables.toLocaleString()}
                  </dd>
                </div>
              </dl>
              <Button
                label="View wave plan"
                icon={fasListOl}
                size="lg"
                onClick={showWavePlanDetailsClickHandler}
                fullWidth
              />
            </div>
          )}
        </div>
        <SlideOver
          title="Wave plan"
          show={openSlideOver}
          onClose={setOpenSlideOver}
          scroll={false}
          size="sm"
        >
          <div className="flex flex-col items-stretch justify-between h-full bg-white border-t border-t-gray-200">
            {currentProject && wavePlanSummaryDetails.length > 0 && (
              <div className="h-full px-2 pt-2 pb-48 overflow-auto">
                {wavePlanSummaryDetails.map((wave, index, waves) => {
                  return (
                    <WaveListItem
                      key={wave.uuid}
                      navigate={navigate}
                      waves={waves}
                      wave={wave}
                      index={index}
                      project={currentProject}
                      downloadWaves={downloadWaves}
                      getWavePlanSummary={getWavePlanSummary}
                      getWavePlanSummaryDetails={getWavePlanSummaryDetails}
                    />
                  );
                })}
                <div
                  className="absolute z-10 bottom-20 inset-x-0 h-12 ml-8 -mb-0.5 bg-gradient-to-t from-white/90"
                  role="presentation"
                  aria-hidden={true}
                ></div>
              </div>
            )}
            <div className="absolute z-10 bottom-0 inset-x-0 flex space-x-4 ml-8 px-6 pt-4 pb-6 bg-white/90 backdrop-blur">
              <Button
                label="Download"
                variant="white"
                icon={solid("folder-arrow-down")}
                onClick={
                  isDownloadingAllWaves
                    ? undefined
                    : downloadAllWavesClickHandler
                }
                isLoading={isDownloadingAllWaves}
                disabled={wavePlanSummaryDetails.length < 1}
                fullWidth
              />
              {!authUser?.isReviewer() && (
                <Button
                  label="Clear"
                  variant="gray"
                  icon={solid("trash-can")}
                  onClick={
                    isRemovingAllWaves ? undefined : removeAllWavesClickHandler
                  }
                  isLoading={isRemovingAllWaves}
                  disabled={wavePlanSummaryDetails.length < 1}
                  soft
                  fullWidth
                />
              )}
            </div>
          </div>
        </SlideOver>
      </div>
    </PageTransition>
  );
}

export default WavePlanner;
