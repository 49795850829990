import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { queryClient } from "./utils/query-client";
import UserContextState from "./contexts/UserContext";
import AuthorizationApi from "./api/AuthorizationApi";
import { getStoredAuthToken } from "./utils/user-storage";
import { authenticateFromOrigin } from "./routes/components/AuthorizationRoute";
import AuthorizationRoutes from "./routes/AuthenticationRoutes";
import DiscoverRoutes from "./routes/DiscoverRoutes";
import AnalyzeRoutes from "./routes/AnalyzeRoutes";
import ConfigureRoutes from "./routes/CofigureRoutes";
import TranslateRoutes from "./routes/TranslateRoutes";
import SupportRoutes from "./routes/SupportRoutes";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClientProvider } from "@tanstack/react-query";
import BaseApi from "./api/BaseApi";
import { isCrawlerCore, isShiftCore } from "./env";
import UploadRoutes from "./routes/UploadRoutes";
import { NotificationsContainer } from "./_ui-kit/Notification";
import { createPortal } from "react-dom";
import AccountBilling from "./pages/configure/AccountBilling";
import RestrictedRoute from "./routes/components/RestrictedRoute";

type SystemCore = "CRAWLER" | "SHIFT";

interface AppError {
  id: string;
  error: string;
  description?: string;
}

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const authApi = new AuthorizationApi((error: string) => {
    queryClient.removeQueries();
    authenticateFromOrigin(navigate, location, error);
  });

  const isLocalhost = !!(
    window.location.hostname === "localhost" ||
    window.location.hostname === "[::1]" ||
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
  );

  useEffect(() => {
    const authToken = getStoredAuthToken();
    if (authToken) {
      authApi.refreshToken(authToken.refreshToken);
      authApi.initRefreshToken();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const authToken = getStoredAuthToken();
    if (
      !authToken &&
      !location.pathname.includes("/sign-in") &&
      !location.pathname.includes("/marketplace-sign-up") &&
      !location.pathname.includes("/user-welcome") &&
      !location.pathname.includes("/forgot-password") &&
      !location.pathname.includes("/setup-password") &&
      !location.pathname.includes("/reset-password") &&
      !location.pathname.includes("/coming-soon")
    ) {
      const message = BaseApi.serverResponseTextUIMessage.INVALID_TOKEN;
      queryClient.removeQueries();
      console.log(location.pathname);
      authenticateFromOrigin(
        navigate,
        location,
        location.pathname === "/" ? undefined : message
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <QueryClientProvider client={queryClient}>
      <UserContextState>
        <Routes>
          {AuthorizationRoutes(authApi)}
          {isCrawlerCore() ? DiscoverRoutes() : null}
          {isShiftCore() ? UploadRoutes() : null}
          {AnalyzeRoutes()}
          {TranslateRoutes()}
          {ConfigureRoutes()}
          {SupportRoutes()}
          {isShiftCore() && (
            <Route
              path="account-billing"
              element={
                <RestrictedRoute>
                  <AccountBilling />
                </RestrictedRoute>
              }
            />
          )}
        </Routes>
      </UserContextState>
      {createPortal(<NotificationsContainer />, document.body)}
      {isLocalhost && (
        <div className="print:hidden">
          <ReactQueryDevtools initialIsOpen={false} />
        </div>
      )}
    </QueryClientProvider>
  );
}

export type { SystemCore, AppError };
export default App;
