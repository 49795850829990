import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { ClientContext } from "../../contexts/ClientContext";
import { Button } from "../../_ui-kit";
import Input from "../../_ui-kit/Input/Input";
import InputGroup from "../../_ui-kit/InputGroup/InputGroup";
import { PageTransition } from "../../_ui-kit/PageTransition";
import { SlideOver } from "../../_ui-kit/SlideOver";
import { Project } from "../../models/Project";
import { ConfigureContext } from "../../contexts/ConfigureContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ConfigurationManager from "../../_ui-kit/ConfigurationManager/ConfigurationManager";
import { User } from "../../models/User";
import { getArrayFromString } from "../../utils/common";
import ProjectsListRow, {
  ProjectsListRowSkeleton,
} from "./components/ProjectsListRow";

type SortDirection = "desc" | "asc";

function ProjectsSetup() {
  const [openSlideOver, setOpenSlideOver] = useState(false);
  const [sliderTitle, setSliderTitle] = useState("");
  const { currentClient } = useContext(ClientContext);
  const { configureApi, projectsList, usersList } =
    useContext(ConfigureContext);
  const [projectsListQuery, setProjectsListQuery] = useState("");
  const [sortDirection, setSortDirection] = useState<SortDirection>("desc");
  const [editProject, setEditProject] = useState<Project>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [assignedUsers, setAssignedUsers] = useState<User[]>([]);
  const filteredProjectsList =
    projectsListQuery === ""
      ? projectsList
      : projectsList?.filter((item) => {
          return item.name
            .toLowerCase()
            .includes(projectsListQuery.toLowerCase());
        });
  const queryClient = useQueryClient();

  const addProjectMutation = useMutation(
    (newProject: any) => {
      return configureApi.addProject(
        newProject.clientId,
        newProject.name,
        newProject.userIds
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users-list"]);
        queryClient.invalidateQueries(["clients-list"]);
        queryClient.invalidateQueries(["clients-list-context"]);
        queryClient.invalidateQueries(["projects-list"]);
        queryClient.invalidateQueries(["projects-list-context"]);
      },
    }
  );

  const updateProjectMutation = useMutation(
    (currentProject: any) => {
      return configureApi.updateProject(
        currentProject.id,
        currentProject.clientId,
        currentProject.name,
        currentProject.userIds
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users-list"]);
        queryClient.invalidateQueries(["clients-list"]);
        queryClient.invalidateQueries(["clients-list-context"]);
        queryClient.invalidateQueries(["projects-list"]);
        queryClient.invalidateQueries(["projects-list-context"]);
      },
    }
  );

  function sortProjects(a: Project, b: Project) {
    if (sortDirection === "desc") {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
    } else {
      if (a.name > b.name) return -1;
      if (a.name < b.name) return 1;
    }
    return 0;
  }

  function sortProjectsClickHandler(): void {
    setSortDirection(sortDirection === "desc" ? "asc" : "desc");
  }

  function addProjectClickHandler(): void {
    setSliderTitle("New project");
    setOpenSlideOver(true);
  }

  function editProjectClickHandler(projectId: string): void {
    if (projectsList) {
      setSliderTitle("Edit project");
      setEditProject(
        projectsList.filter((project) => project.uuid === projectId)[0]
      );
      setOpenSlideOver(true);
    }
  }

  function handleSubmitHandler(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData(e.currentTarget);
    const projectName = formData.get("project-name");
    const projectAssignedUsers = getArrayFromString(
      formData.get("project-assigned-users[selected]") as string
    );

    if (currentClient) {
      if (editProject) {
        updateProjectMutation.mutate(
          {
            id: editProject.uuid,
            clientId: editProject.clientId,
            name: projectName,
            userIds: projectAssignedUsers,
          },
          {
            onSettled: () => {
              closeSlideOver(false);
            },
          }
        );
      } else {
        addProjectMutation.mutate(
          {
            clientId: currentClient.id,
            name: projectName,
            userIds: projectAssignedUsers,
          },
          {
            onSettled: () => {
              closeSlideOver(false);
            },
          }
        );
      }
    }
  }

  function closeSlideOver(close: boolean): void {
    setIsSubmitting(close);
    setOpenSlideOver(close);
    setTimeout(() => setEditProject(undefined), 750);
  }

  return (
    <PageTransition>
      <div id="content" className="px-6 py-9 xl:max-w-5xl xl:mx-auto">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <h2 className="flex items-center text-lg text-gray-900 font-medium">
              Projects
              {!projectsList && (
                <span className="inline-flex px-3 ml-1 text-gray-300">
                  <FontAwesomeIcon
                    icon={solid("spinner")}
                    spin={true}
                    className="w-6 h-6"
                    aria-hidden={true}
                  />
                </span>
              )}
              {projectsList && (
                <span className="rounded-full bg-gray-200 px-3 py-1 ml-3 text-sm text-gray-700 font-bold">
                  {projectsList.length}
                </span>
              )}
            </h2>
            {currentClient && currentClient.isActive && (
              <Button
                label="Add project"
                icon={solid("plus")}
                onClick={addProjectClickHandler}
                disabled={!currentClient}
              />
            )}
          </div>
          <div className="flex items-center space-x-4">
            <div>
              <label htmlFor="searchProjects" className="sr-only">
                Search projects
              </label>
              <div className="flex rounded-md shadow-sm">
                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3.5">
                    <FontAwesomeIcon
                      icon={regular("search")}
                      className="h-3.5 w-3.5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    id="searchProjects"
                    className="block w-full rounded-none rounded-l-md border-gray-300 pl-9 focus:border-primary-500 focus:ring-primary-500 text-sm"
                    placeholder="Search"
                    onChange={(event) =>
                      setProjectsListQuery(event.target.value)
                    }
                    disabled={!projectsList}
                  />
                </div>
                <button
                  type="button"
                  className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                  onClick={sortProjectsClickHandler}
                  disabled={!projectsList}
                >
                  <FontAwesomeIcon
                    icon={
                      sortDirection === "desc"
                        ? solid("arrow-down-short-wide")
                        : solid("arrow-up-short-wide")
                    }
                    className="h-4 w-4 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>Sort</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-col">
          <div className="overflow-x-auto -mx-1">
            <div className="inline-block min-w-full py-2 align-middle px-1">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 text-left text-sm font-medium pr-3 pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-medium"
                      >
                        Created user
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-medium"
                      >
                        Created date
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-medium"
                      >
                        Client
                      </th>
                      <th scope="col" className="relative py-3.5 pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {filteredProjectsList &&
                      filteredProjectsList
                        .sort(sortProjects)
                        .map((project) => (
                          <ProjectsListRow
                            key={project.uuid}
                            project={project}
                            editProjectClickHandler={editProjectClickHandler}
                          />
                        ))}
                    {!projectsList &&
                      [...Array(5)].map((_r, i) => (
                        <ProjectsListRowSkeleton key={`skeleton-${i}`} />
                      ))}
                    {filteredProjectsList &&
                      filteredProjectsList.length < 1 && (
                        <tr>
                          <td
                            className="px-6 py-4 text-sm text-gray-500"
                            colSpan={5}
                          >
                            No projects found.
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <SlideOver
          title={sliderTitle}
          show={openSlideOver}
          onClose={isSubmitting ? () => {} : closeSlideOver}
          scroll={false}
        >
          <div className="h-full border-t border-t-gray-200 bg-white overflow-y-auto">
            <div className="flex flex-col px-6 py-5">
              <form onSubmit={handleSubmitHandler}>
                <div className="flex flex-col space-y-6">
                  <InputGroup
                    label="Name"
                    inputId="project-name"
                    className="text-sm"
                  >
                    <Input
                      id="project-name"
                      name="project-name"
                      type="text"
                      autoComplete="off"
                      required={true}
                      defaultValue={editProject?.name}
                    />
                  </InputGroup>
                  <div>
                    <ConfigurationManager
                      id={"project-assigned-users"}
                      label="Assigned editors and reviewers"
                      description="Search by user name"
                      placeholder={"Name"}
                      searchKey="fullName"
                      listItems={
                        usersList?.filter(
                          (user) => user.isEditor() || user.isReviewer()
                        ) || []
                      }
                      selectedItems={assignedUsers}
                      setSelectedItems={setAssignedUsers}
                      defaultValue={editProject?.userIds || []}
                    />
                  </div>
                </div>
                {currentClient && currentClient.isActive && (
                  <div className="self-start mt-8">
                    <Button
                      type="submit"
                      label={editProject ? "Save changes" : "Add project"}
                      size="lg"
                      icon={editProject ? solid("floppy-disk") : solid("plus")}
                      isLoading={isSubmitting}
                    />
                  </div>
                )}
              </form>
            </div>
          </div>
        </SlideOver>
      </div>
    </PageTransition>
  );
}

export default ProjectsSetup;
