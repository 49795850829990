import {
  Fragment,
  useEffect,
  useRef,
  useState,
  MouseEvent,
  SetStateAction,
  useContext,
} from "react";
import * as ReactDOMServer from "react-dom/server";
import SyntaxHighlighter from "react-syntax-highlighter";
import { googlecode as codeTheme } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import {
  FileProgressList,
  TranslatedSourceAndTarget,
} from "../../../models/ProjectTask";
import { createTwoFilesPatch } from "diff";
import { html } from "diff2html";
import "../../../styles/code-viewer.css";
import {
  CodeQueryParams,
  ViewTypeOption,
  viewTypeOptionIds,
} from "../TranslateResults";
import TasksApi from "../../../api/TasksApi";
import { Button } from "../../../_ui-kit";
import TargetCodeUpdateSlider from "./TargetCodeUpdateSlider";
import { TaskData } from "./TasksList";
import { Project } from "../../../models/Project";
import { AppError } from "../../../App";
import { UserContext } from "../../../contexts/UserContext";
import { ClientContext } from "../../../contexts/ClientContext";
import UnselectableContent from "../../../_ui-kit/UnselectableContent/UnselectableContent";
import { classNames } from "../../../_ui-kit/utils";

interface filterCheckBox {
  [key: string]: boolean;
}

interface errorDetail {
  pageNum: number;
  lineNum: number;
}

interface SourceCodeViewProps {
  currentProject: Project;
  currenTask: TaskData;
  selectedFile: FileProgressList;
  selectedViewType: ViewTypeOption;
  projectId: string;
  objCount: number;
  setObjCount: (param: number) => void;
  fileObjCount: number;
  filterCheckBox: filterCheckBox;
  errorEle: errorDetail;
  setErrorEle: (param: errorDetail) => void;
  defaultParams: CodeQueryParams;
  setPageErrorsList: (value: SetStateAction<AppError[]>) => void;
  clipboard: string[];
  setClipboard: (value: SetStateAction<string[]>) => void;
}

interface StickedSourceCode {
  pageIndex: number;
  objects: TranslatedSourceAndTarget[];
  renderedCode: string;
  lines: number;
  viewType: ViewTypeOption;
}

function SourceCodeView({
  currentProject,
  currenTask,
  selectedFile,
  selectedViewType,
  projectId,
  objCount,
  setObjCount,
  fileObjCount,
  filterCheckBox,
  errorEle,
  setErrorEle,
  defaultParams,
  setPageErrorsList,
  clipboard,
  setClipboard,
}: SourceCodeViewProps) {
  const tasksApi = new TasksApi();
  const queryClient = useQueryClient();
  const condeviewerContainerRef = useRef<HTMLDivElement>(null);
  const { authUser } = useContext(UserContext);
  const { currentClient } = useContext(ClientContext);
  const [lines, setLines] = useState(1);
  const [bottomRef, bottomInView] = useInView();
  const [topRef, topInView] = useInView();
  const [scrollHeight, setScrollHeight] = useState(0);
  const [openSlideOver, setOpenSlideOver] = useState(false);
  const [objectsToEdit, setObjectsToEdit] = useState<
    TranslatedSourceAndTarget[]
  >([]);
  const [queryParams, setQueryParams] = useState<CodeQueryParams>({
    ...defaultParams,
    projectId: projectId,
    taskId: currenTask.uuid,
    fileId: selectedFile.id,
    viewType: selectedViewType,
  });

  const getFetchData = async ({ pageParam = queryParams.pageIndex }) => {
    if (
      queryParams.projectId &&
      queryParams.taskId &&
      queryParams.fileId &&
      queryParams.viewType
    ) {
      const result = await tasksApi.getLibraryTaskFileObjects(
        queryParams.projectId,
        queryParams.taskId,
        queryParams.fileId,
        pageParam,
        queryParams.pageSize
      );
      const fileObjects: TranslatedSourceAndTarget[] = await result.json();

      if (fileObjects.length > 0) setObjCount(objCount + fileObjects.length);

      if (
        queryParams.viewType.id === viewTypeOptionIds.Source ||
        queryParams.viewType.id === viewTypeOptionIds.Target
      ) {
        const stickCode: string = stickCodeForView(fileObjects);

        const result: StickedSourceCode = {
          pageIndex: pageParam,
          objects: fileObjects,
          renderedCode: stickCode,
          lines: lines,
          viewType: selectedViewType,
        };

        return fileObjects.length > 0 ? [result] : [];
      } else if (
        queryParams.viewType?.id === viewTypeOptionIds.SideBySide ||
        queryParams.viewType?.id === viewTypeOptionIds.Combined
      ) {
        let sourceCode = "";
        let translatedCode = "";
        let srclinenumber = 0;
        let tgtlinenumber = 0;
        let totalLineCount = 0;

        fileObjects.forEach((obj: TranslatedSourceAndTarget, idx) => {
          if (idx === 0) {
            srclinenumber = obj.sourceCodeLineStart;
            tgtlinenumber = obj.translatedCodeLineStart;
          }

          if (idx === fileObjects.length - 1) {
            sourceCode += obj.sourceCode;
            if (obj.translatedCode && obj.translatedCode !== "")
              translatedCode += obj.translatedCode;
          } else {
            sourceCode += obj.sourceCode + "\n";
            if (obj.translatedCode && obj.translatedCode !== "")
              translatedCode += obj.translatedCode + "\n";
          }
          totalLineCount += obj.sourceCodeLineCount;
        });

        const computedDiffHtml = getComputedDiffHtml(
          srclinenumber,
          tgtlinenumber,
          sourceCode,
          translatedCode,
          totalLineCount
        );

        const result: StickedSourceCode = {
          pageIndex: pageParam,
          objects: fileObjects,
          renderedCode: computedDiffHtml,
          lines: Math.max(srclinenumber, tgtlinenumber),
          viewType: selectedViewType,
        };

        return fileObjects.length > 0 ? [result] : [];
      }
    }
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    fetchPreviousPage,
    hasPreviousPage,
    isFetchingPreviousPage,
    isFetching,
  } = useInfiniteQuery(["translated-code-view", queryParams], getFetchData, {
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage ? lastPage[0].pageIndex + 1 : 0;
      return nextPage <= Math.ceil(fileObjCount / queryParams.pageSize)
        ? nextPage
        : undefined;
    },
    getPreviousPageParam: (firstPage) => {
      const previousPage = firstPage ? firstPage[0].pageIndex - 1 : 0;
      if (previousPage < 1) return undefined;
      return previousPage < Math.ceil(fileObjCount / queryParams.pageSize)
        ? previousPage
        : undefined;
    },
  });

  function scrollToLine(lineNumber: number) {
    const element = document.getElementById("l1_" + lineNumber);
    if (element) {
      element.scrollIntoView();
      setErrorEle({ pageNum: -1, lineNum: -1 });
    }
  }

  function stickCodeForView(srcData: TranslatedSourceAndTarget[]): string {
    let sourceCode = "";
    let srclinenumber = 0;
    srcData.forEach((sourceObj: TranslatedSourceAndTarget, idx: number) => {
      if (selectedViewType.id === viewTypeOptionIds.Source) {
        if (idx !== srcData.length - 1) {
          sourceCode += sourceObj.sourceCode + "\n";
        } else {
          sourceCode += sourceObj.sourceCode;
        }
        srclinenumber += sourceObj.sourceCodeLineCount;
      }
      if (selectedViewType.id === viewTypeOptionIds.Target) {
        if (sourceObj.translatedCode && sourceObj.translatedCode !== "") {
          sourceCode += sourceObj.translatedCode;
          srclinenumber += sourceObj.translatedCodeLineCount;
        }
      }
    });
    setLines(lines + srclinenumber);
    return sourceCode;
  }

  function getComputedDiffHtml(
    srcLineNumberOffset: number,
    tgtLineNumberOffset: number,
    sourceCode: string,
    translatedCode: string,
    totalLineCount: number
  ): string {
    const diff = createTwoFilesPatch(
      srcLineNumberOffset.toString(),
      tgtLineNumberOffset.toString(),
      sourceCode,
      translatedCode,
      "",
      "",
      { context: totalLineCount }
    );
    const htmlDiff = getDiff(diff);
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(htmlDiff, "text/html");
    const replaceLineNumbersOffset = (element: Element, offset: number) => {
      const lineNumber = +element.innerHTML;
      if (lineNumber > 0) {
        element.innerHTML = (lineNumber + offset).toString();
      }
    };

    if (selectedViewType.id === viewTypeOptionIds.SideBySide) {
      const sides = htmlDoc.querySelectorAll(".d2h-file-side-diff");
      const sourceSide = sides[0];
      const targetSide = sides[1];

      sourceSide
        .querySelectorAll(".d2h-code-side-linenumber")
        .forEach((element) => {
          replaceLineNumbersOffset(element, srcLineNumberOffset);
          element.id = "l1_" + element.innerHTML;
        });
      targetSide
        .querySelectorAll(".d2h-code-side-linenumber")
        .forEach((element) =>
          replaceLineNumbersOffset(element, tgtLineNumberOffset)
        );
    } else if (selectedViewType.id === viewTypeOptionIds.Combined) {
      const sourceLines = htmlDoc.querySelectorAll(".line-num1");
      const targetLines = htmlDoc.querySelectorAll(".line-num2");

      sourceLines.forEach((element) => {
        replaceLineNumbersOffset(element, srcLineNumberOffset);
        element.id = "l1_" + element.innerHTML;
      });
      targetLines.forEach((element) =>
        replaceLineNumbersOffset(element, tgtLineNumberOffset)
      );

      htmlDoc.querySelectorAll(".d2h-del").forEach((element) => {
        if (
          !filterCheckBox.auto &&
          !element.className.includes("opacity-40") &&
          element.parentElement
        ) {
          element.parentElement.className += " opacity-40";
        }
      });

      htmlDoc.querySelectorAll(".d2h-ins").forEach((element) => {
        if (
          !filterCheckBox.auto &&
          !element.className.includes("opacity-40") &&
          element.parentElement
        ) {
          if (element.innerHTML.includes("***EXCEPTION")) {
            if (!filterCheckBox.exception) {
              element.parentElement.className += " opacity-40";
            }

            const lineNumb = element.parentElement.querySelector(
              ".d2h-code-linenumber"
            );
            if (lineNumb) {
              let icon = (
                <FontAwesomeIcon
                  icon={solid("triangle-exclamation")}
                  size="lg"
                />
              );
              lineNumb.innerHTML += `<div class="indicator absolute left-0 text-warning-500">${ReactDOMServer.renderToString(
                icon
              )}</div>`;
            }
          } else if (element.innerHTML.includes("***REVIEW")) {
            if (!filterCheckBox.review) {
              element.parentElement.className += " opacity-40";
            }
          } else {
            if (!element.classList.contains("d2h-code-linenumber"))
              element.parentElement.className += " opacity-40";
          }
        }

        if (
          filterCheckBox.auto &&
          !element.className.includes("opacity-40") &&
          element.parentElement
        ) {
          if (element.innerHTML.includes("***EXCEPTION")) {
            if (!filterCheckBox.exception) {
              element.parentElement.className += " opacity-40";
            }

            const lineNumb = element.parentElement.querySelector(
              ".d2h-code-linenumber"
            );
            if (lineNumb) {
              let icon = (
                <FontAwesomeIcon
                  icon={solid("triangle-exclamation")}
                  size="lg"
                />
              );
              lineNumb.innerHTML += `<div class="indicator absolute left-0 text-warning-500">${ReactDOMServer.renderToString(
                icon
              )}</div>`;
            }
          } else if (element.innerHTML.includes("***REVIEW")) {
            if (!filterCheckBox.review) {
              element.parentElement.className += " opacity-40";
            }
          }
        }
      });
    }

    return htmlDoc.querySelector(".d2h-wrapper")?.outerHTML || "";
  }

  function getDiff(diff: string): string {
    const result = html(diff, {
      drawFileList: false,
      matching: "none",
      outputFormat:
        selectedViewType.id === viewTypeOptionIds.SideBySide
          ? "side-by-side"
          : "line-by-line",
      renderNothingWhenEmpty: false,
    });
    return result;
  }

  function editObjectsClickHandler(
    e: MouseEvent<HTMLButtonElement> | undefined,
    objects: TranslatedSourceAndTarget[]
  ): void {
    setObjectsToEdit(
      objects.map((obj) => {
        return {
          ...obj,
        };
      })
    );
    setOpenSlideOver(true);
  }

  useEffect(() => {
    if (topInView) {
      if (condeviewerContainerRef.current) {
        const div = condeviewerContainerRef.current;
        setScrollHeight(div.scrollHeight);
      }
      fetchPreviousPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topInView]);

  useEffect(() => {
    if (bottomInView) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bottomInView]);

  useEffect(() => {
    if (errorEle && errorEle.pageNum > -1 && errorEle.lineNum > -1) {
      setQueryParams({
        ...queryParams,
        pageIndex: errorEle.pageNum,
      });
      setObjCount(0);
      queryClient.removeQueries(["translated-code-view"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorEle]);

  useEffect(() => {
    setLines(
      selectedViewType.id === viewTypeOptionIds.Combined ||
        selectedViewType.id === viewTypeOptionIds.SideBySide
        ? 0
        : 1
    );
    setQueryParams({
      ...queryParams,
      fileId: selectedFile.id,
      viewType: selectedViewType,
      pageIndex: 1,
    });
    setObjCount(0);
    queryClient.removeQueries(["translated-code-view"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedViewType, selectedFile, filterCheckBox]);

  useEffect(() => {
    if (data && data.pages.length > 0) {
      if (errorEle.lineNum > 0) scrollToLine(errorEle.lineNum);

      let text = data.pages.map((page) => page?.[0]?.renderedCode || "") || [];
      setClipboard(text);
    } else if (condeviewerContainerRef.current && queryParams.pageIndex > 1) {
      const div = condeviewerContainerRef.current;
      if (div.scrollTop === 0) {
        div.scrollTop = div.scrollHeight - scrollHeight;
        setScrollHeight(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div
        ref={condeviewerContainerRef}
        className="relative grow w-full h-96 overflow-x-scroll overflow-y-scroll"
      >
        <div className="relative bg-white">
          {data &&
            (selectedViewType.id === viewTypeOptionIds.Source ||
              selectedViewType.id === viewTypeOptionIds.Target) &&
            data.pages.map(
              (sources: StickedSourceCode[] | undefined, index) => {
                return (
                  <Fragment key={index}>
                    {sources?.map((source) => {
                      return (
                        <div key={lines} className="relative">
                          {authUser?.isAdmin() &&
                            selectedViewType.id ===
                              viewTypeOptionIds.Target && (
                              <div className="sticky top-4 flex justify-center h-8 mb-2">
                                <Button
                                  label="Edit objects"
                                  size="xs"
                                  radius="pill"
                                  icon={solid("code")}
                                  variant="white"
                                  onClick={(e) =>
                                    editObjectsClickHandler(e, source.objects)
                                  }
                                  uppercase
                                />
                              </div>
                            )}
                          <UnselectableContent
                            className={classNames(
                              "codeview",
                              selectedViewType.id === viewTypeOptionIds.Target
                                ? "-mt-10"
                                : "m-0"
                            )}
                            selectable={
                              authUser?.isAdmin() ||
                              currentClient?.clientRestriction?.allowDownload
                            }
                          >
                            <SyntaxHighlighter
                              language="sql"
                              style={codeTheme}
                              showLineNumbers={true}
                              useInlineStyles={true}
                              startingLineNumber={source.lines}
                            >
                              {source.viewType.id ===
                                viewTypeOptionIds.Source ||
                              source.viewType.id === viewTypeOptionIds.Target
                                ? source.renderedCode
                                : ""}
                            </SyntaxHighlighter>
                          </UnselectableContent>
                        </div>
                      );
                    })}
                  </Fragment>
                );
              }
            )}
          {data && hasPreviousPage && (
            <div
              ref={topRef}
              className="absolute z-10 inset-x-0 flex justify-center py-3"
            >
              <Button
                label="Load more"
                icon={solid("arrow-circle-up")}
                size="xs"
                radius="pill"
                variant="white"
                onClick={() => fetchPreviousPage()}
                isLoading={isFetchingPreviousPage}
                uppercase
              />
            </div>
          )}
          {data &&
            (selectedViewType.id === viewTypeOptionIds.SideBySide ||
              selectedViewType.id === viewTypeOptionIds.Combined) &&
            data.pages.map(
              (sources: StickedSourceCode[] | undefined, index) => {
                return (
                  <Fragment key={index}>
                    {sources?.map((source, index) => {
                      return (
                        <UnselectableContent
                          key={index}
                          className="codeview"
                          selectable={
                            authUser?.isAdmin() ||
                            currentClient?.clientRestriction?.allowDownload
                          }
                        >
                          <div
                            id={`code-diff-${index}`}
                            dangerouslySetInnerHTML={{
                              __html:
                                source.viewType.id ===
                                  viewTypeOptionIds.SideBySide ||
                                source.viewType.id ===
                                  viewTypeOptionIds.Combined
                                  ? source.renderedCode
                                  : "",
                            }}
                          />
                        </UnselectableContent>
                      );
                    })}
                  </Fragment>
                );
              }
            )}
          {data && (
            <button
              ref={bottomRef}
              className={`relative w-full bg-white border-y -mt-px py-4 px-11 text-gray-500 ${
                hasNextPage && !isFetchingNextPage
                  ? "hover:bg-gray-50 hover:text-gray-700"
                  : ""
              }`}
              disabled={!hasNextPage || isFetchingNextPage}
              onClick={() => fetchNextPage()}
            >
              {isFetchingNextPage ? (
                <span className="text-gray-400">
                  <FontAwesomeIcon
                    icon={solid("spinner")}
                    size="lg"
                    aria-hidden={true}
                    spin
                  />
                  <span className="sr-only">Loading more objects...</span>
                </span>
              ) : hasNextPage ? (
                <span className="uppercase font-medium text-sm">Load more</span>
              ) : (
                <span className="text-sm">No more objects</span>
              )}
            </button>
          )}
        </div>
      </div>
      {isFetching && (
        <div className="absolute w-full flex flex-col items-center justify-start !m-0 bg-white/70 text-primary-600 rounded-b-md">
          <div className="animate-pulse flex w-full h-1 -mt-px bg-gradient-to-r from-primary-600 to-primary-500"></div>
        </div>
      )}
      {authUser?.isAdmin() &&
        data &&
        selectedViewType.id === viewTypeOptionIds.Target && (
          <TargetCodeUpdateSlider
            currentProject={currentProject}
            currenTask={currenTask}
            selectedFile={selectedFile}
            setObjectsToEdit={setObjectsToEdit}
            objectsToEdit={objectsToEdit}
            openSlideOver={openSlideOver}
            setOpenSlideOver={setOpenSlideOver}
            setPageErrorsList={setPageErrorsList}
          />
        )}
    </>
  );
}

export default SourceCodeView;
