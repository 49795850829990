import { useQuery } from "@tanstack/react-query";
import { createContext, useContext } from "react";
import ConfigureApi from "../api/ConfigureApi";
import { Client, ClientData, ClientPricingPlan } from "../models/Client";
import { Project, ProjectData } from "../models/Project";
import { User } from "../models/User";
import SettingsApi from "../api/SettingsApi";
import { UserContext } from "./UserContext";

interface ConfigureContextProviderProps {
  children: React.ReactNode;
}

interface ConfigureContextProps {
  usersList?: User[];
  clientsList?: Client[];
  projectsList?: Project[];
  pricingPlansList?: ClientPricingPlan[];
  configureApi: ConfigureApi;
  settingsApi: SettingsApi;
}

const ConfigureContext = createContext<ConfigureContextProps>(
  {} as ConfigureContextProps
);

function ConfigureContextProvider({ children }: ConfigureContextProviderProps) {
  const { authUser } = useContext(UserContext);
  const settingsApi = new SettingsApi();
  const configureApi = new ConfigureApi();

  const { data: usersList } = useQuery(
    ["users-list"],
    async () => {
      const users = await configureApi.getUsers();
      return users;
    },
    {
      enabled: (authUser?.isAdmin() || authUser?.isOwner()) ?? false,
      onError: (ex) => {
        console.error(ex);
      },
    }
  );

  const { data: clientsList } = useQuery(
    ["clients-list"],
    async () => {
      const result = await configureApi.getClients();
      const clients: ClientData[] = await result.json();
      return clients.map((client) => new Client(client));
    },
    {
      enabled: authUser?.isAdmin() ?? false,
      onError: (ex) => {
        console.error(ex);
      },
    }
  );

  const { data: projectsList } = useQuery(
    ["projects-list"],
    async () => {
      const result = await configureApi.getProjects();
      const projects: ProjectData[] = await result.json();
      return projects.map((project) => new Project(project));
    },
    {
      enabled: (authUser?.isAdmin() || authUser?.isOwner()) ?? false,
      onError: (ex) => {
        console.error(ex);
      },
    }
  );

  const { data: pricingPlansList } = useQuery(
    ["pricing-plans-list"],
    async () => {
      const result = await settingsApi.getPricingPlans();
      const pricingPlans: ClientPricingPlan[] = await result.json();
      return pricingPlans;
    },
    {
      enabled: authUser?.isAdmin() ?? false,
      onError: (ex) => {
        console.error(ex);
      },
    }
  );

  return (
    <ConfigureContext.Provider
      value={{
        usersList,
        clientsList,
        projectsList,
        pricingPlansList,
        configureApi,
        settingsApi,
      }}
    >
      {children}
    </ConfigureContext.Provider>
  );
}

export { ConfigureContextProvider, ConfigureContext };
