import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import TasksApi from "../../api/TasksApi";
import { ProjectContext } from "../../contexts/ProjectContext";
import { Button } from "../../_ui-kit";
import { MasterPage } from "../../_ui-kit/MasterPage";
import { PageLoaderSpinner } from "../../_ui-kit/PageLoderSpinner";
import { PagePrintFormat } from "../../_ui-kit/PagePrintFormat";
import { PageTransition } from "../../_ui-kit/PageTransition";
import StatementPanel from "./components/StatementPanel";
import {
  TaskData,
  TaskErrors,
  TaskStatus,
  TaskTranslateErrors,
} from "./components/TasksList";
import TaskObjectSummaryItem from "./components/TaskObjectSummaryItem";
import ReportHeader from "./components/ReportHeader";
import { ClientContext } from "../../contexts/ClientContext";
import Modal, { ModalContent } from "../../_ui-kit/Modal/Modal";
import _ from "lodash";
import { UserContext } from "../../contexts/UserContext";

interface TaskObjectSummary {
  totalObjects: number;
  qaReadyObjects: number;
  inLineExceptionObjects: number;
  grammarGapsObjects: number;
  totalObjectsPassed?: number;
  qaErrorObjects?: number;
  missingObjectObjects?: number;
  translationTime?: number;
  testTime?: number;
}

type TranslateError =
  | "analyzeExceptionDetailsList"
  | "translationErrorList"
  | "missingObjectErrorList"
  | "testingErrorList";

function TranslateReportSummary() {
  const tasksApi = new TasksApi();
  const queryClient = useQueryClient();
  const location = useLocation();
  const locationState = location.state;
  const initial: TaskData | undefined = locationState?.task;
  const isTranslate = location.pathname.includes("/translate");
  const { authUser } = useContext(UserContext);
  const { currentClient } = useContext(ClientContext);
  const { currentProject } = useContext(ProjectContext);
  const { taskId } = useParams();
  const [refetchInterval, setRefetchInterval] = useState(1000);
  const [isDownloadingTranslatedCode, setIsDownloadingTranslatedCode] =
    useState(false);
  const [showTranslateErrorModal, setShowTranslateErrorModal] = useState(false);
  const [currentTranslateError, setCurrentTranslateError] =
    useState<TranslateError>();
  const [translateErrorModalTitle, setTranslateErrorModalTitle] = useState("");

  const { data: currentTask, isLoading: isLoadingCurrentTask } = useQuery(
    [
      `task${!currentProject || !taskId ? "-invalid" : ""}`,
      {
        projectId: currentProject?.uuid,
        taskId,
      },
    ],
    async () => {
      const projectId = currentProject?.uuid || "";
      const id = taskId || "";
      const response = await tasksApi.getTask(projectId, id);
      const task: TaskData = await response.json();
      return task;
    },
    {
      initialData: initial,
      staleTime: 0,
      refetchInterval: refetchInterval,
      enabled: !!currentProject && !!taskId,
      onSuccess: (data) => {
        queryClient.removeQueries(["task-invalid"]);
        if (
          (data?.tested && data?.taskStatus === TaskStatus.FINISHED) ||
          data?.taskStatus === TaskStatus.ERROR
        ) {
          setRefetchInterval(0);
          queryClient.invalidateQueries([
            "task-object-summary",
            {
              projectId: currentProject?.uuid,
              taskId,
            },
          ]);
        }
      },
      onError: (ex) => {
        console.log(ex);
        setRefetchInterval(0);
      },
    }
  );

  const { data: taskErrors } = useQuery(
    [
      `task-errors${!currentProject || !currentTask ? "-invalid" : ""}`,
      {
        projectId: currentProject?.uuid,
        taskId: currentTask?.uuid,
      },
    ],
    async () => {
      const projectId = currentProject?.uuid || "";
      const taskId = currentTask?.uuid || "";
      const response = await tasksApi.getTaskErrorDetails(projectId, taskId);
      const errors: TaskErrors[] = await response.json();
      return errors;
    },
    {
      staleTime: 0,
      refetchInterval: refetchInterval,
      enabled:
        !!currentProject && !!currentTask && currentTask.sourceType === "SQL",
      onSuccess: () => {
        queryClient.removeQueries(["task-errors-invalid"]);
      },
      onError: (ex) => {
        console.log(ex);
      },
    }
  );

  const { data: taskTranslateErrors } = useQuery(
    [
      `task-translate-errors${
        !currentProject || !currentTask ? "-invalid" : ""
      }`,
      {
        projectId: currentProject?.uuid,
        taskId: currentTask?.uuid,
      },
    ],
    async () => {
      const projectId = currentProject?.uuid || "";
      const taskId = currentTask?.uuid || "";
      const response = await tasksApi.getTaskTranslateErrorDetails(
        projectId,
        taskId
      );
      const errors: TaskTranslateErrors = await response.json();
      return errors;
    },
    {
      staleTime: 0,
      refetchInterval: refetchInterval,
      enabled:
        !!currentProject && !!currentTask && currentTask.sourceType === "SQL",
      onSuccess: () => {
        queryClient.removeQueries(["task-translate-errors-invalid"]);
      },
      onError: (ex) => {
        console.log(ex);
      },
    }
  );

  const {
    data: currentTaskObjectSummary,
    isLoading: isLoadingCurrentTaskObjectSummary,
  } = useQuery(
    [
      `task-object-summary${!currentProject || !taskId ? "-invalid" : ""}`,
      {
        projectId: currentProject?.uuid,
        taskId,
      },
    ],
    async () => {
      const projectId = currentProject?.uuid || "";
      const id = taskId || "";
      const response = await tasksApi.getTaskObjectSummary(projectId, id);
      const summary: TaskObjectSummary = await response.json();
      return summary;
    },
    {
      enabled:
        !!currentProject && !!currentTask && currentTask.sourceType === "SQL",
      onSuccess: () => {
        queryClient.removeQueries(["task-object-summary-invalid"]);
      },
      onError: (ex) => {
        console.log(ex);
      },
    }
  );

  function printBtnClickHandler(): void {
    window.print();
  }

  function downloadTranslatedCodeClickHandler(): void {
    async function downloadFiles(
      projectId: string,
      taskId: string,
      sourcePlatformName: string,
      targetPlatformName: string
    ) {
      const response = await tasksApi.downloadTranslatedCode(projectId, taskId);
      const result = await response.blob();
      let url = URL.createObjectURL(result);
      let anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `Translated code (${sourcePlatformName} to ${targetPlatformName}).zip`;
      anchor.style.display = "none";
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      URL.revokeObjectURL(url);
      setIsDownloadingTranslatedCode(false);
    }

    if (currentProject && currentTask) {
      setIsDownloadingTranslatedCode(true);
      downloadFiles(
        currentProject.uuid,
        currentTask.uuid,
        currentTask.sourcePlatformName,
        currentTask.targetPlatformName
      );
    }
  }

  function closeTaskErrorsModal(close: boolean): void {
    setShowTranslateErrorModal(false);

    _.delay(() => {
      setTranslateErrorModalTitle("");
      setCurrentTranslateError(undefined);
    }, 200);
  }

  function showTaskErrorModal(errorDetail: TranslateError): void {
    setCurrentTranslateError(errorDetail);
  }

  useEffect(() => {
    if (currentTranslateError) {
      setShowTranslateErrorModal(true);
      switch (currentTranslateError) {
        case "analyzeExceptionDetailsList":
          setTranslateErrorModalTitle("Files with inline-exceptions");
          break;
        case "testingErrorList":
          setTranslateErrorModalTitle("Files with testing errors");
          break;
        case "missingObjectErrorList":
          setShowTranslateErrorModal(true);
          setTranslateErrorModalTitle("Files with missing objects");
          break;
        case "translationErrorList":
          setShowTranslateErrorModal(true);
          setTranslateErrorModalTitle("Files with grammar gap.");
          break;
        default:
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTranslateError]);

  return (
    <MasterPage heading="Summary" useBackButton>
      {currentTask &&
        (currentTaskObjectSummary || currentTask.sourceType === "ETL") && (
          <PagePrintFormat blockClassName="mx-6">
            <PageTransition>
              <div
                id="content"
                className="px-6 py-10 xl:max-w-5xl xl:mx-auto print:max-w-none print:mx-0"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-5">
                    <h2 className="flex items-center space-x-3 text-lg font-medium text-gray-900">
                      <span>Translation report</span>
                      <span className="bg-gray-200 rounded px-2 py-1 uppercase text-xs font-medium text-gray-600 print:bg-gray-100">
                        {currentTask.sourceType}
                      </span>
                    </h2>
                    <div className="print:hidden">
                      <Button
                        label="Print"
                        icon={solid("print")}
                        variant="white"
                        onClick={printBtnClickHandler}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-7 flex flex-col p-5 bg-white shadow ring-1 ring-black ring-opacity-5 rounded-lg overflow-hidden print:p-0 print:ring-0 print:shadow-none print:rounded-none">
                  <div className="w-full max-w-2xl my-14 mx-auto print:max-w-none print:my-0">
                    <div className="flex justify-center space-x-4 mb-10 -mx-16 print:hidden">
                      {currentTask.taskStatus === TaskStatus.TESTING && (
                        <div className="flex flex-col items-center justify-center w-1/3 h-full px-8 py-6 bg-gray-100 border border-gray-200 rounded-lg">
                          <FontAwesomeIcon
                            icon={solid("flask-vial")}
                            className="text-primary-600"
                            size="xl"
                          />
                          <span className="mt-2.5 font-medium text-sm">
                            Testing in progress
                          </span>
                          <div className="progress-bar !h-1.5 mt-2.5 print:hidden">
                            <div className="progress-bar-value"></div>
                          </div>
                        </div>
                      )}
                      {currentTask.taskStatus === TaskStatus.ERROR &&
                        !currentTask.tested && (
                          <div className="flex flex-col items-center justify-center w-1/3 h-full px-3 py-7 bg-danger-100 border border-danger-200 rounded-lg">
                            <FontAwesomeIcon
                              icon={solid("hexagon-xmark")}
                              className="text-danger-500"
                              size="2xl"
                            />
                            <span className="mt-2 mb-0.5 font-medium text-danger-700 text-sm">
                              Test failed
                            </span>
                          </div>
                        )}
                      {currentTask.taskStatus === TaskStatus.FINISHED &&
                        (currentTask.tested || isTranslate) && (
                          <button
                            className="flex flex-col items-center justify-center w-1/3 border focus:outline-none focus:ring-2 focus:ring-offset-2 select-none bg-white border-gray-300 hover:bg-gray-50 focus:ring-primary-500 shadow-sm p-8 text-sm rounded-lg disabled:pointer-events-none disabled:opacity-60"
                            onClick={
                              isDownloadingTranslatedCode || !currentTask.slaMet
                                ? undefined
                                : downloadTranslatedCodeClickHandler
                            }
                            disabled={
                              isDownloadingTranslatedCode ||
                              (!currentClient?.clientRestriction
                                ?.allowDownload &&
                                !authUser?.isAdmin())
                            }
                          >
                            <FontAwesomeIcon
                              icon={
                                isDownloadingTranslatedCode
                                  ? solid("spinner")
                                  : !currentClient?.clientRestriction
                                      ?.allowDownload && !authUser?.isAdmin()
                                  ? solid("ban")
                                  : solid("cloud-arrow-down")
                              }
                              className="text-primary-600 mb-3"
                              size="2xl"
                              spin={isDownloadingTranslatedCode}
                            />
                            <span className="leading-none font-medium text-center">
                              {`${
                                authUser?.isAdmin() ||
                                currentClient?.clientRestriction?.allowDownload
                                  ? "Download translated code"
                                  : "Code download not allowed"
                              }`}
                            </span>
                          </button>
                        )}
                      {currentTask.sourceType === "SQL" && (
                        <Link
                          to={`/translate/code-results/${currentTask.uuid}`}
                          className="flex flex-col items-center justify-center w-1/3 border focus:outline-none focus:ring-2 focus:ring-offset-2 select-none bg-white border-gray-300 hover:bg-gray-50 focus:ring-primary-500 shadow-sm p-8 text-sm rounded-lg"
                        >
                          <FontAwesomeIcon
                            icon={solid("rectangle-code")}
                            className="text-primary-600 mb-3"
                            size="2xl"
                          />
                          <span className="leading-none font-medium text-center">
                            View translated code
                          </span>
                        </Link>
                      )}
                      <Link
                        to={`/analyze/report-results/${currentTask.uuid}`}
                        className="flex flex-col items-center justify-center w-1/3 border focus:outline-none focus:ring-2 focus:ring-offset-2 select-none bg-white border-gray-300 hover:bg-gray-50 focus:ring-primary-500 shadow-sm p-8 text-sm rounded-lg"
                      >
                        <FontAwesomeIcon
                          icon={solid("chart-bar")}
                          className="text-primary-600 mb-3"
                          size="2xl"
                        />
                        <span className="leading-none font-medium text-center">
                          View analyzer report
                        </span>
                      </Link>
                    </div>
                    <ReportHeader
                      isTranslate={isTranslate}
                      task={currentTask}
                      taskErrors={taskErrors}
                      translateObjectSummary={currentTaskObjectSummary}
                    />
                    {currentTaskObjectSummary &&
                      currentTask &&
                      currentTask.sourceType === "SQL" && (
                        <>
                          <dl className="divide-y divide-gray-200 -mt-4 mb-4">
                            <TaskObjectSummaryItem
                              title="Total objects received"
                              subTitle="Total code objects in the library"
                              value={currentTaskObjectSummary.totalObjects}
                            />
                            <TaskObjectSummaryItem
                              title="Objects with source grammar gaps"
                              subTitle="Objects that were not translated because of grammar gaps."
                              value={
                                currentTaskObjectSummary.grammarGapsObjects
                              }
                              onClick={() =>
                                showTaskErrorModal("translationErrorList")
                              }
                              moreDetails={authUser?.isAdmin()}
                              error
                            />
                            <TaskObjectSummaryItem
                              title="Number of in-line exceptions"
                              subTitle="Exceptions found in objects that were translated but need reviewing."
                              value={
                                currentTaskObjectSummary.inLineExceptionObjects
                              }
                              onClick={() =>
                                showTaskErrorModal(
                                  "analyzeExceptionDetailsList"
                                )
                              }
                              moreDetails
                              warning
                            />
                            <div className="!border-t-2 !border-gray-300">
                              <TaskObjectSummaryItem
                                title="Total objects ready for testing"
                                subTitle="Translated objects that will go through testing."
                                value={currentTaskObjectSummary.qaReadyObjects}
                              />
                            </div>
                          </dl>
                          <StatementPanel
                            title={
                              <span className="font-medium text-danger-600">
                                Testing errors
                              </span>
                            }
                          >
                            <dl className="divide-y divide-gray-200 -mb-4">
                              <TaskObjectSummaryItem
                                title="Translated objects with testing errors"
                                subTitle="Translated objects that failed testing."
                                value={
                                  !currentTask.tested &&
                                  currentTask.taskStatus === TaskStatus.ERROR
                                    ? -1
                                    : currentTaskObjectSummary.qaErrorObjects
                                }
                                onClick={() =>
                                  showTaskErrorModal("testingErrorList")
                                }
                                moreDetails
                                error
                              />
                              <TaskObjectSummaryItem
                                title="Identified missing objects"
                                subTitle="Translated objects that may have passed or failed testing with missing objects."
                                value={
                                  !currentTask.tested &&
                                  currentTask.taskStatus === TaskStatus.ERROR
                                    ? -1
                                    : currentTaskObjectSummary.missingObjectObjects
                                }
                                onClick={() =>
                                  showTaskErrorModal("missingObjectErrorList")
                                }
                                moreDetails
                                warning
                              />
                              <div className="!border-t-2 !border-gray-300">
                                <TaskObjectSummaryItem
                                  title="Total objects passed"
                                  subTitle="Translated objects that were successfully tested."
                                  value={
                                    !currentTask.tested &&
                                    currentTask.taskStatus === TaskStatus.ERROR
                                      ? -1
                                      : currentTaskObjectSummary.totalObjectsPassed
                                  }
                                />
                              </div>
                            </dl>
                          </StatementPanel>
                        </>
                      )}
                    {currentTask &&
                      currentTask.sourceType === "ETL" &&
                      [currentTask].map((task) => {
                        const totalJobs = task.size;
                        const jobsWithErrors = task.errorCount;
                        const jobsReadyForDeployment = task.processed || 0;

                        return (
                          <dl
                            key={`translation-summary-${currentTask.uuid}`}
                            className="divide-y divide-gray-200 -mt-4"
                          >
                            <TaskObjectSummaryItem
                              title="Total jobs received"
                              subTitle="Total jobs in the library"
                              value={totalJobs}
                            />

                            <TaskObjectSummaryItem
                              title="Jobs with errors"
                              subTitle="Exceptions found in objects that were translated but need reviewing."
                              value={jobsWithErrors}
                              error
                            />
                            <div className="!border-t-2 !border-gray-300">
                              <TaskObjectSummaryItem
                                title="Jobs ready for deployment"
                                subTitle="Translated jobs that are ready for deployment."
                                value={jobsReadyForDeployment}
                              />
                            </div>
                          </dl>
                        );
                      })}
                  </div>
                </div>
              </div>
            </PageTransition>
          </PagePrintFormat>
        )}
      {taskTranslateErrors && (
        <Modal
          title={translateErrorModalTitle}
          size="2xl"
          show={showTranslateErrorModal}
          onClose={closeTaskErrorsModal}
        >
          {currentTranslateError && (
            <ModalContent>
              {[taskTranslateErrors].map((errors) => {
                const filesErrors = errors[currentTranslateError].reduce(
                  (error: any, current: any) => {
                    error[current.fileName] = error[current.fileName] || [];
                    error[current.fileName].push({
                      errorContent: current.errorContent,
                      errorType: current.errorType,
                      count: current.count,
                    });
                    return error;
                  },
                  {}
                );

                return (
                  <ul
                    key="taskTranslateErrors"
                    className="relative border border-gray-200 rounded-md divide-y"
                  >
                    {Object.keys(filesErrors).map((file) => {
                      return (
                        <li
                          key={file}
                          className="group flex space-x-4 px-5 py-3 first:rounded-t-md last:rounded-b-md border-gray-200 hover:bg-gray-50"
                        >
                          <FontAwesomeIcon
                            icon={regular("file-circle-exclamation")}
                            size="2x"
                            className="mt-0.5 text-gray-400 group-hover:text-gray-600"
                          />
                          <div className="flex flex-col grow space-y-1 overflow-hidden">
                            <div
                              className="py-1.5 truncate text-gray-700 group-hover:text-gray-900 font-medium"
                              title={file}
                            >
                              {file}
                            </div>
                            <div className="text-sm">
                              <ol className="space-y-5 pt-1 font-mono">
                                {filesErrors[file].map(
                                  (error: any, index: number) => {
                                    return (
                                      <li
                                        key={`error-${index}`}
                                        className="flex space-x-6 items-start text-xs text-gray-500 group-hover:text-gray-900"
                                      >
                                        <div className="grow">
                                          {error.errorContent}
                                        </div>
                                        <div className="text-gray-700 group-hover:text-gray-900 font-sans font-medium uppercase whitespace-nowrap">{`${
                                          error.count
                                        } ${
                                          error.count > 1
                                            ? "ocurrences"
                                            : "ocurrence"
                                        }`}</div>
                                      </li>
                                    );
                                  }
                                )}
                              </ol>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                );
              })}
            </ModalContent>
          )}
        </Modal>
      )}
      {((isLoadingCurrentTask && !currentTask) ||
        (isLoadingCurrentTaskObjectSummary &&
          !currentTaskObjectSummary &&
          currentTask?.sourceType === "SQL")) && <PageLoaderSpinner />}
    </MasterPage>
  );
}

export type { TaskObjectSummary };
export default TranslateReportSummary;
