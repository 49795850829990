import { useQuery } from "@tanstack/react-query";
import { createContext } from "react";
import SettingsApi from "../api/SettingsApi";

interface SettingsContextProps {
  environmentSettings?: EnvironmentSetting[];
}

interface SettingsContextStateProps {
  children: React.ReactNode;
}

interface EnvironmentSetting {
  environment_property: string;
  property_value: string;
}

export const SettingsContext = createContext<SettingsContextProps>(
  {} as SettingsContextProps
);

function SettingsContextState({ children }: SettingsContextStateProps) {
  const settingsApi = new SettingsApi();

  const { data: environmentSettings } = useQuery(
    ["environment-settings"],
    async () => {
      const result = await settingsApi.getEnvironmentSettings();
      const settings: EnvironmentSetting[] = await result.json();
      return settings;
    },
    {
      onError: (ex) => {
        console.error(ex);
      },
    }
  );

  return (
    <SettingsContext.Provider
      value={{
        environmentSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export default SettingsContextState;
