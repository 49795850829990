import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Button } from "../../../_ui-kit/Button";
import Modal, { ModalContent, ModalFooter } from "../../../_ui-kit/Modal/Modal";
import { FormEvent, useContext, useEffect, useState } from "react";
import { TaskData, TaskStatus } from "../../translate/components/TasksList";
import TasksApi from "../../../api/TasksApi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AppError } from "../../../App";
import { Project } from "../../../models/Project";
import { useNavigate } from "react-router-dom";
import { Notification, showNotification } from "../../../_ui-kit/Notification";
import { Client } from "../../../models/Client";
import { User } from "../../../models/User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SettingsContext } from "../../../contexts/SettingsContext";

interface TranslateTaskButtonModalProps {
  authUser: User;
  currentClient: Client;
  currentProject: Project;
  currentTask: TaskData;
}

interface TranslationConfirmation {
  taskId: string;
  totalCost: number;
  translationCost: {
    objectCostDetailList: TranslationCostDetails[];
  };
}

interface TranslationCostDetails {
  objectType: string;
  objectCount: number;
  objectPriceInPlan: number;
  objectCost: number;
}

export function TranslateTaskButtonModal({
  authUser,
  currentClient,
  currentProject,
  currentTask,
}: TranslateTaskButtonModalProps) {
  const tasksApi = new TasksApi();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { environmentSettings } = useContext(SettingsContext);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmTranslation, setConfirmTranslation] = useState(false);
  const [pageErrorsList, setPageErrorsList] = useState<AppError[]>([]);
  const [isSqlReport, setIsSqlReport] = useState<boolean>();

  const { data: translateConfirmationDetails } = useQuery(
    [
      `translate-confirmation${
        !currentProject || !currentTask ? "-invalid" : ""
      }`,
      {
        projectId: currentProject?.uuid,
        taskId: currentTask?.uuid,
      },
    ],
    async () => {
      const projectId = currentProject?.uuid || "";
      const taskId = currentTask?.uuid || "";
      const response = isSqlReport
        ? await tasksApi.getTranslateConfirmation(projectId, taskId)
        : await tasksApi.getTranslateEtlConfirmation(projectId, taskId);
      const pricing: TranslationConfirmation = await response.json();
      return pricing;
    },
    {
      enabled:
        !!currentProject &&
        !!currentTask &&
        isSqlReport !== undefined &&
        currentTask.analyzed &&
        !currentTask.translated,
      onSuccess: () => {
        queryClient.removeQueries(["translate-confirmation-invalid"]);
      },
      onError: (ex) => {
        console.log(ex);
      },
    }
  );

  function openConfirmationModal(): void {
    setShowConfirmationModal(true);
  }

  function closeConfirmationModal(close: boolean): void {
    setShowConfirmationModal(close);
    setConfirmTranslation(false);
  }

  function translateSubmitHandler(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();

    if (confirmTranslation) {
      const translateLibrary = async (projectId: string, task: TaskData) => {
        try {
          if (task.fileProgressList) {
            const response = isSqlReport
              ? await tasksApi.translateTask(projectId, task.uuid)
              : await tasksApi.translateEtlTask(projectId, task.uuid);

            if (!response.ok) {
              console.log(response.status, response.statusText);
              setIsSubmitting(false);
            } else {
              queryClient.removeQueries([
                "task",
                {
                  projectId,
                  taskId: task.uuid,
                },
              ]);
              navigate(`/translate/progress/${currentTask?.uuid}`);
            }
          }
        } catch (ex: any) {
          setIsSubmitting(false);
          closeConfirmationModal(true);
          setPageErrorsList((prev) => [
            ...prev,
            {
              id: Math.random().toString(),
              error: "Error translating library",
              description: ex,
            },
          ]);
        }
      };

      if (currentProject && currentTask) {
        setIsSubmitting(true);
        translateLibrary(currentProject.uuid, currentTask);
      }
    } else {
      setConfirmTranslation(true);
    }
  }

  useEffect(() => {
    if (currentTask) {
      setIsSqlReport(currentTask.sourceType === "SQL");
    }
  }, [currentTask]);

  if (
    environmentSettings?.find(
      (setting) => setting.environment_property === "enable_etl_translate"
    )?.property_value === "disable" &&
    currentTask.sourceType === "ETL"
  )
    return null;

  return (
    <>
      {currentTask.analyzed &&
        currentTask.analyzeSlaMet &&
        !currentTask.translated &&
        currentTask.taskStatus === TaskStatus.FINISHED &&
        !authUser.isReviewer() &&
        currentClient &&
        currentClient.isActive && (
          <>
            <Button
              label="Translate"
              icon={regular("plus-square")}
              onClick={openConfirmationModal}
              trailingIcon
            />
            <Modal
              title="Translation pricing confirmation"
              size="2xl"
              show={showConfirmationModal}
              onClose={
                isSubmitting ? () => {} : () => closeConfirmationModal(false)
              }
            >
              <form onSubmit={translateSubmitHandler}>
                <ModalContent>
                  {translateConfirmationDetails && (
                    <>
                      {!confirmTranslation && (
                        <>
                          <table className="w-full divide-y-2 divide-gray-300 mb-6">
                            <thead>
                              <tr className="text-sm uppercase">
                                <th className="py-2 pr-4 text-left font-medium whitespace-nowrap">
                                  Objects
                                </th>
                                <th className="py-2 px-4 text-left font-medium whitespace-nowrap">
                                  Quantity
                                </th>
                                <th className="py-2 px-4 text-left font-medium whitespace-nowrap">
                                  Cost
                                </th>
                                <th className="py-2 px-4 text-left font-medium whitespace-nowrap">
                                  Price
                                </th>
                                <th className="py-2 pl-4 text-left font-medium whitespace-nowrap">
                                  Billed
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {translateConfirmationDetails.translationCost.objectCostDetailList.map(
                                (costDetails) => {
                                  return (
                                    <tr key={costDetails.objectType}>
                                      <td className="py-2 pr-4 whitespace-nowrap">
                                        <span className="text-sm font-medium">
                                          {costDetails.objectType}
                                        </span>
                                      </td>
                                      <td className="py-2 px-4 whitespace-nowrap">
                                        <span className="text-gray-500">
                                          {costDetails.objectCount.toLocaleString()}
                                        </span>
                                      </td>
                                      <td className="py-2 px-4 whitespace-nowrap">
                                        <span className="text-gray-500">
                                          {`$${costDetails.objectPriceInPlan.toLocaleString()}`}
                                        </span>
                                      </td>
                                      <td className="py-2 px-4 text-gray-500 whitespace-nowrap">
                                        {`$${(
                                          costDetails.objectCount *
                                          costDetails.objectPriceInPlan
                                        ).toLocaleString()}`}
                                      </td>
                                      <td className="py-2 pl-4 whitespace-nowrap">
                                        {costDetails.objectCost > 0 && (
                                          <span>{`$${costDetails.objectCost.toLocaleString()}`}</span>
                                        )}
                                        {costDetails.objectCost === 0 && (
                                          <span>Included in plan</span>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td className="py-2 pr-4 leading-6 whitespace-nowrap">
                                  <span className="font-medium">Total</span>
                                </td>
                                <td
                                  colSpan={3}
                                  className="py-2 px-4 whitespace-nowrap"
                                >
                                  <span className="text-gray-500 font-medium">
                                    {translateConfirmationDetails
                                      .translationCost.objectCostDetailList
                                      .length > 0 &&
                                      translateConfirmationDetails.translationCost.objectCostDetailList
                                        .map((detail) => detail.objectCount)
                                        .reduce((a, b) => a + b)
                                        .toLocaleString()}
                                  </span>
                                </td>
                                <td className="py-2 pl-4 whitespace-nowrap">
                                  <span className="font-medium">
                                    {`$${translateConfirmationDetails.totalCost.toLocaleString()}`}
                                  </span>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                          <p className="text-gray-900 font-medium">
                            Would you like to continue?
                          </p>
                          <p className="text-gray-500">
                            You are about to start translating your code. Based
                            on your contract specifications, we've shared the
                            pricing for translating your code above. Please feel
                            free to look it over carefully before you continue.
                          </p>
                        </>
                      )}
                      {confirmTranslation && (
                        <p className="text-lg text-gray-500">
                          Based on your current pricing plan and Analyzer
                          report, the total amount billed for this translation
                          will be{" "}
                          <strong className="text-gray-900">
                            {`$${translateConfirmationDetails.totalCost.toLocaleString()}`}
                          </strong>
                          .
                        </p>
                      )}
                    </>
                  )}
                </ModalContent>
                <ModalFooter>
                  <div className="flex space-x-4">
                    <Button
                      type="submit"
                      label={`${
                        confirmTranslation ? "Translate" : "Yes, continue"
                      }`}
                      icon={
                        confirmTranslation
                          ? solid("plus-square")
                          : solid("check-circle")
                      }
                      isLoading={isSubmitting}
                    />
                    <Button
                      label={`${confirmTranslation ? "Cancel" : "No, cancel"}`}
                      icon={solid("xmark-circle")}
                      variant="white"
                      onClick={() => closeConfirmationModal(false)}
                    />
                  </div>
                </ModalFooter>
              </form>
            </Modal>
          </>
        )}
      {currentTask.taskStatus === TaskStatus.TRANSLATING && (
        <Button
          href={`/translate/progress/${currentTask.uuid}`}
          label="View translation progress"
          icon={regular("code-compare")}
          trailingIcon
        />
      )}
      {currentTask.translated && (
        <Button
          href={`/translate/report-summary/${currentTask.uuid}`}
          label="View translation results"
          icon={regular("code-compare")}
          trailingIcon
        />
      )}
      {currentTask.analyzed &&
        !currentTask.translated &&
        currentTask.taskStatus === TaskStatus.ERROR && (
          <div className="flex items-center space-x-2 px-4 py-2.5 bg-danger-100 border border-danger-300 rounded-md">
            <FontAwesomeIcon
              icon={solid("xmark-circle")}
              className="text-danger-600"
            />
            <span className="text-danger-700 text-sm">
              The library can't be translated.
            </span>
          </div>
        )}
      {pageErrorsList.length > 0 &&
        pageErrorsList.map((error, index, list) => {
          return showNotification(
            <Notification
              key={`error.${error.id}`}
              variant="danger"
              title={error.error}
              subTitle={error.description}
              onClose={() =>
                setPageErrorsList(
                  list.filter((_listItem, newIndex) => newIndex !== index)
                )
              }
            />
          );
        })}
    </>
  );
}
