import { MasterPage } from "../../_ui-kit/MasterPage";
import { PageTransition } from "../../_ui-kit/PageTransition";
import TaskProgress from "../translate/components/TaskProgress";

function AnalysisProgress() {
  return (
    <MasterPage heading="Process" useBackButton>
      <PageTransition>
        <div id="content" className="max-w-3xl mx-auto px-6 py-8">
          <TaskProgress />
        </div>
      </PageTransition>
    </MasterPage>
  );
}

export default AnalysisProgress;
