import { BillingStatement } from "../AccountBilling";
import StatementListRow from "./StatementListRow";

interface StatementSectionProps {
  title: string;
  statements: BillingStatement[];
  totalCount: number;
  totalCosts: number;
}

function StatementSection({
  title,
  statements,
  totalCount,
  totalCosts,
}: StatementSectionProps) {
  return (
    <div className="flex flex-col">
      <div className="sticky top-0 -mx-6 px-8 py-3 bg-gray-100 border-t border-b border-b-gray-300 font-medium uppercase text-sm leading-6 text-gray-900">
        <div className="max-w-5xl mx-auto">
          <h3>
            {title}
            <span className="ml-6">{totalCount.toLocaleString()}</span>
          </h3>
        </div>
      </div>
      <div className="w-full max-w-5xl mx-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pr-3 text-left text-sm font-medium"
              >
                Task library
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-center text-sm font-medium"
              >
                Type
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-medium"
              >
                Platforms
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-medium"
              >
                Created date
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-center text-sm font-medium"
              >
                Procesing time
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-right text-sm font-medium"
              >
                Quantity
              </th>
              <th
                scope="col"
                className="py-3.5 pl-3 text-right text-sm font-medium"
              >
                Costs
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {statements &&
              statements.length > 0 &&
              statements
                .sort((a, b) => {
                  if (a.start < b.start) return -1;
                  if (a.start > b.start) return 1;
                  else return 0;
                })
                .map((statement, index) => {
                  return (
                    <StatementListRow
                      key={`${statement.id}-${statement.start}-${index}`}
                      statement={statement}
                    />
                  );
                })}
            {statements && statements.length === 0 && (
              <tr>
                <td
                  colSpan={7}
                  className="py-5 text-left text-sm text-gray-500"
                >
                  No tasks found
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan={5}
                className="py-5 pr-3 text-right text-sm font-medium uppercase"
              >
                Total
              </td>
              <td className="whitespace-nowrap py-5 px-3 text-right text-sm font-medium">
                {totalCount.toLocaleString()}
              </td>
              <td className="whitespace-nowrap pl-3 py-5 text-right text-sm font-medium">
                {`$${totalCosts.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

export default StatementSection;
