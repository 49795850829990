import { User } from "../../../models/User";

interface UsersListRowProps {
  user: User;
  editUserClickHandler: (param: number) => void;
}

function UsersListRow({ user, editUserClickHandler }: UsersListRowProps) {
  return (
    <tr>
      <td className="whitespace-nowrap py-4 pr-3 text-sm pl-6">
        <div className="flex flex-col">
          <div className="font-medium">{user.fullName}</div>
          <div className="text-gray-500">{user.email}</div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {!user.isAdmin() &&
          (user.userClients.length < 1 ? (
            <span>No clients assigned</span>
          ) : (
            <span>{user.userClients.length}</span>
          ))}
        {user.isAdmin() && <span>All clients</span>}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <span
          className={`inline-flex rounded-full px-3 py-1 text-xs ${
            user.isActive
              ? "bg-success-100 text-success-700"
              : "bg-gray-100 text-gray-600"
          }`}
        >
          {user.isActive ? "Active" : "Inactive"}
        </span>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {user.role}
      </td>
      <td className="relative whitespace-nowrap py-4 pl-3 text-right text-sm font-medium pr-6">
        <button
          className="px-1 text-primary-700 rounded-sm hover:text-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          onClick={() => editUserClickHandler(user.id)}
        >
          Edit
          <span className="sr-only">, {user.fullName}</span>
        </button>
      </td>
    </tr>
  );
}

function UsersListRowSkeleton() {
  return (
    <tr>
      <td className="py-4 pr-3 pl-6 w-1/4">
        <div className="flex flex-col space-y-2 animate-pulse">
          <span className="inline-block rounded bg-gray-200 w-48 h-4" />
          <span className="inline-block rounded bg-gray-200 w-20 h-4" />
        </div>
      </td>
      <td className="px-3 py-4">
        <span className="inline-block rounded bg-gray-200 w-36 h-4 align-middle animate-pulse" />
      </td>
      <td className="px-3 py-4">
        <span className="inline-block rounded bg-gray-200 w-16 h-4 align-middle animate-pulse" />
      </td>
      <td className="px-3 py-4">
        <span className="inline-block rounded bg-gray-200 w-20 h-4 align-middle animate-pulse" />
      </td>
      <td className="py-4 pl-3 pr-6 text-right">
        <span className="inline-block rounded bg-gray-200 w-8 h-4 align-middle animate-pulse" />
      </td>
    </tr>
  );
}

export { UsersListRowSkeleton };
export default UsersListRow;
