import { ClientObjectLevelPricing, ClientPricingPlan } from "./Client";

class PricingPlan {
  planId: string;
  planType: string;
  price: number;
  duration: string;
  freeTablesViews: number;
  freeAnalyzerReports: number;
  objectLevelPricing: ClientObjectLevelPricing[];
  dateCreated: string;
  createdBy: string;
  dateModified: string;
  modifiedBy: string;
  startDate?: string;
  endDate?: string;

  constructor(clienPlan: ClientPricingPlan) {
    this.planId = clienPlan.planId;
    this.planType = clienPlan.planType;
    this.price = clienPlan.price;
    this.duration = clienPlan.duration;
    this.freeTablesViews = clienPlan.freeTablesViews;
    this.freeAnalyzerReports = clienPlan.freeAnalyzerReports;
    this.objectLevelPricing = clienPlan.objectLevelPricing;
    this.dateCreated = clienPlan.dateCreated;
    this.createdBy = clienPlan.createdBy;
    this.dateModified = clienPlan.dateModified;
    this.modifiedBy = clienPlan.modifiedBy;
    this.startDate = clienPlan.startDate;
    this.endDate = clienPlan.endDate;
  }

  static freeReportsOptions = [
    { value: -1, label: "Unlimited" },
    { value: 0, label: "No free reports" },
    { value: 1, label: "1 report" },
    { value: 2, label: "2 reports" },
    { value: 5, label: "5 reports" },
    { value: 10, label: "10 reports" },
    { value: 15, label: "15 reports" },
    { value: 20, label: "20 reports" },
  ];

  static freeTablesViewsOptions = [
    { value: -1, label: "Unlimited" },
    { value: 0, label: "No free tables and views" },
    { value: 1000, label: "1,000" },
    { value: 2500, label: "2,500" },
    { value: 5000, label: "5,000" },
    { value: 10000, label: "10,000" },
    { value: 15000, label: "15,000" },
    { value: 20000, label: "20,000" },
  ];

  static getObjectTypeDisplayName(objectType: string): string {
    switch (objectType) {
      case "TABLE":
        return "Table";
      case "VIEW":
        return "View";
      case "PROCEDURE_FUNCTION":
        return "Procedure / Function";
      case "ETL_JOB":
        return "ETL Job";
      case "ANALYZER_REPORT":
        return "Analyzer Report";
    }
    return "";
  }

  getBillingObjectLevels(): ClientObjectLevelPricing[] {
    return this.objectLevelPricing.filter(
      (object) => object.objectType !== "NON_BILLING"
    );
  }

  getFreeAnalyzerReportsDisplayMessage(): string {
    if (this.freeAnalyzerReports === -1) {
      return "Unlimited analyzer reports";
    } else if (this.freeAnalyzerReports === 0) {
      return "No free analyzer reports";
    } else if (this.freeAnalyzerReports === 1) {
      return "1 free analyzer report";
    } else {
      return `${this.freeAnalyzerReports.toLocaleString()} free analyzer reports`;
    }
  }

  getFreeTablesViewsDisplayMessage(): string {
    if (this.freeTablesViews === -1) {
      return "Unlimited tables and views";
    } else if (this.freeTablesViews === 0) {
      return "No free tables and views";
    } else if (this.freeTablesViews === 1) {
      return "1 free table and view";
    } else {
      return `${this.freeTablesViews.toLocaleString()} free tables and views`;
    }
  }
}

export default PricingPlan;
