import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import ConfigureApi from "../../../api/ConfigureApi";
import { Project, ProjectCollectionInProgress } from "../../../models/Project";
import { Button } from "../../../_ui-kit/Button";
import { SelectedImportCollection } from "../DataUpload";

interface UploadItemProps {
  data: ProjectCollectionInProgress;
  currentProject?: Project;
  setSelectedImportCollection: (param: SelectedImportCollection) => void;
  setOpenImportDialog: (param: boolean) => void;
  show: boolean;
}

const uploadTypes = [
  {
    key: "missing",
    name: "Select type",
  },
  {
    key: "etl",
    name: "ETL data",
  },
  {
    key: "dependency",
    name: "Dependency data",
  },
  {
    key: "category",
    name: "Category data",
  },
  {
    key: "ddl",
    name: "DDL data",
  },
];

function UploadItem({
  data,
  currentProject,
  setSelectedImportCollection,
  setOpenImportDialog,
  show,
}: UploadItemProps) {
  const configureApi = new ConfigureApi();
  const isReadyToImport =
    data.status === Project.uploadState.COMPLETE && !data.imported;
  const isImporting = data.status === Project.uploadState.IMPORTING;
  const isError = data.status === Project.uploadState.ERROR;

  function getPercentage() {
    return Math.round((data.processed / data.size) * 100);
  }

  async function importFileUpload(projectId: string) {
    await configureApi.importCategoryData(projectId, data.collectionId);
  }

  function importDataUploadClickHandler(): void {
    if (
      data.type === "etl" ||
      data.type === "dependency" ||
      data.type === "ddl"
    ) {
      setSelectedImportCollection({
        collectionId: data.collectionId,
        type: data.type,
      });
      setOpenImportDialog(true);
    } else if (data.type === "category") {
      if (currentProject) {
        importFileUpload(currentProject.uuid);
      }
    }
  }

  return (
    <Transition
      as={Fragment}
      show={show}
      leave="ease-in-out duration-500"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 translate-x-12"
    >
      <div className="p-4 bg-white shadow rounded-lg">
        <div className="flex items-center justify-between">
          <div className="text-gray-900 font-medium">
            {uploadTypes.filter((type) => type.key === data.type)[0].name}
          </div>
          {data.status === Project.uploadState.UPLOADING && (
            <div className="bg-primary-100 px-3 py-1 rounded-full text-xs text-primary-700 font-medium">
              Uploading - {getPercentage()}%
            </div>
          )}
          {data.status === Project.uploadState.IMPORTING && (
            <div className="bg-primary-100 px-3 py-1 rounded-full text-xs text-primary-700 font-medium">
              Importing
            </div>
          )}
          {isReadyToImport && (
            <div className="bg-success-100 px-3 py-1 rounded-full text-xs text-success-700 font-medium">
              Ready to import
            </div>
          )}
          {Project.uploadState.COMPLETE && data.imported && (
            <div className="bg-success-100 px-3 py-1 rounded-full text-xs text-success-700 font-medium">
              Complete
            </div>
          )}
          {isError && (
            <div className="bg-danger-100 px-3 py-1 rounded-full text-xs text-danger-700 font-medium">
              Error
            </div>
          )}
        </div>
        <div className="flex items-center space-x-2 mt-2 text-gray-500">
          <FontAwesomeIcon icon={regular("file")} className="mb-0.5" />
          <span className="text-sm leading-6 truncate">
            {data.collectionName}
          </span>
        </div>
        {data.processed < data.size && (
          <>
            <div className="flex justify-between mt-4 mb-1.5 text-xs">
              <span
                className={`font-medium ${
                  isError
                    ? "line-through decoration-2 decoration-gray-400/70"
                    : ""
                }`}
              >{`${data.processed.toLocaleString()} ${
                data.type === "ddl" ? "records" : "jobs"
              }`}</span>
              <span
                className={`${
                  isError
                    ? "text-danger-700 line-through decoration-2 decoration-danger-400/70"
                    : "text-primary-700"
                } font-medium`}
              >{`${data.size.toLocaleString()} ${
                data.type === "ddl" ? "records" : "jobs"
              }`}</span>
            </div>
            <div className="h-2 rounded-full bg-gray-200 overflow-hidden">
              <div
                className={`h-full ${
                  isError ? "bg-danger-600" : "bg-primary-600 animate-pulse"
                } rounded-full`}
                style={{
                  width: `${getPercentage()}%`,
                }}
                role="progressbar"
                aria-label="Files upload progress"
                aria-valuenow={getPercentage()}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
          </>
        )}
        {data.processed === data.size && (
          <div className="mt-3">
            <Button
              label={`Import ${data.size.toLocaleString()} ${
                data.type === "ddl" ? "records" : "jobs"
              }`}
              size="sm"
              variant="white"
              onClick={importDataUploadClickHandler}
              isLoading={isImporting}
              loadingText={`Importing ${
                data.type === "ddl" ? "records" : "jobs"
              }...`}
              fullWidth
            />
          </div>
        )}
      </div>
    </Transition>
  );
}

export { uploadTypes };
export default UploadItem;
