import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import CloseButton from "../CloseButton/CloseButton";
import ModalProps, {
  ModalContentProps,
  ModalFooterProps,
  ModalHeaderProps,
} from "./Modal.types";

function Modal({
  size = "md",
  title,
  onClose,
  show,
  children,
  initialFocus,
}: ModalProps) {
  let modalSize;

  switch (size) {
    case "sm":
      modalSize = "max-w-sm";
      break;
    case "md":
      modalSize = "max-w-md";
      break;
    case "lg":
      modalSize = "max-w-lg";
      break;
    case "xl":
      modalSize = "max-w-xl";
      break;
    case "2xl":
      modalSize = "max-w-2xl";
      break;
  }

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-20"
        onClose={onClose}
        initialFocus={initialFocus}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Backdrop className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full justify-center items-start">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-0 scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-0 scale-95"
            >
              <Dialog.Panel
                className={`relative transform rounded-lg bg-white shadow-xl transition-all my-16 w-full ${modalSize}`}
              >
                <ModalHeader title={title} onClose={onClose} />
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function ModalHeader({ title, description, onClose }: ModalHeaderProps) {
  return (
    <div className="flex items-center justify-between px-6 py-5 border-b border-gray-200">
      <Dialog.Title as="h3" className="text-xl font-medium leading-6">
        {title}
      </Dialog.Title>
      {description && (
        <Dialog.Description className="sr-only">
          {description}
        </Dialog.Description>
      )}
      <div className="ml-3 flex h-7 items-center">
        <CloseButton onClose={onClose} />
      </div>
    </div>
  );
}

function ModalContent({ className, children }: ModalContentProps) {
  return <div className={`p-6 ${className}`}>{children}</div>;
}

function ModalFooter({ className, children }: ModalFooterProps) {
  return (
    <div className={`bg-gray-50 p-6 rounded-b-lg ${className}`}>{children}</div>
  );
}

export { ModalHeader, ModalContent, ModalFooter };
export default Modal;
