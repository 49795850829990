import ClientContextState from "../../contexts/ClientContext";
import ProjectContextState from "../../contexts/ProjectContext";
import { getStoredAuthToken } from "../../utils/user-storage";
import { Authorization } from "./AuthorizationRoute";

interface RestrictedRouteProps {
  children: JSX.Element;
}

function RestrictedRoute({ children }: RestrictedRouteProps) {
  const authToken = getStoredAuthToken();

  if (!authToken) {
    return <Authorization />;
  }

  return (
    <ClientContextState>
      <ProjectContextState>{children}</ProjectContextState>
    </ClientContextState>
  );
}

export default RestrictedRoute;
