import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Keys } from "../../_ui-kit/Keys";
import { Tabs, TabList, TabItem } from "../../_ui-kit/Tabs";
import { MasterPage } from "../../_ui-kit/MasterPage";
import { useEffect, useState } from "react";

interface NavItem {
  id: number;
  name: string;
  path: string;
}

const navigation: NavItem[] = [
  {
    id: 0,
    name: "Data overview",
    path: "data-overview",
  },
  {
    id: 1,
    name: "Wave planner",
    path: "wave-planner",
  },
  {
    id: 2,
    name: "Data platforms",
    path: "data-platforms",
  },
  {
    id: 3,
    name: "Code",
    path: "code",
  },
];

function Analyze() {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    const current = navigation.find((nav) => {
      return location.pathname.includes(nav.path);
    });

    if (current) setCurrentTab(current.id);
    else navigate(navigation[0].path);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <MasterPage heading="Analyze">
      <div className="flex flex-col h-full">
        <div className="shrink-0 pt-4">
          <Tabs selectedIndex={currentTab} manual>
            <TabList className="2xl:justify-center">
              {navigation.map((navItem) => (
                <TabItem
                  key={navItem.id}
                  onClick={() => navigate(navItem.path)}
                  onKeyUp={(e: React.KeyboardEvent<HTMLElement>) => {
                    if (e.key === Keys.Enter) navigate(navItem.path);
                  }}
                label={navItem.name}
                />
              ))}
            </TabList>
          </Tabs>
        </div>
        <div className="grow">
          <Outlet />
        </div>
      </div>
    </MasterPage>
  );
}

export default Analyze;
