import { ReactComponent as InformaticaEtlLogo } from "../../images/vendors/logos/informatica-etl-logo.svg";
import { ReactComponent as IBMDatastageEtlLogo } from "../../images/vendors/logos/ibm-datastage-etl-logo.svg";
import { ReactComponent as IBMDB2Logo } from "../../images/vendors/logos/ibm-db2-logo.svg";
import { ReactComponent as OracleDataLogo } from "../../images/vendors/logos/oracle-data-logo.svg";
import { ReactComponent as ODBCDataLogo } from "../../images/vendors/logos/odbc-data-logo.svg";
import { ReactComponent as ExternalFileDataLogo } from "../../images/vendors/logos/file-system-data-logo.svg";
import { ReactComponent as MicrosoftLogo } from "../../images/vendors/logos/microsoft-logo.svg";
import { ReactComponent as MicrosoftSqlServerLogo } from "../../images/vendors/logos/microsoft-sql-server-logo.svg";
import { ReactComponent as SnowflakeLogo } from "../../images/vendors/logos/snowflake-logo.svg";
import { ReactComponent as NetezzaLogo } from "../../images/vendors/logos/netezza-logo.svg";
import { ReactComponent as TeradataLogo } from "../../images/vendors/logos/teradata-logo.svg";
import { ReactComponent as GreenplumLogo } from "../../images/vendors/logos/greenplum-logo.svg";
import { ReactComponent as AmazonRedshiftLogo } from "../../images/vendors/logos/amazon-redshift-logo.svg";
import { ReactComponent as YellowbrickLogo } from "../../images/vendors/logos/yellowbrick-logo.svg";
import { ReactComponent as DataSetLogo } from "../../images/vendors/logos/dataset-logo.svg";
import { ReactComponent as GoogleBigQueryLogo } from "../../images/vendors/logos/google-big-query-logo.svg";
import { ReactComponent as ApacheHiveLogo } from "../../images/vendors/logos/apache-hive-logo.svg";
import { ReactComponent as ApacheSparkLogo } from "../../images/vendors/logos/apache-spark-logo.svg";
import { ReactComponent as AzureSynapseLogo } from "../../images/vendors/logos/azure-synapse-logo.svg";
import { ReactComponent as PostgreSqlLogo } from "../../images/vendors/logos/postgresql-logo.svg";
import { ReactComponent as TalendLogo } from "../../images/vendors/logos/talend-logo.svg";
import { ReactComponent as DatabricksLogo } from "../../images/vendors/logos/databricks-logo.svg";

import { ReactComponent as UnknownIcon } from "../../images/vendors/icons/unknown-icon.svg";
import { ReactComponent as InformaticaEtlIcon } from "../../images/vendors/icons/informatica-etl-icon.svg";
import { ReactComponent as IBMDatastageEtlIcon } from "../../images/vendors/icons/ibm-datastage-etl-icon.svg";
import { ReactComponent as OracleDataIcon } from "../../images/vendors/icons/oracle-data-icon.svg";
import { ReactComponent as ODBCDataIcon } from "../../images/vendors/icons/odbc-data-icon.svg";
import { ReactComponent as ExternalFileDataIcon } from "../../images/vendors/icons/file-system-data-icon.svg";
import { ReactComponent as MicrosoftIcon } from "../../images/vendors/icons/microsoft-icon.svg";
import { ReactComponent as SalesforceIcon } from "../../images/vendors/icons/salesforce-icon.svg";
import { ReactComponent as NetezzaIcon } from "../../images/vendors/icons/netezza-icon.svg";
import { ReactComponent as VSAMIcon } from "../../images/vendors/icons/vsam-icon.svg";
import { ReactComponent as SnowflakeIcon } from "../../images/vendors/icons/snowflake-icon.svg";
import { ReactComponent as TeradataIcon } from "../../images/vendors/icons/teradata-icon.svg";
import { ReactComponent as GreenplumIcon } from "../../images/vendors/icons/greenplum-icon.svg";
import { ReactComponent as AmazonRedshiftIcon } from "../../images/vendors/icons/amazon-redsfhit-icon.svg";
import { ReactComponent as YellowbrickIcon } from "../../images/vendors/icons/yellowbrick-icon.svg";
import { ReactComponent as DataSetIcon } from "../../images/vendors/icons/dataset-icon.svg";
import { ReactComponent as GoogleBigQueryIcon } from "../../images/vendors/icons/google-big-query-icon.svg";
import { ReactComponent as ApacheHiveIcon } from "../../images/vendors/icons/apache-hive-icon.svg";
import { ReactComponent as ApacheSparkIcon } from "../../images/vendors/icons/apache-spark-icon.svg";
import { ReactComponent as AzureSynapseIcon } from "../../images/vendors/icons/azure-synapse-icon.svg";
import { ReactComponent as PostgreSqlIcon } from "../../images/vendors/icons/postgresql-icon.svg";
import { ReactComponent as TalendIcon } from "../../images/vendors/icons/talend-icon.svg";
import { ReactComponent as DatabricksIcon } from "../../images/vendors/icons/databricks-icon.svg";

import { ReactComponent as InformaticaEtlBanner } from "../../images/vendors/banners/informatica-etl-banner.svg";
import { ReactComponent as IBMDatastageEtlBanner } from "../../images/vendors/banners/ibm-datastage-etl-banner.svg";
import { ReactComponent as MicrosoftBanner } from "../../images/vendors/banners/microsoft-banner.svg";

import { VendorAssetProps, VendorCardProps } from "./VendorCard.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Keys } from "../Keys";
import { classNames, getPropertyDisplayName, getPropertyIcon } from "../utils";

function LogoSkeleton({ title }: { title: string }) {
  return (
    <div
      className="flex items-center justify-center shrink-0 w-40 h-14 space-x-3 rounded-sm bg-gray-300 text-gray-700 font-sans"
      aria-hidden={true}
    >
      <UnknownIcon title={title} role="img" className="shrink-0 w-auto" />
      <span className="text-lg text-gray-600">Unknown</span>
    </div>
  );
}

function BannerSkeleton() {
  return (
    <div
      className="shrink-0 w-[35rem] h-32 bg-gray-200 text-gray-700 font-sans"
      aria-hidden={true}
    >
      <span className="font-bold">Not found</span>
    </div>
  );
}

function VendorAsset({ platform, type, className }: VendorAssetProps) {
  switch (platform.toLocaleLowerCase()) {
    case "informatica":
      if (type === "logo")
        return (
          <InformaticaEtlLogo
            title={platform}
            role="img"
            className="shrink-0 w-auto"
          />
        );
      if (type === "icon")
        return (
          <InformaticaEtlIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner")
        return (
          <InformaticaEtlBanner
            title={platform}
            role="img"
            className="shrink-0 w-auto"
          />
        );
      else return <span>Not found</span>;
    case "oracle":
    case "oracle database":
      if (type === "logo")
        return (
          <OracleDataLogo
            title={platform}
            role="img"
            className="shrink-0 w-auto"
          />
        );
      if (type === "icon")
        return (
          <OracleDataIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner") return <BannerSkeleton />;
      else return <span>Not found</span>;
    case "ibm":
    case "datastage":
    case "ibm datastage":
      if (type === "logo")
        return (
          <IBMDatastageEtlLogo
            title={platform}
            role="img"
            className="shrink-0 w-auto py-1"
          />
        );
      if (type === "icon")
        return (
          <IBMDatastageEtlIcon
            title="IBM DataStage"
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner")
        return (
          <IBMDatastageEtlBanner
            title="IBM DataStage"
            role="img"
            className="shrink-0 w-auto"
          />
        );
      else return <span>Not found</span>;
    case "ibm db2":
    case "db2":
      if (type === "logo")
        return (
          <IBMDB2Logo
            title={platform}
            role="img"
            className="shrink-0 w-auto py-1"
          />
        );
      if (type === "icon")
        return (
          <IBMDatastageEtlIcon
            title="IBM DB2"
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner")
        return (
          <IBMDatastageEtlBanner
            title="IBM DataStage"
            role="img"
            className="shrink-0 w-auto"
          />
        );
      else return <span>Not found</span>;
    case "odbc":
      if (type === "logo")
        return (
          <ODBCDataLogo
            title={platform}
            role="img"
            className="shrink-0 w-auto"
          />
        );
      if (type === "icon")
        return (
          <ODBCDataIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner") return <BannerSkeleton />;
      else return <span>Not found</span>;
    case "microsoft":
    case "microsoft ssis":
    case "ssis":
      if (type === "logo")
        return (
          <MicrosoftLogo
            title={platform}
            role="img"
            className="shrink-0 w-auto"
          />
        );
      if (type === "icon")
        return (
          <MicrosoftIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner")
        return (
          <MicrosoftBanner
            title={platform}
            role="img"
            className="shrink-0 w-auto"
          />
        );
      else return <span>Not found</span>;
    case "microsoft sql server":
    case "sql server":
      if (type === "logo")
        return (
          <MicrosoftSqlServerLogo
            title={platform}
            role="img"
            className="shrink-0 w-auto"
          />
        );
      if (type === "icon")
        return (
          <MicrosoftIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner")
        return (
          <MicrosoftBanner
            title={platform}
            role="img"
            className="shrink-0 w-auto"
          />
        );
      else return <span>Not found</span>;
    case "netezza":
      if (type === "logo")
        return (
          <NetezzaLogo
            title={platform}
            role="img"
            className="shrink-0 w-auto"
          />
        );
      if (type === "icon")
        return (
          <NetezzaIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner") return <BannerSkeleton />;
      else return <span>Not found</span>;
    case "vsam":
      if (type === "logo") return <LogoSkeleton title={platform} />;
      if (type === "icon")
        return (
          <VSAMIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner") return <BannerSkeleton />;
      else return <span>Not found</span>;
    case "salesforce":
      if (type === "logo") return <LogoSkeleton title={platform} />;
      if (type === "icon")
        return (
          <SalesforceIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner") return <BannerSkeleton />;
      else return <span>Not found</span>;
    case "snowflake":
      if (type === "logo")
        return (
          <SnowflakeLogo
            title={platform}
            role="img"
            className="shrink-0 w-auto"
          />
        );
      if (type === "icon")
        return (
          <SnowflakeIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner") return <BannerSkeleton />;
      else return <span>Not found</span>;
    case "teradata":
      if (type === "logo")
        return (
          <TeradataLogo
            title={platform}
            role="img"
            className="shrink-0 w-auto"
          />
        );
      if (type === "icon")
        return (
          <TeradataIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner") return <BannerSkeleton />;
      else return <span>Not found</span>;
    case "greenplum":
      if (type === "logo") return <GreenplumLogo title={platform} />;
      if (type === "icon")
        return (
          <GreenplumIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner") return <BannerSkeleton />;
      else return <span>Not found</span>;
    case "talend":
      if (type === "logo") return <TalendLogo title={platform} />;
      if (type === "icon")
        return (
          <TalendIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner") return <BannerSkeleton />;
      else return <span>Not found</span>;
    case "redshift":
      if (type === "logo") return <AmazonRedshiftLogo title={platform} />;
      if (type === "icon")
        return (
          <AmazonRedshiftIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner") return <BannerSkeleton />;
      else return <span>Not found</span>;
    case "yellowbrick":
      if (type === "logo") return <YellowbrickLogo title={platform} />;
      if (type === "icon")
        return (
          <YellowbrickIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner") return <BannerSkeleton />;
      else return <span>Not found</span>;
    case "dataset":
      if (type === "logo") return <DataSetLogo title={platform} />;
      if (type === "icon")
        return (
          <DataSetIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner") return <BannerSkeleton />;
      else return <span>Not found</span>;
    case "bigquery":
      if (type === "logo") return <GoogleBigQueryLogo title={platform} />;
      if (type === "icon")
        return (
          <GoogleBigQueryIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner") return <BannerSkeleton />;
      else return <span>Not found</span>;
    case "hive":
      if (type === "logo") return <ApacheHiveLogo title={platform} />;
      if (type === "icon")
        return (
          <ApacheHiveIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner") return <BannerSkeleton />;
      else return <span>Not found</span>;
    case "sparksql":
    case "pyspark":
      if (type === "logo") return <ApacheSparkLogo title={platform} />;
      if (type === "icon")
        return (
          <ApacheSparkIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner") return <BannerSkeleton />;
      else return <span>Not found</span>;
    case "synapse":
      if (type === "logo") return <AzureSynapseLogo title={platform} />;
      if (type === "icon")
        return (
          <AzureSynapseIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner") return <BannerSkeleton />;
      else return <span>Not found</span>;
    case "postgresql":
      if (type === "logo") return <PostgreSqlLogo title={platform} />;
      if (type === "icon")
        return (
          <PostgreSqlIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner") return <BannerSkeleton />;
      else return <span>Not found</span>;
    case "databricks":
      if (type === "logo") return <DatabricksLogo title={platform} />;
      if (type === "icon")
        return (
          <DatabricksIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner") return <BannerSkeleton />;
      else return <span>Not found</span>;
    case "file system":
    case "flat file":
    case "sequential file":
    case "complex file format":
    case "file":
      if (type === "logo")
        return (
          <ExternalFileDataLogo
            title={platform}
            role="img"
            className="shrink-0 w-auto"
          />
        );
      if (type === "icon")
        return (
          <ExternalFileDataIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner") return <BannerSkeleton />;
      else return <span>Not found</span>;
    case "unknown":
      if (type === "logo") return <LogoSkeleton title={platform} />;
      if (type === "icon")
        return (
          <UnknownIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner") return <BannerSkeleton />;
      else return <span>Not found</span>;
    default:
      if (type === "logo") return <LogoSkeleton title={platform} />;
      if (type === "icon")
        return (
          <UnknownIcon
            title={platform}
            role="img"
            className={`shrink-0${className ? ` ${className}` : " w-auto"}`}
          />
        );
      if (type === "banner") return <BannerSkeleton />;
      else return <span>Not found</span>;
  }
}

function VendorCard({
  vertical = false,
  clickable = false,
  data,
  ...props
}: VendorCardProps) {
  function handleClick(e?: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e?.preventDefault();
    e?.stopPropagation();
    if (props.onClick) props.onClick();
  }

  return (
    <div
      role={clickable ? "button" : undefined}
      tabIndex={clickable ? 0 : undefined}
      onClick={clickable ? handleClick : undefined}
      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === Keys.Enter) handleClick();
      }}
      className={classNames(
        "flex flex-col bg-white shadow rounded-lg p-6 max-w-md text-sm",
        clickable
          ? "group border border-gray-300 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          : ""
      )}
    >
      <div className="relative">
        <VendorAsset platform={data.name} type="logo" />
        {clickable && (
          <FontAwesomeIcon
            icon={regular("arrow-up-right")}
            aria-hidden={true}
            className="pointer-events-none transition-transform duration-200 absolute -top-1 -right-1 w-6 h-6 text-gray-300 group-hover:text-gray-400 group-hover:rotate-45"
          />
        )}
      </div>
      <h3
        className={classNames(
          "font-medium",
          vertical
            ? "mt-5 mb-3"
            : "mt-4 mb-0.5 relative before:absolute before:w-full before:h-px before:mt-2.5 before:bg-gray-200",
          clickable ? "" : "group-hover:text-gray-900"
        )}
      >
        <span className="relative bg-white pr-6">{data.description}</span>
      </h3>
      {data.assignedName && (
        <div className="mb-4 text-gray-500 text-xs font-medium">
          {data.assignedName}
        </div>
      )}
      <dl
        className={classNames(
          "mt-auto",
          vertical ? "space-y-3 divide-y" : "grid grid-cols-2 gap-x-8 gap-y-3"
        )}
      >
        {Object.keys(data)
          .filter((prop) => {
            if (vertical)
              return (
                prop.toLowerCase() === "controlflows" ||
                prop.toLowerCase() === "dataflows" ||
                prop.toLowerCase() === "sources" ||
                prop.toLowerCase() === "sinks"
              );

            return (
              prop.toLowerCase() === "jobs" ||
              prop.toLowerCase() === "controlflows" ||
              prop.toLowerCase() === "dataflows" ||
              prop.toLowerCase() === "steps" ||
              prop.toLowerCase() === "sources" ||
              prop.toLowerCase() === "sinks"
            );
          })
          .map((key, index, array) => {
            return (
              <div
                className={`group-2 relative flex items-center justify-between ${
                  vertical
                    ? "border-t pt-3"
                    : array.length > (index | (index - 2))
                    ? "border-b border-b-gray-200 pb-3"
                    : ""
                }`}
                key={key}
              >
                <dt className="flex items-center grow min-w-0 mr-3 text-gray-500">
                  <FontAwesomeIcon
                    icon={getPropertyIcon(key)}
                    className="w-4 h-4 mr-3"
                    aria-hidden={true}
                  />
                  <span className="truncate grow">
                    {getPropertyDisplayName(key)}
                  </span>
                </dt>
                <dd className="font-medium">{data[key].toLocaleString()}</dd>
                {!vertical && (key === "sources" || key === "sinks") && (
                  <div className="transition-opacity invisible group-2-hover:visible opacity-0 group-2-hover:opacity-100 group-2-hover:delay-700 absolute right z-40 -right-0 translate-x-1/2">
                    <div className="flex flex-col rounded-md shadow-lg border border-gray-300 bg-white text-sm px-4 py-3">
                      <div className="mb-2 text-xs font-medium">
                        Data Platforms
                      </div>
                      <div className="flex space-x-3 pb-0.5">
                        {data.resourceDetails &&
                          data.resourceDetails[key] &&
                          data.resourceDetails[key].map((platform: string) => (
                            <span key={platform}>
                              <VendorAsset platform={platform} type="icon" />
                            </span>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </dl>
    </div>
  );
}

export { VendorAsset, VendorCard };
