import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VendorAsset } from "../../../_ui-kit/VendorCard";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { TaskData } from "../../translate/components/TasksList";

function AnalyzeTaskHeading({
  currentTask,
  isSqlReport,
}: {
  currentTask: TaskData;
  isSqlReport: boolean;
}) {
  return (
    <div className="flex items-center px-6 py-5">
      <span className="mr-5 text-lg font-medium">
        {currentTask.projectPlatformName}
      </span>
      <div className="flex items-center space-x-3 mr-auto">
        <VendorAsset platform={currentTask.sourcePlatformName} type="icon" />
        {isSqlReport && (
          <>
            <FontAwesomeIcon
              icon={solid("arrow-right")}
              className="text-gray-400"
              size="sm"
            />
            <VendorAsset
              platform={currentTask.targetPlatformName}
              type="icon"
            />
          </>
        )}
      </div>
      <div className="bg-gray-100 rounded px-2 py-1 uppercase text-xs font-medium text-gray-600 ml-6">
        {currentTask.sourceType}
      </div>
    </div>
  );
}

export default AnalyzeTaskHeading;
