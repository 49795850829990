interface AnalysisReportStatementRowProps {
  title: string;
  subTitle: string;
  legend: string;
  percentage: number;
}

function AnalysisReportStatementRow({
  title,
  subTitle,
  legend,
  percentage,
}: AnalysisReportStatementRowProps) {
  return (
    <div className="grid grid-cols-3 gap-6 py-4">
      <div
        className="text-sm text-gray-900 font-medium truncate print:overflow-visible print:whitespace-normal print:break-words"
        title={title}
      >
        {title}
      </div>
      <div className="flex items-center self-start text-sm text-gray-500">
        <div className="w-20 mr-6 text-right">{`${percentage.toFixed(
          2
        )}%`}</div>
        <div className="w-full h-2 rounded-full bg-gray-200 overflow-hidden">
          <div
            className={`h-full bg-primary-600 rounded-full`}
            style={{
              width: `${percentage}%`,
            }}
            role="meter"
            aria-label="Files upload progress"
            aria-valuenow={percentage}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>
        </div>
      </div>
      <div className="text-right text-sm text-primary-700 font-medium">
        {`${subTitle} ${legend}`}
      </div>
    </div>
  );
}

export default AnalysisReportStatementRow;
