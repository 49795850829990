import BaseApi from "./BaseApi";

export default class SettingsApi extends BaseApi {
  apiGetEtlTypes = `${this.baseUrl}/settings/etlplatformtypes`;
  apiGetDataPlatformTypes = `${this.baseUrl}/settings/datawarehouses`;
  apiGetShiftDataPlatformTypes = `${this.baseUrl}/settings/shift_datawarehouses`;
  apiGetShiftTargetDataPlatformTypes = `${this.baseUrl}/settings/shift_target_datawarehouses/{{type}}`;
  apiGetShiftEtlPlatformTypes = `${this.baseUrl}/settings/shift_etlplatformtypes`;
  apiGetSqlPlatforms = `${this.baseUrl}/settings/dataplatformmappings`;
  apiGetPricingPlans = `${this.baseUrl}/settings/pricingplan`;
  apiDeletePricingPlan = `${this.baseUrl}/settings/pricingplan/{{planId}}`;
  apiGetEnvironmentSettings = `${this.baseUrl}/settings/environment_settings`;
  apiUpdateEnvironmentSettings = this.apiGetEnvironmentSettings;
  apiGetTestPlatforms = `${this.baseUrl}/settings/test_platforms`;
  apiUpdateTestPlatforms = this.apiGetTestPlatforms;
  apiGetHealthCheckStatus = `${this.baseUrl}/healthcheck/status`;
  apiGetUserPreferences = `${this.baseUrl}/users/preference`;
  apiGetUsageDetails = `${this.baseUrl}/clients/{{clientId}}/usagedetails`;
  apiUpdateUserPreferences = this.apiGetUserPreferences;
  apiUploadLicenseFile = `${this.baseUrl}/settings/license`;

  getEtlTypes(): Promise<Response> {
    const promise = this.doFetch(this.apiGetEtlTypes, {
      method: "GET",
      headers: this.getAuthApplicationJsonHeaders(),
    });
    return promise;
  }

  getDataPlatformTypes(): Promise<Response> {
    const promise = this.doFetch(this.apiGetDataPlatformTypes, {
      method: "GET",
      headers: this.getAuthApplicationJsonHeaders(),
    });
    return promise;
  }

  getShiftDataPlatformTypes(): Promise<Response> {
    const promise = this.doFetch(this.apiGetShiftDataPlatformTypes, {
      method: "GET",
      headers: this.getAuthApplicationJsonHeaders(),
    });
    return promise;
  }

  getShiftEtlPlatformTypes(): Promise<Response> {
    const promise = this.doFetch(this.apiGetShiftEtlPlatformTypes, {
      method: "GET",
      headers: this.getAuthApplicationJsonHeaders(),
    });
    return promise;
  }

  getShiftTargetDataPlatformTypes(type: "ETL" | "SQL"): Promise<Response> {
    const promise = this.doFetch(
      this.apiGetShiftTargetDataPlatformTypes.replace(
        "{{type}}",
        type.toLowerCase()
      ),
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getSqlTypes(): Promise<Response> {
    const promise = this.doFetch(this.apiGetSqlPlatforms, {
      method: "GET",
      headers: this.getAuthApplicationJsonHeaders(),
    });
    return promise;
  }

  getPricingPlans(): Promise<Response> {
    const promise = this.doFetch(this.apiGetPricingPlans, {
      method: "GET",
      headers: this.getAuthApplicationJsonHeaders(),
    });
    return promise;
  }

  deletePricingPlan(planId: string): Promise<Response> {
    const promise = this.doFetch(
      this.apiDeletePricingPlan.replace("{{planId}}", planId),
      {
        method: "DELETE",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getEnvironmentSettings(): Promise<Response> {
    const promise = this.doFetch(this.apiGetEnvironmentSettings, {
      method: "GET",
      headers: this.getAuthApplicationJsonHeaders(),
    });
    return promise;
  }

  updateEnvironmentSettings(settings: any): Promise<Response> {
    const promise = this.doFetch(this.apiUpdateEnvironmentSettings, {
      method: "PUT",
      headers: this.getAuthApplicationJsonHeaders(),
      body: JSON.stringify(settings),
    });
    return promise;
  }

  getTestPlatforms(): Promise<Response> {
    const promise = this.doFetch(this.apiGetTestPlatforms, {
      method: "GET",
      headers: this.getAuthApplicationJsonHeaders(),
    });
    return promise;
  }

  updateTestPlatforms(platforms: any): Promise<Response> {
    const promise = this.doFetch(this.apiUpdateTestPlatforms, {
      method: "PUT",
      headers: this.getAuthApplicationJsonHeaders(),
      body: JSON.stringify(platforms),
    });
    return promise;
  }

  getHealthCheckStatus(): Promise<Response> {
    const promise = this.doFetch(this.apiGetHealthCheckStatus, {
      method: "GET",
      headers: this.getAuthApplicationJsonHeaders(),
    });
    return promise;
  }

  getUserPreferences(): Promise<Response> {
    const promise = this.doFetch(this.apiGetUserPreferences, {
      method: "GET",
      headers: this.getAuthApplicationJsonHeaders(),
    });
    return promise;
  }

  getUsageDetails(
    clientId: string,
    startDate: string,
    endDate: string
  ): Promise<Response> {
    const queryString = {
      start: startDate,
      end: endDate,
    };

    const promise = this.doFetch(
      this.apiGetUsageDetails.replace("{{clientId}}", clientId) +
        `?${this.objToQueryString(queryString)}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );

    return promise;
  }

  updateUserPreferences(
    userEmail: string,
    enableSplitRqEmail: boolean,
    enableSplitRsEmail: boolean,
    enableAnalyzeRqEmail: boolean,
    enableAnalyzeRsEmail: boolean,
    enableTranslateRqEmail: boolean,
    enableTranslateRsEmail: boolean,
    enableTestRqEmail: boolean,
    enableTestRsEmail: boolean
  ): Promise<Response> {
    const promise = this.doFetch(this.apiUpdateUserPreferences, {
      method: "PUT",
      headers: this.getAuthApplicationJsonHeaders(),
      body: JSON.stringify({
        userEmail,
        enableSplitRqEmail,
        enableSplitRsEmail,
        enableAnalyzeRqEmail,
        enableAnalyzeRsEmail,
        enableTranslateRqEmail,
        enableTranslateRsEmail,
        enableTestRqEmail,
        enableTestRsEmail,
      }),
    });
    return promise;
  }

  uploadLicenseFile(file: File): Promise<Response> {
    let data = new FormData();
    data.append("file", file);

    const promise = this.doFetch(this.apiUploadLicenseFile, {
      method: "POST",
      body: data,
      headers: this.getAuthHeaders(),
    });

    return promise;
  }
}
