import { createContext, useEffect, useState } from "react";
import ConfigureApi from "../api/ConfigureApi";
import DiscoverApi from "../api/DiscoverApi";
import { Platform } from "../_ui-kit/SelectPlatform/SelectPlatform.types";

interface DiscoverContextProviderProps {
  children: React.ReactNode;
}

interface DiscoverContextProps {
  etlPlatformsSummaries: ETLPlatformSummary[];
  setEtlPlatformsSummaries: (param: ETLPlatformSummary[]) => void;
  dataPlatformsSummaries: DataPlatformSummary[];
  setDataPlatformsSummaries: (param: DataPlatformSummary[]) => void;
  migratingDataWarehouses: MigratingDatawarehouse[];
  setMigratingDataWarehouses: (param: MigratingDatawarehouse[]) => void;
  selectedMigratingDataWarehouse: MigratingDatawarehouse | null;
  setSelectedMigratingDataWarehouse: (param: MigratingDatawarehouse) => void;
  discoverApi: DiscoverApi;
  etlTypes: Platform[];
}

interface ETLPlatform {
  etlplatformId: string;
  etlPlatformType: string;
  clientId: string;
  projectId: string;
  repository: string;
  name: string;
  folder: string;
  dateCreated: string;
  dateModified: string;
  lastModifiedBy: string;
}

interface ETLPlatformSummary {
  id: string;
  name: string;
  description: string;
  jobs: number;
  controlflows: number;
  dataflows: number;
  steps: number;
  sources: number;
  sinks: number;
  averageComplexityCoefficient: number;
  aggregateComplexityCoefficient: number;
  averageComplexityScale: string;
  resourceDetails: ResourceDetails;
}

interface ResourceDetails {
  sources: string[];
  sinks: string[];
}

interface DataPlatformSummary {
  id: number;
  name: string;
  description: string;
  controlflows: number;
  dataflows: number;
  steps: number;
  sources: number;
  sinks: number;
  averageComplexityCoefficient: number;
  aggregateComplexityCoefficient: number;
  averageComplexityScale: string;
  Jobs: number;
}

interface MigratingDatawarehouse {
  id: number;
  name: string;
  description: string;
}

interface MigratingDatawarehouseAggregates {
  id: number;
  aggregate: string;
  support: number;
}

interface ControlFlowSummary {
  id: string;
  name: string;
  dataflows: number;
  steps: number;
  sources: number;
  sinks: number;
  averageComplexityCoefficient: number;
  aggregateComplexityCoefficient: number;
  averageComplexityScale: string;
}

interface DataFlowSummary {
  id: string;
  name: string;
  steps: number;
  sources: number;
  sinks: number;
  averageComplexityCoefficient: number;
  aggregateComplexityCoefficient: number;
  averageComplexityScale: string;
}

interface SinkSummary {
  dataPlatform: string;
  dataWarehouse: string;
  description: string;
  jobs: number;
  controlflows: number;
  dataflows: number;
  steps: number;
}

interface SourceSummary {
  dataPlatform: string;
  dataWarehouse: string;
  description: string;
  jobs: number;
  controlflows: number;
  dataflows: number;
  steps: number;
}

interface SearchProps {
  pageIndex: Number;
  pageSize: Number;
  sortKey: "complexity" | "steps" | "sources" | "sinks";
  sortDirection: "desc" | "asc";
  searchFilter: String;
}

const DiscoverContext = createContext<DiscoverContextProps>(
  {} as DiscoverContextProps
);

const discoverApi = new DiscoverApi();

function DiscoverContextProvider({ children }: DiscoverContextProviderProps) {
  const configureApi = new ConfigureApi();
  const [etlTypes, setEtlTypes] = useState<Platform[]>([]);
  const [etlPlatformsSummaries, setEtlPlatformsSummaries] = useState<
    ETLPlatformSummary[]
  >([]);
  const [dataPlatformsSummaries, setDataPlatformsSummaries] = useState<
    DataPlatformSummary[]
  >([]);
  const [migratingDataWarehouses, setMigratingDataWarehouses] = useState<
    MigratingDatawarehouse[]
  >([]);
  const [selectedMigratingDataWarehouse, setSelectedMigratingDataWarehouse] =
    useState<MigratingDatawarehouse | null>(null);

  useEffect(() => {
    async function getEtlList() {
      try {
        const result = await configureApi.getEtlTypes();
        const etls: Platform[] = await result.json();
        setEtlTypes(etls);
      } catch (ex) {
        console.error(ex);
      }
    }
    getEtlList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DiscoverContext.Provider
      value={{
        etlPlatformsSummaries,
        setEtlPlatformsSummaries,
        dataPlatformsSummaries,
        setDataPlatformsSummaries,
        migratingDataWarehouses,
        setMigratingDataWarehouses,
        selectedMigratingDataWarehouse,
        setSelectedMigratingDataWarehouse,
        discoverApi,
        etlTypes,
      }}
    >
      {children}
    </DiscoverContext.Provider>
  );
}

export type {
  ETLPlatform,
  ETLPlatformSummary,
  DataPlatformSummary,
  MigratingDatawarehouse,
  MigratingDatawarehouseAggregates,
  ControlFlowSummary,
  DataFlowSummary,
  SinkSummary,
  SourceSummary,
  SearchProps,
};
export { DiscoverContextProvider, DiscoverContext };
