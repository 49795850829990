import { ClientContext } from "../../contexts/ClientContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { VendorAsset, VendorCard } from "../../_ui-kit/VendorCard";
import { PageTransition } from "../../_ui-kit/PageTransition";
import { Fragment, useContext, useEffect, useState } from "react";
import { SlideOver } from "../../_ui-kit/SlideOver";
import { Button, TabItem, TabList, TabPanels, Tabs } from "../../_ui-kit";
import {
  DiscoverContext,
  ETLPlatform,
  ETLPlatformSummary,
  MigratingDatawarehouse,
} from "../../contexts/DiscoverContext";
import { DetailsTab } from "./components/DetailsTab";
import { ControlFlowsTab } from "./components/ControlFlowsTab";
import { DataFlowsTab } from "./components/DataFlowsTab";
import { SinksTab } from "./components/SinksTab";
import { SourcesTab } from "./components/SourcesTab";
import { Listbox, Transition } from "@headlessui/react";
import { ProjectContext } from "../../contexts/ProjectContext";
import Modal, { ModalContent, ModalFooter } from "../../_ui-kit/Modal/Modal";
import InputGroup from "../../_ui-kit/InputGroup/InputGroup";
import Input from "../../_ui-kit/Input/Input";
import SelectPlatform from "../../_ui-kit/SelectPlatform/SelectPlatform";
import { Platform } from "../../_ui-kit/SelectPlatform/SelectPlatform.types";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

const tabItems = [
  {
    id: 1,
    name: "Details",
  },
  {
    id: 2,
    name: "Control flows",
  },
  {
    id: 3,
    name: "Data flows",
  },
  {
    id: 4,
    name: "Sources",
  },
  {
    id: 5,
    name: "Sinks",
  },
];

function EtlPlatforms() {
  const { authUser } = useContext(UserContext);
  const { currentClient } = useContext(ClientContext);
  const { currentProject } = useContext(ProjectContext);
  const {
    migratingDataWarehouses,
    setMigratingDataWarehouses,
    selectedMigratingDataWarehouse,
    setSelectedMigratingDataWarehouse,
    discoverApi,
    etlTypes,
  } = useContext(DiscoverContext);
  const navigate = useNavigate();
  const [openSlideOver, setOpenSlideOver] = useState(false);
  const [currentEtl, setCurrentEtl] = useState<ETLPlatformSummary | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedEtl, setSelectedEtl] = useState<Platform>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();

  const {
    data: etlList,
    isInitialLoading: isLoadingEtls,
    isFetching: isFetchingEtls,
  } = useQuery(
    [
      `etl-list${!currentClient || !currentProject ? "-invalid" : ""}`,
      {
        clientId: currentClient?.id,
        projectId: currentProject?.uuid,
      },
    ],
    async () => {
      const clientId = currentClient?.id || "";
      const projectId = currentProject?.uuid || "";
      const response = await discoverApi.getETLPlatforms(clientId, projectId);
      const platforms: ETLPlatform[] = await response.json();
      return platforms;
    },
    {
      enabled: !!currentClient && !!currentProject,
      onSuccess: () => {
        queryClient.removeQueries(["etl-list-invalid"]);
      },
    }
  );

  const {
    data: etlSummariesList,
    isInitialLoading: isLoadingSummaries,
    isFetching: isFetchingSummaries,
  } = useQuery(
    [
      `etl-summaries${!currentClient || !currentProject ? "-invalid" : ""}`,
      {
        clientId: currentClient?.id,
        projectId: currentProject?.uuid,
      },
    ],
    async () => {
      const clientId = currentClient?.id || "";
      const projectId = currentProject?.uuid || "";
      const response = await discoverApi.getETLPlatformsSummary(
        clientId,
        projectId
      );
      const summaries: ETLPlatformSummary[] = await response.json();
      return summaries;
    },
    {
      enabled: !!currentClient && !!currentProject,
      onSuccess: () => {
        queryClient.removeQueries(["etl-summaries-invalid"]);
      },
    }
  );

  const addEtlMutation = useMutation(
    (newEtl: any) => {
      return discoverApi.addETLPlatform(
        newEtl.clientId,
        newEtl.projectId,
        newEtl.etlId,
        newEtl.etlName,
        newEtl.etlName,
        "bitbucket"
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["etl-list"]);
        queryClient.invalidateQueries(["etl-summaries"]);
      },
    }
  );

  function showSlideOver(): void {
    setOpenSlideOver(true);
  }

  function etlButtonClickHandler(etl: ETLPlatformSummary): void {
    async function loadMigratingDatawarehouses(
      projectId: string,
      summary: ETLPlatformSummary
    ): Promise<void> {
      const result = await discoverApi.getMigratingDataWarehouses(
        projectId,
        summary.id
      );
      const warehouses: MigratingDatawarehouse[] = await result.json();
      setMigratingDataWarehouses(warehouses);
      setSelectedMigratingDataWarehouse(warehouses[0]);
    }

    if (currentProject) loadMigratingDatawarehouses(currentProject.uuid, etl);

    setCurrentEtl(etl);
    showSlideOver();
  }

  function addEltPlatformSubmitHandler(
    e: React.FormEvent<HTMLFormElement>
  ): void {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData(e.currentTarget);
    const etlId = formData.get("etl-platform[id]") as string;
    const etlName = formData.get("etl-name") as string;

    if (currentClient && currentProject) {
      addEtlMutation.mutate(
        {
          clientId: currentClient.id,
          projectId: currentProject.uuid,
          etlId,
          etlName,
        },
        {
          onSuccess: () => {
            setIsSubmitting(false);
            setShowModal(false);
          },
        }
      );
    }
  }

  useEffect(() => {
    if (etlTypes && etlTypes.length > 0) setSelectedEtl(etlTypes[0]);
  }, [etlTypes]);

  return (
    <PageTransition>
      <div id="content" className="p-6 2xl:max-w-7xl 2xl:mx-auto 2xl:px-0">
        <h2 className="flex items-center mb-6 text-lg font-medium">
          Platforms overview
          {(isLoadingEtls || isLoadingSummaries) && (
            <span className="inline-flex px-3 ml-1 text-gray-300">
              <FontAwesomeIcon
                icon={solid("spinner")}
                spin={true}
                className="w-6 h-6"
                aria-hidden={true}
              />
            </span>
          )}
          {etlSummariesList && etlSummariesList.length > 0 && (
            <span className="rounded-full bg-gray-200 px-4 py-1 ml-3 text-sm font-bold">
              {etlSummariesList.length}
            </span>
          )}
        </h2>
        <div className="grid grid-cols-3 gap-6">
          {(isFetchingEtls || isFetchingSummaries) && (
            <>
              {[...Array(etlList ? etlList.length : 2)].map((_e, i) => (
                <div
                  key={`skeleton-${i}`}
                  className="border border-gray-300 shadow rounded-lg p-6 max-w-md w-full bg-white"
                  aria-hidden={true}
                >
                  <div className="animate-pulse flex flex-col">
                    <div className="rounded bg-gray-200 h-14 w-52 mb-4"></div>
                    <div className="h-7 bg-gray-200 rounded mb-4"></div>
                    <div className="flex-1 space-y-6">
                      <div className="space-y-4 pt-1">
                        <div className="grid grid-cols-2 gap-x-6 gap-y-4">
                          <div className="h-7 bg-gray-200 rounded col-span-1"></div>
                          <div className="h-7 bg-gray-200 rounded col-span-1"></div>
                          <div className="h-7 bg-gray-200 rounded col-span-1"></div>
                          <div className="h-7 bg-gray-200 rounded col-span-1"></div>
                          <div className="h-7 bg-gray-200 rounded col-span-1"></div>
                          <div className="h-7 bg-gray-200 rounded col-span-1"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
          {!isFetchingEtls &&
            !isFetchingSummaries &&
            etlSummariesList &&
            etlList &&
            etlList.length > 0 && (
              <>
                {etlList
                  .filter((etl) => {
                    const found = etlSummariesList.find(
                      (summary) => summary.id === etl.etlplatformId
                    );
                    if (found) return found.jobs < 1;
                    return false;
                  })
                  .map((etl) => {
                    return (
                      <div
                        key={etl.etlplatformId}
                        className="shadow rounded-lg p-6 max-w-md min-h-[17.5rem] w-full bg-white border border-gray-300"
                        role="button"
                        tabIndex={0}
                        onClick={() => navigate("/configure/data-upload")}
                      >
                        <div className="flex flex-col items-center justify-center h-full">
                          <VendorAsset
                            platform={etl.etlPlatformType}
                            type="icon"
                          />
                          <h3 className="font-medium text-sm mt-3">
                            {etl.name}
                          </h3>
                          <p className="bg-gay-50 px-16 mt-1 rounded text-gray-500 text-xs text-center">
                            No jobs available. Click to start uploading your
                            files and data import.
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
          {!isFetchingSummaries &&
            etlSummariesList &&
            etlSummariesList.length > 0 && (
              <>
                {etlSummariesList
                  .filter((summary) => summary.jobs > 0)
                  .map((etl: ETLPlatformSummary) => {
                    return (
                      <VendorCard
                        key={etl.id}
                        name={etl.name}
                        description={etl.description}
                        data={etl}
                        clickable={true}
                        onClick={() => etlButtonClickHandler(etl)}
                      />
                    );
                  })}
              </>
            )}
          {
            authUser?.isReviewer() ?
            (
              <></>
            ) : (
              <button
                className="group border-2 border-gray-300 border-dashed rounded-lg min-h-[17.5rem] text-gray-300 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                onClick={() => setShowModal(true)}
              >
                <FontAwesomeIcon
                  icon={solid("folder-plus")}
                  size="3x"
                  className="mt-5"
                  aria-hidden={true}
                />
                <span className="transition block opacity-0 mt-2 text-xs text-gray-500 font-medium uppercase translate-y-1 group-hover:opacity-100 group-hover:translate-y-0">
                  Add platform
                </span>
              </button>
            )
          }
        </div>
        <Modal
          title="New ETL platform"
          show={showModal}
          onClose={isSubmitting ? () => {} : () => setShowModal(false)}
        >
          <form onSubmit={addEltPlatformSubmitHandler}>
            <ModalContent>
              <div className="mb-5">
                <label className="block font-medium text-gray-700">
                  ETL platform
                </label>
                <div className="mt-2">
                  <SelectPlatform
                    platforms={etlTypes}
                    selectedPlatform={selectedEtl}
                    setSelectedPlatform={setSelectedEtl}
                    name="etl-platform"
                  />
                </div>
              </div>
              <InputGroup label="Name" inputId="etl-name" className="space-y-2">
                <Input
                  id="etl-name"
                  name="etl-name"
                  type="text"
                  size="lg"
                  autoComplete="name"
                  required={true}
                />
              </InputGroup>
            </ModalContent>
            <ModalFooter>
              <div className="flex space-x-4">
                <Button
                  type="submit"
                  label="Add platform"
                  icon={solid("plus")}
                  isLoading={isSubmitting}
                />
                <Button
                  label="Cancel"
                  icon={solid("xmark-circle")}
                  variant="white"
                  onClick={() => setShowModal(false)}
                />
              </div>
            </ModalFooter>
          </form>
        </Modal>
        <SlideOver
          title={currentEtl?.description}
          show={openSlideOver}
          onClose={setOpenSlideOver}
          scroll={false}
        >
          <Tabs>
            <div className="relative z-10 shadow-sm bg-white">
              <div className="relative">
                {currentEtl && (
                  <VendorAsset platform={currentEtl.name} type="banner" />
                )}
                <div className="absolute w-56 right-6 top-11 z-20">
                  <Transition
                    appear
                    enter="transition delay-700 duration-300"
                    enterFrom="opacity-0 translate-y-4"
                    enterTo="opacity-100 translate-y-0"
                  >
                    <Listbox
                      value={selectedMigratingDataWarehouse}
                      onChange={setSelectedMigratingDataWarehouse}
                    >
                      <div className="relative">
                        <Listbox.Button
                          className="relative inline-flex items-center w-full pl-2 pr-9 py-2 border shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500"
                          style={{ borderColor: "rgb(0 0 0 / .1)" }}
                        >
                          <span className="flex items-center w-full space-x-3">
                            {selectedMigratingDataWarehouse && (
                              <VendorAsset
                                platform={selectedMigratingDataWarehouse.name}
                                type="icon"
                              />
                            )}
                            <span className="truncate">
                              {selectedMigratingDataWarehouse?.description}
                            </span>
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex flex-col justify-center items-center pr-3.5 text-gray-500">
                            <FontAwesomeIcon
                              icon={solid("sort-up")}
                              className="w-2.5 h-2.5"
                              aria-hidden={true}
                            />
                            <FontAwesomeIcon
                              icon={solid("sort-down")}
                              className="w-2.5 h-2.5 -mt-1.5"
                              aria-hidden={true}
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Listbox.Options className="origin-top-right absolute right-0 mt-2 py-1 max-w-xs flex flex-col rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-40 focus:outline-none">
                            <h6 className="px-4 pt-2 pb-3 mb-1 border-b font-medium whitespace-nowrap">
                              Migrating Data Warehouses
                            </h6>
                            {migratingDataWarehouses.map((warehouse) => (
                              <Listbox.Option
                                key={warehouse.id}
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 pl-4 pr-12 ${
                                    active
                                      ? "bg-primary-100 text-primary-700"
                                      : "bg-white text-gray-900"
                                  }`
                                }
                                value={warehouse}
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={`block ${
                                        selected ? "font-medium" : "font-normal"
                                      }`}
                                    >
                                      <span className="flex items-center space-x-3">
                                        <VendorAsset
                                          platform={warehouse.name}
                                          type="icon"
                                        />
                                        <span className="truncate">
                                          {warehouse.description}
                                        </span>
                                      </span>
                                    </span>
                                    {selected ? (
                                      <span
                                        className={`absolute inset-y-0 right-0 flex items-center pr-4 text-base text-primary-600`}
                                      >
                                        <span className="sr-only">
                                          Selected
                                        </span>
                                        <FontAwesomeIcon
                                          icon={solid("check")}
                                          aria-hidden={true}
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </Transition>
                </div>
                <TabList justified>
                  {tabItems.map((navItem) => (
                    <TabItem key={navItem.id} label={navItem.name} />
                  ))}
                </TabList>
              </div>
            </div>
            <TabPanels className="flex flex-col grow min-h-0">
              <DetailsTab summary={currentEtl} />
              <ControlFlowsTab summary={currentEtl} />
              <DataFlowsTab summary={currentEtl} />
              <SourcesTab summary={currentEtl} />
              <SinksTab summary={currentEtl} />
            </TabPanels>
          </Tabs>
        </SlideOver>
      </div>
    </PageTransition>
  );
}

export default EtlPlatforms;
