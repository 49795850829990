interface ClientData {
  id: string;
  code: string;
  name: string;
  etlPlatformCount: number;
  dateCreated: string;
  dateModified: string;
  projectCount: number;
  isActive: boolean;
  clientRestriction?: ClientRestriction;
  pricingPlan?: ClientPricingPlan;
}

interface ClientRestriction {
  allowedSQLTargets: number[];
  allowedETLTargets: number[];
  allowDownload: boolean;
  maximumObjects: number;
}

interface ClientPricingPlan {
  planId: string;
  planType: string;
  price: number;
  duration: string;
  freeTablesViews: number;
  freeAnalyzerReports: number;
  objectLevelPricing: ClientObjectLevelPricing[];
  dateCreated: string;
  createdBy: string;
  dateModified: string;
  modifiedBy: string;
  startDate?: string;
  endDate?: string;
}

interface ClientObjectLevelPricing {
  objectType: string;
  price?: number;
}

class Client {
  id: string;
  code: string;
  name: string;
  etlPlatformCount: number;
  dateCreated: string;
  dateModified: string;
  projectCount: number;
  isActive: boolean;
  clientRestriction?: ClientRestriction;
  pricingPlan?: ClientPricingPlan;

  constructor(data: ClientData) {
    this.id = data.id;
    this.code = data.code;
    this.name = data.name;
    this.etlPlatformCount = data.etlPlatformCount;
    this.dateCreated = data.dateCreated;
    this.dateModified = data.dateModified;
    this.projectCount = data.projectCount;
    this.isActive = data.isActive;
    this.clientRestriction = data.clientRestriction;
    this.pricingPlan = data.pricingPlan;
  }

  setClientRestriction(rectriction?: ClientRestriction) {
    this.clientRestriction = rectriction;
  }

  setPricingPlan(plan?: ClientPricingPlan) {
    this.pricingPlan = plan;
  }
}

export type { ClientData, ClientPricingPlan, ClientObjectLevelPricing };
export { Client };
