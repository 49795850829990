import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { AlertProps } from "./Alert.types";

export default function Alert({
  variant = "info",
  show = false,
  className,
  children,
  dismissible = false,
}: AlertProps) {
  const [hide, setHide] = useState(!show);
  let contentClass = ["border rounded-md p-4", className];
  let iconClass = ["shrink-0 w-5 h-5"];
  let bodyClass = ["ml-3"];
  let buttonClass = [
    "inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2",
  ];
  let buttonIconClass = ["w-5 h-5"];
  let icon: IconDefinition;

  switch (variant) {
    case "success":
      contentClass.push("border-success-200 bg-success-50");
      iconClass.push("text-success-400");
      bodyClass.push("text-success-700");
      buttonClass.push(
        "text-success-50 bg-success-50 text-success-500 hover:bg-success-100 focus:ring-success-600 focus:ring-offset-success-50"
      );
      buttonIconClass.push("text-success-400");
      icon = solid("circle-check");
      break;
    case "danger":
      contentClass.push("border-danger-200 bg-danger-50");
      iconClass.push("text-danger-600");
      bodyClass.push("text-danger-700");
      buttonClass.push(
        "text-danger-50 bg-danger-50 text-danger-500 hover:bg-danger-100 focus:ring-danger-600 focus:ring-offset-danger-50"
      );
      buttonIconClass.push("text-danger-400");
      icon = solid("square-xmark");
      break;
    case "warning":
      contentClass.push("border-warning-200 bg-warning-50");
      iconClass.push("text-warning-400");
      bodyClass.push("text-warning-800");
      buttonClass.push(
        "text-warning-50 bg-warning-50 text-warning-500 hover:bg-warning-100 focus:ring-warning-600 focus:ring-offset-warning-50"
      );
      buttonIconClass.push("text-warning-400");
      icon = solid("exclamation-triangle");
      break;
    default:
      contentClass.push("border-info-200 bg-info-50");
      iconClass.push("text-info-400");
      bodyClass.push("text-info-700");
      buttonClass.push(
        "text-info-50 bg-info-50 text-info-500 hover:bg-info-100 focus:ring-info-600 focus:ring-offset-info-50"
      );
      buttonIconClass.push("text-info-400");
      icon = solid("circle-info");
  }

  bodyClass.push("grow");

  return (
    <Transition
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-1 opacity-0 "
      enterTo="translate-y-0 opacity-100"
      leave="transition ease-in"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={!hide}
      appear
    >
      <div className={contentClass.join(" ")} role="alert">
        <div className="flex items-center">
          <FontAwesomeIcon
            icon={icon}
            className={iconClass.join(" ")}
            aria-hidden={true}
          />
          <div className={bodyClass.join(" ")}>{children}</div>
          {dismissible && (
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  className={buttonClass.join(" ")}
                  onClick={() => {
                    setHide(true);
                  }}
                >
                  <span className="sr-only">Dismiss</span>
                  <FontAwesomeIcon
                    icon={regular("xmark")}
                    className={buttonIconClass.join(" ")}
                    aria-hidden={true}
                  />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Transition>
  );
}
