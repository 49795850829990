type UserRole = "ADMIN" | "BILLING" | "OWNER" | "EDITOR" | "REVIEWER";

interface AuthToken {
  token: string;
  refreshToken: string;
  refreshTokenExpiry: string;
  user?: UserData;
}

interface UserData {
  id: number;
  email: string;
  fullName: string;
  role: UserRole;
  userClients: string[];
  dateCreated: string;
  dateModified: string;
  isActive: boolean;
  provider: string;
}

class User {
  id: number;
  email: string;
  fullName: string;
  role: UserRole;
  userClients: string[];
  dateCreated: string;
  dateModified: string;
  isActive: boolean;
  provider: string;

  static userRoles = {
    ADMIN: "ADMIN",
    OWNER: "OWNER",
    EDITOR: "EDITOR",
    REVIEWER: "REVIEWER",
  };

  constructor(data: UserData) {
    this.id = data.id;
    this.email = data.email;
    this.fullName = data.fullName;
    this.role = data.role;
    this.userClients = data.userClients;
    this.dateCreated = data.dateCreated;
    this.dateModified = data.dateModified;
    this.isActive = data.isActive;
    this.provider = data.provider;
  }

  isAdmin(): boolean {
    return this.role === User.userRoles.ADMIN;
  }

  isOwner(): boolean {
    return this.role === User.userRoles.OWNER;
  }

  isEditor(): boolean {
    return this.role === User.userRoles.EDITOR;
  }

  isReviewer(): boolean {
    return this.role === User.userRoles.REVIEWER;
  }
}

export type { UserData, UserRole, AuthToken };
export { User };
