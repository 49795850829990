import { InputHTMLAttributes, useRef } from "react";
import Input from "../../../_ui-kit/Input/Input";
import InputGroup from "../../../_ui-kit/InputGroup/InputGroup";
import PasswordRules from "./PaswordRules";

interface RuledPasswordInputProps {
  id: InputHTMLAttributes<HTMLInputElement>["id"];
  name: InputHTMLAttributes<HTMLInputElement>["name"];
  label: string;
  passwordHasErrors: boolean;
  setPasswordHasErrors: (param: boolean) => void;
}

function RuledPasswordInput({
  id,
  name, 
  label,
  passwordHasErrors,
  setPasswordHasErrors,
}: RuledPasswordInputProps) {
  const newPasswordRef = useRef<HTMLInputElement>(null);
  const inputDescription = `${id}-rules-description`;

  return (
    <InputGroup label={label} inputId="signin-password">
      <Input
        ref={newPasswordRef}
        id={id}
        name={name}
        size="lg"
        isInvalid={passwordHasErrors}
        ariaDescribedby={inputDescription}
        hasAddon={true}
        isPasswordInput={true}
        required
      />
      <PasswordRules
        id={inputDescription}
        className="mt-3.5"
        inputRef={newPasswordRef}
        setHastErrors={setPasswordHasErrors}
      />
    </InputGroup>
  );
}

export default RuledPasswordInput;
