import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useContext } from "react";
import { ClientContext } from "../../contexts/ClientContext";
import { ProjectContext } from "../../contexts/ProjectContext";
import { UserContext } from "../../contexts/UserContext";
import { Button } from "../../_ui-kit";
import { MasterPage } from "../../_ui-kit/MasterPage";
import { PageTransition } from "../../_ui-kit/PageTransition";

interface ProjectRouteProps {
  children: JSX.Element;
  pageTitle: string;
}

function ProjectRoute({ children, pageTitle }: ProjectRouteProps) {
  const { authUser } = useContext(UserContext);
  const { isLoadingClientListInContext } = useContext(ClientContext);
  const { currentProject, isLoadingProjectListInContext } =
    useContext(ProjectContext);

  if (
    isLoadingProjectListInContext ||
    isLoadingClientListInContext ||
    currentProject
  )
    return children;

  return (
    <MasterPage heading={pageTitle}>
      <PageTransition>
        <div id="content" className="px-6 py-10 max-w-lg mx-auto">
          <div className="min-h-full py-16 px-8">
            <h1 className="mb-4 text-3xl font-bold tracking-tight text-gray-900">
              No projects assigned
            </h1>
            <p className="text-base text-gray-600">
              {(authUser?.isAdmin() || authUser?.isOwner()) &&
                "Ready to start working? Begin your journey by launching a fresh project and unlocking the power of analyzing and translating your libraries!"}
              {(authUser?.isReviewer() || authUser?.isEditor()) &&
                "There are no projects assigned to your user account. Feel free to reach out to your client space owner for more information."}
            </p>
            <div className="mt-10 flex items-center space-x-2">
              {(authUser?.isAdmin() || authUser?.isOwner()) && (
                <Button
                  label="Set up a project"
                  href="/configure/projects-setup"
                />
              )}
              <Button
                label="Contact support"
                icon={solid("arrow-right")}
                variant="gray"
                href="mailto:shiftcloud-support@nextpathway.com"
                trailingIcon
                soft
                external
              />
            </div>
          </div>
        </div>
      </PageTransition>
    </MasterPage>
  );
}

export default ProjectRoute;
