import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Button } from "../../../_ui-kit/Button";
import { classNames } from "../../../_ui-kit/utils";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

interface DashboardCardProps {
  heading: string;
  description: string;
  children: React.ReactNode;
  ctaString?: string;
  icon?: IconDefinition;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  variant?: "primary" | "secondary" | "success" | "warning" | "danger" | "info";
}

function DashboardCard({
  heading,
  description,
  children,
  ctaString = "View more",
  icon = solid("arrow-right"),
  onClick,
  disabled,
  variant = "danger",
}: DashboardCardProps) {
  function getCardVariant() {
    switch (variant) {
      case "primary":
        return "bg-primary-600";
      case "secondary":
        return "bg-secondary-600";
      case "success":
        return "bg-success-600";
      case "warning":
        return "bg-warning-600";
      case "danger":
        return "bg-danger-600";
      case "info":
        return "bg-info-600";
      default:
        return "bg-gray-600";
    }
  }

  return (
    <div className="relative bg-white rounded-lg shadow overflow-hidden">
      <div className="flex flex-col items-center p-6 text-center">
        <h3 className="uppercase font-medium text-gray-500 mb-5">{heading}</h3>
        {children}
        <p className="mt-4 mb-6 text-sm text-gray-700">{description}</p>
        <Button
          label={ctaString}
          variant={variant}
          icon={icon}
          onClick={onClick}
          disabled={disabled}
          trailingIcon
          outline
          fullWidth
        />
      </div>
      <div
        className={classNames("inset-x-full bottom-0 h-1.5", getCardVariant())}
        role="presentation"
      />
    </div>
  );
}

export default DashboardCard;
