import { MasterPage } from "../../_ui-kit/MasterPage";
import type { ChartData } from "chart.js";
import { PageTransition } from "../../_ui-kit/PageTransition";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Fragment, useContext, useEffect, useState } from "react";
import {
  DoughnutChart,
  chartColorPalettes,
} from "../../_ui-kit/Charts/Charts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Combobox, Transition } from "@headlessui/react";
import { ProjectContext } from "../../contexts/ProjectContext";
import { VendorAsset } from "../../_ui-kit/VendorCard";
import { classNames } from "../../_ui-kit/utils";
import AnalyzeApi from "../../api/AnalyzeApi";

interface Application {
  id: number;
  name: string;
}

interface SequenceJob {
  applicationId: number;
  applicationName: string;
  id: string;
  name: string;
  parallelJobCount: number;
  parallelJobs: string[];
}

interface ParallelJob {
  id: string;
  name: string;
  subCategory: string;
  totalConnections: number;
  platform: string;
}

function getJobsBySubcategoryChartData(
  jobs: ParallelJob[]
): ChartData<"doughnut"> {
  const labels: string[] = [];
  const data: number[] = [];
  const colors: string[] = [];

  jobs.forEach((job) => {
    let exists = false;
    for (let index = 0; index < labels.length; index++) {
      if (labels[index] === job.subCategory) {
        data[index] += 1;
        exists = true;
        break;
      }
    }
    if (!exists) {
      labels.push(job.subCategory);
      data.push(1);
      colors.push(chartColorPalettes.secondary[colors.length]);
    } else {
      exists = false;
    }
  });

  return {
    labels,
    datasets: [
      {
        label: "Jobs",
        data,
        backgroundColor: colors,
      },
    ],
  };
}

function SequenceJobsOverview() {
  const analyzeApi = new AnalyzeApi();
  const { currentProject } = useContext(ProjectContext);
  const [applicationsList, setApplicationsList] = useState<Application[]>([]);
  const [selectedApplication, setSelectedApplication] =
    useState<Application | null>(null);
  const [sequenceJobsList, setSquenceJobsList] = useState<SequenceJob[]>([]);
  const [selectedSequenceJob, setSelectedSequenceJob] =
    useState<SequenceJob | null>(null);
  const [parallelJobsList, setParallelJobsList] = useState<ParallelJob[]>([]);
  const [filteredParallelJobs, setFilteredParallelJobs] = useState<
    ParallelJob[]
  >([]);
  const [doughnutChartData, setDoughnutChartData] =
    useState<ChartData<"doughnut"> | null>(null);
  const [applicationsListQuery, setApplicationsListQuery] = useState("");
  const filteredApplicationsList =
    applicationsListQuery === ""
      ? applicationsList
      : applicationsList.filter((app) => {
          return app.name
            .toLowerCase()
            .includes(applicationsListQuery.toLowerCase());
        });
  const [sequenceJobsListQuery, setSequenceJobsListQuery] = useState("");
  const filteredSequenceJobsList = selectedApplication
    ? sequenceJobsList.filter((job) => {
        let applicationMatch = job.applicationId === selectedApplication?.id;
        if (sequenceJobsListQuery === "") return applicationMatch;

        return (
          applicationMatch &&
          job.name.toLowerCase().includes(sequenceJobsListQuery.toLowerCase())
        );
      })
    : sequenceJobsList;

  useEffect(() => {
    async function getSequenceJobs(projectId: string) {
      const result = await analyzeApi.getSequenceJobSummary(projectId);
      const jobs: SequenceJob[] = await result.json();
      const applications: Application[] = [];
      setSquenceJobsList(jobs);

      jobs.forEach((job) => {
        if (
          applications.filter((app) => app.id === job.applicationId).length < 1
        )
          applications.push({
            id: job.applicationId,
            name: job.applicationName,
          });
      });

      setApplicationsList(applications);
      setSelectedApplication(applications[0]);
    }

    async function getParallelJobs(projectId: string) {
      const result = await analyzeApi.getParallelJobSummary(projectId);
      const jobs: ParallelJob[] = await result.json();
      setParallelJobsList(jobs);
    }

    if (currentProject) {
      getSequenceJobs(currentProject.uuid);
      getParallelJobs(currentProject.uuid);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  useEffect(() => {
    if (selectedApplication)
      setSelectedSequenceJob(filteredSequenceJobsList[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApplication]);

  useEffect(() => {
    if (selectedSequenceJob && parallelJobsList.length > 0) {
      const jobs: ParallelJob[] = [];
      selectedSequenceJob.parallelJobs.forEach((id) => {
        const job: ParallelJob | undefined = parallelJobsList.find(
          (job) => job.id === id
        );
        if (job) jobs.push(job);
      });
      setFilteredParallelJobs(jobs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSequenceJob, parallelJobsList]);

  useEffect(() => {
    if (filteredParallelJobs.length > 0)
      setDoughnutChartData(getJobsBySubcategoryChartData(filteredParallelJobs));
  }, [filteredParallelJobs]);

  return (
    <MasterPage heading="Sequence jobs" useBackButton>
      <PageTransition>
        <div id="content" className="p-6 2xl:max-w-7xl 2xl:mx-auto 2xl:px-0">
          <h2 className="mt-2 mb-6 text-xl font-medium">
            High level sequence jobs analysis
          </h2>
          <div className="flex flex-col px-6 py-5 bg-white rounded-lg shadow">
            <h3 className="mb-6 text-lg font-medium">
              Parallel jobs by transformations
            </h3>
            <div className="grid grid-cols-2 gap-5">
              {applicationsList && (
                <div className="relative flex flex-col space-y-2">
                  <label className="block w-24 text-sm text-gray-500">
                    Application
                  </label>
                  <Combobox
                    value={selectedApplication}
                    onChange={setSelectedApplication}
                  >
                    <div className="relative">
                      <div className="relative w-full">
                        <Combobox.Input
                          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-4 pr-12 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                          displayValue={(app: Application) => app?.name}
                          onChange={(event) =>
                            setApplicationsListQuery(event.target.value)
                          }
                          spellCheck={false}
                          autoComplete="off"
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                          <span className="pointer-events-none relative inset-y-0 right-0 flex flex-col justify-center items-center px-2 text-gray-500">
                            <FontAwesomeIcon
                              icon={solid("sort-up")}
                              className="w-2.5 h-2.5"
                              aria-hidden={true}
                            />
                            <FontAwesomeIcon
                              icon={solid("sort-down")}
                              className="w-2.5 h-2.5 -mt-1.5"
                              aria-hidden={true}
                            />
                          </span>
                        </Combobox.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setApplicationsListQuery("")}
                      >
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {filteredApplicationsList.length === 0 &&
                          applicationsListQuery !== "" ? (
                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                              Nothing found.
                            </div>
                          ) : (
                            filteredApplicationsList.map((application) => (
                              <Combobox.Option
                                key={application.id}
                                className={({ active, selected }) =>
                                  `relative cursor-default select-none flex py-2 pl-4 pr-9 text-sm ${
                                    selected && !active
                                      ? "bg-primary-100 text-primary-700"
                                      : active
                                      ? "bg-primary-50 text-primary-700"
                                      : "bg-white text-gray-900"
                                  }`
                                }
                                value={application}
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={`block truncate mr-3 ${
                                        selected ? "font-medium" : "font-normal"
                                      }`}
                                    >
                                      {application.name}
                                    </span>
                                    {selected ? (
                                      <span
                                        className={`absolute inset-y-0 right-0 flex items-center pr-4 text-primary-600`}
                                      >
                                        <span className="text-base">
                                          <span className="sr-only">
                                            Selected
                                          </span>
                                          <FontAwesomeIcon
                                            icon={solid("check")}
                                            aria-hidden={true}
                                          />
                                        </span>
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Combobox.Option>
                            ))
                          )}
                        </Combobox.Options>
                      </Transition>
                    </div>
                  </Combobox>
                </div>
              )}
              {sequenceJobsList && (
                <div className="relative flex flex-col space-y-2">
                  <label className="block w-24 text-sm text-gray-500">
                    Sequence job
                  </label>
                  <Combobox
                    value={selectedSequenceJob}
                    onChange={setSelectedSequenceJob}
                  >
                    <div className="relative">
                      <div className="relative w-full">
                        <Combobox.Input
                          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-4 pr-12 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                          displayValue={(job: SequenceJob) => job?.name}
                          onChange={(event) =>
                            setSequenceJobsListQuery(event.target.value)
                          }
                          spellCheck={false}
                          autoComplete="off"
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                          <span className="pointer-events-none relative inset-y-0 right-0 flex flex-col justify-center items-center px-2 text-gray-500">
                            <FontAwesomeIcon
                              icon={solid("sort-up")}
                              className="w-2.5 h-2.5"
                              aria-hidden={true}
                            />
                            <FontAwesomeIcon
                              icon={solid("sort-down")}
                              className="w-2.5 h-2.5 -mt-1.5"
                              aria-hidden={true}
                            />
                          </span>
                        </Combobox.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setSequenceJobsListQuery("")}
                      >
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {filteredSequenceJobsList.length === 0 &&
                          sequenceJobsListQuery !== "" ? (
                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                              Nothing found.
                            </div>
                          ) : (
                            filteredSequenceJobsList.map((job) => (
                              <Combobox.Option
                                key={job.id}
                                className={({ active, selected }) =>
                                  `relative cursor-default select-none flex py-2 pl-4 pr-9 text-sm ${
                                    selected && !active
                                      ? "bg-primary-100 text-primary-700"
                                      : active
                                      ? "bg-primary-50 text-primary-700"
                                      : "bg-white text-gray-900"
                                  }`
                                }
                                value={job}
                              >
                                {({ active, selected }) => (
                                  <>
                                    <span
                                      className={`block truncate mr-3 ${
                                        selected ? "font-medium" : "font-normal"
                                      }`}
                                    >
                                      {job.name}
                                    </span>
                                    <span
                                      className={`ml-auto mr-3 ${
                                        active || selected
                                          ? "text-primary-600"
                                          : "text-gray-500"
                                      }`}
                                    >
                                      {job.parallelJobs.length} Parallel jobs
                                    </span>
                                    {selected ? (
                                      <span
                                        className={`absolute inset-y-0 right-0 flex items-center pr-4 text-primary-600`}
                                      >
                                        <span className="text-base">
                                          <span className="sr-only">
                                            Selected
                                          </span>
                                          <FontAwesomeIcon
                                            icon={solid("check")}
                                            aria-hidden={true}
                                          />
                                        </span>
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Combobox.Option>
                            ))
                          )}
                        </Combobox.Options>
                      </Transition>
                    </div>
                  </Combobox>
                </div>
              )}
            </div>
            {doughnutChartData && (
              <div className="w-2/3 px-2 my-8">
                <DoughnutChart
                  data={doughnutChartData}
                  title="Parallel jobs"
                  size="lg"
                />
              </div>
            )}
            {filteredParallelJobs.length > 0 && (
              <div className="mb-1 ring-1 ring-gray-300 rounded-md overflow-hidden">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-6 pr-3 text-left text-sm font-medium"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-medium"
                      >
                        Transformation
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-medium"
                      >
                        Connections
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-3 pr-6 text-left text-sm font-medium"
                      >
                        Platform
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {filteredParallelJobs.map((job, index) => {
                      return (
                        <tr key={job.id}>
                          <td
                            className={classNames(
                              index === 0 ? "" : "border-t border-transparent",
                              "relative py-4 pl-6 pr-3 text-sm"
                            )}
                          >
                            {job.name}
                            {index !== 0 ? (
                              <div
                                className="absolute right-0 left-6 -top-px h-px bg-gray-200"
                                role="presentation"
                                aria-hidden={true}
                              />
                            ) : null}
                          </td>
                          <td className="py-4 px-3 text-sm text-gray-500">
                            {job.subCategory}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                            {job.totalConnections}
                          </td>
                          <td
                            className={classNames(
                              index === 0 ? "" : "border-t border-transparent",
                              "relative py-3.5 pl-3 pr-6 text-right text-sm font-medium"
                            )}
                          >
                            <div className="flex flex-nowrap items-center space-x-3">
                              <VendorAsset
                                platform={job.platform}
                                type="icon"
                              />
                              <span>{job.platform}</span>
                            </div>
                            {index !== 0 ? (
                              <div
                                className="absolute right-6 left-0 -top-px h-px bg-gray-200"
                                role="presentation"
                                aria-hidden={true}
                              />
                            ) : null}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </PageTransition>
    </MasterPage>
  );
}

export default SequenceJobsOverview;
