import { ReactComponent as AppLogoShiftFull } from "../../../images/brand/logo-shift-full.svg";

function HeroShiftCloud() {
  return (
    <div className="flex flex-col h-full bg-white border-r border-r-gray-200 shadow-md overflow-hidden">
      <div className="flex flex-col items-center h-full min-h-[50rem] px-12 pt-12 bg-hero-shift bg-bottom bg-no-repeat bg-[length:125%]">
        <AppLogoShiftFull
          title="SHIFT&trade; Cloud"
          role="img"
          className="flex-shrink-0 w-28 3xl:w-32 4xl:w-44 h-auto"
        />
        <div className="flex flex-col items-center max-w-lg 3xl:max-w-3xl mt-6 space-y-4 4xl:space-y-8">
          <h1 className="text-center text-5xl 3xl:text-6xl 4xl:text-7xl text-primary-700 font-bold tracking-wide">
            Translate your legacy code for the cloud.
          </h1>
          <p className="max-w-xs 3xl:max-w-xl text-center text-lg 3xl:text-xl 4xl:text-3xl 4xl:leading-10 text-gray-500 3xl:tracking-wide">
            Accelerate the translation of your legacy code and data pipelines to
            cloud targets with{" "}
            <strong className="text-primary-700 font-medium">
              SHIFT&trade; Cloud
            </strong>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

export default HeroShiftCloud;
