import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Button } from "../../../_ui-kit";
import { classNames } from "../../../_ui-kit/utils";

interface TaskObjectSummaryItemProps {
  title: string;
  subTitle: string;
  error?: boolean;
  warning?: boolean;
  value?: number;
  moreDetails?: boolean;
  onClick?: () => void;
}

function TaskObjectSummaryItem({
  title,
  subTitle,
  error = false,
  warning = false,
  value,
  moreDetails,
  onClick,
}: TaskObjectSummaryItemProps) {
  const hasMoreDetails = moreDetails && value !== undefined && value > 0;
  return (
    <div
      className={classNames(
        "flex items-center space-x-8 py-4 print:break-inside-avoid",
        hasMoreDetails ? "group" : ""
      )}
    >
      <dt className="w-full space-y-1">
        <div className="text-gray-900 text-sm font-medium">{title}</div>
        <div className="shrink-0 text-gray-500 text-sm">{subTitle}</div>
      </dt>
      <dd
        className="shrink-0 text-right text-gray-900 font-medium focus:outline-none"
        tabIndex={0}
      >
        <span
          className={classNames(
            hasMoreDetails
              ? "group-hover:hidden group-focus-within:hidden"
              : "",
            error
              ? "text-danger-600"
              : warning
              ? "text-warning-700"
              : "text-gray-900"
          )}
        >
          {value === undefined
            ? "Pending"
            : value >= 0
            ? value.toLocaleString()
            : "N/A"}
        </span>
        {hasMoreDetails && (
          <div className="group-hover:block group-focus-within:block hidden">
            <Button
              label="Details"
              variant={error ? "danger" : warning ? "warning" : "white"}
              size="xs"
              icon={solid("circle-chevron-right")}
              onClick={onClick}
              radius="pill"
              uppercase
              trailingIcon
            />
          </div>
        )}
      </dd>
    </div>
  );
}

export default TaskObjectSummaryItem;
