import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../../../_ui-kit/Input/Input";
import InputGroup from "../../../_ui-kit/InputGroup/InputGroup";
import { SlideOver } from "../../../_ui-kit/SlideOver";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  ClientObjectLevelPricing,
  ClientPricingPlan,
} from "../../../models/Client";
import { Button } from "../../../_ui-kit";
import { ChangeEvent, useContext, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ConfigureContext } from "../../../contexts/ConfigureContext";
import Select from "../../../_ui-kit/Select/Select";
import PricingPlan from "../../../models/PricingPlan";

interface PricingPlanEditSliderProps {
  openSlideOver: boolean;
  setOpenSlideOver: (open: boolean) => void;
  sliderTitle: string;
  editPricingPlan?: ClientPricingPlan;
  setEditPricingPlan: (pricingPlan?: ClientPricingPlan) => void;
  update: boolean;
}

const durationOptions = [
  { value: "WEEKLY", label: "Weekly" },
  { value: "MONTHLY", label: "Monthly" },
  { value: "ANNUALLY", label: "Annually" },
];

const defaultPricingPlan: ClientPricingPlan = {
  planId: "",
  planType: "",
  price: 0,
  duration: "MONTHLY",
  freeTablesViews: 0,
  freeAnalyzerReports: 0,
  objectLevelPricing: [
    {
      objectType: "TABLE",
      price: 0,
    },
    {
      objectType: "VIEW",
      price: 0,
    },
    {
      objectType: "PROCEDURE_FUNCTION",
      price: 0,
    },
    {
      objectType: "ETL_JOB",
      price: 0,
    },
    {
      objectType: "ANALYZER_REPORT",
      price: 0,
    },
    {
      objectType: "NON_BILLING",
    },
  ],
  dateCreated: "",
  createdBy: "",
  dateModified: "",
  modifiedBy: "",
};

export default function PricingPlanEditSlider({
  openSlideOver,
  setOpenSlideOver,
  sliderTitle,
  editPricingPlan = defaultPricingPlan,
  setEditPricingPlan,
  update,
}: PricingPlanEditSliderProps) {
  const queryClient = useQueryClient();
  const { configureApi } = useContext(ConfigureContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const addProjectMutation = useMutation(
    (newPricingPlan: any) => {
      return configureApi.addPricingPlan(
        newPricingPlan.planType,
        newPricingPlan.price,
        newPricingPlan.duration,
        newPricingPlan.freeTablesViews,
        newPricingPlan.freeAnalyzerReports,
        newPricingPlan.objectLevelPricing
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["pricing-plans-list"]);
        queryClient.invalidateQueries(["users-list"]);
        queryClient.invalidateQueries(["clients-list"]);
        queryClient.invalidateQueries(["clients-list-context"]);
        queryClient.invalidateQueries(["projects-list"]);
        queryClient.invalidateQueries(["projects-list-context"]);
      },
    }
  );

  const updateProjectMutation = useMutation(
    (currentPricingPlan: any) => {
      return configureApi.updatePricingPlan(
        currentPricingPlan.planId,
        currentPricingPlan.planType,
        currentPricingPlan.price,
        currentPricingPlan.duration,
        currentPricingPlan.freeTablesViews,
        currentPricingPlan.freeAnalyzerReports,
        currentPricingPlan.objectLevelPricing
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["pricing-plans-list"]);
        queryClient.invalidateQueries(["users-list"]);
        queryClient.invalidateQueries(["clients-list"]);
        queryClient.invalidateQueries(["clients-list-context"]);
        queryClient.invalidateQueries(["projects-list"]);
        queryClient.invalidateQueries(["projects-list-context"]);
      },
    }
  );

  function handleSubmitHandler(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    setIsSubmitting(true);

    const plan = {
      planId: editPricingPlan.planId,
      planType: editPricingPlan.planType,
      price: editPricingPlan.price,
      duration: editPricingPlan.duration,
      freeTablesViews: editPricingPlan.freeTablesViews,
      freeAnalyzerReports: editPricingPlan.freeAnalyzerReports,
      objectLevelPricing: editPricingPlan.objectLevelPricing,
    };

    if (update) {
      updateProjectMutation.mutate(plan, {
        onSettled: () => {
          closeSlideOver(false);
        },
      });
    } else {
      addProjectMutation.mutate(plan, {
        onSettled: () => {
          closeSlideOver(false);
        },
      });
    }
  }

  function pricingPlanItemPriceChangeHandler(
    e: ChangeEvent<HTMLInputElement>,
    plan: ClientObjectLevelPricing
  ): void {
    const pricingPlan = structuredClone(editPricingPlan);
    if (!pricingPlan) return;

    pricingPlan.objectLevelPricing = pricingPlan.objectLevelPricing.map(
      (item) => {
        if (item.objectType === plan.objectType) {
          item.price = +e.target.value;
        }
        return item;
      }
    );
    setEditPricingPlan(pricingPlan);
  }

  function closeSlideOver(close: boolean): void {
    setIsSubmitting(close);
    setOpenSlideOver(close);
    setTimeout(() => setEditPricingPlan(undefined), 750);
  }

  return (
    <SlideOver
      title={sliderTitle}
      show={openSlideOver}
      onClose={isSubmitting ? () => {} : closeSlideOver}
      scroll={false}
    >
      <div className="h-full border-t border-t-gray-200 bg-white overflow-y-auto">
        <div className="flex flex-col px-6 py-5">
          <form onSubmit={handleSubmitHandler}>
            <div className="flex flex-col space-y-6">
              <InputGroup
                label="Name"
                inputId="pricing-plan-type"
                className="text-sm"
              >
                <Input
                  id="pricing-plan-type"
                  type="text"
                  autoComplete="off"
                  required={true}
                  onChange={(e) =>
                    setEditPricingPlan({
                      ...editPricingPlan,
                      planType: e.target.value,
                    })
                  }
                  value={editPricingPlan.planType}
                />
              </InputGroup>
              <div className="grid grid-cols-2 gap-4">
                <InputGroup
                  label="Cycle"
                  inputId="pricing-plan-duration"
                  className="text-sm"
                >
                  <Select
                    id="pricing-plan-duration"
                    required={true}
                    onChange={(e) =>
                      setEditPricingPlan({
                        ...editPricingPlan,
                        duration: e.target.value,
                      })
                    }
                    value={editPricingPlan.duration}
                  >
                    {durationOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </InputGroup>
                <InputGroup
                  label="Access fee"
                  inputId="pricing-plan-price"
                  className="text-sm"
                >
                  <span className="absolute inset-y-0 flex items-center pl-2.5 pb-px pointer-events-none">
                    <FontAwesomeIcon
                      icon={solid("dollar")}
                      className="w-3.5 h-3.5 text-gray-500"
                    />
                  </span>
                  <Input
                    id="pricing-plan-price"
                    type="number"
                    step={500}
                    min={0}
                    onChange={(e) =>
                      setEditPricingPlan({
                        ...editPricingPlan,
                        price: +e.target.value,
                      })
                    }
                    value={editPricingPlan.price}
                    required={true}
                    placeholder="0.00"
                    className="!pl-7"
                  />
                </InputGroup>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <InputGroup
                  label="Free analyzer reports"
                  inputId="pricing-plan-free-reports"
                  className="text-sm"
                >
                  <Select
                    id="pricing-plan-free-reports"
                    name="pricing-plan-free-reports"
                    required={true}
                    onChange={(e) =>
                      setEditPricingPlan({
                        ...editPricingPlan,
                        freeAnalyzerReports: +e.target.value,
                      })
                    }
                    value={editPricingPlan.freeAnalyzerReports}
                  >
                    {PricingPlan.freeReportsOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </InputGroup>
                <InputGroup
                  label="Free tables and views"
                  inputId="pricing-plan-free-tables-views"
                  className="text-sm"
                >
                  <Select
                    id="pricing-plan-free-tables-views"
                    name="pricing-plan-free-tables-views"
                    required={true}
                    onChange={(e) =>
                      setEditPricingPlan({
                        ...editPricingPlan,
                        freeTablesViews: +e.target.value,
                      })
                    }
                    value={editPricingPlan.freeTablesViews}
                  >
                    {PricingPlan.freeTablesViewsOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </InputGroup>
              </div>
              <div className="!mt-8 pt-6 border-t-2 border-gray-200">
                <h3 className="mb-3 font-medium uppercase text-gray-500">
                  Pricing details
                </h3>
                <div className="grid grid-cols-3 gap-4">
                  {new PricingPlan(editPricingPlan)
                    .getBillingObjectLevels()
                    .map((plan, index) => {
                      const id = `client-plan-${plan.objectType}-${index}`;
                      return (
                        <div key={`item-${index}`}>
                          <InputGroup
                            label={PricingPlan.getObjectTypeDisplayName(
                              plan.objectType
                            )}
                            inputId={id}
                            className="text-sm"
                          >
                            <span className="absolute inset-y-0 flex items-center pl-2.5 pb-px pointer-events-none">
                              <FontAwesomeIcon
                                icon={solid("dollar")}
                                className="w-3.5 h-3.5 text-gray-500"
                              />
                            </span>
                            <Input
                              id={id}
                              type="number"
                              min={0}
                              step="any"
                              autoComplete="off"
                              required={true}
                              onChange={(e) =>
                                pricingPlanItemPriceChangeHandler(e, plan)
                              }
                              value={plan.price}
                              placeholder="0.00"
                              className="!pl-7"
                            />
                          </InputGroup>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="self-start mt-12">
              <Button
                type="submit"
                label={editPricingPlan.planType ? "Save changes" : "Add plan"}
                size="lg"
                icon={editPricingPlan ? solid("floppy-disk") : solid("plus")}
                isLoading={isSubmitting}
              />
            </div>
          </form>
        </div>
      </div>
    </SlideOver>
  );
}
