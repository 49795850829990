import { ReactNode } from "react";
import { Route } from "react-router-dom";
import RestrictedRoute from "./components/RestrictedRoute";
import TranslateList from "../pages/translate/TranslateList";
import TranslateProgress from "../pages/translate/TranslateProgress";
import TranslateReportSummary from "../pages/translate/TranslateReportSummary";
import TranslateCodeResults from "../pages/translate/TranslateResults";
import ProjectRoute from "./components/ProjectRoute";

function TranslateRoutes(): ReactNode {
  return (
    <>
      <Route
        path="translate/list"
        element={
          <RestrictedRoute>
            <ProjectRoute pageTitle="Translate">
              <TranslateList />
            </ProjectRoute>
          </RestrictedRoute>
        }
      />
      <Route
        path="translate/progress/:taskId"
        element={
          <RestrictedRoute>
            <TranslateProgress />
          </RestrictedRoute>
        }
      />
      <Route
        path="translate/report-summary/:taskId"
        element={
          <RestrictedRoute>
            <TranslateReportSummary />
          </RestrictedRoute>
        }
      />
      <Route
        path="translate/code-results/:taskId"
        element={
          <RestrictedRoute>
            <TranslateCodeResults />
          </RestrictedRoute>
        }
      />
    </>
  );
}

export default TranslateRoutes;
