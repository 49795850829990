import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Alert from "../../_ui-kit/Alert/Alert";
import { MasterPage } from "../../_ui-kit/MasterPage";
import MarketplaceApi from "../../api/MarketplaceApi";
import InputGroup, {
  InputGroupErrorDescription,
} from "../../_ui-kit/InputGroup/InputGroup";
import Input from "../../_ui-kit/Input/Input";
import { Button } from "../../_ui-kit/Button";
import { PageLoaderSpinner } from "../../_ui-kit/PageLoderSpinner";
import { useLocation, useNavigate } from "react-router-dom";
import { classNames } from "../../_ui-kit/utils";

interface SubscriptionResponse {
  clientSpaceName: string;
  id: string;
  subscriptionName: string;
  offerId: string;
  planId: string;
  orgCode: string;
  subscription: {
    id: string;
    publisherId: string;
    offerId: string;
    name: string;
    saasSubscriptionStatus: string;
    beneficiary: {
      emailId: string;
      objectId: string;
      tenantId: string;
      puid: string;
    };
    purchaser: {
      emailId: string;
      objectId: string;
      tenantId: string;
      puid: string;
    };
    planId: string;
    term: {
      startDate: string;
      endDate: string;
      termUnit: string;
    };
    autoRenew: boolean;
    isTest: boolean;
    isFreeTrial: boolean;
    allowedCustomerOperations: string[];
    sandboxType: string;
    created: string;
    lastModified: string;
    sessionMode: string;
  };
}

interface ActivationResponse {
  clientId: string;
  clientSpaceName: string;
  subscriptionId: string;
  subscriptionName: string;
  planId: string;
  ownerEmails: string[];
  status: boolean;
}

export default function MarketplaceSignUp() {
  const marketplaceApi = new MarketplaceApi();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [subscriptionToken] = useState(params.get("token"));
  const [idToken, setIdToken] = useState(params.get("id_token"));
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [subscription, setSubscription] = useState<SubscriptionResponse>();
  const [activation, setActivation] = useState<ActivationResponse>();
  const [isLoadingIdToken, setIsLoadingIdToken] = useState(true);
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(true);
  const [isActivatingSubscription, setIsActivatingSubscription] =
    useState(false);
  const [clientNameRequiredError, setClientNameRequiredError] = useState(false);

  function onError(error: string, redirect: boolean): void {
    setIsLoadingIdToken(false);
    setIsLoadingSubscription(false);
    setIsActivatingSubscription(false);
    if (redirect)
      navigate("/sign-in", {
        state: {
          from: {
            error,
          },
        },
        replace: true,
      });
    else setError(error);
  }

  async function loadSubscriptionInformation(
    idToken: string,
    subscriptionToken: string
  ): Promise<void> {
    try {
      const response = await marketplaceApi.getSubscription(
        idToken,
        subscriptionToken
      );
      if (response.ok) {
        const subs: SubscriptionResponse = await response.json();
        setSubscription(subs);
        setIsLoadingSubscription(false);
      } else {
        onError("Error while getting subscription.", true);
      }
    } catch (ex: any) {
      onError(ex, true);
    }
  }

  function clientNameBlurHandler(
    e: ChangeEvent<HTMLInputElement>,
    setEmailAddressRequiredError: (param: boolean) => void
  ): void {
    const user = e.target.value;
    if (!user) {
      setEmailAddressRequiredError(true);
    } else {
      setEmailAddressRequiredError(false);
    }
  }

  function signUpSubmitHandler(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    setIsActivatingSubscription(true);

    const activateSubscription = async (
      idToken: string,
      sub: SubscriptionResponse
    ): Promise<void> => {
      try {
        const formData = new FormData(e.currentTarget);
        const displayName = formData.get("signup-clientname") as string;
        const subscriptionId = sub.id;
        const planId = sub.planId;
        const clientCode = sub.orgCode;
        const beneficiary = sub.subscription.beneficiary.emailId;
        const purchaser = sub.subscription.purchaser.emailId;
        const ownerEmails = [beneficiary];

        if (beneficiary !== purchaser) ownerEmails.push(beneficiary);

        const response = await marketplaceApi.activateSubscription(
          idToken,
          subscriptionId,
          planId,
          clientCode,
          displayName,
          ownerEmails
        );
        if (response.ok) {
          const activation: ActivationResponse = await response.json();
          setActivation(activation);
          if (activation.status) {
            setIsActivatingSubscription(false);
            setSuccess("Subscription activated successfully.");
          } else {
            onError("Activation failed. Please reach out to support.", false);
          }
        } else {
          onError("Error while activating subscription.", false);
        }
      } catch (ex: any) {
        onError(ex, false);
      }
    };

    if (idToken && subscription) activateSubscription(idToken, subscription);
  }

  useEffect(() => {
    let checkPopup: NodeJS.Timeout;

    if (!subscriptionToken && !idToken) {
      onError("Authentication token is missing.", true);
    }

    if (!idToken && subscriptionToken) {
      const popup = window.open(
        marketplaceApi.apiGetIdToken,
        "_blank",
        `width=512,height=672,menubar=no,toolbar=no,resizable=no`
      );

      checkPopup = setInterval(() => {
        try {
          if (popup?.closed) {
            setIdToken(
              (popup.document.getElementById("hdnIdToken") as HTMLInputElement)
                .value
            );
            setIsLoadingIdToken(false);
            clearInterval(checkPopup);
          }
        } catch (ex) {
          clearInterval(checkPopup);
          popup?.close();
          onError("Error while getting Id Token.", true);
        }
      }, 1000);
    }

    if (idToken && !subscriptionToken) {
      window.close();
    }

    if (idToken && subscriptionToken) {
      loadSubscriptionInformation(idToken, subscriptionToken);
    }

    return () => {
      clearInterval(checkPopup);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToken, subscriptionToken, navigate]);

  return (
    <>
      {subscriptionToken && (
        <MasterPage type="signin" heading="Sign Up">
          {error && (
            <Alert variant="danger" className="mb-8" show={!!error} dismissible>
              <p className="text-sm font-medium">{error}</p>
            </Alert>
          )}
          {success && (
            <Alert
              variant="success"
              className="mb-8"
              show={!!success}
              dismissible
            >
              <p className="text-sm font-medium">{success}</p>
            </Alert>
          )}
          {(activation || subscription) && (
            <>
              <h2 className="mb-1 text-4xl font-bold text-gray-900">
                We're glad you're here!
              </h2>
              <p className="mb-6 text-sm text-gray-600">
                Let's get started with your subscription.
              </p>
            </>
          )}
          {!isLoadingSubscription && !activation && subscription && (
            <>
              {[subscription].map((sub) => {
                const list = [
                  {
                    id: 1,
                    title: "Offer",
                    description: sub.offerId,
                  },
                  {
                    id: 2,
                    title: "Plan",
                    description: sub.planId,
                  },
                  {
                    id: 3,
                    title: "Subscription",
                    description: sub.subscription.name,
                  },
                  {
                    id: 4,
                    title: "Beneficiary",
                    description: sub.subscription.beneficiary.emailId,
                  },
                  {
                    id: 5,
                    title: "Purchaser",
                    description: sub.subscription.purchaser.emailId,
                  },
                  {
                    id: 6,
                    title: "Client code",
                    description: sub.orgCode,
                  },
                ];
                return (
                  <div
                    key={sub.id}
                    className="mb-6 px-6 pt-4 pb-2 ring-1 ring-black ring-opacity-5 bg-white shadow-sm rounded-lg"
                  >
                    <h3 className="mb-1 text-sm leading-6 font-medium text-gray-500 text-left uppercase">
                      Subscription details
                    </h3>
                    <dl className="divide-y divide-gray-300 text-left text-sm">
                      {list.map((item) => {
                        return (
                          <div
                            key={item.id}
                            className="flex items-center space-x-4 py-3"
                          >
                            <dt className="shrink-0 w-1/3 text-gray-500">
                              {item.title}
                            </dt>
                            <dd className="grow font-medium text-gray-900">
                              {item.description}
                            </dd>
                          </div>
                        );
                      })}
                    </dl>
                  </div>
                );
              })}
              <form
                onSubmit={signUpSubmitHandler}
                className="flex flex-col space-y-6 text-left"
              >
                <InputGroup
                  label="Client space name"
                  inputId="signup-clientname"
                >
                  <Input
                    type="text"
                    id="signup-clientname"
                    name="signup-clientname"
                    size="lg"
                    autoComplete="clientname"
                    placeholder="Company - Department"
                    isInvalid={clientNameRequiredError}
                    onBlur={(e) =>
                      clientNameBlurHandler(e, setClientNameRequiredError)
                    }
                    ariaDescribedby={
                      clientNameRequiredError
                        ? "signup-clientname-required-error"
                        : undefined
                    }
                    required
                  />
                  {clientNameRequiredError && (
                    <InputGroupErrorDescription
                      id="signup-clientname-required-error"
                      text="Client space name is required."
                    />
                  )}
                </InputGroup>
                <Button
                  label="Sign up"
                  size="lg"
                  type="submit"
                  isLoading={isActivatingSubscription}
                />
              </form>
            </>
          )}
          {!isActivatingSubscription && activation && (
            <>
              {[activation].map((act) => {
                const list = [
                  {
                    id: 1,
                    title: "Plan",
                    description: (
                      <span className="font-medium text-gray-900">
                        {act.planId}
                      </span>
                    ),
                  },
                  {
                    id: 2,
                    title: "Owners",
                    description: (
                      <ul className="flex flex-col space-y-1">
                        {act.ownerEmails.map((owner, index) => (
                          <li key={`${owner}-${index}`}>
                            <a
                              href={`mailto:${owner}`}
                              className="text-primary-700 hover:underline"
                            >
                              {owner}
                            </a>
                          </li>
                        ))}
                      </ul>
                    ),
                  },
                  {
                    id: 3,
                    title: "Subscription",
                    description: (
                      <span className="font-medium text-gray-900">
                        {act.subscriptionName}
                      </span>
                    ),
                  },
                  {
                    id: 4,
                    title: "Client space name",
                    description: (
                      <span className="font-medium text-gray-900">
                        {act.clientSpaceName}
                      </span>
                    ),
                  },
                  {
                    id: 5,
                    title: "Status",
                    description: (
                      <span
                        className={classNames(
                          "inline-block px-3 py-1 rounded-full text-xs",
                          act.status
                            ? "bg-success-100 text-success-700"
                            : "bg-danger-100 text-danger-700"
                        )}
                      >
                        {act.status ? "Active" : "Inactive"}
                      </span>
                    ),
                  },
                ];
                return (
                  <div
                    key={act.subscriptionId}
                    className="mb-6 px-6 pt-4 pb-2 ring-1 ring-black ring-opacity-5 bg-white shadow-sm rounded-lg"
                  >
                    <h3 className="mb-1 text-sm leading-6 font-medium text-gray-500 text-left uppercase">
                      Activation details
                    </h3>
                    <dl className="divide-y divide-gray-300 text-left text-sm">
                      {list.map((item) => {
                        return (
                          <div
                            key={item.id}
                            className="flex items-center space-x-4 py-3"
                          >
                            <dt className="shrink-0 w-1/3 text-gray-500">
                              {item.title}
                            </dt>
                            <dd className="grow">{item.description}</dd>
                          </div>
                        );
                      })}
                    </dl>
                  </div>
                );
              })}
              {activation.status && (
                <Button label="Sign in" size="lg" href="/sign-in" />
              )}
            </>
          )}
          {isLoadingSubscription && <PageLoaderSpinner />}
        </MasterPage>
      )}
      {isLoadingIdToken && (
        <div className="w-full h-full grid items-center">
          <PageLoaderSpinner />
        </div>
      )}
      <input type="hidden" id="hdnIdToken" value={idToken || ""} />
    </>
  );
}
