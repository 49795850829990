// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar {
  height: 0.5rem;
  width: 100%;
  overflow: hidden;
  border-radius: 9999px;
  background-color: rgb(8 145 178 / 0.25);
}

.progress-bar-value {
  height: 100%;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity));
  transform-origin: 0% 50%;
  animation: indeterminateAnimation 1s infinite linear;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/progress-bar.css"],"names":[],"mappings":"AACE;EAAA,cAAgE;EAAhE,WAAgE;EAAhE,gBAAgE;EAAhE,qBAAgE;EAAhE;AAAgE;;AAIhE;EAAA,YAAmC;EAAnC,WAAmC;EAAnC,kBAAmC;EAAnC,uDAAmC;EACnC,wBAAwB;EACxB;AAFmC;;AAKrC;EACE;IACE,kCAAkC;EACpC;EACA;IACE,oCAAoC;EACtC;EACA;IACE,uCAAuC;EACzC;AACF","sourcesContent":[".progress-bar {\n  @apply w-full h-2 bg-primary-600/25 rounded-full overflow-hidden;\n}\n\n.progress-bar-value {\n  @apply w-full h-full bg-primary-600;\n  transform-origin: 0% 50%;\n  animation: indeterminateAnimation 1s infinite linear;\n}\n\n@keyframes indeterminateAnimation {\n  0% {\n    transform: translateX(0) scaleX(0);\n  }\n  40% {\n    transform: translateX(0) scaleX(0.4);\n  }\n  100% {\n    transform: translateX(100%) scaleX(0.5);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
