import {
  ChangeEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { SlideOver } from "../../../_ui-kit/SlideOver";
import SyntaxHighlighter from "react-syntax-highlighter";
import { googlecode as codeTheme } from "react-syntax-highlighter/dist/esm/styles/hljs";
import _ from "lodash";
import { Button } from "../../../_ui-kit";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  FileProgressList,
  TranslatedSourceAndTarget,
} from "../../../models/ProjectTask";
import { VendorAsset } from "../../../_ui-kit/VendorCard";
import { TaskData } from "./TasksList";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import TasksApi from "../../../api/TasksApi";
import { Project } from "../../../models/Project";
import { AppError } from "../../../App";

interface TargetCodeUpdateSliderProps {
  currentProject: Project;
  currenTask: TaskData;
  selectedFile: FileProgressList;
  setObjectsToEdit: (objects: TranslatedSourceAndTarget[]) => void;
  objectsToEdit: TranslatedSourceAndTarget[];
  openSlideOver: boolean;
  setOpenSlideOver: (open: boolean) => void;
  setPageErrorsList: (value: SetStateAction<AppError[]>) => void;
}

export default function TargetCodeUpdateSlider({
  currentProject,
  currenTask,
  selectedFile,
  setObjectsToEdit,
  objectsToEdit,
  openSlideOver,
  setOpenSlideOver,
  setPageErrorsList,
}: TargetCodeUpdateSliderProps) {
  const tasksApi = new TasksApi();
  const queryClient = useQueryClient();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [code, setCode] = useState("");

  const updateObjectsMutation = useMutation(
    (objectsToUpdate: any) => {
      return tasksApi.updateTaskTargetObjects(
        objectsToUpdate.projectId,
        objectsToUpdate.taskId,
        objectsToUpdate.fileId,
        objectsToUpdate.objects
      );
    },
    {
      onSettled: () => {
        setIsSaving(false);
        closeSlideOver(false);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["translated-code-view"]);
      },
      onError: (ex: any) => {
        setPageErrorsList((prev) => [
          ...prev,
          {
            id: Math.random().toString(),
            error: "Error updating objects",
            description: ex,
          },
        ]);
      },
    }
  );

  function nextPageClickHandler(): void {
    if (pageIndex < objectsToEdit.length - 1) {
      setPageIndex(pageIndex + 1);
    }
  }

  function previousPageClickHandler(): void {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1);
    }
  }

  function textareaChangeHandler(e: ChangeEvent<HTMLTextAreaElement>) {
    let text = e.target.value;

    if (!text) {
      text = " ";
      _.delay(() => {
        e.target.setSelectionRange(0, 0);
      }, 0);
    }

    setCode(text);
  }

  function textareaFocusHandler(e: ChangeEvent<HTMLTextAreaElement>) {
    const textarea = e.target;
    const codeViewer = textarea.parentElement?.querySelector(".codeview");
    const lineNumberList = codeViewer?.querySelectorAll(".linenumber");
    const lineNumber = lineNumberList?.[lineNumberList.length - 1];

    if (textarea && codeViewer && lineNumber) {
      const getElementFullWidth = (element: Element) => {
        const style = window.getComputedStyle(element);
        const width =
          parseFloat(style.width) +
          parseFloat(style.marginLeft) +
          parseFloat(style.marginRight);

        return width;
      };

      const getElementHeight = (element: Element) => {
        const style = window.getComputedStyle(element);
        const height = parseFloat(style.height);

        return height;
      };

      const getElementMarginLeft = (element: Element) => {
        const style = window.getComputedStyle(element);
        const marginLeft = parseFloat(style.marginLeft);

        return marginLeft;
      };

      const width = getElementFullWidth(codeViewer) || 0;
      const marginLeft = getElementMarginLeft(codeViewer) || 0;
      const paddingLeft = getElementFullWidth(lineNumber) || 0;
      const lineHeight = getElementHeight(lineNumber) || 0;

      textarea.style.width = `${width}px`;
      textarea.style.marginLeft = `${marginLeft}px`;
      textarea.style.paddingLeft = `${paddingLeft}px`;
      textarea.style.lineHeight = `${lineHeight}px`;
    }
  }

  function saveCodeClickHandler(): void {
    setIsSaving(true);
    updateObjectsMutation.mutate({
      projectId: currentProject.uuid,
      taskId: currenTask.uuid,
      fileId: selectedFile.id,
      objects: objectsToEdit.map((object) => {
        return {
          objectId: object.objectId,
          fileName: object.fileName,
          code: object.translatedCode,
        };
      }),
    });
  }

  function closeSlideOver(close: boolean): void {
    setPageIndex(0);
    setIsSaving(close);
    setOpenSlideOver(close);
    setObjectsToEdit([]);
  }

  useEffect(() => {
    if (openSlideOver && objectsToEdit && objectsToEdit.length > 0) {
      const targetCode = objectsToEdit[pageIndex];
      setCode(targetCode.translatedCode);
    }
  }, [openSlideOver, objectsToEdit, pageIndex]);

  useEffect(() => {
    if (code) {
      objectsToEdit[pageIndex].translatedCode = code;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <SlideOver
      title={selectedFile.name}
      size="xl"
      show={openSlideOver}
      onClose={isSaving ? () => {} : closeSlideOver}
      scroll={false}
    >
      <div className="flex items-center justify-between py-2 px-6 border-t border-b-2 border-t-gray-200 bg-white">
        <Button
          label="Save all"
          icon={solid("save")}
          size="xs"
          onClick={saveCodeClickHandler}
          isLoading={isSaving}
          uppercase
          outline
        />
        <div className="flex items-center space-x-2">
          <VendorAsset platform={currenTask.targetPlatformName} type="icon" />
          <span className="text-sm font-medium">
            {currenTask.targetPlatformDescription}
          </span>
        </div>
        <div className="flex items-center space-x-3">
          <span className="text-sm text-gray-500">
            {pageIndex + 1} / {objectsToEdit.length}
          </span>
          <div className="flex space-x-2">
            <Button
              onClick={previousPageClickHandler}
              label="Previous"
              size="xs"
              variant="white"
              icon={solid("arrow-left")}
              disabled={pageIndex === 0}
              hideLabel
            />
            <Button
              onClick={nextPageClickHandler}
              label="Next"
              size="xs"
              variant="white"
              icon={solid("arrow-right")}
              disabled={pageIndex === objectsToEdit.length - 1}
              hideLabel
            />
          </div>
        </div>
      </div>
      <div className="h-full bg-white overflow-y-auto">
        <div className="relative w-fit min-w-full flex bg-gray-50 focus-within:bg-gray-100 border-b border-gray-200">
          <textarea
            className="absolute inset-0 min-w-full resize-none bg-transparent p-0 font-mono text-sm text-transparent caret-black border-0 outline-none focus:outline-dotted focus:ring-0"
            value={code}
            onChange={textareaChangeHandler}
            onFocus={textareaFocusHandler}
            ref={textareaRef}
            spellCheck={false}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
          />
          <div className="codeview">
            <SyntaxHighlighter
              language="sql"
              style={codeTheme}
              showLineNumbers={true}
              useInlineStyles={true}
              startingLineNumber={1}
              customStyle={{
                flex: "1",
                background: "transparent",
              }}
            >
              {code}
            </SyntaxHighlighter>
          </div>
        </div>
      </div>
    </SlideOver>
  );
}
