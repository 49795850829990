import { PagePrintFormatProps } from "./PagePrintFormat.types";
import { ReactComponent as AppLogoNextPathway } from "../../images/brand/logo-next-pathway.svg";
import { ReactComponent as AppLogoShiftFull } from "../../images/brand/logo-shift-full.svg";

export function PagePrintFormat({
  blockClassName,
  children,
}: PagePrintFormatProps) {
  return (
    <>
      <section
        className={`hidden print:block print:break-inside-avoid pb-4 border-b-2 border-gray-300 print-color-exact ${
          blockClassName ? blockClassName : ""
        }`}
      >
        <div className="flex items-center justify-between">
          <AppLogoNextPathway
            title="Next Pathway"
            role="img"
            className="flex-shrink-0 w-24 3xl:w-32 4xl:w-44 h-auto"
          />
          <AppLogoShiftFull
            title="SHIFT&trade; Cloud"
            role="img"
            className="flex-shrink-0 w-20 3xl:w-32 4xl:w-44 h-auto"
          />
        </div>
      </section>
      <div className="print-color-exact">{children}</div>
      <section
        className={`hidden print:block pt-8 border-t-2 border-gray-300 print:break-inside-avoid print-color-exact ${
          blockClassName ? blockClassName : ""
        }`}
      >
        <div className="flex flex-col">
          <h6 className="mb-2 text-sm text-gray-900 font-bold">CONFIDENTIAL</h6>
          <p className="mb-4 text-xs">
            This document contains information of a proprietary nature and is
            intended for the exclusive use of the SHIFT&trade; Cloud client that
            requested this Analyzer Report. Use of this Report will strictly
            adhere the terms of the License Agreement; any unauthorized
            reproduction shall constitute an infringement of copyright.
          </p>
          <h6 className="mb-2 text-sm text-gray-900 font-bold">DISCLAIMER</h6>
          <p className="mb-4 text-xs">
            THIS INFORMATION IS PROVIDED “AS IS.” NEXT PATHWAY INC. MAKES NO
            REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THE CONTENTS HEREOF
            AND ASSUMES NO RESPONSIBILITY FOR ANY INACCURACIES, ERRORS, OR
            OMISSIONS THAT MAY APPEAR IN THIS INFORMATION. IN NO EVENT WILL NEXT
            PATHWAY INC. BE LIABLE TO ANY PERSON FOR ANY RELIANCE, DIRECT,
            INDIRECT, SPECIAL, OR OTHER CONSEQUENTIAL DAMAGES ARISING FROM THE
            USE OF ANY INFORMATION CONTAINED HEREIN.
          </p>
          <p className="mb-4 text-xs">
            Corporate logos are for informational purposes only, and Next
            Pathway is not associated with or sponsored by the respective
            trademark owner.
          </p>
          <p className="mb-4 text-xs">
            SHIFT&trade; Cloud is an existing, applied for or registered
            trademark of Next Pathway Inc.
          </p>
          <p className="text-xs text-gray-900 font-medium">
            Copyright &copy; 2023 Next Pathway Inc.
          </p>
        </div>
      </section>
    </>
  );
}
