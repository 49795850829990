import BaseApi from "./BaseApi";

export default class MarketplaceApi extends BaseApi {
  apiSSO = `${this.baseUrl}/auth/microsoft`;
  //apiSSO = `https://shiftcloud-uat.nextpathway.com/svc/api/v1/auth/microsoft`;
  apiGetIdToken = `${this.baseUrl}/auth/idtoken`;
  //apiGetIdToken = `https://shiftcloud-uat.nextpathway.com/svc/api/v1/auth/idtoken`;
  apiGetSubscription = `${this.baseUrl}/shift/azure/subscriptions`;
  //apiGetSubscription = `https://shiftcloud-uat.nextpathway.com/svc/api/v1/shift/azure/subscriptions`;
  apiActivateCustomer = `${this.baseUrl}/shift/azure/customers`;
  //apiActivateCustomer = `https://shiftcloud-uat.nextpathway.com/svc/api/v1/shift/azure/customers`;

  private getHeaders(idToken: string): any {
    const headers: any = this.getApplicationJsonHeaders();
    headers["id_token"] = `Bearer ${idToken}`;
    return headers;
  }

  getSubscription(
    id_token: string,
    subscriptionToken: string
  ): Promise<Response> {
    const queryString = { token: subscriptionToken };
    const promise = this.doFetch(
      `${this.apiGetSubscription}?${this.objToQueryString(queryString)}`,
      {
        method: "POST",
        headers: this.getHeaders(id_token),
      }
    );

    return promise;
  }

  activateSubscription(
    id_token: string,
    subscriptionId: string,
    planId: string,
    clientCode: string,
    displayName: string,
    ownerEmails: string[]
  ): Promise<Response> {
    const promise = this.doFetch(this.apiActivateCustomer, {
      method: "POST",
      headers: this.getHeaders(id_token),
      body: JSON.stringify({
        subscriptionId,
        planId,
        clientCode,
        displayName,
        ownerEmails,
      }),
    });
    return promise;
  }
}
