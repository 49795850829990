import {
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { googlecode as codeTheme } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import UploadApi from "../../../api/UploadApi";
import { UserContext } from "../../../contexts/UserContext";
import { ClientContext } from "../../../contexts/ClientContext";
import UnselectableContent from "../../../_ui-kit/UnselectableContent/UnselectableContent";

interface SourceObj {
  src: string;
  lines: number;
}

interface FileObj {
  objectId: string;
  fileName: string;
  sourceCode: string;
  sourceCodeLineCount: number;
}

interface CodeViewProps {
  projectId: string;
  libId: string;
  fileId: number;
  objCount: number;
  setObjCount: (param: number) => void;
  selectedFile: any;
  language: string;
  clipboard: string[];
  setClipboard: (value: SetStateAction<string[]>) => void;
}

interface CodeQueryParams {
  pageIndex: number;
  pageSize: number;
  projectId?: string;
  libraryId?: string;
  fileId?: number;
}

const defaultParams: CodeQueryParams = {
  pageIndex: 1,
  pageSize: 5,
};

function CodeView({
  fileId,
  objCount,
  setObjCount,
  libId,
  projectId,
  selectedFile,
  language,
  clipboard,
  setClipboard,
}: CodeViewProps) {
  const uploadApi = new UploadApi();
  const queryClient = useQueryClient();
  const { authUser } = useContext(UserContext);
  const { currentClient } = useContext(ClientContext);
  const { ref, inView } = useInView();
  const [lines, setLines] = useState(1);
  const [queryParams, setQueryParams] = useState<CodeQueryParams>({
    ...defaultParams,
    projectId: projectId,
    libraryId: libId,
    fileId: fileId,
  });

  const getFetchData = async ({ pageParam = queryParams.pageIndex }) => {
    if (queryParams.projectId && queryParams.libraryId && queryParams.fileId) {
      const result = await uploadApi.getFileObjects(
        queryParams.projectId,
        queryParams.libraryId,
        queryParams.fileId,
        pageParam,
        queryParams.pageSize,
        null,
        null
      );
      const fileObjects: FileObj[] = await result.json();

      if (fileObjects.length > 0) setObjCount(objCount + fileObjects.length);

      let sourceCode = "";
      let srclinenumber = 0;

      fileObjects.forEach((obj: FileObj, idx: number) => {
        if (idx !== fileObjects.length - 1) {
          sourceCode += obj.sourceCode + "\n";
        } else {
          sourceCode += obj.sourceCode;
        }
        srclinenumber += obj.sourceCodeLineCount;
      });

      setLines(lines + srclinenumber);
      const res: SourceObj = { src: sourceCode, lines: lines };

      return fileObjects.length > 0 ? [res] : [];
    }
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery(["source-code-view", queryParams], getFetchData, {
      getNextPageParam: (lastPage, pages) => {
        return lastPage && lastPage.length > 0 ? pages.length + 1 : undefined;
      },
    });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  useEffect(() => {
    setLines(1);
    setQueryParams({
      ...queryParams,
      fileId: selectedFile.id,
    });
    setObjCount(0);
    queryClient.removeQueries(["source-code-view"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  useEffect(() => {
    if (data && data.pages.length > 0) {
      let text = data.pages.map((page) => page?.[0]?.src || "") || [];
      setClipboard(text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div className="grow w-full h-96 overflow-x-scroll overflow-y-scroll">
        <div className="relative bg-white text-sm leading-6">
          {data &&
            data.pages.map((summaries: SourceObj[] | undefined, index) => {
              return (
                <Fragment key={index}>
                  {summaries?.map((summary) => {
                    return (
                      <UnselectableContent
                        key={lines}
                        className="codeview"
                        selectable={
                          authUser?.isAdmin() ||
                          currentClient?.clientRestriction?.allowDownload
                        }
                      >
                        <SyntaxHighlighter
                          language={language}
                          style={codeTheme}
                          showLineNumbers={true}
                          useInlineStyles={true}
                          startingLineNumber={summary.lines}
                        >
                          {summary.src}
                        </SyntaxHighlighter>
                      </UnselectableContent>
                    );
                  })}
                </Fragment>
              );
            })}
          {data && (
            <button
              ref={ref}
              className={`w-full bg-white border-y -mt-px py-4 px-11 text-gray-500 ${
                hasNextPage && !isFetchingNextPage
                  ? "hover:bg-gray-50 hover:text-gray-700"
                  : ""
              }`}
              disabled={!hasNextPage || isFetchingNextPage}
              onClick={() => fetchNextPage()}
            >
              {isFetchingNextPage ? (
                <span className="text-gray-400">
                  <FontAwesomeIcon
                    icon={solid("spinner")}
                    size="lg"
                    aria-hidden={true}
                    spin
                  />
                  <span className="sr-only">Loading more objects...</span>
                </span>
              ) : hasNextPage ? (
                <span className="uppercase font-medium text-sm">Load more</span>
              ) : (
                <span className="text-sm">No more objects</span>
              )}
            </button>
          )}
        </div>
      </div>
      {isFetching && (
        <div className="absolute top-0 w-full flex flex-col items-center justify-start !m-0 bg-white/70 text-primary-600 rounded-b-md">
          <div className="animate-pulse flex w-full h-1 -mt-px bg-gradient-to-r from-primary-600 to-primary-500"></div>
        </div>
      )}
    </>
  );
}

export default CodeView;
