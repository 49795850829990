import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface PasswordRuleItemProps {
  valid: boolean;
  label: string;
  validate?: boolean;
}

function PasswordRuleItem({
  valid,
  label,
  validate = false,
}: PasswordRuleItemProps) {
  let text = "";

  switch (label) {
    case "eightChar":
      text = "8 characters minimum";
      break;
    case "lowercase":
      text = "One lowercase character";
      break;
    case "uppercase":
      text = "One uppercase character";
      break;
    case "specialChar":
      text = "One special character";
      break;
    case "number":
      text = "One number";
      break;
    default:
      text = label;
  }

  return (
    <li className="flex items-center space-x-2 text-sm leading-none">
      {valid && (
        <FontAwesomeIcon
          icon={solid("check-circle")}
          className="w-4 h-4 text-green-600"
          aria-hidden={true}
        />
      )}
      {!valid && (
        <FontAwesomeIcon
          icon={validate ? solid("xmark-circle") : regular("circle")}
          className={`w-4 h-4 ${
            validate ? "text-danger-600" : "text-gray-400"
          }`}
          aria-hidden={true}
        />
      )}
      <span
        className={`${
          valid
            ? "text-success-700"
            : validate
            ? "text-danger-700"
            : "text-gray-900"
        }`}
      >
        {text}
      </span>
    </li>
  );
}

export default PasswordRuleItem;
