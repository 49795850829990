import BaseApi from "./BaseApi";

export default class UploadApi extends BaseApi {
  apiGetLibraries = `${this.baseUrl}/{{projectId}}/shift/libraries`;
  apiAddLibrary = this.apiGetLibraries;
  apiDeleteLibrary = `${this.baseUrl}/{{projectId}}/shift/libraries/{{libraryId}}`;
  apiUploadLibraryFiles = `${this.baseUrl}/{{projectId}}/shift/libraries/{{libraryId}}/upload`;
  apiImportLibraryFiles = `${this.baseUrl}/{{projectId}}/shift/libraries/{{libraryId}}/dataimport`;
  apiGetLibraryFiles = `${this.baseUrl}/{{projectId}}/shift/libraries/{{libraryId}}/files`;
  apiGetLibraryFileObjects = `${this.baseUrl}/{{projectId}}/shift/libraries/{{libraryId}}/{{fileId}}/code`;
  apiGetLibraryTargetMapping = `${this.baseUrl}/{{projectId}}/shift/libraries/{{libraryId}}/sourcetargetmapping`;
  apiGetSqlTaskAnalyzeConfirmation = `${this.baseUrl}/{{projectId}}/shift/libraries/{{libraryId}}/confirmanalyze`;
  apiGetEtlTaskAnalyzeConfirmation = `${this.baseUrl}/{{projectId}}/shift/libraries/{{libraryId}}/confirm_etl_analyze`;
  apiAnalyzeSqlLibrary = `${this.baseUrl}/{{projectId}}/shift/libraries/{{libraryId}}/analyze`;
  apiAnalyzeEtlLibrary = `${this.baseUrl}/{{projectId}}/shift/libraries/{{libraryId}}/analyze_etl`;
  apiDownloadSourceCode = `${this.baseUrl}/{{projectId}}/shift/libraries/{{libraryId}}/download_source`;
  apiDownloadSplitLogs = `${this.baseUrl}/{{projectId}}/shift/libraries/{{libraryId}}/split_logs`;

  addLibrary(
    projectId: string,
    name: string,
    sourceType: string,
    platformId: string,
    embeddedSqlSourcePlatformId?: string
  ): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiAddLibrary.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}`,
      {
        method: "POST",
        headers: this.getAuthApplicationJsonHeaders(),
        body: JSON.stringify({
          name,
          sourceType,
          platformId,
          embeddedSqlSourcePlatformId,
        }),
      }
    );
    return promise;
  }

  deleteLibrary(projectId: string, libraryId: string, isAdmin?: boolean) {
    const queryString: any = {};

    if (isAdmin) queryString.force = true;

    const promise = this.doFetch(
      `${this.apiDeleteLibrary
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{libraryId}}", encodeURIComponent(libraryId))}${
        this.isObjEmpty(queryString)
          ? ""
          : `?${this.objToQueryString(queryString)}`
      }`,
      {
        method: "DELETE",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getLibrary(projectId: string, libraryId: string) {
    const promise = this.doFetch(
      `${this.apiDeleteLibrary
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{libraryId}}", encodeURIComponent(libraryId))}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getLibraries(clientId: string, projectId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetLibraries.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  uploadLibraryFiles(
    projectId: string,
    libraryId: string,
    files: File[]
  ): Promise<Response> {
    let data = new FormData();
    files.forEach((file) => data.append("file", file));

    const promise = this.doFetch(
      `${this.apiUploadLibraryFiles
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{libraryId}}", encodeURIComponent(libraryId))}`,
      {
        method: "POST",
        headers: this.getAuthHeaders(),
        body: data,
      }
    );
    return promise;
  }

  importLibraryFiles(projectId: string, libraryId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiImportLibraryFiles
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{libraryId}}", encodeURIComponent(libraryId))}`,
      {
        method: "POST",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getLibraryFiles(projectId: string, libraryId: string) {
    const promise = this.doFetch(
      `${this.apiGetLibraryFiles
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{libraryId}}", encodeURIComponent(libraryId))}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getFileObjects(
    projectId: string,
    libraryId: string,
    fileId: number,
    pageIndex: number,
    pageSize: number,
    filter: string | null,
    type: string | null
  ) {
    const queryString: any = {
      pageIndex: pageIndex,
      pageSize: pageSize,
    };
    if (filter) queryString.filter = filter;
    if (type) queryString.type = type;
    const promise = this.doFetch(
      `${this.apiGetLibraryFileObjects
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{libraryId}}", encodeURIComponent(libraryId))
        .replace("{{fileId}}", encodeURIComponent(fileId))}${
        this.isObjEmpty(queryString)
          ? ""
          : `?${this.objToQueryString(queryString)}`
      }`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getLibraryTargetMapping(projectId: string, libraryId: string) {
    const promise = this.doFetch(
      `${this.apiGetLibraryTargetMapping
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{libraryId}}", encodeURIComponent(libraryId))}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getAnalyzeConfirmation(
    projectId: string,
    libraryId: string
  ): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetSqlTaskAnalyzeConfirmation
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{libraryId}}", encodeURIComponent(libraryId))}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getEtlAnalyzeConfirmation(
    projectId: string,
    libraryId: string
  ): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetEtlTaskAnalyzeConfirmation
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{libraryId}}", encodeURIComponent(libraryId))}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  analyzeLibrary(
    projectId: string,
    libraryId: string,
    taskName: string,
    targetPlatformId: string | number,
    fileIds: number[]
  ): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiAnalyzeSqlLibrary
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{libraryId}}", encodeURIComponent(libraryId))}`,
      {
        method: "POST",
        headers: this.getAuthApplicationJsonHeaders(),
        body: JSON.stringify({
          name: taskName,
          targetPlatformId,
          fileIds,
        }),
      }
    );
    return promise;
  }

  analyzeEtlLibrary(
    projectId: string,
    libraryId: string,
    taskName: string,
    targetPlatformId: string | number,
    targetEmbeddedPlatformId: string | number,
    fileIds: number[]
  ): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiAnalyzeEtlLibrary
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{libraryId}}", encodeURIComponent(libraryId))}`,
      {
        method: "POST",
        headers: this.getAuthApplicationJsonHeaders(),
        body: JSON.stringify({
          name: taskName,
          targetPlatformId,
          targetEmbeddedPlatformId,
          fileIds,
        }),
      }
    );
    return promise;
  }

  downloadSourceCode(projectId: string, libraryId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiDownloadSourceCode
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{libraryId}}", encodeURIComponent(libraryId))}`,
      {
        method: "GET",
        headers: this.getAuthOctetStreamHeaders(),
      }
    );
    return promise;
  }

  downloadSplitLogs(projectId: string, libraryId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiDownloadSplitLogs
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{libraryId}}", encodeURIComponent(libraryId))}`,
      {
        method: "GET",
        headers: this.getAuthOctetStreamHeaders(),
      }
    );
    return promise;
  }
}
