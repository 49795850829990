import { Collections } from "../models/Project";
import { User, UserData } from "../models/User";
import BaseApi from "./BaseApi";

export default class ConfigureApi extends BaseApi {
  apiGetEtlTypes = `${this.baseUrl}/settings/etlplatformtypes`;
  apiGetDataPlatforms = `${this.baseUrl}/settings/datawarehouses`;
  apiGetUsers = `${this.baseUrl}/users`;
  apiGetUserByEmail = `${this.apiGetUsers}/email`;
  apiAddUser = this.apiGetUsers;
  apiUpdateUser = this.apiGetUsers;
  apiGetClients = `${this.baseUrl}/clients`;
  apiGetClientById = `${this.baseUrl}/clients/{{clientId}}`;
  apiAddClient = this.apiGetClients;
  apiUpdateClient = this.apiGetClients;
  apiDeleteClient = this.apiGetClients;
  apiGetClientPricingPlan = `${this.baseUrl}/clients/{{clientId}}/pricingplan`;
  apiAddClientPricingPlan = this.apiGetClientPricingPlan;
  apiUpdateClientPricingPlan = this.apiGetClientPricingPlan;
  apiAddPricingPlan = `${this.baseUrl}/settings/pricingplan`;
  apiUpdatePricingPlan = this.apiAddPricingPlan;
  apiGetProjects = `${this.baseUrl}/wp/projects`;
  apiGetProjectByClient = `${this.baseUrl}/wp/{{clientId}}/projects`;
  apiGetProjectById = `${this.apiGetProjects}/{{projectId}}`;
  apiAddProject = this.apiGetProjects;
  apiUpdateProject = this.apiGetProjects;
  apiDeleteProject = this.apiGetProjects;
  apiUploadCsvFile = `${this.baseUrl}/wp/{{projectId}}/csvupload`;
  apiUploadCsvProgress = `${this.baseUrl}/wp/{{projectId}}/csvprogress`;
  apiUploadCsvProgressCollections = `${this.baseUrl}/wp/{{projectId}}/csvprogress/collections`;

  apiAsyncImportETLData = `${this.baseUrl}/wp/{{projectId}}/dataimport/etl`;
  apiAsyncImportDependencyData = `${this.baseUrl}/wp/{{projectId}}/dataimport/dependency`;
  apiAsyncImportCategoryData = `${this.baseUrl}/wp/{{projectId}}/dataimport/job_category`;
  apiAsyncImportDDLData = `${this.baseUrl}/wp/{{projectId}}/dataimport/ddl`;
  apiImportETLData = `${this.baseUrl}/wp/{{projectId}}/dataload`;
  apiImportDependencyData = `${this.baseUrl}/wp/{{projectId}}/dataload`;
  apiImportCategoryData = `${this.baseUrl}/wp/{{projectId}}/dataload/job_category`;
  apiImportDDLData = `${this.baseUrl}/wp/{{projectId}}/dataload/ddl`;

  async getUsers(): Promise<User[]> {
    const result = await this.doFetch(`${this.apiGetUsers}`, {
      method: "GET",
      headers: this.getAuthApplicationJsonHeaders(),
    });
    const users: UserData[] = await result.json();
    return users.map((user) => new User(user));
  }

  async getUserByEmail(email: string): Promise<User> {
    const queryString: any = {};
    if (email) queryString.email = email;

    const result = await this.doFetch(
      `${this.apiGetUserByEmail}${
        this.isObjEmpty(queryString)
          ? ""
          : `?${this.objToQueryString(queryString)}`
      }`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    const user: UserData = await result.json();
    return new User(user);
  }

  addUser(
    fullName: string,
    email: string,
    role: string,
    isActive: string,
    userClients: string[],
    provider: string
  ): Promise<Response> {
    const promise = this.doFetch(this.apiAddUser, {
      method: "POST",
      headers: this.getAuthApplicationJsonHeaders(),
      body: JSON.stringify({
        fullName,
        email,
        role,
        isActive,
        userClients,
        provider,
      }),
    });
    return promise;
  }

  updateUser(
    id: string,
    fullName: string,
    email: string,
    role: string,
    isActive: string,
    userClients: string[],
    provider: string
  ): Promise<Response> {
    const promise = this.doFetch(`${this.apiUpdateUser}`, {
      method: "PUT",
      headers: this.getAuthApplicationJsonHeaders(),
      body: JSON.stringify({
        id,
        fullName,
        email,
        role,
        isActive,
        userClients,
        provider,
      }),
    });
    return promise;
  }

  getClients(): Promise<Response> {
    const promise = this.doFetch(this.apiGetClients, {
      method: "GET",
      headers: this.getAuthApplicationJsonHeaders(),
    });
    return promise;
  }

  getClientById(clientId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetClientById.replace("{{clientId}}", clientId)}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  addClient(
    code: string,
    name: string,
    clientRestriction: any[]
  ): Promise<Response> {
    const promise = this.doFetch(this.apiAddClient, {
      method: "POST",
      headers: this.getAuthApplicationJsonHeaders(),
      body: JSON.stringify({ code, name, clientRestriction }),
    });
    return promise;
  }

  updateClient(
    id: string,
    code: string,
    name: string,
    isActive: boolean,
    clientRestriction: any[]
  ): Promise<Response> {
    const promise = this.doFetch(`${this.apiUpdateClient}`, {
      method: "PUT",
      headers: this.getAuthApplicationJsonHeaders(),
      body: JSON.stringify({ id, code, name, isActive, clientRestriction }),
    });
    return promise;
  }

  getClientPrincingPlan(clientId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetClientPricingPlan.replace(
        "{{clientId}}",
        encodeURIComponent(clientId)
      )}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  addClientPrincingPlan(
    clientId: string,
    planId: string,
    startDate: string,
    endDate: string
  ): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiAddClientPricingPlan.replace(
        "{{clientId}}",
        encodeURIComponent(clientId)
      )}`,
      {
        method: "POST",
        body: JSON.stringify({ planId, startDate, endDate }),
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  updateClientPrincingPlan(
    clientId: string,
    planId: string,
    startDate: string,
    endDate: string,
    freeTablesViews: string,
    freeAnalyzerReports: string,
    price: string,
    objectLevelPricing: any
  ): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiUpdateClientPricingPlan.replace(
        "{{clientId}}",
        encodeURIComponent(clientId)
      )}`,
      {
        method: "PUT",
        body: JSON.stringify({
          planId,
          startDate,
          endDate,
          freeTablesViews,
          freeAnalyzerReports,
          price,
          objectLevelPricing,
        }),
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  addPricingPlan(
    planType: string,
    price: number,
    duration: string,
    freeTablesViews: number,
    freeAnalyzerReports: number,
    objectLevelPricing: any
  ): Promise<Response> {
    const promise = this.doFetch(this.apiAddPricingPlan, {
      method: "POST",
      headers: this.getAuthApplicationJsonHeaders(),
      body: JSON.stringify({
        planType,
        price,
        duration,
        freeTablesViews,
        freeAnalyzerReports,
        objectLevelPricing,
      }),
    });
    return promise;
  }

  updatePricingPlan(
    planId: string,
    planType: string,
    price: number,
    duration: string,
    freeTablesViews: number,
    freeAnalyzerReports: number,
    objectLevelPricing: any
  ): Promise<Response> {
    const promise = this.doFetch(`${this.apiUpdatePricingPlan}`, {
      method: "PUT",
      headers: this.getAuthApplicationJsonHeaders(),
      body: JSON.stringify({
        planId,
        planType,
        price,
        duration,
        freeTablesViews,
        freeAnalyzerReports,
        objectLevelPricing,
      }),
    });
    return promise;
  }

  getProjects(): Promise<Response> {
    const promise = this.doFetch(this.apiGetProjects, {
      method: "GET",
      headers: this.getAuthApplicationJsonHeaders(),
    });
    return promise;
  }

  getProjectsByClient(clientId: string): Promise<Response> {
    const promise = this.doFetch(
      this.apiGetProjectByClient.replace(
        "{{clientId}}",
        encodeURIComponent(clientId)
      ),
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getProjectById(projectId: string): Promise<Response> {
    const promise = this.doFetch(
      this.apiGetProjectByClient.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      ),
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  addProject(
    clientId: string,
    name: string,
    userIds: string[]
  ): Promise<Response> {
    const promise = this.doFetch(this.apiAddProject, {
      method: "POST",
      headers: this.getAuthApplicationJsonHeaders(),
      body: JSON.stringify({ clientId, name, userIds }),
    });
    return promise;
  }

  updateProject(
    projectId: string,
    clientId: string,
    name: string,
    userIds: string[]
  ): Promise<Response> {
    const queryString: any = {
      projectId,
    };

    const promise = this.doFetch(
      `${this.apiUpdateProject}?${this.objToQueryString(queryString)}`,
      {
        method: "PUT",
        headers: this.getAuthApplicationJsonHeaders(),
        body: JSON.stringify({ clientId, name, userIds }),
      }
    );
    return promise;
  }

  getEtlTypes(): Promise<Response> {
    const promise = this.doFetch(this.apiGetEtlTypes, {
      method: "GET",
      headers: this.getAuthApplicationJsonHeaders(),
    });
    return promise;
  }

  getDataPlatforms(): Promise<Response> {
    const promise = this.doFetch(this.apiGetDataPlatforms, {
      method: "GET",
      headers: this.getAuthApplicationJsonHeaders(),
    });
    return promise;
  }

  uploadCsvFile(
    projectId: string,
    file: File,
    type: string
  ): Promise<Response> {
    const queryString: any = {
      type,
    };

    let data = new FormData();
    data.append("file", file);

    const promise = this.doFetch(
      `${this.apiUploadCsvFile.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}?${this.objToQueryString(queryString)}`,
      {
        method: "POST",
        body: data,
        headers: this.getAuthHeaders(),
      }
    );
    return promise;
  }

  getCsvProgressCollections(projectId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiUploadCsvProgressCollections.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getCsvProgress(
    projectId: string,
    collections: Collections[]
  ): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiUploadCsvProgress.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}`,
      {
        method: "POST",
        headers: this.getAuthApplicationJsonHeaders(),
        body: JSON.stringify(collections),
      }
    );
    return promise;
  }

  importETLData(
    projectId: string,
    collectionId: string,
    etlPlatformId: string,
    defaultDataWarehouse: string
  ): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiAsyncImportETLData.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}`,
      {
        method: "POST",
        headers: this.getAuthApplicationJsonHeaders(),
        body: JSON.stringify({
          collectionId,
          etlPlatformId,
          defaultDataWarehouse,
        }),
      }
    );
    return promise;
  }

  importDependencyData(
    projectId: string,
    collectionId: string,
    defaultDataPlatform: string,
    defaultSchema: string
  ): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiAsyncImportDependencyData.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}`,
      {
        method: "POST",
        headers: this.getAuthApplicationJsonHeaders(),
        body: JSON.stringify({
          collectionId,
          defaultDataPlatform,
          defaultSchema,
        }),
      }
    );
    return promise;
  }

  importCategoryData(
    projectId: string,
    collectionId: string
  ): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiAsyncImportCategoryData.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}`,
      {
        method: "POST",
        headers: this.getAuthApplicationJsonHeaders(),
        body: JSON.stringify({ collectionId }),
      }
    );
    return promise;
  }

  importDDLData(
    projectId: string,
    collectionId: string,
    defaultDataPlatform: string,
    defaultSchema: string
  ): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiAsyncImportDDLData.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}`,
      {
        method: "POST",
        headers: this.getAuthApplicationJsonHeaders(),
        body: JSON.stringify({
          collectionId,
          defaultDataPlatform,
          defaultSchema,
        }),
      }
    );
    return promise;
  }
}
