import { MasterPage } from "../../_ui-kit/MasterPage";
import { PageTransition } from "../../_ui-kit/PageTransition";
import TasksList from "./components/TasksList";

function TranslateList() {
  return (
    <MasterPage heading="Translate">
      <PageTransition>
        <div id="content" className="px-6 py-10 xl:max-w-5xl xl:mx-auto">
          <TasksList />
        </div>
      </PageTransition>
    </MasterPage>
  );
}

export default TranslateList;
