import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ChangeEvent,
  FormEvent,
  DragEvent,
  useContext,
  useRef,
  useState,
  useEffect,
} from "react";
import SettingsApi from "../../api/SettingsApi";
import { AppError } from "../../App";
import { SettingsContext } from "../../contexts/SettingsContext";
import { Button } from "../../_ui-kit";
import { Notification, showNotification } from "../../_ui-kit/Notification";
import { PageTransition } from "../../_ui-kit/PageTransition";
import { VendorAsset } from "../../_ui-kit/VendorCard";
import SystemSettingInputGroup from "./components/SystemSettingInputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "../../_ui-kit/utils";

interface EnvironmentSetting {
  environment_property: string;
  property_value: string;
}

interface TestPlatform {
  platformName: string;
  testPlatform: string;
  platformDescription?: string;
}

interface LicenseFile {
  file: File;
  type: "error" | "success";
  error?: string;
}

const licenseFileName = "shift-v1-license.properties";
const lincenseFileFirstLine = "#Next Pathway Inc. License file";

function SystemSettings() {
  const settingsApi = new SettingsApi();
  const queryClient = useQueryClient();
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const { environmentSettings } = useContext(SettingsContext);
  const [isSubmittingEnvironmentSettings, setIsSubmittingEnvironmentSettings] =
    useState(false);
  const [isSubmittingTestPlatforms, setIsSubmittingTestPlatforms] =
    useState(false);
  const [pageErrorsList, setPageErrorsList] = useState<AppError[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<LicenseFile | null>(null);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [licenseFileHasError, setLicenseFileHasError] = useState(false);

  const { data: testPlatforms } = useQuery(
    ["test-platform-settings"],
    async () => {
      const result = await settingsApi.getTestPlatforms();
      const platforms: TestPlatform[] = await result.json();
      return platforms;
    }
  );

  const updateEnvironmentSettingsMutation = useMutation(
    (environmentSettings: EnvironmentSetting[]) => {
      return settingsApi.updateEnvironmentSettings(environmentSettings);
    },
    {
      onError: (error: any) => {
        setPageErrorsList((prev) => [
          ...prev,
          {
            id: Math.random().toString(),
            error: "Error saving the environment configurations",
            description: error,
          },
        ]);
      },
      onSettled: () => {
        setIsSubmittingEnvironmentSettings(false);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["environment-settings"]);
      },
    }
  );

  const updateTestPlatformsMutation = useMutation(
    (testPlatforms: TestPlatform[]) => {
      return settingsApi.updateTestPlatforms(testPlatforms);
    },
    {
      onError: (error: any) => {
        setPageErrorsList((prev) => [
          ...prev,
          {
            id: Math.random().toString(),
            error: "Error saving the test platforms",
            description: error,
          },
        ]);
      },
      onSettled: () => {
        setIsSubmittingTestPlatforms(false);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["test-platform-settings"]);
      },
    }
  );

  async function getFileToUpload(file: File): Promise<LicenseFile | null> {
    let licenseFile: LicenseFile | null = null;

    if (file) {
      const isContentFileInvalid = async (fileContent: File) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = (e) => {
            try {
              const result = e.target?.result as null | string;
              if (result && result.indexOf(lincenseFileFirstLine) !== 0) {
                resolve(true);
              }
              resolve(false);
            } catch (ex: any) {
              reject(ex);
            }
          };

          reader.onerror = (ex) => {
            reject(ex);
          };

          reader.readAsText(fileContent);
        });
      };

      let invalidFileName = file.name !== licenseFileName;
      let invalidFileContent = await isContentFileInvalid(file);
      let fileTooBig = file.size > 1048576; // 1MB
      let hasError = invalidFileName || fileTooBig || invalidFileContent;

      licenseFile = {
        file: file,
        type: hasError ? "error" : "success",
        error: hasError
          ? invalidFileName
            ? "Invalid file name."
            : invalidFileContent
            ? "Invalid file content."
            : "File size is too big."
          : undefined,
      };
    }
    return licenseFile;
  }

  function uploadLicenseFile(file: File): void {
    const errorMessage = "Error uploading the license file.";
    const uploadFile = async (license: File) => {
      try {
        const result = await settingsApi.uploadLicenseFile(license);
        const response = await result.text();
        const uploadSuccess = response === "true";
        setIsUploadingFile(false);
        if (uploadSuccess) {
          setIsUploadComplete(true);
        } else {
          setIsUploadComplete(false);
          setPageErrorsList((prev) => [
            ...prev,
            {
              id: Math.random().toString(),
              error: errorMessage,
              description: "License file upload failed.",
            },
          ]);
        }
      } catch (ex: any) {
        setIsUploadingFile(false);
        setIsUploadComplete(false);
        setPageErrorsList((prev) => [
          ...prev,
          {
            id: Math.random().toString(),
            error: errorMessage,
            description: ex,
          },
        ]);
      }
    };
    setIsUploadingFile(true);
    uploadFile(file);
  }

  function onFileReadSuccess(file: LicenseFile | null): void {
    setFileToUpload(file);
  }

  function onFileReadError(ex: ProgressEvent<FileReader>): void {
    setPageErrorsList((prev) => [
      ...prev,
      {
        id: Math.random().toString(),
        error: "Error reading the file.",
        description: ex.target?.error?.message || "Unknown error.",
      },
    ]);
  }

  function submitEnvironmentSettingsHandler(
    e: FormEvent<HTMLFormElement>
  ): void {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const fields: any = formData.entries();
    const settings: EnvironmentSetting[] = [];

    for (const pair of fields) {
      settings.push({
        environment_property: pair[0].split("-")[2],
        property_value: pair[1],
      });
    }

    if (settings.length > 0) {
      setIsSubmittingEnvironmentSettings(true);
      updateEnvironmentSettingsMutation.mutate(settings);
    }
  }

  function submitTestPlatformsHandler(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const fields: any = formData.entries();
    const platforms: TestPlatform[] = [];

    for (const pair of fields) {
      platforms.push({
        platformName: pair[0].split("-")[2],
        testPlatform: pair[1],
      });
    }

    if (platforms.length > 0) {
      setIsSubmittingTestPlatforms(true);
      updateTestPlatformsMutation.mutate(platforms);
    }
  }

  function fileUploadClickHandler() {
    const input = fileUploadRef.current;

    if (input) {
      input.value = "";
      input.click();
    }
  }

  function fileUploadChangeHandler(e: ChangeEvent<HTMLInputElement>) {
    const filesList = e.target.files;
    if (filesList) {
      getFileToUpload(filesList[0])
        .then(onFileReadSuccess)
        .catch(onFileReadError);
    }
  }

  function fileUploadOnDragEnterHandler(e: DragEvent<HTMLDivElement>) {
    e.stopPropagation();
    e.preventDefault();

    if (
      !e.relatedTarget ||
      (e.relatedTarget && !e.currentTarget.contains(e.relatedTarget as Node))
    ) {
      setIsDragging(true);
    }
  }

  function fileUploadOnDragLeaveHandler(e: DragEvent<HTMLDivElement>) {
    e.stopPropagation();
    e.preventDefault();

    if (
      !e.relatedTarget ||
      (e.relatedTarget && !e.currentTarget.contains(e.relatedTarget as Node))
    ) {
      setIsDragging(false);
    }
  }

  function fileUploadOnDragOverHandler(e: DragEvent<HTMLDivElement>) {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  }

  function fileUploadOnDropHandler(e: DragEvent<HTMLDivElement>) {
    e.stopPropagation();
    e.preventDefault();

    const filesList = e.dataTransfer.files;
    getFileToUpload(filesList[0])
      .then(onFileReadSuccess)
      .catch(onFileReadError);
    setIsDragging(false);
  }

  function clearFileToUploadHandler(): void {
    setFileToUpload(null);
    setIsUploadingFile(false);
    setIsUploadComplete(false);
    setLicenseFileHasError(false);

    if (fileUploadRef.current) fileUploadRef.current.value = "";
  }

  useEffect(() => {
    if (fileToUpload) {
      if (fileToUpload.type === "error") {
        setLicenseFileHasError(true);
      } else {
        uploadLicenseFile(fileToUpload.file);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileToUpload]);

  return (
    <PageTransition>
      <div id="content" className="px-6 py-9 xl:max-w-5xl xl:mx-auto">
        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-1">
            <h2 className="text-lg font-medium leading-6 text-gray-900">
              Environment Configurations
            </h2>
            <p className="mt-1 text-sm text-gray-600">
              In order to keep everything running smoothly, it's important for
              us to ensure our environment is properly configured.
            </p>
          </div>
          <div className="col-span-2">
            <form onSubmit={submitEnvironmentSettingsHandler}>
              <div className="shadow overflow-hidden rounded-md">
                <div className="space-y-6 bg-white p-6">
                  {environmentSettings && (
                    <div className="flex flex-col space-y-4">
                      {environmentSettings.map((setting) => {
                        return (
                          <SystemSettingInputGroup
                            key={`setting-${setting.environment_property}`}
                            inputId={`environment-property-${setting.environment_property}`}
                            label={setting.environment_property}
                            defaultValue={setting.property_value}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className="flex justify-end space-x-4 py-4 px-6 bg-gray-50 ">
                  <Button
                    type="reset"
                    label="Reset"
                    icon={solid("rotate")}
                    variant="white"
                  />
                  <Button
                    type="submit"
                    label="Save"
                    icon={solid("floppy-disk")}
                    isLoading={isSubmittingEnvironmentSettings}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div aria-hidden="true">
          <div className="py-6">
            <div className="border-t border-gray-200" />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-1">
            <h2 className="text-lg font-medium leading-6 text-gray-900">
              Test Platforms
            </h2>
            <p className="mt-1 text-sm text-gray-600">
              By forging a faultless link between the data and our servers, you
              can optimize each testing platform to reach its optimal
              performance.
            </p>
          </div>
          <div className="col-span-2">
            <form onSubmit={submitTestPlatformsHandler}>
              <div className="overflow-hidden shadow rounded-md">
                <div className="space-y-6 bg-white p-6">
                  {testPlatforms && (
                    <div className="flex flex-col space-y-6">
                      {testPlatforms.map((platform) => {
                        return (
                          <div
                            key={`test-${platform.platformName}`}
                            className="flex space-x-4"
                          >
                            <VendorAsset
                              platform={platform.platformName}
                              type="icon"
                              className="w-8 h-8"
                            />
                            <div className="w-full pt-1.5 pl-4 border-l-2 border-gray-200">
                              <SystemSettingInputGroup
                                inputId={`test-platforn-${platform.platformName}`}
                                label={
                                  platform.platformDescription ||
                                  platform.platformName
                                }
                                defaultValue={platform.testPlatform}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className="flex justify-end space-x-4 py-4 px-6 bg-gray-50 ">
                  <Button
                    type="reset"
                    label="Reset"
                    icon={solid("rotate")}
                    variant="white"
                  />
                  <Button
                    type="submit"
                    label="Save"
                    icon={solid("floppy-disk")}
                    isLoading={isSubmittingTestPlatforms}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div aria-hidden="true">
          <div className="py-6">
            <div className="border-t border-gray-200" />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-1">
            <h2 className="text-lg font-medium leading-6 text-gray-900">
              License upload
            </h2>
            <p className="mt-1 text-sm text-gray-600">
              Upload your license file to keep runners active and working for
              the platform.
            </p>
          </div>
          <div className="col-span-2">
            <form onSubmit={submitEnvironmentSettingsHandler}>
              <div className="shadow overflow-hidden rounded-md">
                <div className="bg-white p-6">
                  <div
                    onDragEnter={fileUploadOnDragEnterHandler}
                    onDragLeave={fileUploadOnDragLeaveHandler}
                    onDragOver={fileUploadOnDragOverHandler}
                    onDrop={fileUploadOnDropHandler}
                    className={classNames(
                      "relative min-h-full flex flex-col items-center justify-center grow px-20 h-72 border-2 rounded-md",
                      !isUploadingFile &&
                        !isUploadComplete &&
                        !licenseFileHasError
                        ? "border-dashed"
                        : isDragging
                        ? "border-primary-500"
                        : "border-gray-300 focus-within:border-primary-500",
                      isUploadingFile
                        ? "!border-primary-200 !bg-primary-50"
                        : "",
                      isUploadComplete
                        ? "!border-success-200 !bg-green-50"
                        : "",
                      licenseFileHasError
                        ? "!border-danger-200 !bg-danger-50"
                        : ""
                    )}
                  >
                    <input
                      ref={fileUploadRef}
                      onChange={fileUploadChangeHandler}
                      id="fileUpload"
                      type="file"
                      accept=".properties"
                      hidden
                    />
                    {!licenseFileHasError &&
                      !isUploadingFile &&
                      !isUploadComplete && (
                        <button
                          onClick={fileUploadClickHandler}
                          className={`transition-colors duration-300 flex flex-col items-center justify-center ${
                            isDragging
                              ? "text-primary-700"
                              : "text-gray-500 hover:!text-primary-600 focus:!text-primary-700"
                          } focus:outline-none`}
                          disabled={isUploadingFile || licenseFileHasError}
                        >
                          <FontAwesomeIcon
                            icon={solid("cloud-arrow-up")}
                            size="4x"
                            aria-hidden={true}
                          />
                          <span className="mt-2 max-w-xs px-20 text-xs text-gray-700 font-medium">
                            Drop your license file here or click to upload.
                          </span>
                        </button>
                      )}
                    {(licenseFileHasError || isUploadComplete) && (
                      <div className="absolute inset-1 flex flex-col items-center justify-center">
                        <p
                          className={classNames(
                            "flex items-center space-x-2 my-3",
                            licenseFileHasError ? "text-danger-700" : "",
                            isUploadComplete ? "text-success-700" : ""
                          )}
                        >
                          <FontAwesomeIcon
                            icon={
                              licenseFileHasError
                                ? solid("times-circle")
                                : solid("check-circle")
                            }
                            className={classNames(
                              "w-5 h-5",
                              licenseFileHasError ? "text-danger-600" : "",
                              isUploadComplete ? "text-success-600" : ""
                            )}
                          />
                          <span className="text-sm font-medium">
                            {licenseFileHasError
                              ? fileToUpload?.error
                              : "License file upload complete."}
                          </span>
                        </p>
                        <Button
                          variant={licenseFileHasError ? "danger" : "success"}
                          label={
                            licenseFileHasError ? "Try again" : "Upload again"
                          }
                          onClick={clearFileToUploadHandler}
                          size="xs"
                          uppercase
                          outline
                        />
                      </div>
                    )}
                    {isUploadingFile && (
                      <div className="absolute inset-1 flex flex-col items-center justify-center">
                        <div className="w-1/2 mt-10 mb-3">
                          <div className="progress-bar">
                            <div className="progress-bar-value"></div>
                          </div>
                        </div>
                        <p className="text-sm text-primary-700 font-medium">
                          Uploading...
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {pageErrorsList.length > 0 &&
          pageErrorsList.map((error, index, list) => {
            return showNotification(
              <Notification
                key={`error.${error.id}`}
                variant="danger"
                title={error.error}
                subTitle={error.description}
                onClose={() =>
                  setPageErrorsList(
                    list.filter((_listItem, newIndex) => newIndex !== index)
                  )
                }
              />
            );
          })}
      </div>
    </PageTransition>
  );
}

export default SystemSettings;
