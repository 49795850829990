import { Fragment, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Menu, RadioGroup, Transition } from "@headlessui/react";
import { AppBarProps, AppBarWidgetProps } from "./AppBar.types";
import { CommandPalette } from "../CommandPalette";
import { Button } from "../Button";
import { classNames } from "../utils";
import { UserContext } from "../../contexts/UserContext";
import { DataUploadContext } from "../../contexts/DataUploadContext";
import { ClientContext } from "../../contexts/ClientContext";
import Modal, { ModalContent } from "../Modal/Modal";
import { Client } from "../../models/Client";
import { isCrawlerCore } from "../../env";
import { ReactComponent as AppLogoNextPathway } from "../../images/brand/logo-next-pathway.svg";

export function AppBar({
  heading = "Module",
  noElevation = false,
  children,
  onBackClick,
}: AppBarProps) {
  const navigate = useNavigate();
  const { authUser, signOut } = useContext(UserContext);
  const { currentClient, setCurrentClient, clientsListInContext } =
    useContext(ClientContext);
  const [openSwitchClientModal, setOpenSwitchClientModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState<Client>();
  const [openAboutModal, setOpenAboutModal] = useState(false);

  function openModalClickHandler(): void {
    setSelectedClient(
      clientsListInContext?.find((client) => client.id === currentClient?.id)
    );
    setOpenSwitchClientModal(true);
  }

  function signOutClickHandler(): void {
    signOut();
    navigate("/sign-in", {
      state: { from: { info: "You've signed out from the platform." } },
    });
  }

  function closeSwitchClientModal(close: boolean) {
    const clientToSwitch = clientsListInContext?.find(
      (client) => client.id === selectedClient?.id
    );
    if (clientToSwitch) setCurrentClient(clientToSwitch);
    setOpenSwitchClientModal(close);
  }

  useEffect(() => {
    if (selectedClient?.id !== currentClient?.id) closeSwitchClientModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  return (
    <>
      <header
        className={`relative z-10 flex items-center bg-white px-6 py-3 print:hidden ${
          noElevation ? "" : "shadow"
        }`}
      >
        <h1 className="flex items-center w-1/3">
          {onBackClick && (
            <button
              className="px-2.5 py-1.5 mr-4 bg-white border border-gray-300 rounded-full shadow-sm text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-gray-50 focus:ring-primary-500"
              onClick={onBackClick}
            >
              <FontAwesomeIcon
                icon={solid("arrow-left")}
                aria-hidden={true}
                className="w-4 h-4"
              />
              <span className="sr-only">Back</span>
            </button>
          )}
          <Transition
            as="span"
            className="font-medium text-lg"
            appear
            show
            enter="transition duration-300"
            enterFrom="opacity-0 -translate-x-2"
            enterTo="opacity-100 translate-x-0"
          >
            {heading}
          </Transition>
        </h1>
        <div className="w-1/3 py-px invisible">
          <CommandPalette id="global-search" />
        </div>
        <div className="flex items-center justify-end w-1/3">
          {children}
          <Menu as="div" className="relative ml-6">
            <Menu.Button className="flex rounded-full text-primary-600 hover:text-primary-700 border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
              <FontAwesomeIcon
                icon={regular("circle-user")}
                className="w-9 h-9"
                aria-hidden={true}
              />
              <span className="sr-only">User menu</span>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-60 flex flex-col rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 z-40 focus:outline-none">
                <div className="flex flex-col px-4 py-3 text-gray-700">
                  <div className="flex items-center space-x-3 pb-2.5 mb-2.5 border-b border-gray-200">
                    <div className="min-w-0 flex flex-col grow">
                      <p className="text-sm text-gray-500">
                        Current client space
                      </p>
                      <p className="text-sm text-gray-900 font-medium truncate">
                        {currentClient?.name}
                      </p>
                    </div>
                    {clientsListInContext &&
                      clientsListInContext?.length > 1 && (
                        <Menu.Item as={Fragment}>
                          <Button
                            label="Change client"
                            icon={solid("arrow-right-arrow-left")}
                            variant="primary"
                            size="xs"
                            radius="pill"
                            hideLabel={true}
                            onClick={openModalClickHandler}
                            role="menuitem"
                          />
                        </Menu.Item>
                      )}
                  </div>
                  <div className="min-w-0 grow">
                    <p className="sr-only">Signed in as</p>
                    <p className="text-sm truncate">{authUser?.fullName}</p>
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {authUser?.email}
                    </p>
                  </div>
                </div>
                <div className="py-1 ">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/user-profile"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "hidden flex-1 px-4 py-2 text-sm"
                        )}
                      >
                        <FontAwesomeIcon
                          icon={solid("address-card")}
                          className={`mr-2.5 ${
                            active ? "text-gray-500" : "text-gray-400"
                          }`}
                          size="lg"
                          aria-hidden={true}
                          fixedWidth
                        />
                        My profile
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/user-preferences"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        <FontAwesomeIcon
                          icon={solid("folder-gear")}
                          className={`mr-2.5 ${
                            active ? "text-gray-500" : "text-gray-400"
                          }`}
                          size="lg"
                          aria-hidden={true}
                          fixedWidth
                        />
                        Preferences
                      </Link>
                    )}
                  </Menu.Item>
                  {authUser?.provider === "BASIC" && (
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/change-password"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          <FontAwesomeIcon
                            icon={solid("unlock-keyhole")}
                            className={`mr-2.5 ${
                              active ? "text-gray-500" : "text-gray-400"
                            }`}
                            size="lg"
                            aria-hidden={true}
                            fixedWidth
                          />
                          Change password
                        </Link>
                      )}
                    </Menu.Item>
                  )}
                </div>
                {(authUser?.isAdmin() || authUser?.isOwner()) && (
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/account-billing"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "flex items-center w-full text-left px-4 py-2 text-sm"
                          )}
                        >
                          <FontAwesomeIcon
                            icon={solid("money-check-dollar")}
                            className={`mr-2.5 ${
                              active ? "text-gray-500" : "text-gray-400"
                            }`}
                            size="lg"
                            aria-hidden={true}
                            fixedWidth
                          />
                          Account billing
                        </Link>
                      )}
                    </Menu.Item>
                  </div>
                )}
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "flex items-center w-full text-left px-4 py-2 text-sm"
                        )}
                        onClick={() => setOpenAboutModal(true)}
                      >
                        <FontAwesomeIcon
                          icon={solid("circle-info")}
                          className={`mr-2.5 ${
                            active ? "text-gray-500" : "text-gray-400"
                          }`}
                          size="lg"
                          aria-hidden={true}
                          fixedWidth
                        />
                        About
                      </button>
                    )}
                  </Menu.Item>
                  {authUser?.isAdmin() && (
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/health-check"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block flex-1 px-4 py-2 text-sm"
                          )}
                        >
                          <FontAwesomeIcon
                            icon={solid("shield-check")}
                            className={`mr-2.5 ${
                              active ? "text-gray-500" : "text-gray-400"
                            }`}
                            size="lg"
                            aria-hidden={true}
                            fixedWidth
                          />
                          Health check
                        </Link>
                      )}
                    </Menu.Item>
                  )}
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="https://www.nextpathway.com"
                        target="_blank"
                        rel="noreferrer"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "flex items-center w-full text-left px-4 py-2 text-sm"
                        )}
                      >
                        <FontAwesomeIcon
                          icon={solid("at")}
                          className={`mr-2.5 ${
                            active ? "text-gray-500" : "text-gray-400"
                          }`}
                          size="lg"
                          aria-hidden={true}
                          fixedWidth
                        />
                        <span className="mr-auto">nextpathway.com</span>
                        <FontAwesomeIcon
                          icon={solid("arrow-up-right-from-square")}
                          className="w-3.5 h-3.5 mb-0.5 text-gray-400"
                          aria-hidden={true}
                        />
                      </a>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "flex items-center w-full text-left px-4 py-2 text-sm"
                        )}
                        onClick={signOutClickHandler}
                      >
                        <FontAwesomeIcon
                          icon={solid("right-from-bracket")}
                          className={`mr-2.5 ${
                            active ? "text-gray-500" : "text-gray-400"
                          }`}
                          size="lg"
                          aria-hidden={true}
                          fixedWidth
                        />
                        Sign out
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </header>
      {clientsListInContext && clientsListInContext?.length > 1 && (
        <Modal
          size="lg"
          title="Client selection"
          show={openSwitchClientModal}
          onClose={closeSwitchClientModal}
        >
          <ModalContent className="-mt-px pt-0 rounded-b-lg bg-white">
            <RadioGroup value={selectedClient} onChange={setSelectedClient}>
              <RadioGroup.Label className="sr-only">
                Select a client
              </RadioGroup.Label>
              <div className="relative -space-y-px">
                {clientsListInContext?.map((client, planIdx) => (
                  <RadioGroup.Option
                    key={client.name}
                    value={client}
                    className={({ checked }) =>
                      classNames(
                        planIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                        planIdx === clientsListInContext.length - 1
                          ? "rounded-bl-md rounded-br-md"
                          : "",
                        checked
                          ? "bg-primary-50 border-primary-200 z-10"
                          : "border-gray-200",
                        "relative border cursor-pointer pl-4 pr-6 py-4 grid grid-cols-3 focus:outline-none"
                      )
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <span className="flex items-center text-sm">
                          <span
                            className={classNames(
                              checked
                                ? "bg-primary-600 border-transparent"
                                : "bg-white border-gray-300",
                              active
                                ? "ring-2 ring-offset-2 ring-primary-500"
                                : "",
                              "h-4 w-4 rounded-full border flex items-center justify-center shrink-0"
                            )}
                            aria-hidden="true"
                          >
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <RadioGroup.Label
                            as="span"
                            className={classNames(
                              checked ? "text-primary-900" : "text-gray-900",
                              "ml-3 font-medium truncate"
                            )}
                          >
                            {client.name}
                          </RadioGroup.Label>
                        </span>
                        <RadioGroup.Description
                          as="span"
                          className="text-center"
                        >
                          <span
                            className={`inline-flex rounded-full px-3 py-1 text-xs ${
                              client.isActive
                                ? "bg-success-100 text-success-700"
                                : "bg-gray-100 text-gray-600"
                            }`}
                          >
                            {client.isActive ? "Active" : "Inactive"}
                          </span>
                        </RadioGroup.Description>

                        <RadioGroup.Description
                          as="span"
                          className="text-sm text-center"
                        >
                          <span
                            className={classNames(
                              checked ? "text-primary-900" : "text-gray-900",
                              "font-medium"
                            )}
                          >
                            {client.projectCount} Projects
                          </span>
                        </RadioGroup.Description>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </ModalContent>
        </Modal>
      )}
      <Modal title="About us" onClose={setOpenAboutModal} show={openAboutModal}>
        <ModalContent>
          <div className="flex flex-col justify-center">
            <div className="flex items-center space-x-8 mb-8">
              <AppLogoNextPathway
                title="Next Pathway"
                role="img"
                className="flex-shrink-0 w-36 h-auto"
              />
              <div className="mt-1">
                <Button
                  href="https://www.nextpathway.com"
                  label="Visit nextpathway.com"
                  size="sm"
                  icon={solid("arrow-up-right-from-square")}
                  variant="white"
                  trailingIcon
                  external
                />
              </div>
            </div>
            <p className="mb-4 font-medium">
              The automated cloud migration company.
            </p>
            <p className="mb-4">
              We automate the end to end challenges our customers face when
              migrating applications to the cloud.
            </p>
            <p>
              <a
                className="text-primary-700 font-medium hover:underline"
                href="https://www.nextpathway.com/shift-cloud"
                target="_blank"
                rel="noreferrer"
              >
                SHIFT&trade; Cloud
              </a>{" "}
              is our next generation code translation software that can
              automatically migrate legacy workloads from data warehouses and
              data lakes to any cloud target.
            </p>
            <div className="mt-8 space-y-3 text-xs text-gray-500">
              <p>
                Corporate logos are for informational purposes only, and Next
                Pathway is not associated with or sponsored by the respective
                trademark owner.
              </p>
              <p>Copyright &copy; 2023 Next Pathway Inc.</p>
              <p>
                SHIFT&trade; Cloud is an existing, applied for or registered
                trademark of Next Pathway Inc.
              </p>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}

export function AppBarWidget({ className, children }: AppBarWidgetProps) {
  const { authUser } = useContext(UserContext);
  const { projectUploadInProgress } = useContext(DataUploadContext);

  return (
    <div className={className}>
      {children}
      {(authUser?.isAdmin() || authUser?.isOwner()) && isCrawlerCore() && (
        <Link
          to="/configure/data-upload"
          className="relative flex text-gray-500 hover:text-gray-600 focus:text-gray-600 focus-visible:outline-none"
          title={`${
            projectUploadInProgress ? "Upload in progress" : "Data upload"
          }`}
        >
          <FontAwesomeIcon
            icon={
              projectUploadInProgress
                ? regular("cloud")
                : regular("cloud-upload")
            }
            className="w-7 h-7"
          />
          <span className="sr-only">Data upload</span>
          {projectUploadInProgress && (
            <span className="absolute bottom-[0.8rem] left-[0.9rem] flex h-3 w-3">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-secondary-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-secondary-500 border-2 border-white"></span>
              <span className="sr-only">Upload in progress</span>
            </span>
          )}
        </Link>
      )}
    </div>
  );
}
