import { ReactNode } from "react";
import { Route } from "react-router-dom";
import AuthorizationApi from "../api/AuthorizationApi";
import SignIn from "../pages/authorization/SignIn";
import PublicRoute from "./components/PublicRoute";
import RestrictedRoute from "./components/RestrictedRoute";
import ResetPassword from "../pages/authorization/ResetPassword";
import ForgotPassword from "../pages/authorization/ForgotPassword";
import UserProfile from "../pages/authorization/UserProfile";
import UserPreferences from "../pages/authorization/UserPreferences";
import UserChangePassword from "../pages/authorization/UserChangePassword";
import NotFound from "../pages/authorization/NotFound";
import ComingSoon from "../pages/authorization/ComingSoon";
import UserWelcome from "../pages/authorization/UserWelcome";
import HealthCheck from "../pages/authorization/HealthCheck";
import MarketplaceSignUp from "../pages/authorization/MarketplaceSignUp";

function AuthorizationRoutes(api: AuthorizationApi): ReactNode {
  return (
    <>
      <Route
        path="sign-in"
        element={
          <PublicRoute force>
            <SignIn authApi={api} />
          </PublicRoute>
        }
      />
      <Route
        path="marketplace-sign-up"
        element={
          <PublicRoute force>
            <MarketplaceSignUp />
          </PublicRoute>
        }
      />
      <Route
        path="user-welcome"
        element={
          <PublicRoute>
            <UserWelcome authApi={api} />
          </PublicRoute>
        }
      />
      {["setup-password/:code", "reset-password/:code"].map((path, index) => (
        <Route
          path={path}
          element={
            <PublicRoute>
              <ResetPassword authApi={api} />
            </PublicRoute>
          }
          key={`route-${index}`}
        />
      ))}
      <Route
        path="forgot-password"
        element={
          <PublicRoute>
            <ForgotPassword authApi={api} />
          </PublicRoute>
        }
      />
      <Route
        path="user-profile"
        element={
          <RestrictedRoute>
            <UserProfile />
          </RestrictedRoute>
        }
      />
      <Route
        path="user-preferences"
        element={
          <RestrictedRoute>
            <UserPreferences />
          </RestrictedRoute>
        }
      />
      <Route
        path="change-password"
        element={
          <RestrictedRoute>
            <UserChangePassword authApi={api} />
          </RestrictedRoute>
        }
      />
      <Route
        path="health-check"
        element={
          <RestrictedRoute>
            <HealthCheck />
          </RestrictedRoute>
        }
      />
      <Route
        path="coming-soon"
        element={
          <PublicRoute>
            <ComingSoon />
          </PublicRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </>
  );
}

export default AuthorizationRoutes;
