import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "../src/styles/main.css";
import App from "./App";

const container = document.getElementById("np-studio");

if (container) {
  const root = createRoot(container);
  root.render(
    //<React.SrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    //</React.StrictMode>
  );
}

reportWebVitals();
