import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StatementPanel from "../../translate/components/StatementPanel";

interface AnalysisReportExceptionsSectionProps {
  exceptionsCount: number;
  exceptionCategoriesCount?: number;
  exceptionsList?: ExceptionItem[];
}

interface ExceptionItem {
  name: string;
  count: number;
}

function AnalysisReportExceptionsSection({
  exceptionsCount,
  exceptionCategoriesCount,
  exceptionsList,
}: AnalysisReportExceptionsSectionProps) {
  return (
    <>
      <div className="mt-8 mb-6 px-8 py-7 border-2 rounded-md border-warning-600 bg-warning-50 print:break-inside-avoid">
        <div className="flex items-center space-x-5">
          <FontAwesomeIcon
            icon={solid("exclamation-triangle")}
            size="2x"
            className="text-warning-600"
          />
          <div className="flex flex-col space-y-2">
            <div className="text-2xl leading-none text-warning-900 font-bold">{`${exceptionsCount.toLocaleString()} exceptions found`}</div>
            <div className="text-warning-700 leading-none">{`${exceptionCategoriesCount?.toLocaleString()} different exception categories`}</div>
          </div>
        </div>
      </div>
      {exceptionsCount > 0 && (
        <StatementPanel
          title={
            <span className="font-medium text-warning-700">
              Exception categories
            </span>
          }
        >
          <div className="flex flex-col divide-y divide-gray-200">
            {exceptionsList &&
              exceptionsList
                .sort((a, b) => b.count - a.count)
                .map((exception, index) => {
                  return (
                    <div key={exception.name} className="flex py-4 text-sm">
                      <span className="font-bold">{`${index + 1}.`}</span>
                      <span className="ml-1 mr-auto">{exception.name}</span>
                      <span className="font-medium text-warning-700 text-right whitespace-nowrap ml-6">{`${exception.count.toLocaleString()} occurrences`}</span>
                    </div>
                  );
                })}
          </div>
        </StatementPanel>
      )}
    </>
  );
}

export default AnalysisReportExceptionsSection;
