import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ClassAttributes, forwardRef, useState } from "react";
import { InputGroupAddonButton } from "../InputGroup";
import { classNames } from "../utils";
import { InputProps } from "./Input.types";

const Input = forwardRef(
  (
    {
      id,
      name,
      size = "md",
      type = "text",
      min,
      max,
      step,
      inputMode,
      autoComplete,
      className,
      required,
      disabled,
      onChange,
      onBlur,
      placeholder,
      defaultValue,
      ariaDescribedby,
      maxLength,
      pattern,
      readOnly,
      isInvalid = false,
      value,
      hasAddon = false,
      isPasswordInput = false,
    }: InputProps,
    ref: ClassAttributes<HTMLInputElement>["ref"]
  ) => {
    let inputSize;
    const [showPassword, setShowPassword] = useState(false);

    switch (size) {
      case "sm":
        inputSize = "py-1.5 px-2.5 text-sm";
        break;
      case "md":
        inputSize = "text-sm";
        break;
      case "lg":
        inputSize = "py-2 px-3";
        break;
    }

    return (
      <>
        <input
          ref={ref}
          id={id}
          name={name}
          type={isPasswordInput ? (showPassword ? type : "password") : type}
          min={min}
          max={max}
          step={step}
          inputMode={inputMode}
          autoComplete={autoComplete}
          className={classNames(
            `block w-full rounded-md border-gray-300 bg-white autofill:!bg-primary-100 shadow-sm focus:border-primary-500 focus:ring-primary-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500`,
            inputSize,
            className || "",
            isInvalid
              ? "!border-danger-500 focus:!border-danger-500 focus:!ring-danger-500"
              : "",
            isInvalid || hasAddon || isPasswordInput ? "!pr-12" : ""
          )}
          disabled={disabled}
          required={required}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          defaultValue={defaultValue}
          aria-describedby={ariaDescribedby}
          maxLength={maxLength}
          pattern={pattern}
          readOnly={readOnly}
          value={value}
        />
        {isPasswordInput && (
          <InputGroupAddonButton
            icon={showPassword ? regular("eye-slash") : regular("eye")}
            text={showPassword ? "Hide" : "Show"}
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
      </>
    );
  }
);

export default Input;
