import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RefObject, useContext, useState } from "react";
import { Button } from "../../_ui-kit";
import { PageTransition } from "../../_ui-kit/PageTransition";
import { Client, ClientData, ClientPricingPlan } from "../../models/Client";
import { ConfigureContext } from "../../contexts/ConfigureContext";
import ClientsListRow, {
  ClientsListRowSkeleton,
} from "./components/ClientsListRow";
import ClientEditSlider from "./components/ClientEditSlider";

type SortDirection = "desc" | "asc";

function ClientManagement() {
  const { configureApi, clientsList } = useContext(ConfigureContext);
  const [openSlideOver, setOpenSlideOver] = useState(false);
  const [sliderTitle, setSliderTitle] = useState("");
  const [clientsListQuery, setClientsListQuery] = useState("");
  const [sortDirection, setSortDirection] = useState<SortDirection>("desc");
  const [editClient, setEditClient] = useState<Client>();
  const filteredClientsList =
    clientsListQuery === ""
      ? clientsList
      : clientsList?.filter((item) => {
          return item.name
            .toLowerCase()
            .includes(clientsListQuery.toLowerCase());
        });

  function sortClients(a: Client, b: Client) {
    if (sortDirection === "desc") {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
    } else {
      if (a.name > b.name) return -1;
      if (a.name < b.name) return 1;
    }
    return 0;
  }

  function sortClientsClickHandler(): void {
    setSortDirection(sortDirection === "desc" ? "asc" : "desc");
  }

  function addClientHandler(): void {
    setSliderTitle("New client");
    setOpenSlideOver(true);
  }

  function editClientClickHandler(
    clientId: string,
    editButton: RefObject<HTMLButtonElement>
  ): void {
    const getClientPricingPlan = async (
      client: Client
    ): Promise<ClientPricingPlan | undefined> => {
      const result = await configureApi.getClientPrincingPlan(client.id);
      const response = await result.text();
      if (response === "") return undefined;
      const pricingPlan: ClientPricingPlan = await JSON.parse(response);
      return pricingPlan;
    };

    const loadEditClient = async (client: Client) => {
      const result = await configureApi.getClientById(client.id);
      const updateClient: ClientData = await result.json();
      client.setClientRestriction(updateClient.clientRestriction);

      const pricingPlan = await getClientPricingPlan(client);
      client.setPricingPlan(pricingPlan);

      setEditClient(client);
      setOpenSlideOver(true);

      if (editButton.current) editButton.current.disabled = false;
    };

    if (clientsList) {
      const editClient = clientsList.find((client) => client.id === clientId);
      if (editClient && editButton.current) {
        editButton.current.disabled = true;
        loadEditClient(editClient);
        setSliderTitle("Edit client");
      }
    }
  }

  return (
    <PageTransition>
      <div id="content" className="px-6 py-9 xl:max-w-5xl xl:mx-auto">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <h2 className="flex items-center text-lg text-gray-900 font-medium">
              Clients
              {!clientsList && (
                <span className="inline-flex px-3 ml-1 text-gray-300">
                  <FontAwesomeIcon
                    icon={solid("spinner")}
                    spin={true}
                    className="w-6 h-6"
                    aria-hidden={true}
                  />
                </span>
              )}
              {clientsList && (
                <span className="rounded-full bg-gray-200 px-3 py-1 ml-3 text-sm text-gray-700 font-bold">
                  {clientsList.length}
                </span>
              )}
            </h2>
            <Button
              label="Add client"
              icon={solid("plus")}
              onClick={addClientHandler}
            />
          </div>
          <div>
            <label htmlFor="searchClients" className="sr-only">
              Search clients
            </label>
            <div className="flex rounded-md shadow-sm">
              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3.5">
                  <FontAwesomeIcon
                    icon={regular("search")}
                    className="h-3.5 w-3.5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  id="searchClients"
                  className="block w-full rounded-none rounded-l-md border-gray-300 pl-9 focus:border-primary-500 focus:ring-primary-500 text-sm"
                  placeholder="Search"
                  onChange={(event) => setClientsListQuery(event.target.value)}
                  disabled={!clientsList}
                />
              </div>
              <button
                type="button"
                className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                onClick={sortClientsClickHandler}
                disabled={!clientsList}
              >
                <FontAwesomeIcon
                  icon={
                    sortDirection === "desc"
                      ? solid("arrow-down-short-wide")
                      : solid("arrow-up-short-wide")
                  }
                  className="h-4 w-4 text-gray-400"
                  aria-hidden="true"
                />
                <span>Sort</span>
              </button>
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-col">
          <div className="overflow-x-auto -mx-1">
            <div className="inline-block min-w-full py-2 align-middle px-1">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 text-left text-sm font-medium pr-3 pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-medium"
                      >
                        Created date
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-medium"
                      >
                        Projects
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-medium"
                      >
                        Status
                      </th>
                      <th scope="col" className="relative py-3.5 pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {filteredClientsList &&
                      filteredClientsList
                        .sort(sortClients)
                        .map((client) => (
                          <ClientsListRow
                            key={client.id}
                            client={client}
                            editClientClickHandler={editClientClickHandler}
                          />
                        ))}
                    {!clientsList &&
                      [...Array(5)].map((_r, i) => (
                        <ClientsListRowSkeleton key={`skeleton-${i}`} />
                      ))}
                    {filteredClientsList && filteredClientsList.length < 1 && (
                      <tr>
                        <td
                          className="px-6 py-4 text-sm text-gray-500"
                          colSpan={5}
                        >
                          No clients found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <ClientEditSlider
          openSlideOver={openSlideOver}
          setOpenSlideOver={setOpenSlideOver}
          sliderTitle={sliderTitle}
          clientsList={clientsList}
          editClient={editClient}
          setEditClient={setEditClient}
        />
      </div>
    </PageTransition>
  );
}

export default ClientManagement;
