import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import { VendorAsset } from "../VendorCard";
import { SelectPlatformProps } from "./SelectPlatform.types";

export default function SelectPlatform({
  platforms,
  selectedPlatform,
  setSelectedPlatform,
  name,
}: SelectPlatformProps) {
  useEffect(() => {
    if (!selectedPlatform) {
      setSelectedPlatform(platforms[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Listbox
      value={selectedPlatform}
      onChange={setSelectedPlatform}
      name={name}
    >
      <div className="relative">
        <Listbox.Button className="relative inline-flex items-center w-full pl-2 pr-9 py-2 border border-gray-300 shadow-sm rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500">
          <span className="flex items-center w-full space-x-3">
            {selectedPlatform && (
              <VendorAsset platform={selectedPlatform.name} type="icon" />
            )}
            <span className="truncate">{selectedPlatform?.description}</span>
            {selectedPlatform?.assignedName && (
              <>
                <span className="text-gray-500">&bull;</span>
                <span className="truncate text-gray-500">
                  {selectedPlatform.assignedName}
                </span>
              </>
            )}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex flex-col justify-center items-center pr-3.5 text-gray-500">
            <FontAwesomeIcon
              icon={solid("sort-up")}
              className="w-2.5 h-2.5"
              aria-hidden={true}
            />
            <FontAwesomeIcon
              icon={solid("sort-down")}
              className="w-2.5 h-2.5 -mt-1.5"
              aria-hidden={true}
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Listbox.Options className="origin-top-right absolute right-0 mt-2 py-1 w-full max-h-36 overflow-auto flex flex-col rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-40 focus:outline-none">
            {platforms.map((platform) => (
              <Listbox.Option
                key={platform.id}
                className={({ active, selected }) =>
                  `relative cursor-default select-none py-2 pl-4 pr-12 ${
                    selected && !active
                      ? "bg-primary-100 text-primary-700"
                      : active
                      ? "bg-primary-50 text-primary-700"
                      : "bg-white text-gray-900"
                  }`
                }
                value={platform}
              >
                {({ active, selected }) => (
                  <>
                    <span className="flex items-center space-x-3">
                      <VendorAsset platform={platform.name} type="icon" />
                      <span className="truncate">{platform.description}</span>
                      {platform.assignedName && (
                        <>
                          <span className="text-gray-500">&bull;</span>
                          <span className="truncate text-gray-500">
                            {platform.assignedName}
                          </span>
                        </>
                      )}
                    </span>
                    {selected ? (
                      <span
                        className={`absolute inset-y-0 right-0 flex items-center pr-4 text-base text-primary-600`}
                      >
                        <span className="sr-only">Selected</span>
                        <FontAwesomeIcon
                          icon={solid("check")}
                          aria-hidden={true}
                        />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
