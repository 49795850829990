import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox, Transition } from "@headlessui/react";
import { ChangeEvent, Fragment, useContext, useEffect, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import { Button, TabPanel } from "../../../_ui-kit";
import { CollapsibleListItem } from "../../../_ui-kit/CollapsibleListItem";
import { SearchBox } from "../../../_ui-kit/SearchBox";
import {
  ControlFlowSummary,
  DiscoverContext,
  ETLPlatformSummary,
} from "../../../contexts/DiscoverContext";
import { ProjectContext } from "../../../contexts/ProjectContext";

interface ControlFlowsTabProps {
  summary: ETLPlatformSummary | null;
}

interface ControlFlowQueryParams {
  pageIndex: number;
  pageSize: number;
  sortKey: string;
  sortDirection: string;
  searchFilter: string;
  projectId?: string;
  migratingWarehouse?: string;
}

interface SortOption {
  id: string;
  name: string;
  type: "sort" | "direction";
}

const sortKeyOptions: SortOption[] = [
  { id: "complexity", name: "Complexity", type: "sort" },
  { id: "dataflows", name: "Data flows", type: "sort" },
  { id: "steps", name: "Steps", type: "sort" },
  { id: "sources", name: "Sources", type: "sort" },
  { id: "sinks", name: "Sinks", type: "sort" },
];

const directionOptions: SortOption[] = [
  { id: "desc", name: "Descendant", type: "direction" },
  { id: "asc", name: "Ascendant", type: "direction" },
];

const defaultParams: ControlFlowQueryParams = {
  pageIndex: 1,
  pageSize: 10,
  sortKey: sortKeyOptions[0].id,
  sortDirection: directionOptions[0].id,
  searchFilter: "",
};

function getDirectionLabel(sortOption: SortOption, direction: SortOption) {
  switch (sortOption.id) {
    case "complexity":
      if (direction.id === "desc") return "Most complex";
      return "Least complex";
    case "jobs":
      if (direction.id === "desc") return "More jobs";
      return "Less jobs";
    case "controlflows":
      if (direction.id === "desc") return "More control flows";
      return "Less control flows";
    case "dataflows":
      if (direction.id === "desc") return "More data flows";
      return "Less data flows";
    case "sinks":
      if (direction.id === "desc") return "More sinks";
      return "Less sinks";
    case "sources":
      if (direction.id === "desc") return "More sources";
      return "Less sources";
    default:
    case "steps":
      if (direction.id === "desc") return "More steps";
      return "Less steps";
  }
}

function getSortIcon(direction: SortOption) {
  if (direction.id === "desc") return solid("arrow-down-wide-short");
  return solid("arrow-up-short-wide");
}

function getListItemSubtitle(summary: ControlFlowSummary, sortType: string) {
  switch (sortType) {
    case "complexity":
      return `Score ${summary.aggregateComplexityCoefficient.toLocaleString()}`;
    case "dataflows":
      return `${summary.dataflows.toLocaleString()} Data flows`;
    case "steps":
      return `${summary.steps.toLocaleString()} Steps`;
    case "sources":
      return `${summary.sources.toLocaleString()} Sources`;
    case "sinks":
      return `${summary.sinks.toLocaleString()} Sinks`;
    default:
      return "-";
  }
}

export function ControlFlowsTab({ summary }: ControlFlowsTabProps) {
  const { discoverApi } = useContext(DiscoverContext);
  const { ref, inView } = useInView();
  const { currentProject } = useContext(ProjectContext);
  const { selectedMigratingDataWarehouse } = useContext(DiscoverContext);
  const [searchFilter, setSearchFilter] = useState(defaultParams.searchFilter);
  const [selectedSortOptions, setSelectedSortOptions] = useState<SortOption[]>([
    sortKeyOptions[0],
    directionOptions[0],
  ]);
  const [queryParams, setQueryParams] = useState<ControlFlowQueryParams>({
    ...defaultParams,
    projectId: currentProject?.uuid,
  });
  const getFetchData = async ({ pageParam = queryParams.pageIndex }) => {
    if (summary && queryParams.projectId && queryParams.migratingWarehouse) {
      const result = await discoverApi.getMigratingControlFlows(
        queryParams.projectId,
        summary.id,
        pageParam,
        queryParams.pageSize,
        queryParams.sortKey,
        queryParams.sortDirection,
        queryParams.searchFilter,
        queryParams.migratingWarehouse
      );
      const controlflows: ControlFlowSummary[] = await result.json();
      return controlflows;
    }
  };
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery(["etl-controlflows-tab", queryParams], getFetchData, {
      getNextPageParam: (lastPage, pages) => {
        return lastPage && lastPage.length > 0 ? pages.length + 1 : undefined;
      },
    });

  function searchFilterChangeHandler(e: ChangeEvent<HTMLInputElement>) {
    setSearchFilter(e.target.value);
  }

  function sortOptionChangeHandler(options: SortOption[]) {
    const sortKey = options.filter(
      (option) =>
        option.type === "sort" && option.id !== selectedSortOptions[0].id
    )[0];

    if (sortKey) {
      setSelectedSortOptions([sortKey, selectedSortOptions[1]]);
      return;
    }

    const direction = options.filter(
      (option) =>
        option.type === "direction" && option.id !== selectedSortOptions[1].id
    )[0];

    if (direction) setSelectedSortOptions([selectedSortOptions[0], direction]);
  }

  function loadMoreClickHandler() {
    fetchNextPage();
  }

  useEffect(() => {
    if (inView) fetchNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  useEffect(() => {
    setQueryParams({ ...queryParams, searchFilter: searchFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter]);

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      sortKey: selectedSortOptions[0].id,
      sortDirection: selectedSortOptions[1].id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSortOptions]);

  useEffect(() => {
    if (selectedMigratingDataWarehouse)
      setQueryParams({
        ...queryParams,
        migratingWarehouse: selectedMigratingDataWarehouse.id.toString(),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMigratingDataWarehouse]);

  return (
    <TabPanel className="overflow-y-scroll h-full">
      <div className="relative flex px-6 py-4 space-x-4">
        <div className="grow">
          <SearchBox
            id={"search-control-flows"}
            placeholder="Search control flows"
            onChange={searchFilterChangeHandler}
          />
        </div>
        <Listbox
          value={selectedSortOptions}
          onChange={sortOptionChangeHandler}
          multiple
        >
          <div className="relative">
            <Listbox.Button
              id="controlflows-sort"
              className="relative inline-flex items-center pl-4 pr-9 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              <span className="flex text-base">
                <FontAwesomeIcon
                  icon={getSortIcon(selectedSortOptions[1])}
                  className="text-gray-400 mr-2"
                  aria-hidden={true}
                />
                <span className="sr-only">{selectedSortOptions[1].name}</span>
              </span>
              <span className="block truncate">
                {selectedSortOptions[0].name}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex flex-col justify-center items-center pr-3.5 text-gray-500">
                <FontAwesomeIcon
                  icon={solid("sort-up")}
                  className="w-2.5 h-2.5"
                  aria-hidden={true}
                />
                <FontAwesomeIcon
                  icon={solid("sort-down")}
                  className="w-2.5 h-2.5 -mt-1.5"
                  aria-hidden={true}
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Listbox.Options className="origin-top-right absolute right-0 mt-2 py-1 w-48 flex flex-col rounded-md shadow-lg bg-white text-sm ring-1 ring-black ring-opacity-5 z-40 focus:outline-none">
                <h6 className="px-4 py-2 font-medium">Sort by</h6>
                {sortKeyOptions.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active, selected }) =>
                      `relative cursor-default select-none py-2 pl-4 pr-9 ${
                        selected && !active
                          ? "bg-primary-100 text-primary-700"
                          : active
                          ? "bg-primary-50 text-primary-700"
                          : "bg-white text-gray-900"
                      }`
                    }
                    value={option}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {option.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 right-0 flex items-center pr-4 text-primary-600`}
                          >
                            <span className="text-base">
                              <span className="sr-only">Selected</span>
                              <FontAwesomeIcon
                                icon={solid("check")}
                                aria-hidden={true}
                              />
                            </span>
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
                <h6 className="px-4 pt-3 pb-2 mt-1 border-t font-medium">
                  Order
                </h6>
                {directionOptions.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active, selected }) =>
                      `relative cursor-default select-none py-2 pl-4 pr-9 ${
                        selected && !active
                          ? "bg-primary-100 text-primary-700"
                          : active
                          ? "bg-primary-50 text-primary-700"
                          : "bg-white text-gray-900"
                      }`
                    }
                    value={option}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {getDirectionLabel(selectedSortOptions[0], option)}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 right-0 flex items-center pr-4 text-primary-600`}
                          >
                            <span className="text-base">
                              <span className="sr-only">Selected</span>
                              <FontAwesomeIcon
                                icon={solid("check")}
                                aria-hidden={true}
                              />
                            </span>
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
      <div className="relative bg-white border-y">
        {data &&
          data.pages.map(
            (summaries: ControlFlowSummary[] | undefined, index) => {
              return (
                <Fragment key={index}>
                  {summaries?.map((summary) => {
                    return (
                      <CollapsibleListItem
                        key={summary.id}
                        icon={regular("diagram-next")}
                        title={summary.name}
                        subTitle={getListItemSubtitle(
                          summary,
                          selectedSortOptions[0].id
                        )}
                      >
                        <>
                          <div className="max-w-sm pl-1 py-4">
                            <dl className="grid grid-cols-3 gap-x-12 gap-y-2.5">
                              <div className="flex flex-col space-y-1">
                                <span className="text-gray-500">
                                  Complexity
                                </span>
                                <span className="font-medium">
                                  {summary.aggregateComplexityCoefficient.toLocaleString()}
                                </span>
                              </div>
                              <div className="flex flex-col space-y-1">
                                <span className="text-gray-500">
                                  Average scale
                                </span>
                                <span className="font-medium">
                                  {summary.averageComplexityScale.toLowerCase()}
                                </span>
                              </div>
                              <div className="flex flex-col space-y-1">
                                <span className="text-gray-500">
                                  Data Flows
                                </span>
                                <span className="font-medium">
                                  {summary.dataflows.toLocaleString()}
                                </span>
                              </div>
                              <div className="flex flex-col space-y-1">
                                <span className="text-gray-500">Steps</span>
                                <span className="font-medium">
                                  {summary.steps.toLocaleString()}
                                </span>
                              </div>
                              <div className="flex flex-col space-y-1">
                                <span className="text-gray-500">Sources</span>
                                <span className="font-medium">
                                  {summary.sources.toLocaleString()}
                                </span>
                              </div>
                              <div className="flex flex-col space-y-1">
                                <span className="text-gray-500">Sinks</span>
                                <span className="font-medium">
                                  {summary.sinks.toLocaleString()}
                                </span>
                              </div>
                            </dl>
                          </div>
                          <Button
                            label="More details"
                            size="xs"
                            variant="white"
                            radius="pill"
                            uppercase
                          />
                        </>
                      </CollapsibleListItem>
                    );
                  })}
                </Fragment>
              );
            }
          )}
        {!data && isFetching && (
          <div className="divide-y">
            {[...Array(queryParams.pageSize)].map((e, i) => (
              <div
                key={i}
                className="px-6 py-4 w-full bg-white"
                aria-hidden={true}
              >
                <div className="animate-pulse flex items-center">
                  <div className="rounded bg-gray-200 w-6 h-6 mr-3"></div>
                  <div className="grow h-4 bg-gray-200 rounded mr-6"></div>
                  <div className="w-28 h-4 bg-gray-200 rounded"></div>
                </div>
              </div>
            ))}
          </div>
        )}
        {data && (
          <button
            ref={ref}
            className={`w-full bg-white border-t -mt-px py-4 px-11 text-gray-500 ${
              hasNextPage && !isFetchingNextPage
                ? "hover:bg-gray-50 hover:text-gray-700"
                : ""
            }`}
            disabled={!hasNextPage || isFetchingNextPage}
            onClick={loadMoreClickHandler}
          >
            {isFetchingNextPage ? (
              <span className="text-gray-400">
                <FontAwesomeIcon
                  icon={solid("spinner")}
                  size="lg"
                  aria-hidden={true}
                  spin
                />
                <span className="sr-only">Loading more entries...</span>
              </span>
            ) : hasNextPage ? (
              <span className="uppercase font-medium text-sm">Load more</span>
            ) : (
              <span className="text-sm">
                {data.pages.length === 1
                  ? "No entries found"
                  : "No more entries"}
              </span>
            )}
          </button>
        )}
        {data && isFetching && !isFetchingNextPage && (
          <div className="absolute inset-0 flex justify-center bg-white opacity-70 py-10">
            <span className="animate-pulse bg-white p-2 uppercase text-sm font-medium">
              Updating...
            </span>
          </div>
        )}
      </div>
    </TabPanel>
  );
}
