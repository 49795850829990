import { useEffect, useState } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import AuthorizationApi from "../../api/AuthorizationApi";
import { Button } from "../../_ui-kit";
import Alert from "../../_ui-kit/Alert/Alert";
import { AuthRouteLocationState } from "../../routes/components/AuthorizationRoute";
import { MasterPage } from "../../_ui-kit/MasterPage";
import InputGroup, {
  InputGroupErrorDescription,
} from "../../_ui-kit/InputGroup/InputGroup";
import Input from "../../_ui-kit/Input/Input";
import { userNameBlurHandler } from "./SignIn";

interface SingInProps {
  authApi: AuthorizationApi;
}

const sendPassword = async (
  email: string,
  setIsSubmitting: (param: boolean) => void,
  setError: (param: string) => void,
  navigate: NavigateFunction,
  authApi: AuthorizationApi
) => {
  try {
    await authApi.requestPasswordReset(email, true);
    navigate("/sign-in", {
      state: {
        from: {
          info: "Password reset instructions have been sent.",
        },
      },
    });
    setIsSubmitting(false);
  } catch (ex: any) {
    setIsSubmitting(false);
    setError(ex);
  }
};

export default function ForgotPassword({ authApi }: SingInProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [info, setInfo] = useState("");
  const [emailAddressRequiredError, setEmailAddressRequiredError] =
    useState(false);
  const [emailAddressFormatError, setEmailAddressFormatError] = useState(false);
  const navigate = useNavigate();
  const locationState = useLocation().state as AuthRouteLocationState;

  function handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");
    setInfo("");

    const formData = new FormData(e.currentTarget);
    const userEmail = formData.get("forgot-username") as string;

    sendPassword(userEmail, setIsSubmitting, setError, navigate, authApi);
  }

  useEffect(() => {
    setError(locationState?.from?.error || "");
    setInfo(locationState?.from?.info || "");
    window.history.replaceState(null, "");
  }, [locationState]);

  return (
    <MasterPage type="signin" heading="Forgot Password">
      {error && (
        <Alert variant="danger" className="mb-8" show={!!error} dismissible>
          <p className="text-sm font-medium">{error}</p>
        </Alert>
      )}
      {info && (
        <Alert className="mb-8" show={!!info} dismissible>
          <p className="text-sm font-medium">{info}</p>
        </Alert>
      )}
      <h2 className="mb-1 text-4xl font-bold text-gray-900">
        Forgot your password?
      </h2>
      <p className="mb-6 text-sm text-gray-600">
        We'll be able to help you set up a new one.
      </p>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col space-y-8 text-left"
      >
        <InputGroup label="Email address" inputId="forgot-username">
          <Input
            type="email"
            id="forgot-username"
            name="forgot-username"
            size="lg"
            autoComplete="username"
            isInvalid={emailAddressRequiredError || emailAddressFormatError}
            onBlur={(e) =>
              userNameBlurHandler(
                e,
                setEmailAddressRequiredError,
                setEmailAddressFormatError
              )
            }
            ariaDescribedby={
              emailAddressRequiredError
                ? "forgot-username-required-error"
                : emailAddressFormatError
                ? "forgot-username-format-error"
                : undefined
            }
            required
          />
          {emailAddressRequiredError && (
            <InputGroupErrorDescription
              id="forgot-username-required-error"
              text="Your email address is required."
            />
          )}
          {emailAddressFormatError && (
            <InputGroupErrorDescription
              id="forgot-username-format-error"
              text="Your email address is invalid."
            />
          )}
        </InputGroup>
        <div className="flex flex-col space-y-2">
          <Button
            label="Reset password"
            size="lg"
            type="submit"
            isLoading={isSubmitting}
          />
          <Button
            label="Cancel"
            size="lg"
            variant="gray"
            onClick={() => navigate(-1)}
            soft
            underline
          />
        </div>
      </form>
    </MasterPage>
  );
}

export { sendPassword };
