import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "../utils";
import {
  InputGroupButtonProps,
  InputGroupErrorDescriptionProps,
  InputGroupProps,
} from "./InputGroup.types";

export default function InputGroup({
  label,
  inputId,
  className,
  children,
}: InputGroupProps) {
  return (
    <div className={className}>
      <label htmlFor={inputId} className="block font-medium text-gray-700">
        {label}
      </label>
      <div className="relative mt-1">{children}</div>
    </div>
  );
}

function InputGroupAddonButton({ icon, text, onClick }: InputGroupButtonProps) {
  return (
    <button
      type="button"
      className="absolute top-1.5 right-2 flex mt-px p-1.5 text-gray-500 hover:text-gray-700 rounded focus:outline-none focus:bg-gray-100 focus:text-primary-700"
      onClick={onClick}
    >
      <span className="sr-only">{text}</span>
      <FontAwesomeIcon icon={icon} className="w-5 h-4" />
    </button>
  );
}

function InputGroupErrorDescription({
  id,
  showIcon = true,
  text,
  hideText = false,
}: InputGroupErrorDescriptionProps) {
  return (
    <>
      {showIcon && (
        <FontAwesomeIcon
          icon={solid("xmark-circle")}
          className="absolute top-3 right-4 w-4 h-4 text-red-600"
        />
      )}
      <p
        id={id}
        className={classNames(
          "mt-1.5 text-xs text-red-700",
          hideText ? "sr-only" : ""
        )}
      >
        {text}
      </p>
    </>
  );
}

export { InputGroupAddonButton, InputGroupErrorDescription };
