import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare as fasArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { faArrowRight as fasArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { ReactComponent as AppLogo } from "../../images/brand/logo-crawler.svg";
import { Button } from "../../_ui-kit";

export default function NotFound() {
  return (
    <>
      <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-8">
          <div className="flex-shrink-0 flex justify-center">
            <a href="/" className="inline-flex">
              <AppLogo
                title="Next Pathway Studio"
                role="img"
                width={114}
                height={68}
              />
            </a>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-sm font-bold text-primary-700 uppercase tracking-wide">
                404 error
              </p>
              <h1 className="mt-2 text-5xl font-black text-gray-600 tracking-tight">
                Page not found.
              </h1>
              <p className="mt-2 text-base text-gray-500">
                Sorry, we couldn't find the page you're looking for.
              </p>
              <div className="mt-6">
                <Button
                  label="Go back home"
                  icon={fasArrowRight}
                  trailingIcon={true}
                  soft={true}
                  href="/"
                />
              </div>
            </div>
          </div>
        </main>
        <footer className="flex-shrink-0 max-w-7xl w-full mx-auto px-8">
          <nav className="flex justify-center space-x-4">
            <a
              href="https://www.nextpathway.com/contact/"
              target="_blank"
              rel="noreferrer"
              className="text-sm font-medium text-gray-500 hover:text-gray-600"
            >
              Contact us
              <FontAwesomeIcon
                icon={fasArrowUpRightFromSquare}
                className="ml-2 w-3.5 h-3.5"
                aria-hidden={true}
              />
            </a>
            <span
              className="inline-block border-l border-gray-300"
              aria-hidden="true"
            />
            <a
              href="https://www.linkedin.com/company/next-pathway-inc-/"
              target="_blank"
              rel="noreferrer"
              className="text-sm font-medium text-gray-500 hover:text-gray-600"
            >
              LinkedIn
              <FontAwesomeIcon
                icon={fasArrowUpRightFromSquare}
                className="ml-2 w-3.5 h-3.5"
                aria-hidden={true}
              />
            </a>
            <span
              className="inline-block border-l border-gray-300"
              aria-hidden="true"
            />
            <a
              href="https://twitter.com/nextpathway"
              target="_blank"
              rel="noreferrer"
              className="text-sm font-medium text-gray-500 hover:text-gray-600"
            >
              Twitter
              <FontAwesomeIcon
                icon={fasArrowUpRightFromSquare}
                className="ml-2 w-3.5 h-3.5"
                aria-hidden={true}
              />
            </a>
          </nav>
        </footer>
      </div>
    </>
  );
}
