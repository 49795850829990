import { Project } from "../../../models/Project";
import { getFormattedDate } from "../../../utils/common";

interface ProjectsListRowProps {
  project: Project;
  editProjectClickHandler: (param: string) => void;
}

function ProjectsListRow({
  project,
  editProjectClickHandler,
}: ProjectsListRowProps) {
  return (
    <tr>
      <td className="whitespace-nowrap py-4 pr-3 text-sm pl-6 font-medium">
        {project.name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {project.createdUsername}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {getFormattedDate(project.dateCreated)}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {project.clientName}
      </td>
      <td className="relative whitespace-nowrap py-4 pl-3 text-right text-sm font-medium pr-6">
        <button
          className="px-1 text-primary-700 rounded-sm hover:text-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          onClick={() => editProjectClickHandler(project.uuid)}
        >
          Edit
          <span className="sr-only">, {project.name}</span>
        </button>
      </td>
    </tr>
  );
}

function ProjectsListRowSkeleton() {
  return (
    <tr>
      <td className="py-4 pr-3 pl-6 leading-5">
        <span className="inline-block rounded bg-gray-200 w-40 h-4 align-middle animate-pulse" />
      </td>
      <td className="px-3 py-4 leading-5">
        <span className="inline-block rounded bg-gray-200 w-20 h-4 align-middle animate-pulse" />
      </td>
      <td className="px-3 py-4 leading-5">
        <span className="inline-block rounded bg-gray-200 w-20 h-4 align-middle animate-pulse" />
      </td>
      <td className="px-3 py-4 leading-5">
        <span className="inline-block rounded bg-gray-200 w-36 h-4 align-middle animate-pulse" />
      </td>
      <td className="py-4 pl-3 pr-6 text-right leading-5">
        <span className="inline-block rounded bg-gray-200 w-8 h-4 align-middle animate-pulse" />
      </td>
    </tr>
  );
}

export { ProjectsListRowSkeleton };
export default ProjectsListRow;
