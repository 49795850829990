import BaseApi from "./BaseApi";

export default class TasksApi extends BaseApi {
  apiGetTasks = `${this.baseUrl}/{{projectId}}/shift/tasks`;
  apiGetTaskById = `${this.baseUrl}/{{projectId}}/shift/tasks/{{taskId}}`;
  apiDeleteTaskById = this.apiGetTaskById;
  apiTranslateTask = `${this.baseUrl}/{{projectId}}/shift/tasks/{{taskId}}/translate`;
  apiTranslateEtlTask = `${this.baseUrl}/{{projectId}}/shift/tasks/{{taskId}}/etl_translate`;
  apiCancelTask = `${this.baseUrl}/{{projectId}}/shift/tasks/{{taskId}}/cancel`;
  apiGetTaskPricingSummary = `${this.baseUrl}/{{projectId}}/shift/tasks/{{taskId}}/pricingsummary`;
  apiGetSqlAnalyzerReport = `${this.baseUrl}/{{projectId}}/shift/tasks/{{taskId}}/analyzer_report`;
  apiGetEtlAnalyzerReport = `${this.baseUrl}/{{projectId}}/shift/tasks/{{taskId}}/etl_analyzer_report`;
  apiGetTaskObjectSummary = `${this.baseUrl}/{{projectId}}/shift/tasks/{{taskId}}/task_object_summary`;
  apiGetLibraryTaskFileObjects = `${this.baseUrl}/{{projectId}}/shift/tasks/{{taskId}}/{{fileId}}/code`;
  apiUpdateTaskTargetObjects = `${this.baseUrl}/{{projectId}}/shift/tasks/{{taskId}}/files/{{fileId}}/codes`;
  apiDownloadTranslatedCode = `${this.baseUrl}/{{projectId}}/shift/tasks/{{taskId}}/download_translate`;
  apiGetTaskTranslateConfirmation = `${this.baseUrl}/{{projectId}}/shift/tasks/{{taskId}}/confirmtranslate`;
  apiGetTaskTranslateEtlConfirmation = `${this.baseUrl}/{{projectId}}/shift/tasks/{{taskId}}/confirmetltranslate`;
  apiGetTranslatedFileErrors = `${this.baseUrl}/{{projectId}}/shift/tasks/{{taskId}}/{{fileId}}/task_object_errors`;
  apiGetTaskErrorDetails = `${this.baseUrl}/{{projectId}}/shift/tasks/{{taskId}}/error_details`;
  apiGetTaskTranslateErrorDetails = `${this.baseUrl}/{{projectId}}/shift/tasks/{{taskId}}/translate_error_details`;
  apiDownloadTaskLogs = `${this.baseUrl}/{{projectId}}/shift/tasks/{{taskId}}/task_logs`;

  getTasks(projectId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetTasks.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getTask(projectId: string, taskId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetTaskById
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{taskId}}", encodeURIComponent(taskId))}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  deleteTask(
    projectId: string,
    taskId: string,
    force: boolean
  ): Promise<Response> {
    const queryString: any = {
      force,
    };
    const promise = this.doFetch(
      `${this.apiDeleteTaskById
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace(
          "{{taskId}}",
          encodeURIComponent(taskId)
        )}?${this.objToQueryString(queryString)}`,
      {
        method: "DELETE",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  translateTask(projectId: string, taskId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiTranslateTask
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{taskId}}", encodeURIComponent(taskId))}`,
      {
        method: "POST",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );

    return promise;
  }

  translateEtlTask(projectId: string, taskId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiTranslateEtlTask
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{taskId}}", encodeURIComponent(taskId))}`,
      {
        method: "POST",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );

    return promise;
  }

  cancelTask(projectId: string, taskId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiCancelTask
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{taskId}}", encodeURIComponent(taskId))}`,
      {
        method: "POST",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );

    return promise;
  }

  getTaskPricingSummary(projectId: string, taskId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetTaskPricingSummary
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{taskId}}", encodeURIComponent(taskId))}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getAnalyzerReport(projectId: string, taskId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetSqlAnalyzerReport
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{taskId}}", encodeURIComponent(taskId))}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getEtlAnalyzerReport(projectId: string, taskId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetEtlAnalyzerReport
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{taskId}}", encodeURIComponent(taskId))}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getLibraryTaskFileObjects(
    projectId: string,
    taskId: string,
    fileId: number,
    pageIndex: number,
    pageSize: number,
    searchFilter?: string,
    errorSearch?: string,
    errorType?: string
  ) {
    const queryString: any = {
      pageIndex: pageIndex,
      pageSize: pageSize,
    };
    if (searchFilter) queryString.filter = searchFilter;
    if (errorSearch) queryString.errorSearch = errorSearch;
    if (errorType) queryString.errorType = errorType;
    const promise = this.doFetch(
      `${this.apiGetLibraryTaskFileObjects
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{fileId}}", encodeURIComponent(fileId))
        .replace("{{taskId}}", encodeURIComponent(taskId))}${
        this.isObjEmpty(queryString)
          ? ""
          : `?${this.objToQueryString(queryString)}`
      }
            `,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  updateTaskTargetObjects(
    projectId: string,
    taskId: string,
    fileId: string,
    objects: any[]
  ): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiUpdateTaskTargetObjects
        .replace("{{projectId}}", encodeURIComponent(taskId))
        .replace("{{taskId}}", encodeURIComponent(taskId))
        .replace("{{fileId}}", encodeURIComponent(fileId))}`,
      {
        method: "PUT",
        headers: this.getAuthApplicationJsonHeaders(),
        body: JSON.stringify(objects),
      }
    );
    return promise;
  }

  getTaskObjectSummary(projectId: string, taskId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetTaskObjectSummary
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{taskId}}", encodeURIComponent(taskId))}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  downloadTranslatedCode(projectId: string, taskId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiDownloadTranslatedCode
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{taskId}}", encodeURIComponent(taskId))}`,
      {
        method: "GET",
        headers: this.getAuthOctetStreamHeaders(),
      }
    );
    return promise;
  }

  getTranslateConfirmation(
    projectId: string,
    taskId: string
  ): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetTaskTranslateConfirmation
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{taskId}}", encodeURIComponent(taskId))}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getTranslateEtlConfirmation(
    projectId: string,
    taskId: string
  ): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetTaskTranslateEtlConfirmation
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{taskId}}", encodeURIComponent(taskId))}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getTranslatedFileErrors(projectId: string, taskId: string, fileId: number) {
    const promise = this.doFetch(
      `${this.apiGetTranslatedFileErrors
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{fileId}}", encodeURIComponent(fileId))
        .replace("{{taskId}}", encodeURIComponent(taskId))}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getTaskErrorDetails(projectId: string, taskId: string) {
    const promise = this.doFetch(
      `${this.apiGetTaskErrorDetails
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{taskId}}", encodeURIComponent(taskId))}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getTaskTranslateErrorDetails(projectId: string, taskId: string) {
    const promise = this.doFetch(
      `${this.apiGetTaskTranslateErrorDetails
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{taskId}}", encodeURIComponent(taskId))}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  downloadTaskLogs(
    projectId: string,
    taskId: string,
    type: "analyze" | "translate"
  ): Promise<Response> {
    const queryString: any = {
      type,
    };

    const promise = this.doFetch(
      `${this.apiDownloadTaskLogs
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace(
          "{{taskId}}",
          encodeURIComponent(taskId)
        )}?${this.objToQueryString(queryString)}`,
      {
        method: "GET",
        headers: this.getAuthOctetStreamHeaders(),
      }
    );
    return promise;
  }
}
