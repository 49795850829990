import MarketplaceApi from "../../../api/MarketplaceApi";
import { ReactComponent as SignInWith } from "../../../images/vendors/azure-active-directory.svg";

export default function MicrosoftSignOn() {
  const marketplaceApi = new MarketplaceApi();

  function signInClickHandler() {
    window.open(marketplaceApi.apiSSO, "_self", "noopener noreferrer");
  }

  return (
    <button
      className="inline-flex items-center border font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 select-none bg-white border-gray-300 text-gray-700 hover:bg-gray-50 focus:ring-primary-500 shadow-sm px-4 rounded-md"
      onClick={signInClickHandler}
    >
      <span className="sr-only">Sign in with Microsoft</span>
      <SignInWith className="h-auto shrink-0 mx-auto" />
    </button>
  );
}
