import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Project } from "../../../models/Project";
import { getFormattedDateTime } from "../../../utils/common";
import { classNames } from "../../../_ui-kit/utils";
import { VendorAsset } from "../../../_ui-kit/VendorCard";
import TaskListRowMenuItem from "./TaskListRowMenuItem";
import { TaskData, TaskStatus } from "./TasksList";

interface TaskListRowProps {
  path: string;
  task: TaskData;
  isSubmitted: boolean;
  isTranslate: boolean;
  hasError: boolean;
  isFinished: boolean;
  status: string;
  isAdmin: boolean;
  project: Project;
  isLast?: boolean;
}

interface TasksListRowSkeletonProps {
  isAdmin: boolean;
}

const taskActions = [
  {
    id: "download-logs",
    icon: solid("folder-arrow-down"),
    label: "Download logs",
    important: false,
  },
  {
    id: "cancel-task",
    icon: solid("power-off"),
    label: "Cancel task",
    important: false,
  },
  {
    id: "delete-task",
    icon: solid("trash-alt"),
    label: "Delete task",
    important: false,
  },
];

function TaskListRow({
  path,
  task,
  isTranslate,
  hasError,
  isFinished,
  status,
  isAdmin,
  project,
  isLast = false,
}: TaskListRowProps) {
  return (
    <tr className="group">
      <td className="whitespace-nowrap py-4 pr-3 text-sm pl-6 w-1/5">
        <div className="flex flex-col space-y-0.5">
          <div className="font-medium">
            {task.taskStatus === TaskStatus.SUBMITTED && (
              <span>{task.projectPlatformName}</span>
            )}
            {task.taskStatus !== TaskStatus.SUBMITTED && (
              <Link
                to={path}
                state={{ task }}
                className="hover:underline hover:text-primary-700"
              >
                <span>{task.projectPlatformName}</span>
              </Link>
            )}
          </div>
          <div className="text-gray-500">
            {((isTranslate && !task.translated) ||
              task.sourceType === "ETL") && (
              <span>{task.size.toLocaleString()} Objects</span>
            )}
            {((isTranslate && task.translated) || !isTranslate) &&
              task.sourceType !== "ETL" && (
                <Link
                  to={
                    isTranslate
                      ? `/translate/code-results/${task.uuid}`
                      : `/upload/library-files/${task.projectPlatformId}`
                  }
                  className="hover:underline hover:text-primary-600"
                >
                  {task.size.toLocaleString()} Objects
                </Link>
              )}
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-0">
        <div className="bg-gray-100 rounded px-2 py-1 uppercase text-xs font-medium text-gray-600">
          {task.sourceType}
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <div className="flex items-center space-x-3">
          <VendorAsset type="icon" platform={task.sourcePlatformName} />
          {(task.sourceType === "SQL" || isTranslate) && (
            <>
              <FontAwesomeIcon
                icon={solid("arrow-right")}
                className="text-gray-400"
                size="sm"
              />
              <VendorAsset type="icon" platform={task.targetPlatformName} />
            </>
          )}
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {task.createdBy}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {getFormattedDateTime(task.dateCreated)}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-center">
        {!isTranslate && (
          <>
            {task.taskStatus === TaskStatus.ANALYZING && (
              <span title="Analysis in progress">
                <FontAwesomeIcon
                  icon={solid("spinner-third")}
                  className="w-5 h-5 text-gray-400 animate-spin"
                />
                <span className="sr-only">Analysis in progress</span>
              </span>
            )}
            {task.analyzed && (
              <span
                title={task.analyzeSlaMet ? "SLA met" : "Too many errors"}
                className={`inline-flex rounded-full p-1.5 ${
                  task.analyzeSlaMet ? "bg-success-100" : "bg-warning-100"
                }`}
              >
                <FontAwesomeIcon
                  icon={
                    task.analyzeSlaMet ? solid("check") : solid("exclamation")
                  }
                  className={`w-3 h-3 ${
                    task.analyzeSlaMet ? "text-success-700" : "text-warning-600"
                  }`}
                />
                <span className="sr-only">
                  {task.analyzeSlaMet ? "SLA met" : "Too many errors"}
                </span>
              </span>
            )}
            {task.taskStatus === TaskStatus.SUBMITTED && !task.analyzed && (
              <>
                <FontAwesomeIcon
                  icon={solid("dash")}
                  className="text-gray-400"
                  size="xs"
                />
                <span className="sr-only">Not available</span>
              </>
            )}
          </>
        )}
        {isTranslate && (
          <>
            {(task.taskStatus === TaskStatus.TESTING ||
              task.taskStatus === TaskStatus.TRANSLATING) && (
              <span
                title={`${
                  task.taskStatus === TaskStatus.TESTING
                    ? "Testing in progress"
                    : "Translation in progress"
                }`}
              >
                <FontAwesomeIcon
                  icon={solid("spinner-third")}
                  className="w-5 h-5 text-gray-400 animate-spin"
                />
                <span className="sr-only">{`${
                  task.taskStatus === TaskStatus.TESTING
                    ? "Testing in progress"
                    : "Translation in progress"
                }`}</span>
              </span>
            )}
            {task.taskStatus !== TaskStatus.TRANSLATING &&
              task.taskStatus !== TaskStatus.TESTING &&
              task.taskStatus !== TaskStatus.SUBMITTED && (
                <span
                  title={
                    task.tested
                      ? task.slaMet
                        ? "Test passed"
                        : "Too many translation errors"
                      : "Test failed"
                  }
                  className={`inline-flex rounded-full p-1.5 ${
                    task.tested
                      ? task.slaMet
                        ? "bg-success-100"
                        : "bg-warning-100"
                      : "bg-danger-100"
                  }`}
                >
                  <FontAwesomeIcon
                    icon={
                      task.tested
                        ? task.slaMet
                          ? solid("check")
                          : solid("exclamation")
                        : solid("times")
                    }
                    className={`w-3 h-3 ${
                      task.tested
                        ? task.slaMet
                          ? "text-success-700"
                          : "text-warning-600"
                        : "text-danger-700"
                    }`}
                  />
                  <span className="sr-only">
                    {task.tested
                      ? task.slaMet
                        ? "Test passed"
                        : "Too many translation errors"
                      : "Test failed"}
                  </span>
                </span>
              )}
            {task.taskStatus === TaskStatus.SUBMITTED && (
              <>
                <FontAwesomeIcon
                  icon={solid("dash")}
                  className="text-gray-400"
                  size="xs"
                />
                <span className="sr-only">Not available</span>
              </>
            )}
          </>
        )}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm">
        <span
          className={`inline-flex rounded-full px-3 py-1 text-xs ${
            hasError
              ? "bg-danger-100 text-danger-700"
              : isFinished
              ? "bg-success-100 text-success-700"
              : "bg-gray-100 text-gray-600"
          }`}
        >
          {status}
        </span>
      </td>
      {isAdmin && (
        <td className="w-0 py-4 text-sm pr-6">
          <Menu as="div" className="relative">
            <Menu.Button className="invisible group-hover:visible group-focus-within:visible flex p-2 rounded-md bg-gray-50 hover:bg-gray-100 border border-transparent text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
              <span className="sr-only">Options</span>
              <FontAwesomeIcon icon={solid("ellipsis-vertical")} />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className={classNames(
                  isLast
                    ? "origin-bottom-right bottom-full mb-2"
                    : "origin-top-right mt-2",
                  "absolute right-0 w-40 py-1 flex flex-col rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-40 focus:outline-none"
                )}
              >
                {taskActions.map((action) => {
                  if (action.id === "delete-task" && !isAdmin) return null;

                  return (
                    <TaskListRowMenuItem
                      key={action.id}
                      isTranslate={isTranslate}
                      actionId={action.id}
                      actionIcon={action.icon}
                      actionLabel={action.label}
                      actionIsImportant={action.important}
                      projectId={project.uuid}
                      taskStatus={task.taskStatus}
                      taskId={task.uuid}
                      isAdmin={isAdmin}
                    />
                  );
                })}
              </Menu.Items>
            </Transition>
          </Menu>
        </td>
      )}
    </tr>
  );
}

function TasksListRowSkeleton({ isAdmin }: TasksListRowSkeletonProps) {
  return (
    <tr>
      <td className="py-4 pr-3 pl-6 w-1/5">
        <div className="flex flex-col space-y-2.5 animate-pulse">
          <span className="inline-block rounded bg-gray-200 w-48 h-4" />
          <span className="inline-block rounded bg-gray-200 w-20 h-4" />
        </div>
      </td>
      <td className="px-3 py-4">
        <span className="inline-block rounded bg-gray-200 w-9 h-6 animate-pulse align-middle" />
      </td>
      <td className="px-3 py-4">
        <div className="flex items-center space-x-3 animate-pulse">
          <span className="inline-block rounded bg-gray-200 w-6 h-6" />
          <FontAwesomeIcon
            icon={solid("arrow-right")}
            className="text-gray-200"
            size="sm"
          />
          <span className="inline-block rounded bg-gray-200 w-6 h-6" />
        </div>
      </td>
      <td className="px-3 py-4">
        <span className="inline-block rounded bg-gray-200 w-24 h-4 animate-pulse align-middle" />
      </td>
      <td className="px-3 py-4">
        <span className="inline-block rounded bg-gray-200 w-48 h-4 animate-pulse align-middle" />
      </td>
      <td className="px-3 py-4 text-center">
        <span className="inline-block rounded-full bg-gray-200 w-6 h-6 animate-pulse align-middle" />
      </td>
      <td className="px-3 py-4 text-center">
        <span className="inline-block rounded bg-gray-200 w-20 h-4 animate-pulse align-middle" />
      </td>
      {isAdmin && (
        <td className="w-0 py-4 pr-6">
          <span className="invisible inline-block rounded bg-gray-200 w-4 h-6 animate-pulse align-middle" />
        </td>
      )}
    </tr>
  );
}

export { TasksListRowSkeleton };
export default TaskListRow;
