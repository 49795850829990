import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { TempBarChart } from "../../../_ui-kit/BarChart";
import { Button } from "../../../_ui-kit/Button";
import { TabPanel } from "../../../_ui-kit/Tabs";
import { VendorAsset } from "../../../_ui-kit/VendorCard";
import { DiscoverContext, ETLPlatformSummary, MigratingDatawarehouse, MigratingDatawarehouseAggregates } from "../../../contexts/DiscoverContext";
import { ProjectContext } from "../../../contexts/ProjectContext";

interface DetailsTabProps {
  summary: ETLPlatformSummary | null;
}

export function DetailsTab({ summary, ...props }: DetailsTabProps) {
  const { discoverApi } = useContext(DiscoverContext);
  const { currentProject } = useContext(ProjectContext);
  const { selectedMigratingDataWarehouse } = useContext(DiscoverContext);
  const [aggregates, setAggregates] = useState<
    MigratingDatawarehouseAggregates[]
  >([]);

  function getAggregateValue(key: string): number {
    return aggregates.filter((agg) => agg.aggregate === key)[0].support;
  }

  useEffect(() => {
    async function loadMigratingDatawarehouseAggregates(
      projectId: string,
      etl: ETLPlatformSummary,
      warehouse: MigratingDatawarehouse
    ): Promise<void> {
      const result = await discoverApi.getMigratingDataWarehouseAggregates(
        projectId,
        etl.id,
        warehouse.id.toString()
      );
      const aggregates: MigratingDatawarehouseAggregates[] =
        await result.json();
      setAggregates(aggregates);
    }

    if (currentProject && summary && selectedMigratingDataWarehouse) {
      setAggregates([]);
      loadMigratingDatawarehouseAggregates(
        currentProject.uuid,
        summary,
        selectedMigratingDataWarehouse
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject, selectedMigratingDataWarehouse, summary]);

  return (
    <TabPanel className="overflow-y-scroll h-full bg-white">
      <div className="p-6">
        <h2 className="font-medium mb-4">Migrating data warehouse</h2>
        {aggregates.length < 1 && (
          <div className="rounded-lg bg-white mb-6" aria-hidden={true}>
            <div className="animate-pulse flex items-center space-x-4">
              <div className="rounded bg-gray-200 w-52 h-20"></div>
              <div className="w-full h-7 bg-gray-200 rounded"></div>
            </div>
          </div>
        )}
        {aggregates.length > 0 && (
          <div className="flex items-center mb-6">
            <div className="flex items-center rounded bg-gray-100 p-3">
              {selectedMigratingDataWarehouse && (
                <VendorAsset
                  platform={selectedMigratingDataWarehouse.name}
                  type="logo"
                />
              )}
            </div>
            <div className="ml-4 font-medium">
              <FontAwesomeIcon
                icon={regular("diagram-cells")}
                size="lg"
                className="text-gray-500 mr-3"
                aria-hidden={true}
              />
              {getAggregateValue("DWJobs")} / {getAggregateValue("EtlJobs")}{" "}
              Jobs
            </div>
          </div>
        )}
        <Button label="Go to wave planner" variant="white" fullWidth />
        {aggregates.length < 1 && (
          <div className="rounded-lg bg-white my-6" aria-hidden={true}>
            <div className="animate-pulse flex flex-col">
              <div className="rounded bg-gray-200 w-32 h-7 mb-5"></div>
              <div className="flex space-x-10 mb-6">
                <div className="w-full h-7 bg-gray-200 rounded"></div>
                <div className="w-full h-7 bg-gray-200 rounded"></div>
              </div>
              <div className="w-full h-12 bg-gray-200 rounded mb-3"></div>
              <div className="w-full h-20 bg-gray-200 rounded mb-5"></div>
              <div className="flex space-x-10 mb-5">
                <div className="w-full h-7 bg-gray-200 rounded"></div>
                <div className="w-full h-7 bg-gray-200 rounded"></div>
              </div>
              <div className="grid grid-cols-2 gap-x-10 gap-y-4">
                <div className="h-7 bg-gray-200 rounded col-span-1"></div>
                <div className="h-7 bg-gray-200 rounded col-span-1"></div>
                <div className="h-7 bg-gray-200 rounded col-span-1"></div>
                <div className="h-7 bg-gray-200 rounded col-span-1"></div>
                <div className="h-7 bg-gray-200 rounded col-span-1"></div>
                <div className="h-7 bg-gray-200 rounded col-span-1"></div>
              </div>
            </div>
          </div>
        )}
        {aggregates.length > 0 && (
          <>
            <section className="my-6">
              <h1 className="font-medium mb-4">Job summary</h1>
              <dl className="mt-auto grid grid-cols-2 gap-x-12 gap-y-4 text-sm">
                <div className="flex items-center justify-between border-b border-b-gray-200 pb-3">
                  <dt className="flex items-center grow min-w-0 mr-3 text-gray-500">
                    <FontAwesomeIcon
                      icon={solid("upload")}
                      className="w-4 h-4 mr-3"
                      aria-hidden={true}
                    />
                    <span className="truncate grow">Total reads</span>
                  </dt>
                  <dd className="font-medium">
                    {getAggregateValue("TotalReads")}
                  </dd>
                </div>
                <div className="flex items-center justify-between border-b border-b-gray-200 pb-3">
                  <dt className="flex items-center grow min-w-0 mr-3 text-gray-500">
                    <FontAwesomeIcon
                      icon={solid("download")}
                      className="w-4 h-4 mr-3"
                      aria-hidden={true}
                    />
                    <span className="truncate grow">Total writes</span>
                  </dt>
                  <dd className="font-medium">
                    {getAggregateValue("TotalWrites")}
                  </dd>
                </div>
              </dl>
              <div className="mt-6">
                <TempBarChart
                  exclusive={getAggregateValue("Exclusive")}
                  readingOthers={getAggregateValue("ReadingOthers")}
                  readingWritingOthers={getAggregateValue(
                    "ReadingWritingOthers"
                  )}
                  writingOthers={getAggregateValue("WritingOthers")}
                />
              </div>
            </section>
            <div className="grid grid-cols-2 gap-x-12 gap-y-3">
              <section>
                <h1 className="font-medium">Ingress</h1>
                <dl className="space-y-3 divide-y pt-1 text-sm">
                  <div className="flex items-center justify-between pt-3">
                    <dt className="flex items-center grow min-w-0 mr-3 text-gray-500">
                      <FontAwesomeIcon
                        icon={solid("database")}
                        className="w-4 h-4 mr-3"
                        aria-hidden={true}
                      />
                      <span className="truncate grow">Sources</span>
                    </dt>
                    <dd className="font-medium">
                      {getAggregateValue("Sources")}
                    </dd>
                  </div>
                  <div className="flex items-center justify-between pt-3">
                    <dt className="flex items-center grow min-w-0 mr-3 text-gray-500">
                      <FontAwesomeIcon
                        icon={solid("chart-bullet")}
                        className="w-4 h-4 mr-3"
                        aria-hidden={true}
                      />
                      <span className="truncate grow">Complex jobs</span>
                    </dt>
                    <dd className="font-medium">
                      {getAggregateValue("Ingress_ComplexJobs")}
                    </dd>
                  </div>
                  <div className="flex items-center justify-between pt-3">
                    <dt className="flex items-center grow min-w-0 mr-3 text-gray-500">
                      <FontAwesomeIcon
                        icon={solid("bars-progress")}
                        className="w-4 h-4 mr-3"
                        aria-hidden={true}
                      />
                      <span className="truncate grow">Simple jobs</span>
                    </dt>
                    <dd className="font-medium">
                      {getAggregateValue("Ingress_SimpleJobs")}
                    </dd>
                  </div>
                </dl>
              </section>
              <section>
                <h1 className="font-medium">Egress</h1>
                <dl className="space-y-3 divide-y pt-1 text-sm">
                  <div className="flex items-center justify-between pt-3">
                    <dt className="flex items-center grow min-w-0 mr-3 text-gray-500">
                      <FontAwesomeIcon
                        icon={solid("database")}
                        className="w-4 h-4 mr-3"
                        aria-hidden={true}
                      />
                      <span className="truncate grow">Sinks</span>
                    </dt>
                    <dd className="font-medium">
                      {getAggregateValue("Sinks")}
                    </dd>
                  </div>
                  <div className="flex items-center justify-between pt-3">
                    <dt className="flex items-center grow min-w-0 mr-3 text-gray-500">
                      <FontAwesomeIcon
                        icon={solid("chart-bullet")}
                        className="w-4 h-4 mr-3"
                        aria-hidden={true}
                      />
                      <span className="truncate grow">Complex jobs</span>
                    </dt>
                    <dd className="font-medium">
                      {getAggregateValue("Egress_ComplexJobs")}
                    </dd>
                  </div>
                  <div className="flex items-center justify-between pt-3">
                    <dt className="flex items-center grow min-w-0 mr-3 text-gray-500">
                      <FontAwesomeIcon
                        icon={solid("bars-progress")}
                        className="w-4 h-4 mr-3"
                        aria-hidden={true}
                      />
                      <span className="truncate grow">Simple jobs</span>
                    </dt>
                    <dd className="font-medium">
                      {getAggregateValue("Egress_SimpleJobs")}
                    </dd>
                  </div>
                </dl>
              </section>
            </div>
          </>
        )}
      </div>
    </TabPanel>
  );
}
