import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Keys } from "../../_ui-kit/Keys";
import { Tabs, TabList, TabItem } from "../../_ui-kit/Tabs";
import { MasterPage } from "../../_ui-kit/MasterPage";
import { DiscoverContextProvider } from "../../contexts/DiscoverContext";
import { useEffect, useState } from "react";

interface NavItem {
  id: number;
  name: string;
  path: string;
}

const navigation: NavItem[] = [
  {
    id: 0,
    name: "ETL Platforms",
    path: "etl-platforms",
  },
  {
    id: 1,
    name: "Data platforms",
    path: "data-platforms",
  },
  {
    id: 2,
    name: "Orchestration Job",
    path: "orchestration-job",
  },
  {
    id: 3,
    name: "Code",
    path: "code",
  },
];

function Discover() {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    const current = navigation.find((nav) => {
      return location.pathname.includes(nav.path);
    });

    if (current) setCurrentTab(current.id);
    else navigate(navigation[0].path);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <DiscoverContextProvider>
      <MasterPage heading="Discover">
        <div className="flex flex-col h-full overflow-y-auto">
          <div className="shrink-0 pt-4">
            <Tabs selectedIndex={currentTab} manual>
              <TabList className="2xl:justify-center">
                {navigation.map((navItem) => (
                  <TabItem
                    key={navItem.id}
                    onClick={() => navigate(navItem.path)}
                    onKeyUp={(e: React.KeyboardEvent<HTMLElement>) => {
                      if (e.key === Keys.Enter) navigate(navItem.path);
                    }}
                    label={navItem.name}
                  />
                ))}
              </TabList>
            </Tabs>
          </div>
          <div className="grow">
            <Outlet />
          </div>
        </div>
      </MasterPage>
    </DiscoverContextProvider>
  );
}

export default Discover;
