import Checkbox from "../../../_ui-kit/Checkbox/Checkbox";
import { Platform } from "../../../_ui-kit/SelectPlatform/SelectPlatform.types";
import { VendorAsset } from "../../../_ui-kit/VendorCard";

interface PlatformSelectionItemProps {
  platform: Platform;
  allowedSQLTargets?: number[];
  type: "sql" | "etl";
}

function PlatformSelectionItem({
  platform,
  allowedSQLTargets,
  type,
}: PlatformSelectionItemProps) {
  return (
    <label className="flex items-center space-x-2 p-2.5 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-primary-500 focus:ring-2 focus:ring-offset-2 select-none">
      <VendorAsset type="icon" platform={platform.name} />
      <span className="text-sm text-gray-900 !mr-auto">
        {platform.description}
      </span>
      <Checkbox
        id={`checkbox-target-${type}-${platform.id}`}
        name={`client-target-${type}-platforms`}
        defaultChecked={allowedSQLTargets?.includes(+platform.id)}
        value={platform.id}
      />
    </label>
  );
}

export default PlatformSelectionItem;
