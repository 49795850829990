interface LinkedBoxProps {
  title: string;
  subTitle: string;
  hideLeftHandle?: boolean;
  hideRightHandle?: boolean;
}

function LinkedBox({
  title,
  subTitle,
  hideLeftHandle = false,
  hideRightHandle = false,
}: LinkedBoxProps) {
  return (
    <div className="relative">
      {!hideLeftHandle && (
        <div
          className="absolute top-1/2 left-0 -translate-x-1/2 z-10 w-2 h-2 -mt-1 rounded-full bg-info-300"
          role="presentation"
          aria-hidden={true}
        />
      )}
      <div className="relative z-20 flex items-center space-x-3 px-3 py-2.5 bg-info-50 border border-info-300 rounded-md">
        <dt className="w-44 text-xs leading-5 text-info-600 font-medium uppercase">
          {title}
        </dt>
        <dd className="grow text-right text-base text-info-900 font-bold">
          {subTitle}
        </dd>
      </div>
      {!hideRightHandle && (
        <div
          className="absolute top-1/2 right-0 translate-x-1/2 z-10 w-2 h-2 -mt-1 rounded-full bg-info-300"
          role="presentation"
          aria-hidden={true}
        />
      )}
    </div>
  );
}

export default LinkedBox;
