import _ from "lodash";
import { getApiUrl, getApiVersion } from "../env";
import {
  clearStoredAuthToken,
  getStoredAuthToken,
} from "../utils/user-storage";

export default class BaseApi {
  constructor() {
    this.baseUrl = `${getApiUrl()}/v${getApiVersion()}`;
  }

  sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
  static isRefreshingToken = false;
  static onSessionExpire = (message: string) => {};
  static serverResponseText = {
    INVALID_TOKEN: "INVALID_TOKEN",
    INVALID_CREDENTIALS: "INVALID_CREDENTIALS",
  };
  static serverResponseTextUIMessage = {
    INVALID_TOKEN: "Your session has expired; please log in again.",
    INVALID_CREDENTIALS: "You've entered incorrect credentials.",
  };
  baseUrl;

  private getAuthTokenFromStorage() {
    const auth = getStoredAuthToken();
    if (auth) return auth.token;
  }

  getErrorMessage(text: string) {
    let message = "Oops! Something went wrong unexpectedly.";

    switch (text) {
      case BaseApi.serverResponseText.INVALID_CREDENTIALS:
        message = BaseApi.serverResponseTextUIMessage.INVALID_CREDENTIALS;
        break;
      case BaseApi.serverResponseText.INVALID_TOKEN:
        message = BaseApi.serverResponseTextUIMessage.INVALID_TOKEN;
        break;
      case "FOLDER_DOES_NOT_EXIST":
        message = "No logs available; the folder does not exist.";
        break;
      case "FAILED_TO_CREATE_FOLDER":
        message = "No logs available; failed to create folder.";
        break;
      case "TASK_IS_NOT_TESTED":
        message = "Task is not tested yet.";
        break;
      case "TESTING_FAILED_TO_MEET_SLA_RATE":
        message = "Testing failed to meet the SLA rate.";
        break;
      case "TASK_OBJECTS_COUNT_IS_OVER_LIMIT":
        message =
          "This library will push you beyond the allowed objects on your subscription plan.";
        break;
      case "NO_LOG_IS_FOUND":
      case "NO_TRANSLATE_LOG_IS_FOUND":
        message = "No logs available.";
        break;
      case "INVALID_RESET_CODE":
        message = "Invalid reset code.";
        break;
      case "PLAN_EXPIRED":
        message = "Your plan has expired.";
        break;
      case "CLIENT_CODE_NAME_HAS_BEEN_USED":
        message = "Client code name has been used.";
        break;
      case "INVALID_CLIENT_SECRET":
        message = "Invalid client secret.";
        break;
      case "DUPLICATED_ACTIVE_PLAN":
        message = "You already have an active plan.";
        break;
      case "RESOLVE_MAXIMUM_RETRIES_REACHED":
        message = "Maximum retries reached.";
        break;
      case "LOGIN_MAXIMUM_RETRIES_REACHED":
        message = "Maximum retries reached.";
        break;
      case "INTERNAL_SERVER_ERROR":
        message = "Something went wrong.";
        break;
      case "ACTIVE_SUBSCRIPTION_EXISTED":
        message = "Your account has an active subscription.";
        break;
      case "INVALID_x-ms-marketplace-token_RESOLVE":
        message = "Invalid marketplace subscription.";
        break;
      case "Invalid_Subscription_Status: Subscribed":
        message = "Your account is already subscribed.";
        break;
      case "Invalid_Subscripton_Status: Suspended":
        message = "Your account is suspended.";
        break;
      case "Invalid_Subscription_Status: Unsubscribed":
        message = "Your account is unsubscribed.";
        break;
      case "Authorization Failed":
        message = "Authorization failed.";
        break;
      case "Invalid Id Token":
        message = "Invalid Id Token.";
        break;
      case "ETL_TRANSLATE_IS_NOT_ENABLED":
        message = "ETL translate is not enabled.";
        break;
      case "DUPLICATED_CLIENT_SPACE_NAME":
        message = "Client space name has been used.";
        break;
      case "PLAN_IS_ASSOCIATED_WITH_CLIENTS":
        message = "Plan is associated with an existing client.";
        break;
      case "401":
        message = "Unauthorized access.";
        break;
    }

    return message;
  }

  isObjEmpty(queryString: {}) {
    return _.isEmpty(queryString);
  }

  objToQueryString(obj: any) {
    const keyValuePairs = [];
    for (const key in obj) {
      keyValuePairs.push(
        encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
      );
    }
    return keyValuePairs.join("&");
  }

  getApplicationJsonHeaders() {
    return {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };
  }

  getAuthHeaders() {
    return {
      Authorization: `Bearer ${this.getAuthTokenFromStorage()}`,
      Accept: "application/json",
    };
  }

  getAuthApplicationJsonHeaders() {
    return {
      Authorization: `Bearer ${this.getAuthTokenFromStorage()}`,
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };
  }

  getAuthOctetStreamHeaders() {
    return {
      Authorization: `Bearer ${this.getAuthTokenFromStorage()}`,
      Accept: "application/octet-stream",
      "Content-Type": "application/octet-stream; charset=utf-8",
    };
  }

  async doFetch(
    input: RequestInfo | URL,
    init?: RequestInit | undefined
  ): Promise<Response> {
    try {
      const requestInit = init || {};
      if (!requestInit.credentials) requestInit.credentials = "omit";
      while (BaseApi.isRefreshingToken) await this.sleep(200);
      const response = await fetch(input, requestInit);
      if (response.ok) return response;
      if (response.status === 401) {
        const result = await response.text();
        const error = this.getErrorMessage(result || "401");
        clearStoredAuthToken();
        BaseApi.onSessionExpire(error);
        return Promise.reject(error);
      }
      const text = await response.text();
      console.error("Fetch error:", text || "Unknown error");
      return Promise.reject(`${this.getErrorMessage(text)}`);
    } catch (ex) {
      console.error("Fetch error:", ex);
      return Promise.reject(`Fetch error: ${ex}`);
    }
  }
}
