import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { ClientContext } from "../../contexts/ClientContext";
import { PageTransition } from "../../_ui-kit/PageTransition";
import { VendorCard } from "../../_ui-kit/VendorCard";
import { ProjectContext } from "../../contexts/ProjectContext";
import { DiscoverContext } from "../../contexts/DiscoverContext";

interface DataPlatformSummary {
  id: number;
  name: string;
  description: string;
  controlflows: number;
  dataflows: number;
  steps: number;
  sources: number;
  sinks: number;
  averageComplexityCoefficient: number;
  aggregateComplexityCoefficient: number;
  averageComplexityScale: string;
  Jobs: number;
}

function DataPlatforms() {
  const { discoverApi } = useContext(DiscoverContext);
  const { currentClient } = useContext(ClientContext);
  const { currentProject } = useContext(ProjectContext);
  const { dataPlatformsSummaries, setDataPlatformsSummaries } =
    useContext(DiscoverContext);
  const [isLoadingSummaries, setIsLoadingSummaries] = useState(false);

  useEffect(() => {
    async function getDataPlatformsSummaries(
      cliendId: string,
      projectId: string
    ): Promise<void> {
      const result = await discoverApi.getDataPlatformsSummary(
        cliendId,
        projectId
      );
      const platforms: DataPlatformSummary[] = await result.json();
      setIsLoadingSummaries(false);
      setDataPlatformsSummaries(platforms);
    }

    if (currentClient && currentProject) {
      setIsLoadingSummaries(true);
      getDataPlatformsSummaries(currentClient.id, currentProject.uuid);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient, currentProject]);

  return (
    <PageTransition>
      <div id="content" className="p-6 2xl:max-w-7xl 2xl:mx-auto 2xl:px-0">
        <h2 className="flex items-center mb-6 text-lg font-medium">
          Datasource types
          {isLoadingSummaries && (
            <span className="inline-flex px-3 ml-1 text-gray-300">
              <FontAwesomeIcon
                icon={solid("spinner")}
                spin={true}
                className="w-6 h-6"
                aria-hidden={true}
              />
            </span>
          )}
          {dataPlatformsSummaries.length > 0 && (
            <span className="rounded-full bg-gray-200 px-4 py-1 ml-3 text-sm font-bold">
              {dataPlatformsSummaries.length}
            </span>
          )}
        </h2>
        <div className="grid grid-cols-4 gap-6">
          {isLoadingSummaries && (
            <>
              {[...Array(4)].map((_e, i) => (
                <div
                  key={i}
                  className="border border-gray-300 shadow rounded-lg py-6 px-6 max-w-md w-full bg-white"
                  aria-hidden={true}
                >
                  <div className="animate-pulse flex flex-col">
                    <div className="rounded bg-gray-200 h-14 w-52 mb-5"></div>
                    <div className="h-5 w-40 bg-gray-200 rounded mb-5"></div>
                    <div className="space-y-4">
                      <div className="h-7 bg-gray-200 rounded col-span-1"></div>
                      <div className="h-7 bg-gray-200 rounded col-span-1"></div>
                      <div className="h-7 bg-gray-200 rounded col-span-1"></div>
                      <div className="h-7 bg-gray-200 rounded col-span-1"></div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
          {dataPlatformsSummaries.length > 0 &&
            dataPlatformsSummaries.map((dataPlatform: DataPlatformSummary) => {
              return (
                <VendorCard
                  key={dataPlatform.id}
                  name={dataPlatform.name}
                  description={dataPlatform.description}
                  data={dataPlatform}
                  vertical
                />
              );
            })}
        </div>
      </div>
    </PageTransition>
  );
}

export default DataPlatforms;
