import { BillingStatement } from "../AccountBilling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { VendorAsset } from "../../../_ui-kit/VendorCard";
import {
  getFormattedDateTime,
  getProcessingTimeText,
} from "../../../utils/common";

interface StatementListRowProps {
  statement: BillingStatement;
}

function StatementListRow({ statement }: StatementListRowProps) {
  return (
    <tr className="print:break-inside-avoid">
      <td className="whitespace-nowrap pr-3 py-5 text-sm w-1/5">
        <div className="flex flex-col space-y-1">
          <span className="font-medium">{statement.library}</span>
          <span className="text-gray-500">{statement.project}</span>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 w-0">
        <div className="bg-gray-100 rounded px-2 py-1 uppercase text-xs font-medium text-gray-600">
          {statement.sourceType}
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        <div className="flex items-center space-x-3">
          <VendorAsset type="icon" platform={statement.sourcePlatform} />
          {statement.sourceType === "SQL" && (
            <>
              <FontAwesomeIcon
                icon={solid("arrow-right")}
                className="text-gray-400"
                size="sm"
              />
              <VendorAsset type="icon" platform={statement.targetPlatform} />
            </>
          )}
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        {getFormattedDateTime(statement.start)}
      </td>
      <td className="w-0 whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        {getProcessingTimeText(statement.processingTimeSeconds)}
      </td>
      <td className="whitespace-nowrap px-3 py-5 text-right text-sm text-gray-500">
        {statement.objectCount.toLocaleString()}
      </td>
      <td className="whitespace-nowrap pl-3 py-5 text-right text-sm text-gray-500">
        {`$${statement.objectCost.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`}
      </td>
    </tr>
  );
}

export default StatementListRow;
