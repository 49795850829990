import { createContext, useEffect, useState } from "react";
import ConfigureApi from "../api/ConfigureApi";
import { Client, ClientData, ClientPricingPlan } from "../models/Client";
import { useQuery, useQueryClient } from "@tanstack/react-query";

interface ClientContextProps {
  isLoadingClientListInContext: boolean;
  clientsListInContext?: Client[];
  currentClient?: Client;
  setCurrentClient: (param: Client) => void;
}

interface ClientContextStateProps {
  children: React.ReactNode;
}

export const ClientContext = createContext<ClientContextProps>(
  {} as ClientContextProps
);

function ClientContextState({ children }: ClientContextStateProps) {
  const configureApi = new ConfigureApi();
  const queryClient = useQueryClient();
  const [currentClient, setCurrentClient] = useState<Client>();

  const {
    data: clientsListInContext,
    isLoading: isLoadingClientListInContext,
  } = useQuery(
    ["clients-list-context"],
    async () => {
      const result = await configureApi.getClients();
      const clients: ClientData[] = await result.json();
      clients.sort(
        (a, b) => +new Date(a.dateCreated) - +new Date(b.dateCreated)
      );
      return clients.map((client) => new Client(client));
    },
    {
      onError: (ex) => {
        console.error(ex);
      },
      onSuccess: (clients) => {
        let client = clients[0];

        if (currentClient) {
          const selected = clients.find(
            (client) => client.id === currentClient.id
          );
          if (selected) {
            setCurrentClient(selected);
          }
        } else {
          setCurrentClient(client);
        }
      },
    }
  );

  useQuery(
    [
      `client-pricing-plan${!currentClient ? "-invalid" : ""}`,
      { clientId: currentClient?.id },
    ],
    async () => {
      const clientId = currentClient?.id || "";
      const result = await configureApi.getClientPrincingPlan(clientId);
      const plan: ClientPricingPlan = await result.json();
      return plan;
    },
    {
      enabled: !!currentClient,
      onError: () => {
        console.error("Error getting pricing plan");
      },
      onSuccess: (plan) => {
        if (currentClient) {
          currentClient.setPricingPlan(plan);
        }
        queryClient.removeQueries(["client-pricing-plan-invalid"]);
      },
    }
  );

  useEffect(() => {
    if (currentClient) {
      const setClientRestriction = async (clientToUpdate: Client) => {
        try {
          const response = await configureApi.getClientById(clientToUpdate.id);
          const result: ClientData = await response.json();
          clientToUpdate.setClientRestriction(result.clientRestriction);
        } catch (ex) {
          console.error(ex);
        }
      };

      setClientRestriction(currentClient);
      queryClient.invalidateQueries(["client-pricing-plan"]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient]);

  return (
    <ClientContext.Provider
      value={{
        isLoadingClientListInContext,
        clientsListInContext,
        currentClient,
        setCurrentClient,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
}

export default ClientContextState;
