import {
  getStoredAuthToken,
  setStoredAuthToken,
  clearStoredAuthToken,
} from "../utils/user-storage";
import { AuthToken } from "../models/User";
import BaseApi from "./BaseApi";

export default class AuthorizationApi extends BaseApi {
  apiLogin = `${this.baseUrl}/auth/login`;
  apiRefreshToken = `${this.baseUrl}/auth/{{refreshToken}}`;
  apiSetPassword = `${this.baseUrl}/users/password`;
  apiRequestPasswordReset = `${this.baseUrl}/users/passwordreset`;
  apiChangePassword = `${this.baseUrl}/users/changePassword`;
  apiVerifyResetCodeValidation = `${this.baseUrl}/users/resetcodevalidation`;

  constructor(onSessionExpire: (message: string) => void) {
    super();
    BaseApi.onSessionExpire = onSessionExpire;
  }

  static refreshTokenTimeout: NodeJS.Timer | undefined;

  initRefreshToken(): void {
    const authToken = getStoredAuthToken();

    if (!authToken) return;

    clearTimeout(AuthorizationApi.refreshTokenTimeout);

    AuthorizationApi.refreshTokenTimeout = setTimeout(async () => {
      try {
        await this.refreshToken(authToken.refreshToken);
        this.initRefreshToken();
      } catch (ex: any) {
        clearStoredAuthToken();
      }
    }, 25 * 60000);
  }

  async signIn(email: string, password: string): Promise<Response> {
    try {
      return await this.doFetch(this.apiLogin, {
        method: "POST",
        body: JSON.stringify({ email, password }),
        headers: this.getApplicationJsonHeaders(),
      });
    } catch (ex) {
      clearTimeout(AuthorizationApi.refreshTokenTimeout);
      clearStoredAuthToken();
      return Promise.reject();
    }
  }

  async refreshToken(refreshToken: string): Promise<void> {
    BaseApi.isRefreshingToken = true;
    try {
      const refreshResponse: Response = await fetch(
        this.apiRefreshToken.replace(
          "{{refreshToken}}",
          encodeURIComponent(refreshToken)
        ),
        {
          method: "POST",
          headers: this.getApplicationJsonHeaders(),
        }
      );
      if (refreshResponse.status === 401) {
        const result = await refreshResponse.text();
        clearTimeout(AuthorizationApi.refreshTokenTimeout);
        clearStoredAuthToken();
        BaseApi.onSessionExpire(this.getErrorMessage(result));
        return Promise.reject("Session expired.");
      } else if (refreshResponse.ok) {
        const token: AuthToken = await refreshResponse.json();
        setStoredAuthToken(token);
        BaseApi.isRefreshingToken = false;
      } else {
        clearTimeout(AuthorizationApi.refreshTokenTimeout);
        clearStoredAuthToken();
        return Promise.reject(`Couldn't refresh the session.`);
      }
    } catch (ex) {
      clearTimeout(AuthorizationApi.refreshTokenTimeout);
      clearStoredAuthToken();
      BaseApi.isRefreshingToken = false;
      return Promise.reject("Error refreshing the session.");
    }
  }

  async setPassword(
    userEmail: string,
    code: string,
    password: string
  ): Promise<void> {
    const promise: Response = await fetch(this.apiSetPassword, {
      method: "POST",
      body: JSON.stringify({ userEmail, code, password }),
      headers: this.getApplicationJsonHeaders(),
    });
    if (!promise.ok) {
      return Promise.reject(`Couldn't set up your password.`);
    }
  }

  async verifyResetCodeValidation(email: string): Promise<Response> {
    const promise: Response = await fetch(
      this.apiVerifyResetCodeValidation + `?email=${email}`,
      {
        method: "GET",
        headers: this.getApplicationJsonHeaders(),
      }
    );
    if (!promise.ok) {
      return Promise.reject(`Couldn't verify your password authorization.`);
    }
    return promise;
  }

  async requestPasswordReset(userEmail: string, reset: boolean): Promise<void> {
    const promise: Response = await fetch(this.apiRequestPasswordReset, {
      method: "POST",
      body: JSON.stringify({ userEmail, set: !reset }),
      headers: this.getApplicationJsonHeaders(),
    });
    if (!promise.ok) {
      return Promise.reject(`Couldn't reset your password.`);
    }
  }

  async changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<void> {
    const promise: Response = await fetch(this.apiChangePassword, {
      method: "POST",
      body: JSON.stringify({ oldPassword, newPassword }),
      headers: this.getAuthApplicationJsonHeaders(),
    });
    if (!promise.ok) {
      return Promise.reject(`Couldn't change your password.`);
    }
  }
}
