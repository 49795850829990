import { TaskData } from "../translate/components/TasksList";
import AnalyzeTaskHeading from "./components/AnalyzeTaskHeading";
import DashboardCard from "./components/DashboardCard";
import { RingChart } from "../../_ui-kit/RingChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate } from "react-router-dom";
import { PageTransition } from "../../_ui-kit/PageTransition";
import { EtlAnalyzerReport } from "./AnalyzeResults";
import { TranslateTaskButtonModal } from "./components/TranslateTaskButtonModal";
import { Project } from "../../models/Project";
import { User } from "../../models/User";
import { Client } from "../../models/Client";

interface AnalyzeDashboardProps {
  authUser: User;
  currentClient: Client;
  currentProject: Project;
  currentTask: TaskData;
  etlAnalyzerReport: EtlAnalyzerReport;
}

function AnalyzeDashboard({
  authUser,
  currentClient,
  currentProject,
  currentTask,
  etlAnalyzerReport,
}: AnalyzeDashboardProps) {
  const navigate = useNavigate();

  function migrateClickHandler(): void {
    navigate(`/analyze/report-results/migrate/${currentTask.uuid}`);
  }

  function rationalizeClickHandler(): void {
    navigate(`/analyze/report-results/rationalize/${currentTask.uuid}`);
  }

  return (
    <PageTransition>
      <div id="content" className="max-w-3xl mx-auto py-8">
        <div className="flex items-center justify-between space-x-3 mb-6">
          <h2 className="text-lg font-medium">Analyze dashboard</h2>
          <TranslateTaskButtonModal
            authUser={authUser}
            currentClient={currentClient}
            currentProject={currentProject}
            currentTask={currentTask}
          />
        </div>
        <div className="flex flex-col space-y-6">
          <div className="shrink-0 bg-white rounded-lg shadow">
            <AnalyzeTaskHeading
              currentTask={currentTask}
              isSqlReport={currentTask.sourceType === "SQL"}
            />
          </div>
          <div className="grid grid-cols-3 gap-6">
            <DashboardCard
              heading="Analyze"
              description="Detailed summary of what we see in the uploaded ETL code in the platform."
              variant="success"
              onClick={migrateClickHandler}
            >
              <RingChart
                size={144}
                title="Automation chart"
                progress={
                  etlAnalyzerReport.dashboardData.etlSummary
                    .automationPercentage
                }
                variant="success"
              >
                <div className="absolute inset-0 flex flex-col items-center justify-center">
                  <span className="text-4xl text-gray-900 font-bold">
                    {
                      etlAnalyzerReport.dashboardData.etlSummary
                        .automationPercentage
                    }
                    %
                  </span>
                  <span className="uppercase text-gray-500 text-xs font-medium">
                    Automation
                  </span>
                </div>
              </RingChart>
            </DashboardCard>
            <DashboardCard
              heading="Rationalize"
              description="Insights about rationalization and modernization during migration."
              variant="info"
              onClick={rationalizeClickHandler}
            >
              <RingChart
                size={144}
                title="Simplification chart"
                progress={
                  etlAnalyzerReport.dashboardData.etlSummary
                    .simplificationPercentage
                }
                variant="info"
              >
                <div className="absolute inset-0 flex flex-col items-center justify-center">
                  <span className="-mt-2 uppercase text-gray-500 text-xs font-medium">
                    Up to
                  </span>
                  <span className="text-4xl text-gray-900 font-bold">
                    {
                      etlAnalyzerReport.dashboardData.etlSummary
                        .simplificationPercentage
                    }
                    %
                  </span>
                  <span className="uppercase text-gray-500 text-xs font-medium">
                    Simplification
                  </span>
                </div>
              </RingChart>
            </DashboardCard>
            <DashboardCard
              heading="Visualize"
              description="Explore the lineage of your preferred jobs and tables in your data."
              variant="warning"
              ctaString="Coming soon"
              icon={solid("tag")}
              disabled
            >
              <div className="flex flex-col items-center justify-center">
                <span className="flex p-6 mb-2 bg-warning-100 rounded-full">
                  <FontAwesomeIcon
                    icon={regular("diagram-successor")}
                    className="w-8 h-8 text-warning-600"
                  />
                </span>
                <span className="text-4xl text-gray-900 font-bold">
                  {etlAnalyzerReport.dashboardData.etlSummary.visualizeObjectsCount.toLocaleString()}
                </span>
                <span className="uppercase text-gray-500 text-xs font-medium">
                  Jobs and tables
                </span>
              </div>
            </DashboardCard>
          </div>
        </div>
      </div>
    </PageTransition>
  );
}

export default AnalyzeDashboard;
