import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox, Popover, Transition } from "@headlessui/react";
import {
  ChangeEvent,
  MouseEvent,
  Fragment,
  useContext,
  useState,
  useEffect,
  MutableRefObject,
} from "react";
import { useParams } from "react-router-dom";
import Checkbox from "../../_ui-kit/Checkbox/Checkbox";
import { MasterPage } from "../../_ui-kit/MasterPage";
import { PageTransition } from "../../_ui-kit/PageTransition";
import { RadioSwitch } from "../../_ui-kit/RadioSwitch";
import { VendorAsset } from "../../_ui-kit/VendorCard";
import SourceCodeView from "./components/SourceCodeView";
import { ProjectContext } from "../../contexts/ProjectContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import TasksApi from "../../api/TasksApi";
import { TaskData, TaskFile } from "./components/TasksList";
import { ClientContext } from "../../contexts/ClientContext";
import { AppError } from "../../App";
import { showNotification, Notification } from "../../_ui-kit/Notification";
import { UserContext } from "../../contexts/UserContext";
import CopyCodeToClipboardButton from "./components/CopyCodeToClipboardButton";

interface ViewTypeOption {
  id: string;
  name: string;
  icon: IconDefinition;
}

interface TranslationObjectError {
  fileIdx: number;
  lineNo: number;
  error: string;
}

interface CodeQueryParams {
  pageIndex: number;
  pageSize: number;
  searchFilter: string;
  searchError: string;
  projectId?: string;
  taskId?: string;
  fileId?: number;
  viewType?: ViewTypeOption;
  lineNumber?: number;
}

const viewTypeOptionIds = {
  Source: "Source",
  Target: "Target",
  SideBySide: "SideBySide",
  Combined: "Combined",
};

const defaultParams: CodeQueryParams = {
  pageIndex: 1,
  pageSize: 5,
  searchFilter: "",
  searchError: "",
};

const contextViewOptions: ViewTypeOption[] = [
  {
    id: viewTypeOptionIds.Source,
    name: "Source",
    icon: solid("border-top-left"),
  },
  {
    id: viewTypeOptionIds.Target,
    name: "Target",
    icon: solid("border-bottom-right"),
  },
  {
    id: viewTypeOptionIds.SideBySide,
    name: "Side by side",
    icon: solid("columns"),
  },
  {
    id: viewTypeOptionIds.Combined,
    name: "Combined",
    icon: solid("table-rows"),
  },
];

interface filterCheckBox {
  [key: string]: boolean;
}

interface errorDetail {
  pageNum: number;
  lineNum: number;
}

function TranslateCodeResults() {
  const tasksApi = new TasksApi();
  const queryClient = useQueryClient();
  const { authUser } = useContext(UserContext);
  const { currentClient } = useContext(ClientContext);
  const { currentProject } = useContext(ProjectContext);
  const { taskId } = useParams();
  const [selectedFile, setSelectedFile] = useState<TaskFile>();
  const [selectedFileError, setSelectedFileError] =
    useState<TranslationObjectError>();
  const [fileErrorsListQuery, setFileErrorsListQuery] = useState("");
  const [objCount, setObjCount] = useState(5);
  const [selectedContextView, setSelectedContextView] =
    useState<ViewTypeOption>(contextViewOptions[0]);
  const [translationErrors, setTranslationErrors] = useState<
    TranslationObjectError[]
  >([]);
  const [pageErrorsList, setPageErrorsList] = useState<AppError[]>([]);
  const [clipboard, setClipboard] = useState<string[]>([]);
  const filteredFileErrorsList =
    fileErrorsListQuery === ""
      ? translationErrors
      : translationErrors.filter((item) => {
          return (
            item.error
              .toLowerCase()
              .includes(fileErrorsListQuery.toLowerCase()) ||
            item.lineNo
              .toString()
              .toLowerCase()
              .includes(fileErrorsListQuery.toLowerCase())
          );
        });
  const [filterCheckBox, setFilterCheckBox] = useState<filterCheckBox>({
    auto: true,
    todo: true,
    review: true,
    exception: true,
  });
  const [errorEle, setErrorEle] = useState<errorDetail>({
    pageNum: -1,
    lineNum: -1,
  });

  const { data: translateTaskFile } = useQuery(
    [
      `translate-task-file${!currentProject ? "-invalid" : ""}`,
      { projectId: currentProject?.uuid, taskId },
    ],
    async () => {
      const projectId = currentProject?.uuid || "";
      const task = taskId || "";
      const result = await tasksApi.getTask(projectId, task);
      const files: TaskData = await result.json();
      return files;
    },
    {
      enabled: !!currentProject && !!taskId,
      onSuccess: () => {
        queryClient.removeQueries(["translate-task-file-invalid"]);
      },
    }
  );

  const { data: translationObjectErrors } = useQuery(
    [
      `translate-task-object-errors${!currentProject ? "-invalid" : ""}`,
      {
        projectId: currentProject?.uuid,
        taskId: taskId,
        fileId: selectedFile?.id,
      },
    ],
    async () => {
      const projectId = currentProject?.uuid || "";
      const task = taskId || "";
      const fid = selectedFile?.id || 0;
      const result = await tasksApi.getTranslatedFileErrors(
        projectId,
        task,
        fid
      );
      const errors: TranslationObjectError[] = await result.json();
      return errors;
    },
    {
      enabled: !!currentProject && !!taskId && !!selectedFile?.id,
      onSuccess: () => {
        queryClient.removeQueries(["translate-task-object-errors-invalid"]);
      },
    }
  );

  function errorsFilterChangeHandler(e: ChangeEvent<HTMLInputElement>) {
    setFileErrorsListQuery(e.target.value);
  }

  function fileErrorClickHandler(
    e: MouseEvent<HTMLButtonElement>,
    err: TranslationObjectError,
    close: (
      focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null>
    ) => void
  ) {
    const page = Math.ceil(err.fileIdx / defaultParams.pageSize);
    setErrorEle({
      pageNum: page,
      lineNum: err.lineNo,
    });
    setSelectedFileError(undefined);
    close();
  }

  function changeFilter(val: boolean, filter: string): void {
    let temp = { ...filterCheckBox };
    temp[filter] = val;
    setFilterCheckBox(temp);
    setSelectedFileError(undefined);
  }

  useEffect(() => {
    if (
      translateTaskFile &&
      translateTaskFile.fileProgressList &&
      translateTaskFile.fileProgressList.length > 0
    ) {
      setSelectedFile(translateTaskFile.fileProgressList[0]);
    }
  }, [translateTaskFile]);

  useEffect(() => {
    if (translationObjectErrors) {
      setTranslationErrors(translationObjectErrors);
    }
  }, [translationObjectErrors]);

  return (
    <MasterPage heading="Translated code" useBackButton>
      <PageTransition>
        <div id="content" className="h-full">
          <div className="flex h-full">
            <div className="flex flex-col w-full min-h-full h-fit bg-white overflow-hidden">
              <div className="flex items-center border-b bg-gray-50 border-gray-200 px-5 py-2.5 space-x-3">
                <div className="mr-auto">
                  <RadioSwitch
                    label="Code viewer context"
                    options={contextViewOptions}
                    selectedOption={selectedContextView}
                    onChange={setSelectedContextView}
                  />
                </div>
                {selectedContextView.name === "Combined" && (
                  <div className="flex items-center space-x-6 text-sm">
                    <span className="font-medium">Focus on:</span>
                    <Checkbox
                      id="autoCheck"
                      label="Changes (Auto)"
                      defaultChecked={filterCheckBox.auto}
                      onChange={(e) => changeFilter(e.target.checked, "auto")}
                    />
                    <Checkbox
                      id="reviewsCheck"
                      label="Reviews"
                      defaultChecked={filterCheckBox.review}
                      onChange={(e) => changeFilter(e.target.checked, "review")}
                    />
                    <Checkbox
                      id="exceptionCheck"
                      label="Exceptions"
                      defaultChecked={filterCheckBox.exception}
                      onChange={(e) =>
                        changeFilter(e.target.checked, "exception")
                      }
                    />
                  </div>
                )}
              </div>
              <div className="flex items-center shrink-0 bg-gray-50 px-5 py-3 text-sm border-b-2 border-b-gray-200">
                <div className="pr-4 mr-4 border-r">
                  {selectedContextView.name === "Source" && (
                    <VendorAsset
                      platform={
                        translateTaskFile
                          ? translateTaskFile.sourcePlatformName
                          : ""
                      }
                      type="icon"
                    />
                  )}
                  {selectedContextView.name === "Target" && (
                    <VendorAsset
                      platform={
                        translateTaskFile
                          ? translateTaskFile.targetPlatformName
                          : ""
                      }
                      type="icon"
                    />
                  )}
                  {(selectedContextView.name === "Side by side" ||
                    selectedContextView.name === "Combined") && (
                    <div className="flex items-center space-x-2">
                      <VendorAsset
                        platform={
                          translateTaskFile
                            ? translateTaskFile.sourcePlatformName
                            : ""
                        }
                        type="icon"
                      />
                      <FontAwesomeIcon
                        icon={solid("arrow-right")}
                        size="sm"
                        className="text-gray-400"
                      />
                      <VendorAsset
                        platform={
                          translateTaskFile
                            ? translateTaskFile.targetPlatformName
                            : ""
                        }
                        type="icon"
                      />
                    </div>
                  )}
                </div>
                <span className="flex items-center space-x-2.5 mr-4 font-medium">
                  <span className="bg-gray-200 rounded px-2 py-1 uppercase text-xs font-medium text-gray-600">
                    {translateTaskFile?.sourceType}
                  </span>
                  <span>{translateTaskFile?.projectPlatformName}</span>
                </span>
                {selectedFile && (
                  <Listbox value={selectedFile} onChange={setSelectedFile}>
                    <div className="relative">
                      <Listbox.Button className="relative inline-flex items-center w-full pl-3 pr-7 py-1 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-100 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                        <span className="flex items-center w-full space-x-2">
                          <FontAwesomeIcon
                            icon={regular("file")}
                            className="h-4 text-primary-600"
                          />
                          <span className="truncate text-gray-900 font-medium">
                            {selectedFile?.name}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex flex-col justify-center items-center pr-2.5 text-gray-500">
                          <FontAwesomeIcon
                            icon={solid("sort-up")}
                            className="w-2 h-2"
                            aria-hidden={true}
                          />
                          <FontAwesomeIcon
                            icon={solid("sort-down")}
                            className="w-2 h-2 -mt-1.5"
                            aria-hidden={true}
                          />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Listbox.Options className="origin-top-right absolute left-0 mt-2 py-1 max-w-sm max-h-52 overflow-auto flex flex-col rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-40 focus:outline-none">
                          <Listbox.Label className="px-4 pt-2 pb-2.5 mb-1 border-b border-gray-200 text-gray-500 font-medium uppercase">
                            Library files (
                            {translateTaskFile?.fileProgressList
                              ? translateTaskFile.fileProgressList.length
                              : 0}
                            )
                          </Listbox.Label>
                          {translateTaskFile?.fileProgressList &&
                            translateTaskFile?.fileProgressList.map(
                              (platform) => (
                                <Listbox.Option
                                  key={platform.id}
                                  className={({ active, selected }) =>
                                    `relative cursor-default select-none py-2 pl-4 pr-12 ${
                                      selected && !active
                                        ? "bg-primary-100 text-primary-700"
                                        : active
                                        ? "bg-primary-50 text-primary-700"
                                        : "bg-white text-gray-900"
                                    }`
                                  }
                                  value={platform}
                                >
                                  {({ active, selected }) => (
                                    <>
                                      <span className="flex items-center space-x-3">
                                        <FontAwesomeIcon
                                          icon={regular("file")}
                                          className={` ${
                                            active || selected
                                              ? "text-primary-500"
                                              : "text-gray-400"
                                          }`}
                                          size="lg"
                                        />
                                        <span className="truncate">
                                          {platform.name}
                                        </span>
                                      </span>
                                      {selected ? (
                                        <span
                                          className={`absolute inset-y-0 right-0 flex items-center pr-4 text-base text-primary-600`}
                                        >
                                          <span className="sr-only">
                                            Selected
                                          </span>
                                          <FontAwesomeIcon
                                            icon={solid("check")}
                                            aria-hidden={true}
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              )
                            )}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                )}
                <span className="ml-4 mr-auto border-gray-300 text-gray-500">
                  {objCount.toLocaleString()}
                  <span className="mx-1">/</span>
                  {selectedFile?.objectCount.toLocaleString()} objects
                </span>
                {selectedContextView.name === "Combined" &&
                  translationErrors &&
                  translationErrors.length > 0 && (
                    <Popover>
                      <div className="relative pr-4 mr-4 border-r border-gray-200">
                        <Popover.Button className="relative inline-flex items-center border font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 select-none bg-danger-700 hover:bg-danger-600 text-white shadow-sm focus:ring-danger-500 border-transparent pl-2.5 pr-6 py-1.5 text-xs rounded">
                          <span className="flex items-center w-full space-x-3">
                            <span className="flex items-center mx-auto">
                              <FontAwesomeIcon
                                icon={solid("times-circle")}
                                className="mr-2"
                              />
                              <span>
                                Translation errors (
                                {translationErrors.length.toLocaleString()})
                              </span>
                            </span>
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex flex-col justify-center items-center pr-2.5">
                            <FontAwesomeIcon
                              icon={solid("sort-up")}
                              className="w-2 h-2"
                              aria-hidden={true}
                            />
                            <FontAwesomeIcon
                              icon={solid("sort-down")}
                              className="w-2 h-2 -mt-1"
                              aria-hidden={true}
                            />
                          </span>
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Popover.Panel className="origin-top-right absolute right-0 mt-2 py-1 w-96 flex flex-col rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-40 focus:outline-none">
                            {({ close }) => (
                              <>
                                <div className="px-4 mt-3 pb-4 border-b font-sans">
                                  <label
                                    htmlFor="errorSearch"
                                    className="sr-only"
                                  >
                                    Error search
                                  </label>
                                  <input
                                    type="text"
                                    id="errorSearch"
                                    className="block w-full rounded-md border-gray-300 px-4 focus:border-primary-500 focus:ring-primary-500 text-sm"
                                    placeholder="Search errors"
                                    value={fileErrorsListQuery}
                                    onChange={errorsFilterChangeHandler}
                                  />
                                </div>
                                <div className="max-h-96 flex flex-col overflow-auto">
                                  {filteredFileErrorsList.map(
                                    (error, index) => {
                                      const selected =
                                        selectedFileError?.error ===
                                        error.error;
                                      return (
                                        <button
                                          key={`${error.lineNo}_${index}`}
                                          className={`group relative select-none py-2 pl-4 pr-12 text-left ${
                                            selected
                                              ? "bg-danger-700 text-white"
                                              : "bg-white text-gray-900 hover:bg-danger-50 hover:text-danger-700"
                                          }`}
                                          onClick={(e) =>
                                            fileErrorClickHandler(
                                              e,
                                              error,
                                              close
                                            )
                                          }
                                        >
                                          <div className="flex flex-col space-y-1 pb-1">
                                            <div className="font-sans font-medium">
                                              Line{" "}
                                              {error.lineNo.toLocaleString()}
                                            </div>
                                            <div
                                              className={`text-xs ${
                                                selected
                                                  ? "text-danger-200"
                                                  : "text-gray-500 group-hover:text-danger-600"
                                              }`}
                                            >
                                              {error.error}
                                            </div>
                                          </div>
                                          {selected ? (
                                            <span
                                              className={`absolute inset-y-0 right-0 flex items-center pr-4 text-base text-white`}
                                            >
                                              <span className="sr-only">
                                                Selected
                                              </span>
                                              <FontAwesomeIcon
                                                icon={solid("check")}
                                                aria-hidden={true}
                                              />
                                            </span>
                                          ) : null}
                                        </button>
                                      );
                                    }
                                  )}
                                  {filteredFileErrorsList.length < 1 && (
                                    <div className="pt-4 px-4 mb-4">
                                      Nothing found.
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </Popover.Panel>
                        </Transition>
                      </div>
                    </Popover>
                  )}
                {(authUser?.isAdmin() ||
                  currentClient?.clientRestriction?.allowDownload) &&
                  (selectedContextView.name === "Source" ||
                    selectedContextView.name === "Target") && (
                    <CopyCodeToClipboardButton clipboard={clipboard} />
                  )}
                {!authUser?.isAdmin() &&
                  !currentClient?.clientRestriction?.allowDownload && (
                    <div className="flex p-2.5 bg-white text-gray-500 rounded-full shadow-sm ring-1 ring-black ring-opacity-5">
                      <FontAwesomeIcon
                        icon={solid("lock")}
                        className="w-3.5 h-3.5"
                      />
                    </div>
                  )}
              </div>
              {selectedFile && currentProject && translateTaskFile && (
                <SourceCodeView
                  currentProject={currentProject}
                  currenTask={translateTaskFile}
                  objCount={objCount}
                  setObjCount={setObjCount}
                  fileObjCount={selectedFile.objectCount}
                  selectedFile={selectedFile}
                  selectedViewType={selectedContextView}
                  projectId={currentProject.uuid}
                  filterCheckBox={filterCheckBox}
                  errorEle={errorEle}
                  setErrorEle={setErrorEle}
                  defaultParams={defaultParams}
                  setPageErrorsList={setPageErrorsList}
                  clipboard={clipboard}
                  setClipboard={setClipboard}
                />
              )}
            </div>
          </div>
          {pageErrorsList.length > 0 &&
            pageErrorsList.map((error, index, list) => {
              return showNotification(
                <Notification
                  key={`error.${error.id}`}
                  variant="danger"
                  title={error.error}
                  subTitle={error.description}
                  onClose={() =>
                    setPageErrorsList(
                      list.filter((_listItem, newIndex) => newIndex !== index)
                    )
                  }
                />
              );
            })}
        </div>
      </PageTransition>
    </MasterPage>
  );
}

export type { ViewTypeOption, CodeQueryParams };
export { viewTypeOptionIds };
export default TranslateCodeResults;
