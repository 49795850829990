import { AuthToken } from "../models/User";

const USER_LOCALSTORAGE_KEY = "npstudio_auth_token";

export function getStoredAuthToken(): AuthToken | null {
  const storedAuthToken = localStorage.getItem(USER_LOCALSTORAGE_KEY);
  return storedAuthToken && storedAuthToken !== "undefined"
    ? JSON.parse(storedAuthToken)
    : null;
}

export function setStoredAuthToken(authToken: AuthToken): void {
  localStorage.setItem(
    USER_LOCALSTORAGE_KEY,
    JSON.stringify({
      token: authToken.token,
      refreshToken: authToken.refreshToken,
      refreshTokenExpiry: authToken.refreshTokenExpiry,
    })
  );
}

export function clearStoredAuthToken(): void {
  localStorage.removeItem(USER_LOCALSTORAGE_KEY);
}


