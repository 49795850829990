import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthorizationApi from "../../api/AuthorizationApi";
import { Button } from "../../_ui-kit";
import { encode } from "base-64";
import Alert from "../../_ui-kit/Alert/Alert";
import { AuthRouteLocationState } from "../../routes/components/AuthorizationRoute";
import { MasterPage } from "../../_ui-kit/MasterPage";
import { UserContext } from "../../contexts/UserContext";
import RuledPasswordInput from "./components/RuledPasswordInput";
import InputGroup, {
  InputGroupErrorDescription,
} from "../../_ui-kit/InputGroup/InputGroup";
import Input from "../../_ui-kit/Input/Input";
import { passwordBlurHandler } from "./SignIn";

interface SingInProps {
  authApi: AuthorizationApi;
}

export default function UserChangePassword({ authApi }: SingInProps) {
  const navigate = useNavigate();
  const locationState = useLocation().state as AuthRouteLocationState;
  const { signOut } = useContext(UserContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [info, setInfo] = useState("");
  const [passwordRequiredError, setPasswordRequiredError] = useState(false);
  const [newPasswordHasErrors, setNewPasswordHasErrors] = useState(false);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");
    setInfo("");

    const formData = new FormData(e.currentTarget);
    const currentPassword = formData.get("auth-current-password") as string;
    const newPassword = formData.get("auth-new-password") as string;

    const changePassword = async () => {
      try {
        await authApi.changePassword(
          encode(currentPassword),
          encode(newPassword)
        );
        signOut();
        navigate("/sign-in", {
          state: {
            from: { info: "You've changed your password successfully." },
          },
        });
      } catch (ex: any) {
        setIsSubmitting(false);
        setError(ex);
      }
    };

    changePassword();
  }

  useEffect(() => {
    setError(locationState?.from?.error || "");
    setInfo(locationState?.from?.info || "");
    window.history.replaceState(null, "");
  }, [locationState]);

  return (
    <MasterPage type="signin" heading="Change Password">
      {error && (
        <Alert variant="danger" className="mb-8" show={!!error} dismissible>
          <p className="text-sm font-medium">{error}</p>
        </Alert>
      )}
      {info && (
        <Alert className="mb-8" show={!!info} dismissible>
          <p className="text-sm font-medium">{info}</p>
        </Alert>
      )}
      <h3 className="mb-1 text-4xl font-bold text-gray-900">
        Change your password
      </h3>
      <p className="mb-6 text-sm text-gray-600">
        You can set up your new password below.
      </p>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col space-y-8 text-left"
      >
        <div className="flex flex-col space-y-5">
          <InputGroup label="Current password" inputId="signin-password">
            <Input
              id="auth-current-password"
              name="auth-current-password"
              size="lg"
              autoComplete="current-password"
              isInvalid={passwordRequiredError}
              onBlur={(e) => passwordBlurHandler(e, setPasswordRequiredError)}
              ariaDescribedby={
                passwordRequiredError
                  ? "signin-password-required-error"
                  : undefined
              }
              isPasswordInput
              required
            />
            {passwordRequiredError && (
              <InputGroupErrorDescription
                id="signin-password-required-error"
                text="Your current password is required."
                showIcon={false}
              />
            )}
          </InputGroup>
          <RuledPasswordInput
            id="auth-new-password"
            name="auth-new-password"
            label="New password"
            passwordHasErrors={newPasswordHasErrors}
            setPasswordHasErrors={setNewPasswordHasErrors}
          />
        </div>
        <div className="flex flex-col space-y-2">
          <Button
            label="Set password"
            size="lg"
            type="submit"
            isLoading={isSubmitting}
          />
          <Button
            label="Cancel"
            size="lg"
            variant="gray"
            isLoading={isSubmitting}
            onClick={() => navigate(-1)}
            soft
            underline
          />
        </div>
      </form>
    </MasterPage>
  );
}
