import { ReactNode } from "react";
import { Route } from "react-router-dom";
import RestrictedRoute from "./components/RestrictedRoute";
import Support from "../pages/support/Support";

function SupportRoutes(): ReactNode {
  return (
    <>
      <Route
        path="support"
        element={
          <RestrictedRoute>
            <Support />
          </RestrictedRoute>
        }
      />
    </>
  );
}

export default SupportRoutes;
