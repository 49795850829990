import {
  Navigate,
  NavigateFunction,
  Location,
  useLocation,
} from "react-router-dom";

interface AuthRouteLocationState {
  from: { location?: { pathname: string }; error?: string; info?: string };
}

function authenticateFromOrigin(
  navigate: NavigateFunction,
  location: Location,
  error?: string
) {
  navigate("/sign-in", {
    state: { from: { location, error } },
    replace: true,
  });
}

function Authorization() {
  const location = useLocation();
  return <Navigate to="/sign-in" state={{ from: location }} replace />;
}

export type { AuthRouteLocationState };
export { Authorization, authenticateFromOrigin };
