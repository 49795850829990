import { HTMLAttributes, RefObject, useEffect, useState } from "react";
import { classNames } from "../../../_ui-kit/utils";
import PasswordRuleItem from "./PasswordRuleItem";

interface PasswordRulesProps {
  inputRef: RefObject<HTMLInputElement>;
  id?: HTMLAttributes<HTMLDivElement>["id"];
  className?: HTMLAttributes<HTMLDivElement>["className"];
  setHastErrors?: (param: boolean) => void;
}

interface PasswordValidation {
  uppercase: boolean;
  lowercase: boolean;
  specialChar: boolean;
  number: boolean;
  eightChar: boolean;
}

interface PasswordRule {
  valid: boolean;
  label: string;
}

const defaultPasswordErrors = {
  eightChar: false,
  lowercase: false,
  uppercase: false,
  specialChar: false,
  number: false,
};

function PasswordRules({
  inputRef,
  id,
  className,
  setHastErrors,
}: PasswordRulesProps) {
  const [rules, setRules] = useState<PasswordRule[]>([]);
  const [validate, setValidate] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState<PasswordValidation>(
    defaultPasswordErrors
  );

  function validatePasswordKeyupHandler(this: HTMLInputElement): void {
    const password = this.value;
    if (password) {
      const eightCharsOrMore = /.{8,}/g;
      const atLeastOneLowercase = /[a-z]/g;
      const atLeastOneUppercase = /[A-Z]/g;
      const atLeastOneSpecialCharacter =
        /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g;
      const atLeastOneNumeric = /[0-9]/g;
      const errors = {
        eightChar: eightCharsOrMore.test(password),
        lowercase: atLeastOneLowercase.test(password),
        uppercase: atLeastOneUppercase.test(password),
        specialChar: atLeastOneSpecialCharacter.test(password),
        number: atLeastOneNumeric.test(password),
      };
      setPasswordErrors(errors);
    } else {
      setPasswordErrors(defaultPasswordErrors);
    }
  }

  function validatePasswordBlurHandler(this: HTMLInputElement): void {
    setValidate(true);
  }

  useEffect(() => {
    const input = inputRef.current;

    input?.addEventListener("keyup", validatePasswordKeyupHandler);
    input?.addEventListener("blur", validatePasswordBlurHandler);

    return () => {
      input?.removeEventListener("keyup", validatePasswordKeyupHandler);
      input?.addEventListener("blur", validatePasswordBlurHandler);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef]);

  useEffect(() => {
    setRules(
      Object.entries(passwordErrors).map(([key, value]) => {
        return { valid: value, label: key };
      })
    );
  }, [passwordErrors]);

  useEffect(() => {
    if (validate)
      setHastErrors?.(!Object.values(passwordErrors).every((error) => error));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validate, rules]);

  return (
    <div id={id} className={className}>
      <span className="sr-only">Password rules</span>
      <ul
        className={classNames(
          `list-none m-0 p-0 space-y-3`,
          className ? className : ""
        )}
      >
        {rules.map((rule) => {
          return (
            <PasswordRuleItem
              key={rule.label}
              valid={rule.valid}
              label={rule.label}
              validate={validate}
            />
          );
        })}
      </ul>
    </div>
  );
}

export default PasswordRules;
