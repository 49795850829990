import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatBytes } from "../../../utils/common";
import { UploadListItem } from "../DataUpload";
import UploadItemTypeSelect from "./UploadItemTypeSelect";

interface UploadItemSummaryListProps {
  filesToUpload: UploadListItem[];
  setFilesToUpload: (param: UploadListItem[]) => void;
}

function UploadItemSummaryList({
  filesToUpload,
  setFilesToUpload,
}: UploadItemSummaryListProps) {
  return (
    <ul className="relative border border-gray-200 rounded-md divide-y">
      {filesToUpload.map((fileItem, index) => {
        const hasError = fileItem.type === "error";
        return (
          <li
            key={`${fileItem.file.name}_${index}`}
            className={`flex items-center space-x-4 px-5 py-3 first:rounded-t-md last:rounded-b-md border-gray-00 ${
              !hasError ? "hover:bg-gray-100" : ""
            }`}
          >
            <FontAwesomeIcon
              icon={hasError ? regular("file-xmark") : regular("file")}
              size="2x"
              className={`${hasError ? "text-danger-600" : "text-gray-400"}`}
            />
            <div className="flex flex-col space-y-1 !mr-auto overflow-hidden">
              <span
                className={`text-sm truncate ${
                  hasError ? "text-danger-700" : "text-gray-700"
                } leading-4 font-medium`}
              >
                {fileItem.file.name}
              </span>
              <span
                className={`text-xs ${
                  hasError ? "text-danger-700" : "text-gray-500"
                }`}
              >
                {formatBytes(fileItem.file.size)}
              </span>
            </div>
            {!hasError && (
              <UploadItemTypeSelect
                index={index}
                filesToUpload={filesToUpload}
                setFilesToUpload={setFilesToUpload}
              />
            )}
            {hasError && (
              <div className="flex items-center space-x-2 text-sm">
                <FontAwesomeIcon
                  icon={solid("xmark-circle")}
                  className="text-danger-600"
                />
                <span className="text-xs text-danger-700 whitespace-nowrap">
                  {fileItem.error}
                </span>
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
}

export default UploadItemSummaryList;
