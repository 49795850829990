import { useContext } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Keys } from "../../_ui-kit/Keys";
import { Tabs, TabList, TabItem } from "../../_ui-kit/Tabs";
import { MasterPage } from "../../_ui-kit/MasterPage";
import { useEffect, useState } from "react";
import { ConfigureContextProvider } from "../../contexts/ConfigureContext";
import { UserContext } from "../../contexts/UserContext";
import { isCrawlerCore } from "../../env";
import { UserRole } from "../../models/User";
import { SystemCore } from "../../App";

interface NavItem {
  id: number;
  name: string;
  path: string;
  roles: UserRole[];
  cores: SystemCore[];
  tabIndex?: number;
}

const dateFormat = new Intl.DateTimeFormat("en-CA", {
  year: "numeric",
  month: "long",
  day: "numeric",
});

const navigation: NavItem[] = [
  {
    id: 0,
    name: "Users administration",
    path: "users-administration",
    roles: ["OWNER", "ADMIN"],
    cores: ["CRAWLER", "SHIFT"],
  },
  {
    id: 1,
    name: "Client management",
    path: "client-management",
    roles: ["ADMIN"],
    cores: ["CRAWLER", "SHIFT"],
  },
  {
    id: 2,
    name: "Pricing plans",
    path: "pricing-plans",
    roles: ["ADMIN", "BILLING"],
    cores: ["SHIFT"],
  },
  {
    id: 3,
    name: "Projects setup",
    path: "projects-setup",
    roles: ["OWNER", "ADMIN"],
    cores: ["CRAWLER", "SHIFT"],
  },
  {
    id: 4,
    name: "System settings",
    path: "system-settings",
    roles: ["ADMIN"],
    cores: ["SHIFT"],
  },
  {
    id: 5,
    name: "Data upload",
    path: "data-upload",
    roles: ["OWNER", "ADMIN"],
    cores: ["CRAWLER"],
  },
];

export const getFormattedDate = (date: string): string => {
  return dateFormat.format(new Date(date));
};

function Configure() {
  const navigate = useNavigate();
  const location = useLocation();
  const defaultTabIndex = 0;
  const { authUser } = useContext(UserContext);
  const [navItems, setNavItems] = useState<NavItem[]>([]);
  const [currentTab, setCurrentTab] = useState(defaultTabIndex);

  useEffect(() => {
    if (!authUser) return;

    setNavItems(
      navigation.filter((navItem) => {
        if (authUser && navItem.roles.indexOf(authUser.role) === -1)
          return false;
        if (navItem.cores.indexOf(isCrawlerCore() ? "CRAWLER" : "SHIFT") === -1)
          return false;
        return true;
      })
    );
  }, [authUser]);

  useEffect(() => {
    if (navItems.length > 0) {
      const current = navItems.find((nav) => {
        return location.pathname.includes(nav.path);
      });

      if (current) setCurrentTab(current.tabIndex || defaultTabIndex);
      else navigate(navItems[defaultTabIndex].path);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, navItems]);

  return (
    <ConfigureContextProvider>
      <MasterPage heading="Configure">
        <div className="flex flex-col h-full">
          <div className="shrink-0 pt-4">
            <Tabs selectedIndex={currentTab} manual>
              <TabList className="2xl:justify-center">
                {navItems.map((navItem, index) => {
                  navItem.tabIndex = index;
                  return (
                    <TabItem
                      key={navItem.id}
                      onClick={() => navigate(navItem.path)}
                      onKeyUp={(e: React.KeyboardEvent<HTMLElement>) => {
                        if (e.key === Keys.Enter) navigate(navItem.path);
                      }}
                      label={navItem.name}
                    />
                  );
                })}
              </TabList>
            </Tabs>
          </div>
          <div className="grow">
            <Outlet />
          </div>
        </div>
      </MasterPage>
    </ConfigureContextProvider>
  );
}

export default Configure;
