import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RadioGroup } from "@headlessui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TasksApi from "../../api/TasksApi";
import { ClientContext } from "../../contexts/ClientContext";
import { ProjectContext } from "../../contexts/ProjectContext";
import { UserContext } from "../../contexts/UserContext";
import { Button } from "../../_ui-kit";
import { PagePrintFormat } from "../../_ui-kit/PagePrintFormat";
import { PageTransition } from "../../_ui-kit/PageTransition";
import ReportHeader from "../translate/components/ReportHeader";
import StatementPanel from "../translate/components/StatementPanel";
import { TaskData, TaskErrors } from "../translate/components/TasksList";
import AnalysisReportExceptionsSection from "./components/AnalysisReportExceptionsSection";
import AnalysisReportStatementRow from "./components/AnalysisReportStatementRow";
import { AppError } from "../../App";
import { Notification, showNotification } from "../../_ui-kit/Notification";
import { EtlAnalyzerReport, SqlAnalyzerReport } from "./AnalyzeResults";
import { TranslateTaskButtonModal } from "./components/TranslateTaskButtonModal";

interface AnalysisReportResultsProps {
  currentTask: TaskData;
  sqlAnalyzerReport?: SqlAnalyzerReport;
  etlAnalyzerReport?: EtlAnalyzerReport;
}

interface ReportStat {
  id:
    | "lines"
    | "objects"
    | "sourceSinkList"
    | "controlFlowSteps"
    | "dataFlowSteps";
  title: string;
  subTitle: string;
}

function AnalysisReportResults({
  currentTask,
  sqlAnalyzerReport,
  etlAnalyzerReport,
}: AnalysisReportResultsProps) {
  const tasksApi = new TasksApi();
  const queryClient = useQueryClient();
  const location = useLocation();
  const isAnalyze = location.pathname.includes("/analyze");
  const { authUser } = useContext(UserContext);
  const { currentClient } = useContext(ClientContext);
  const { currentProject } = useContext(ProjectContext);
  const [reportStats, setReportStats] = useState<ReportStat[]>([]);
  const [currentReportStat, setCurrentReportStat] = useState<ReportStat>();
  const [isSqlReport, setIsSqlReport] = useState<boolean>();
  const [pageErrorsList, setPageErrorsList] = useState<AppError[]>([]);

  const { data: taskErrors } = useQuery(
    [
      `task-errors${!currentProject || !currentTask ? "-invalid" : ""}`,
      {
        projectId: currentProject?.uuid,
        taskId: currentTask?.uuid,
      },
    ],
    async () => {
      const projectId = currentProject?.uuid || "";
      const taskId = currentTask?.uuid || "";
      const response = await tasksApi.getTaskErrorDetails(projectId, taskId);
      const errors: TaskErrors[] = await response.json();
      return errors;
    },
    {
      enabled: !!currentProject && !!currentTask && isSqlReport === true,
      onSuccess: () => {
        queryClient.removeQueries(["translate-confirmation-invalid"]);
      },
      onError: (ex) => {
        console.log(ex);
      },
    }
  );

  function printBtnClickHandler(): void {
    window.print();
  }

  useEffect(() => {
    if (sqlAnalyzerReport) {
      setReportStats([
        {
          id: "lines",
          title: sqlAnalyzerReport.stats.lines.toLocaleString(),
          subTitle: "Lines of code",
        },
        {
          id: "objects",
          title: sqlAnalyzerReport.stats.objects.toLocaleString(),
          subTitle: "Number of statements",
        },
      ]);
    }
  }, [sqlAnalyzerReport]);

  useEffect(() => {
    if (etlAnalyzerReport) {
      setReportStats([
        {
          id: "controlFlowSteps",
          title: etlAnalyzerReport.stats.controlFlows.toLocaleString(),
          subTitle: "Control flows",
        },
        {
          id: "dataFlowSteps",
          title: etlAnalyzerReport.stats.dataFlows.toLocaleString(),
          subTitle: "Data flows",
        },
        {
          id: "sourceSinkList",
          title: etlAnalyzerReport.sourceSinkList.length.toLocaleString(),
          subTitle: "Sources / Sinks",
        },
      ]);
    }
  }, [etlAnalyzerReport]);

  useEffect(() => {
    if (reportStats.length > 0) setCurrentReportStat(reportStats[0]);
  }, [reportStats]);

  useEffect(() => {
    if (currentTask) {
      setIsSqlReport(currentTask.sourceType === "SQL");
    }
  }, [currentTask]);

  return (
    <>
      {currentTask &&
        currentReportStat &&
        (sqlAnalyzerReport || etlAnalyzerReport) && (
          <>
            <PagePrintFormat blockClassName="mx-6">
              <PageTransition>
                <div
                  id="content"
                  className="px-6 py-10 xl:max-w-5xl xl:mx-auto print:max-w-none print:mx-0"
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-5 print:w-full">
                      <hgroup className="flex flex-col print:w-full print:flex-row print:items-center print:justify-between">
                        <h2 className="flex items-center space-x-3 text-lg font-medium text-gray-900">
                          <span>Analyzer report</span>
                          <span className="hidden bg-gray-100 rounded px-2 py-1 uppercase text-xs font-medium text-gray-600 print:block">
                            {currentTask.sourceType}
                          </span>
                        </h2>
                        <h3 className="text-sm text-gray-600 print:text-base print:font-medium print:text-gray-900">{`Reference No. ${
                          sqlAnalyzerReport
                            ? sqlAnalyzerReport.reference
                            : etlAnalyzerReport?.reference
                        }`}</h3>
                      </hgroup>
                      <span className="self-center bg-gray-200 rounded px-2 py-1 uppercase text-xs font-medium text-gray-600 print:hidden">
                        {currentTask.sourceType}
                      </span>
                      <div className="print:hidden">
                        <Button
                          label="Print"
                          icon={solid("print")}
                          variant="white"
                          onClick={printBtnClickHandler}
                        />
                      </div>
                    </div>
                    {authUser && currentClient && currentProject && (
                      <div className="print:hidden">
                        <TranslateTaskButtonModal
                          authUser={authUser}
                          currentClient={currentClient}
                          currentProject={currentProject}
                          currentTask={currentTask}
                        />
                      </div>
                    )}
                  </div>
                  <div className="mt-6 flex flex-col bg-white shadow ring-1 ring-black ring-opacity-5 rounded-lg overflow-hidden print:p-0 print:rounded-none print:ring-0 print:shadow-none">
                    <div className="w-full max-w-2xl my-14 mx-auto print:max-w-none print:my-0">
                      <ReportHeader
                        isTranslate={!isAnalyze}
                        task={currentTask}
                        taskErrors={taskErrors}
                        reportProcessingTime={
                          isSqlReport
                            ? sqlAnalyzerReport?.processingTime
                            : etlAnalyzerReport?.time
                        }
                        reportDateCreated={
                          isSqlReport
                            ? sqlAnalyzerReport?.dateCreated
                            : etlAnalyzerReport?.dateCreated
                        }
                      />
                      {(sqlAnalyzerReport || etlAnalyzerReport) && (
                        <>
                          <RadioGroup
                            value={currentReportStat}
                            onChange={setCurrentReportStat}
                            className="mb-6"
                          >
                            <RadioGroup.Label
                              as="h3"
                              className="mb-4 font-medium"
                            >
                              Source statements breakdown
                            </RadioGroup.Label>
                            <div
                              className={`grid ${
                                isSqlReport
                                  ? "grid-cols-2 gap-x-12"
                                  : "grid-cols-3 gap-x-6"
                              }`}
                            >
                              {reportStats.map((stat) => {
                                return (
                                  <RadioGroup.Option
                                    as="button"
                                    key={stat.id}
                                    value={stat}
                                    className={({ active, checked }) =>
                                      `${
                                        active
                                          ? "ring-2 ring-primary-500 ring-offset-2 print:ring-0"
                                          : ""
                                      }
                                    ${
                                      checked
                                        ? "bg-white border-primary-600 bg-opacity-75 text-primary-700 print:bg-gray-100 print:border-gray-200 print:bg-opacity-100"
                                        : "bg-gray-100"
                                    }
                                    relative w-full ${
                                      isSqlReport ? "h-36" : "h-32"
                                    } px-6 rounded-lg border-2 border-gray-200 cursor-pointer focus:outline-none print:text-gray-700`
                                    }
                                  >
                                    {({ checked }) => (
                                      <>
                                        <span
                                          className={`block ${
                                            isSqlReport
                                              ? "text-5xl mb-2"
                                              : "text-4xl mb-1"
                                          } font-black`}
                                        >
                                          {stat.title}
                                        </span>
                                        <span className="block text-gray-500 leading-none">
                                          {stat.subTitle}
                                        </span>
                                        {checked && (
                                          <span className="absolute -top-4 -right-3 flex p-0.5 bg-white rounded-full text-primary-600 print:hidden">
                                            <FontAwesomeIcon
                                              icon={solid("check-circle")}
                                              size="xl"
                                            />
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </RadioGroup.Option>
                                );
                              })}
                            </div>
                          </RadioGroup>
                          <div className="space-y-4">
                            {reportStats.map((stat) => {
                              const statementSection =
                                stat.id === "sourceSinkList"
                                  ? "sourceSinkList"
                                  : stat.id === "dataFlowSteps"
                                  ? "dataFlowSteps"
                                  : "controlFlowSteps";
                              const counts =
                                stat.id === "controlFlowSteps"
                                  ? etlAnalyzerReport?.stats
                                      .controlFlowActivities
                                  : stat.id === "dataFlowSteps"
                                  ? etlAnalyzerReport?.stats.dataFlowSteps
                                  : (etlAnalyzerReport?.stats.sources || 0) +
                                    (etlAnalyzerReport?.stats.sinks || 0);

                              if (
                                (etlAnalyzerReport && !counts) ||
                                (sqlAnalyzerReport &&
                                  !sqlAnalyzerReport.statements.length)
                              )
                                return null;

                              return (
                                <div
                                  key={`statements-${stat.id}`}
                                  className={`${
                                    currentReportStat.id === stat.id
                                      ? "block"
                                      : "hidden print:block"
                                  }`}
                                >
                                  <StatementPanel
                                    title={
                                      <span className="inline-flex items-center font-medium text-primary-700">
                                        <>
                                          {stat.id === "lines" &&
                                            "Statements by lines of code"}
                                          {stat.id === "objects" &&
                                            "Statements count details"}
                                          {stat.id === "controlFlowSteps" &&
                                            "Control flow activity types"}
                                          {stat.id === "dataFlowSteps" &&
                                            "Data flow step types"}
                                          {stat.id === "sourceSinkList" &&
                                            "Sources and Sinks types"}
                                          {!isSqlReport && (
                                            <span className="ml-3.5 text-xs px-2.5 py-1 rounded-full bg-gray-100 text-gray-700">
                                              {`${counts?.toLocaleString()} ${
                                                stat.id === "sourceSinkList"
                                                  ? "connections"
                                                  : "occurrences"
                                              }`}
                                            </span>
                                          )}
                                        </>
                                      </span>
                                    }
                                  >
                                    <div className="flex flex-col divide-y divide-gray-200">
                                      {sqlAnalyzerReport &&
                                        isSqlReport &&
                                        sqlAnalyzerReport.statements
                                          .sort((a, b) =>
                                            stat.id === "lines"
                                              ? b.linePercentage -
                                                a.linePercentage
                                              : b.objectPercentage -
                                                a.objectPercentage
                                          )
                                          .map((statement) => {
                                            let subTitle =
                                              statement.statementCount.toLocaleString();
                                            let percentage =
                                              statement.objectPercentage;
                                            let legend = "statements";

                                            if (stat.id === "lines") {
                                              subTitle =
                                                statement.lineCount.toLocaleString();
                                              percentage =
                                                statement.linePercentage;
                                              legend = "lines";
                                            }
                                            return (
                                              <AnalysisReportStatementRow
                                                key={statement.type}
                                                title={statement.type}
                                                subTitle={subTitle}
                                                legend={legend}
                                                percentage={percentage}
                                              />
                                            );
                                          })}
                                      {etlAnalyzerReport &&
                                        !isSqlReport &&
                                        etlAnalyzerReport[statementSection]
                                          .sort((a, b) => b.count - a.count)
                                          .map((statement) => {
                                            const total =
                                              stat.id === "controlFlowSteps"
                                                ? etlAnalyzerReport.stats
                                                    .controlFlowActivities
                                                : stat.id === "dataFlowSteps"
                                                ? etlAnalyzerReport.stats
                                                    .dataFlowSteps
                                                : etlAnalyzerReport.stats
                                                    .sources +
                                                  etlAnalyzerReport.stats.sinks;
                                            const percentage =
                                              (statement.count / total) * 100;
                                            const legend =
                                              stat.id === "sourceSinkList"
                                                ? "connections"
                                                : "occurrences";
                                            return (
                                              <AnalysisReportStatementRow
                                                key={statement.name}
                                                title={statement.name}
                                                subTitle={statement.count.toLocaleString()}
                                                legend={legend}
                                                percentage={percentage}
                                              />
                                            );
                                          })}
                                    </div>
                                  </StatementPanel>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                      {sqlAnalyzerReport && (
                        <AnalysisReportExceptionsSection
                          exceptionsCount={
                            sqlAnalyzerReport.stats.exceptions || 0
                          }
                          exceptionCategoriesCount={
                            sqlAnalyzerReport.stats.exceptionCategories || 0
                          }
                          exceptionsList={sqlAnalyzerReport.exceptionCategories?.map(
                            (ex) => {
                              return { name: ex.category, count: ex.count };
                            }
                          )}
                        />
                      )}
                      {etlAnalyzerReport && (
                        <AnalysisReportExceptionsSection
                          exceptionsCount={
                            etlAnalyzerReport.stats.exceptions || 0
                          }
                          exceptionCategoriesCount={
                            etlAnalyzerReport.stats.exceptionTypes || 0
                          }
                          exceptionsList={etlAnalyzerReport.exceptions?.map(
                            (ex) => {
                              return { name: ex.name, count: ex.count };
                            }
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </PageTransition>
            </PagePrintFormat>
          </>
        )}
      {pageErrorsList.length > 0 &&
        pageErrorsList.map((error, index, list) => {
          return showNotification(
            <Notification
              key={`error.${error.id}`}
              variant="danger"
              title={error.error}
              subTitle={error.description}
              onClose={() =>
                setPageErrorsList(
                  list.filter((_listItem, newIndex) => newIndex !== index)
                )
              }
            />
          );
        })}
    </>
  );
}

export default AnalysisReportResults;
