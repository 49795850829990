import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FormEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SettingsApi from "../../api/SettingsApi";
import UploadApi from "../../api/UploadApi";
import { AppError } from "../../App";
import { ClientContext } from "../../contexts/ClientContext";
import { ProjectContext } from "../../contexts/ProjectContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { UserContext } from "../../contexts/UserContext";
import { Project } from "../../models/Project";
import Alert from "../../_ui-kit/Alert/Alert";
import { Button } from "../../_ui-kit/Button";
import Input from "../../_ui-kit/Input/Input";
import InputGroup, {
  InputGroupErrorDescription,
} from "../../_ui-kit/InputGroup/InputGroup";
import { MasterPage } from "../../_ui-kit/MasterPage";
import Modal, { ModalContent, ModalFooter } from "../../_ui-kit/Modal/Modal";
import { Notification, showNotification } from "../../_ui-kit/Notification";
import { PageTransition } from "../../_ui-kit/PageTransition";
import { RadioSwitch } from "../../_ui-kit/RadioSwitch";
import SelectPlatform from "../../_ui-kit/SelectPlatform/SelectPlatform";
import { Platform } from "../../_ui-kit/SelectPlatform/SelectPlatform.types";
import { TaskData } from "../translate/components/TasksList";
import LibraryCard, { LibraryCardSkeleton } from "./components/LibraryCard";
import { LibInfo } from "./LibraryFiles";

type SourceType = "SQL" | "ETL";

type LibraryAction = "delete" | "analyze" | "translate" | "view-errors";

interface Library {
  id: number;
  uuid: string;
  name: string;
  sourceType: SourceType;
  platformId: number;
  platformName: string;
  embeddedSqlSourcePlatformId: number;
  createdBy: number;
  creatorName: string;
  dateCreated: string;
  lastUpdated: string;
  projectPlatformStatus: string;
  fileCount: number;
  uploadedCount: number;
  objectCount: number;
  platformFileIds: number[];
  errors: string[];
  validationErrors: string[];
}

interface PlatformMappingData {
  targetPlatformId: number;
  targetPlatformName: string;
  targetPlatformDescription: string;
  sourceType: SourceType;
}

interface AnalyzerReportConfirmation {
  freeAnalyzerReportInPlan: number;
  freeAnalyzerReportLeft: number;
  analyzerReportPriceInPlan: number;
  analyzerReportCost: number;
}

interface LibraryType {
  id: string;
  name: string;
  disabled: boolean;
}

type SortDirection = "desc" | "asc";

function downloadLibraryFiles(
  setIsDownloadingSourceCode: (param: boolean) => void,
  currentProject: Project | undefined,
  libraryId: string | undefined,
  currentLibrary: LibInfo | undefined
): void {
  const uploadApi = new UploadApi();
  async function downloadWave(
    projectId: string,
    libraryId: string,
    libraryName: string
  ) {
    const response = await uploadApi.downloadSourceCode(projectId, libraryId);
    const result = await response.blob();
    let url = URL.createObjectURL(result);
    let anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = `Source code (Library - ${libraryName}).zip`;
    anchor.style.display = "none";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    URL.revokeObjectURL(url);
    setIsDownloadingSourceCode(false);
  }

  if (currentProject && libraryId && currentLibrary) {
    setIsDownloadingSourceCode(true);
    downloadWave(currentProject.uuid, libraryId, currentLibrary.name);
  }
}

function LibraryUpload() {
  const settingsApi = new SettingsApi();
  const uploadApi = new UploadApi();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { authUser } = useContext(UserContext);
  const { currentClient } = useContext(ClientContext);
  const { currentProject } = useContext(ProjectContext);
  const { environmentSettings } = useContext(SettingsContext);
  const [pageErrorsList, setPageErrorsList] = useState<AppError[]>([]);
  const [selectedPlatform, setSelectedPlatform] = useState<Platform>();
  const [selectedEmbeddedPlatform, setSelectedEmbeddedPlatform] =
    useState<Platform>();
  const [sourcePlatformsList, setSourcePlatformsList] = useState<Platform[]>(
    []
  );
  const [refetchInterval, setRefetchInterval] = useState(1000);
  const [openNewLibraryDialog, setOpenNewLibraryDialog] = useState(false);
  const [sortDirection, setSortDirection] = useState<SortDirection>("asc");
  const [librariesListQuery, setLibrariesListQuery] = useState("");
  const [loadedLibrariesList, setLoadedLibrariesList] = useState<Library[]>([]);
  const [isAddingLibrary, setIsAddingLibrary] = useState(false);
  const [isAnalyzingLibrary, setIsAnalyzingLibrary] = useState(false);
  const [libraryAction, setLibraryAction] = useState<LibraryAction>();
  const [currentLibrary, setCurrentLibrary] = useState<Library>();
  const [
    libraryTargetPlatformsMappingList,
    setLibraryTargetPlatformsMappingList,
  ] = useState<Platform[]>([]);
  const [
    libraryTargetSqlPlatformsMappingList,
    setLibraryTargetSqlPlatformsMappingList,
  ] = useState<Platform[]>([]);

  const [selectedLibraryTargetPlatform, setSelectedLibraryTargetPlatform] =
    useState<Platform>();
  const [
    selectedLibraryTargetEmbeddedPlatform,
    setSelectedLibraryTargetEmbeddedPlatform,
  ] = useState<Platform>();
  const [openAnalyzeDialog, setOpenAnalyzeDialog] = useState(false);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    useState(false);
  const [openLibraryErrorsDialog, setOpenLibraryErrorsDialog] = useState(false);
  const [isDeletingLibrary, setIsDeletingLibrary] = useState(false);
  const [sourceTypesOptions, setSourceTypesOptions] = useState<LibraryType[]>(
    []
  );
  const [librarySourceType, setLibrarySourceType] = useState<LibraryType>();
  const [
    analyzerRerportConfirmationDetails,
    setAnalyzerReportConfirmationDetails,
  ] = useState<AnalyzerReportConfirmation>();
  const [confirmAnalysis, setConfirmAnalysis] = useState(false);
  const [libraryNameExistsError, setLibraryNameExistsError] = useState(false);
  const [isDownloadingSplitLog, setIsDownloadingSplitLog] = useState(false);
  const [errorDownloadingSplitLog, setErrorDownloadingSplitLog] =
    useState(false);

  const filteredLibrariesList =
    librariesListQuery === ""
      ? loadedLibrariesList
      : loadedLibrariesList?.filter((item) => {
          return (
            item.name
              .toLowerCase()
              .includes(librariesListQuery.toLowerCase()) ||
            item.uuid === librariesListQuery
          );
        });

  const filerTypes = (data: Platform[]) => {
    return data.filter((platform) => platform.name.toLowerCase() !== "unknown");
  };

  const { data: etlTypes } = useQuery(
    ["etl-types"],
    async () => {
      const result = await settingsApi.getShiftEtlPlatformTypes();
      const etls: Platform[] = await result.json();
      return etls;
    },
    {
      select: filerTypes,
    }
  );

  const { data: sqlTypes } = useQuery(
    ["sql-types"],
    async () => {
      const result = await settingsApi.getShiftDataPlatformTypes();
      const sqls: Platform[] = await result.json();
      return sqls;
    },
    {
      select: filerTypes,
    }
  );

  const { data: libraries, isLoading: isLoadingLibraries } = useQuery(
    [
      `libraries-list${!currentClient || !currentProject ? "-invalid" : ""}`,
      {
        clientId: currentClient?.id,
        projectId: currentProject?.uuid,
      },
    ],
    async () => {
      const clientId = currentClient?.id || "";
      const projectId = currentProject?.uuid || "";
      const response = await uploadApi.getLibraries(clientId, projectId);
      const platforms: Library[] = await response.json();
      setLoadedLibrariesList(platforms);
      return platforms;
    },
    {
      staleTime: 0,
      refetchInterval: refetchInterval,
      enabled: !!currentClient && !!currentProject,
      onSuccess: () => {
        queryClient.removeQueries(["libraries-list-invalid"]);
      },
      onError: (ex) => {
        console.log(ex);
        setRefetchInterval(0);
      },
    }
  );

  const addLibraryMutation = useMutation(
    (newLibrary: any) => {
      return uploadApi.addLibrary(
        newLibrary.projectId,
        newLibrary.libraryName,
        newLibrary.sourceType,
        newLibrary.libraryPlatform,
        newLibrary.librayEmbeddedSql
      );
    },
    {
      onSettled: () => {
        setIsAddingLibrary(false);
        closeAddLibraryDialog(false);
      },
      onError: (error: any) => {
        setPageErrorsList((prev) => [
          ...prev,
          {
            id: Math.random().toString(),
            error: "Error adding library",
            description: error,
          },
        ]);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["libraries-list"]);
      },
    }
  );

  const deleteLibraryMutation = useMutation(
    (currentLibrary: any) => {
      return uploadApi.deleteLibrary(
        currentLibrary.projectId,
        currentLibrary.libraryId,
        authUser?.isAdmin()
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["libraries-list"]);
        setIsDeletingLibrary(false);
        closeDeleteLibraryDialog(false);
      },
      onError: (error: any) => {
        setPageErrorsList((prev) => [
          ...prev,
          {
            id: Math.random().toString(),
            error: "Error deliting library",
            description: error,
          },
        ]);
      },
    }
  );

  function closeAddLibraryDialog(close: boolean): void {
    setOpenNewLibraryDialog(close);
    setLibraryNameExistsError(false);
  }

  function closeDeleteLibraryDialog(close: boolean): void {
    setOpenDeleteConfirmationDialog(close);
    setLibraryAction(undefined);
  }

  function closeAnalyzeLibraryDialog(close: boolean): void {
    setOpenAnalyzeDialog(close);
    setAnalyzerReportConfirmationDetails(undefined);
    setConfirmAnalysis(false);
    setLibraryAction(undefined);
  }

  function closeLibraryErrorsDialog(close: boolean): void {
    setOpenLibraryErrorsDialog(close);
    setErrorDownloadingSplitLog(false);
    setLibraryAction(undefined);
  }

  function openAddLibraryDialogClickHandler() {
    setOpenNewLibraryDialog(true);
  }

  function addLibrarySubmitHandler(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const libraryName = formData.get("add-library-name") as string;
    const sourceType = librarySourceType?.id;
    const libraryPlatform = formData.get("add-library-platform[id]") as string;
    const librayEmbeddedSql = formData.get(
      "add-library-embedded-sql-platform[id]"
    ) as string;
    const libraryNameExists = loadedLibrariesList
      ? loadedLibrariesList.filter(
          (library) => library.name.toLowerCase() === libraryName.toLowerCase()
        ).length > 0
      : false;

    const addLibrary = (projectId: string) => {
      addLibraryMutation.mutate({
        projectId,
        libraryName,
        sourceType,
        libraryPlatform,
        librayEmbeddedSql,
      });
    };

    if (currentProject && selectedPlatform && !libraryNameExists) {
      setIsAddingLibrary(true);
      addLibrary(currentProject.uuid);
    }

    setLibraryNameExistsError(libraryNameExists);
  }

  function deleteClickHandler(): void {
    const deleteLibrary = (projectId: string, libraryId: string) => {
      deleteLibraryMutation.mutate({
        projectId,
        libraryId,
      });
    };
    if (currentProject && currentLibrary) {
      setIsDeletingLibrary(true);
      deleteLibrary(currentProject.uuid, currentLibrary.uuid);
    }
  }

  function analyzeClickHandler(): void {
    const analyze = async (
      projectId: string,
      library: Library,
      targetPlatform: Platform,
      targetEmbeddedPlatform?: Platform
    ) => {
      try {
        const date = new Date();
        const targetPlatformId = targetPlatform?.id || "no_target";
        const targetEmbeddedPlatformId =
          targetEmbeddedPlatform?.id || "no_embedded_target";
        const taskName = `${library.name.replace(
          " ",
          "_"
        )}_${targetPlatformId}_${
          library.sourceType
        }_${date.getUTCFullYear()}_${date.getUTCMonth()}_${date.getUTCDate()}_${date.getUTCHours()}_${date.getUTCMinutes()}_${date.getUTCSeconds()}_${date.getUTCMilliseconds()}`.toLowerCase();
        const response =
          library.sourceType === "SQL"
            ? await uploadApi.analyzeLibrary(
                projectId,
                library.uuid,
                taskName,
                targetPlatformId,
                library.platformFileIds
              )
            : await uploadApi.analyzeEtlLibrary(
                projectId,
                library.uuid,
                taskName,
                targetPlatformId,
                targetEmbeddedPlatformId,
                library.platformFileIds
              );
        if (!response.ok) {
          setIsAnalyzingLibrary(false);
          closeAnalyzeLibraryDialog(false);
          setPageErrorsList((prev) => [
            ...prev,
            {
              id: Math.random().toString(),
              error: "Error analyzing library",
              description: response.statusText,
            },
          ]);
        } else {
          const newTask: TaskData = await response.json();
          navigate(`/analyze/progress/${newTask.uuid}`);
        }
      } catch (ex: any) {
        setIsAnalyzingLibrary(false);
        closeAnalyzeLibraryDialog(false);
        setPageErrorsList((prev) => [
          ...prev,
          {
            id: Math.random().toString(),
            error: "Error analyzing library",
            description: ex,
          },
        ]);
      }
    };
    const isEtl = currentLibrary?.sourceType === "ETL";

    if (confirmAnalysis) {
      if (
        currentProject &&
        currentLibrary &&
        selectedLibraryTargetPlatform &&
        (!isEtl || (isEtl && selectedLibraryTargetEmbeddedPlatform))
      ) {
        setIsAnalyzingLibrary(true);
        analyze(
          currentProject.uuid,
          currentLibrary,
          selectedLibraryTargetPlatform,
          selectedLibraryTargetEmbeddedPlatform
        );
      }
    } else {
      setConfirmAnalysis(true);
    }
  }

  function downloadSplitLogsClickHandler(): void {
    const downloadLogs = async (projectId: string, libraryId: string) => {
      try {
        const response = await uploadApi.downloadSplitLogs(
          projectId,
          libraryId
        );
        const result = await response.blob();
        let url = URL.createObjectURL(result);
        let anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `Library split log.zip`;
        anchor.style.display = "none";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(url);
        setIsDownloadingSplitLog(false);
      } catch (ex) {
        console.log(ex);
        setIsDownloadingSplitLog(false);
        setErrorDownloadingSplitLog(true);
      }
    };

    if (currentProject && currentLibrary) {
      setIsDownloadingSplitLog(true);
      downloadLogs(currentProject.uuid, currentLibrary.uuid);
    }
  }

  function sortLibrariesClickHandler(): void {
    setSortDirection(sortDirection === "desc" ? "asc" : "desc");
  }

  function sortLibraries(a: Library, b: Library) {
    if (sortDirection === "asc") {
      if (a.dateCreated < b.dateCreated) return -1;
      if (a.dateCreated > b.dateCreated) return 1;
    } else {
      if (a.dateCreated > b.dateCreated) return -1;
      if (a.dateCreated < b.dateCreated) return 1;
    }
    return 0;
  }

  useEffect(() => {
    if (environmentSettings) {
      const etlEnabledProp = environmentSettings.find(
        (setting) => setting.environment_property === "enable_etl"
      );
      const sourceTypes: LibraryType[] = [
        {
          id: "SQL",
          name: "SQL",
          disabled: false,
        },
        {
          id: "ETL",
          name: "ETL",
          disabled:
            !(etlEnabledProp && etlEnabledProp.property_value === "enable") ||
            false,
        },
      ];
      setSourceTypesOptions(sourceTypes);
    }
  }, [environmentSettings]);

  useEffect(() => {
    if (sourceTypesOptions) setLibrarySourceType(sourceTypesOptions[0]);
  }, [sourceTypesOptions]);

  useEffect(() => {
    if (librarySourceType) {
      const isSqlPlatform = librarySourceType.id === "SQL";
      if (isSqlPlatform && sqlTypes && sqlTypes.length > 0)
        setSourcePlatformsList(sqlTypes);
      if (!isSqlPlatform && etlTypes && etlTypes.length > 0)
        setSourcePlatformsList(etlTypes);
    }
  }, [librarySourceType, sqlTypes, etlTypes]);

  useEffect(() => {
    if (sourcePlatformsList && sourcePlatformsList.length > 0)
      setSelectedPlatform(sourcePlatformsList[0]);
  }, [sourcePlatformsList]);

  useEffect(() => {
    if (sqlTypes && sqlTypes.length > 0) {
      setSelectedEmbeddedPlatform(sqlTypes[0]);
    }
  }, [sqlTypes]);

  useEffect(() => {
    const getLibraryTargetMapping = async (
      projectId: string,
      libraryId: string
    ) => {
      const result = await uploadApi.getLibraryTargetMapping(
        projectId,
        libraryId
      );
      const platforms: PlatformMappingData[] = await result.json();
      const selectPlatforms = (platform: PlatformMappingData) => {
        return {
          id: platform.targetPlatformId,
          name: platform.targetPlatformName,
          description: platform.targetPlatformDescription,
        };
      };

      const sqlPlatforms = filerTypes(
        platforms
          .filter((platform) => platform.sourceType === "SQL")
          .map(selectPlatforms)
      );

      const etlPlatforms = filerTypes(
        platforms
          .filter((platform) => platform.sourceType === "ETL")
          .map(selectPlatforms)
      );

      if (currentLibrary?.sourceType === "SQL") {
        setLibraryTargetPlatformsMappingList(sqlPlatforms);
        setSelectedLibraryTargetPlatform(sqlPlatforms[0]);
      } else {
        setLibraryTargetPlatformsMappingList(etlPlatforms);
        setSelectedLibraryTargetPlatform(etlPlatforms[0]);
        setLibraryTargetSqlPlatformsMappingList(sqlPlatforms);
        setSelectedLibraryTargetEmbeddedPlatform(sqlPlatforms[0]);
      }
    };

    const getAnalyzeConfirmation = async (
      projectId: string,
      libraryId: string
    ) => {
      const result =
        currentLibrary?.sourceType.toLowerCase() === "sql"
          ? await uploadApi.getAnalyzeConfirmation(projectId, libraryId)
          : await uploadApi.getEtlAnalyzeConfirmation(projectId, libraryId);
      const confirmation: AnalyzerReportConfirmation = await result.json();
      setAnalyzerReportConfirmationDetails(confirmation);
    };

    if (libraryAction && currentProject && currentLibrary) {
      if (libraryAction === "delete") {
        setOpenDeleteConfirmationDialog(true);
      } else if (libraryAction === "analyze") {
        getAnalyzeConfirmation(currentProject.uuid, currentLibrary.uuid);
        getLibraryTargetMapping(currentProject.uuid, currentLibrary.uuid);
        setOpenAnalyzeDialog(true);
      } else if (libraryAction === "view-errors") {
        setOpenLibraryErrorsDialog(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLibrary, libraryAction]);

  return (
    <MasterPage heading="Upload">
      <PageTransition>
        <div
          id="content"
          className="min-h-full p-6 2xl:max-w-7xl 2xl:mx-auto 2xl:px-0"
        >
          <div className="grid grid-cols-2 xl:grid-cols-3 gap-6 pt-4 mb-6">
            <div className="xl:col-span-2 flex items-center space-x-4">
              <h2 className="flex items-center text-lg text-gray-900 font-medium">
                Libraries
                {isLoadingLibraries && !libraries && (
                  <span className="inline-flex px-3 ml-1 text-gray-300">
                    <FontAwesomeIcon
                      icon={solid("spinner")}
                      spin={true}
                      className="w-6 h-6"
                      aria-hidden={true}
                    />
                  </span>
                )}
                {libraries && (
                  <span className="rounded-full bg-gray-200 px-3 py-1 ml-3 text-sm text-gray-700 font-bold">
                    {libraries.length}
                  </span>
                )}
              </h2>
              {currentClient &&
                currentClient.isActive &&
                !authUser?.isReviewer() && (
                  <Button
                    label="Add library"
                    icon={solid("plus")}
                    onClick={openAddLibraryDialogClickHandler}
                  />
                )}
            </div>
            <div>
              <label htmlFor="searchTasks" className="sr-only">
                Search tasks
              </label>
              <div className="flex rounded-md shadow-sm">
                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3.5">
                    <FontAwesomeIcon
                      icon={regular("search")}
                      className="h-3.5 w-3.5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    id="searchTasks"
                    className="block w-full rounded-none rounded-l-md border-gray-300 pl-9 focus:border-primary-500 focus:ring-primary-500 text-sm"
                    placeholder="Search"
                    onChange={(event) =>
                      setLibrariesListQuery(event.target.value)
                    }
                    disabled={!loadedLibrariesList}
                    size={40}
                  />
                </div>
                <button
                  type="button"
                  className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                  onClick={sortLibrariesClickHandler}
                  disabled={!loadedLibrariesList}
                >
                  <FontAwesomeIcon
                    icon={
                      sortDirection === "desc"
                        ? solid("arrow-down-short-wide")
                        : solid("arrow-up-short-wide")
                    }
                    className="h-4 w-4 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>Sort</span>
                </button>
              </div>
            </div>
          </div>
          <div className="h-full grid grid-cols-2 content-start xl:grid-cols-3 gap-6">
            {isLoadingLibraries && !libraries && (
              <>
                {[...Array(5)].map((_e, i) => (
                  <LibraryCardSkeleton key={`skeleton-${i}`} />
                ))}
              </>
            )}
            {filteredLibrariesList &&
              filteredLibrariesList
                .sort(sortLibraries)
                .map((library) => (
                  <LibraryCard
                    library={library}
                    key={library.uuid}
                    setLibraryAction={setLibraryAction}
                    setCurrentLibrary={setCurrentLibrary}
                  />
                ))}
            {currentClient &&
              currentClient.isActive &&
              !authUser?.isReviewer() && (
                <button
                  className={`${
                    sortDirection === "desc" ? "order-first" : ""
                  } group border-2 border-gray-300 border-dashed rounded-lg h-64 text-gray-300 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"`}
                  onClick={openAddLibraryDialogClickHandler}
                >
                  <FontAwesomeIcon
                    icon={solid("folder-plus")}
                    size="3x"
                    className="mt-5"
                    aria-hidden={true}
                  />
                  <span className="transition block opacity-0 mt-2 text-xs text-gray-500 font-medium uppercase translate-y-1 group-hover:opacity-100 group-hover:translate-y-0">
                    Add library
                  </span>
                </button>
              )}
          </div>
        </div>
      </PageTransition>
      <Modal
        title="New library"
        show={openNewLibraryDialog}
        onClose={isAddingLibrary ? () => {} : closeAddLibraryDialog}
      >
        <form onSubmit={addLibrarySubmitHandler}>
          <ModalContent>
            <div className="flex flex-col space-y-4 pb-1">
              {librarySourceType && (
                <RadioSwitch
                  label="Library type"
                  options={sourceTypesOptions}
                  selectedOption={librarySourceType}
                  onChange={setLibrarySourceType}
                  disabled={
                    !sourceTypesOptions.every(
                      (option) => option.disabled === false
                    )
                  }
                />
              )}
              <InputGroup
                label="Name"
                inputId="add-library-name"
                className="space-y-2"
              >
                <Input
                  type="text"
                  id="add-library-name"
                  name="add-library-name"
                  size="lg"
                  maxLength={30}
                  placeholder="Enter your library name"
                  autoComplete="off"
                  isInvalid={libraryNameExistsError}
                  ariaDescribedby={
                    libraryNameExistsError
                      ? "add-library-name-error"
                      : undefined
                  }
                  required
                />

                {libraryNameExistsError && (
                  <InputGroupErrorDescription
                    id="add-library-name-error"
                    text="A library with the same name already exists."
                  />
                )}
              </InputGroup>
              <div>
                <label className="block font-medium text-gray-700">
                  Platform
                </label>
                <div className="mt-2">
                  <SelectPlatform
                    name="add-library-platform"
                    platforms={sourcePlatformsList}
                    selectedPlatform={selectedPlatform}
                    setSelectedPlatform={setSelectedPlatform}
                  />
                </div>
              </div>
              {sqlTypes && librarySourceType?.id === "ETL" && (
                <div>
                  <label className="block font-medium text-gray-700">
                    Embedded SQL
                  </label>
                  <div className="mt-2">
                    <SelectPlatform
                      name="add-library-embedded-sql-platform"
                      platforms={sqlTypes}
                      selectedPlatform={selectedEmbeddedPlatform}
                      setSelectedPlatform={setSelectedEmbeddedPlatform}
                    />
                  </div>
                </div>
              )}
            </div>
          </ModalContent>
          <ModalFooter>
            <div className="flex space-x-4">
              <Button
                type="submit"
                label="Add library"
                icon={solid("plus")}
                isLoading={isAddingLibrary}
              />
              <Button
                label="Cancel"
                icon={solid("xmark-circle")}
                variant="white"
                disabled={isAddingLibrary}
                onClick={() => closeAddLibraryDialog(false)}
              />
            </div>
          </ModalFooter>
        </form>
      </Modal>
      <Modal
        title="Analysis pricing confirmation"
        show={openAnalyzeDialog}
        onClose={isAnalyzingLibrary ? () => {} : closeAnalyzeLibraryDialog}
      >
        <ModalContent>
          {!confirmAnalysis && analyzerRerportConfirmationDetails && (
            <div>
              {analyzerRerportConfirmationDetails.freeAnalyzerReportInPlan ===
                -1 && (
                <div>
                  <div className="flex flex-col space-y-3">
                    <p className="text-primary-700 text-lg font-medium">
                      You are about to analyze this library. The cost for this
                      report is{" "}
                      <strong>
                        $
                        {analyzerRerportConfirmationDetails.analyzerReportCost.toLocaleString()}
                      </strong>
                      .
                    </p>
                    <p>Would you like to continue analyzing this library?</p>
                  </div>
                </div>
              )}
              {analyzerRerportConfirmationDetails.freeAnalyzerReportInPlan !==
                -1 &&
                analyzerRerportConfirmationDetails.freeAnalyzerReportLeft ===
                  0 && (
                  <div className="flex flex-col space-y-3">
                    <p className="text-primary-700 text-lg font-medium">
                      You have used all your free analyzer reports for the
                      current period. Therefore, the cost for this report is{" "}
                      <strong>
                        $
                        {analyzerRerportConfirmationDetails.analyzerReportCost.toLocaleString()}
                      </strong>
                      .
                    </p>
                    <p>Would you like to continue analyzing this library?</p>
                  </div>
                )}
              {analyzerRerportConfirmationDetails.freeAnalyzerReportInPlan !==
                -1 &&
                analyzerRerportConfirmationDetails.freeAnalyzerReportLeft >
                  0 && (
                  <>
                    <div className="flex items-center space-x-4 mb-4">
                      <div className="shrink-0 bg-gray-100 border-2 border-gray-200 px-5 py-4 rounded-md text-primary-700 text-2xl font-bold">
                        {analyzerRerportConfirmationDetails.freeAnalyzerReportLeft.toLocaleString()}{" "}
                        /{" "}
                        {analyzerRerportConfirmationDetails.freeAnalyzerReportInPlan.toLocaleString()}
                      </div>{" "}
                      <p className="text-lg leading-tight text-primary-700">
                        You have available{" "}
                        {analyzerRerportConfirmationDetails.freeAnalyzerReportLeft.toLocaleString()}{" "}
                        free analyzer reports left on your current plan.
                      </p>
                    </div>
                    <p>
                      Would you like to continue analyzing this library and use
                      one of them?
                    </p>
                  </>
                )}
            </div>
          )}
          {confirmAnalysis && (
            <div className="flex flex-col space-y-4 pb-1">
              {libraryTargetPlatformsMappingList.length > 0 && (
                <div>
                  <label className="block font-medium text-gray-700">
                    Target platform
                  </label>
                  <div className="mt-2">
                    <SelectPlatform
                      name="analyze-target-platform"
                      platforms={libraryTargetPlatformsMappingList}
                      selectedPlatform={selectedLibraryTargetPlatform}
                      setSelectedPlatform={setSelectedLibraryTargetPlatform}
                    />
                  </div>
                </div>
              )}
              {currentLibrary?.sourceType === "ETL" &&
                libraryTargetSqlPlatformsMappingList.length > 0 && (
                  <div>
                    <label className="block font-medium text-gray-700">
                      Target embedded SQL
                    </label>
                    <div className="mt-2">
                      <SelectPlatform
                        name="analyze-target-embedded-platform"
                        platforms={libraryTargetSqlPlatformsMappingList}
                        selectedPlatform={selectedLibraryTargetEmbeddedPlatform}
                        setSelectedPlatform={
                          setSelectedLibraryTargetEmbeddedPlatform
                        }
                      />
                    </div>
                  </div>
                )}
              {libraryTargetPlatformsMappingList.length < 1 && (
                <Alert variant="warning" show={true}>
                  <p className="text-sm font-medium">
                    No target platforms available.
                  </p>
                </Alert>
              )}
            </div>
          )}
        </ModalContent>
        <ModalFooter>
          <div className="flex space-x-4">
            {((currentLibrary?.sourceType === "SQL" &&
              libraryTargetPlatformsMappingList.length > 0) ||
              currentLibrary?.sourceType === "ETL") && (
              <Button
                type="submit"
                label={`${
                  currentLibrary?.sourceType === "ETL"
                    ? "Yes, analyze"
                    : confirmAnalysis
                    ? "Analyze"
                    : "Yes, continue"
                }`}
                icon={
                  confirmAnalysis ? solid("plus-square") : solid("check-circle")
                }
                isLoading={isAnalyzingLibrary}
                onClick={analyzeClickHandler}
              />
            )}
            <Button
              label={`${confirmAnalysis ? "Cancel" : "No, cancel"}`}
              icon={solid("xmark-circle")}
              variant="white"
              disabled={isAnalyzingLibrary}
              onClick={() => closeAnalyzeLibraryDialog(false)}
            />
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        title="Delete confirmation"
        size="lg"
        show={openDeleteConfirmationDialog}
        onClose={isDeletingLibrary ? () => {} : closeDeleteLibraryDialog}
      >
        <ModalContent>
          <div className="flex items-start">
            <div className="w-10 h-10 mr-4 flex items-center justify-center shrink-0 bg-danger-100 rounded-full text-danger-600">
              <FontAwesomeIcon
                icon={regular("triangle-exclamation")}
                size="lg"
              />
            </div>
            <div>
              <p className="text-gray-500">
                Are you sure you want to delete{" "}
                <strong className="text-gray-900">
                  {currentLibrary?.name}
                </strong>{" "}
                library? All of your data will be permanently removed from our
                servers forever.{" "}
                {authUser?.isAdmin() && (
                  <span className="text-danger-700">
                    In addition, the billing information will be deleted.
                  </span>
                )}{" "}
                This action cannot be undone.
              </p>
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <div className="flex space-x-4 pl-14">
            <Button
              variant="danger"
              type="submit"
              label="Delete library"
              icon={solid("trash-alt")}
              isLoading={isDeletingLibrary}
              onClick={deleteClickHandler}
            />
            <Button
              label="Cancel"
              icon={solid("xmark-circle")}
              variant="white"
              disabled={isDeletingLibrary}
              onClick={() => closeDeleteLibraryDialog(false)}
            />
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        title="Library errors"
        size="xl"
        show={openLibraryErrorsDialog}
        onClose={closeLibraryErrorsDialog}
      >
        <ModalContent className="space-y-6">
          {currentLibrary &&
            currentLibrary.validationErrors.length < 1 &&
            authUser?.isAdmin() && (
              <Alert variant="danger" show>
                <div className="relative flex items-center grow">
                  <p className="grow text-sm font-medium pr-32">
                    Download the library logs for more details to review.
                  </p>
                  <div className="absolute right-0">
                    <Button
                      label={`${
                        errorDownloadingSplitLog ? "Unavailable" : "Download"
                      }`}
                      icon={
                        errorDownloadingSplitLog
                          ? solid("ban")
                          : solid("folder-arrow-down")
                      }
                      variant="danger"
                      size="xs"
                      radius="pill"
                      onClick={downloadSplitLogsClickHandler}
                      isLoading={isDownloadingSplitLog}
                      disabled={errorDownloadingSplitLog}
                    />
                  </div>
                </div>
              </Alert>
            )}
          {currentLibrary && currentLibrary.errors.length > 0 && (
            <ol className="list-decimal list-inside space-y-2.5 mx-0 p-4 bg-gray-100 rounded-md text-sm font-mono">
              {currentLibrary.errors.map((error, index) => {
                return (
                  <li
                    key={`error-${index}`}
                    className="marker:font-medium marker:text-gray-600 text-gray-900"
                  >
                    {error}
                  </li>
                );
              })}
            </ol>
          )}
          {currentLibrary && currentLibrary.validationErrors.length > 0 && (
            <ol className="list-decimal list-inside space-y-2.5 mx-0 p-4 bg-gray-100 rounded-md text-sm font-mono">
              {currentLibrary.validationErrors.map((error, index) => {
                return (
                  <li
                    key={`validation-error-${index}`}
                    className="marker:font-medium marker:text-gray-600 text-gray-900"
                  >
                    {error}
                  </li>
                );
              })}
            </ol>
          )}
        </ModalContent>
      </Modal>
      {pageErrorsList.length > 0 &&
        pageErrorsList.map((error, index, list) => {
          return showNotification(
            <Notification
              key={`error.${error.id}`}
              variant="danger"
              title={error.error}
              subTitle={error.description}
              onClose={() =>
                setPageErrorsList(
                  list.filter((_listItem, newIndex) => newIndex !== index)
                )
              }
            />
          );
        })}
    </MasterPage>
  );
}

export type { Library, LibraryAction };
export { downloadLibraryFiles };
export default LibraryUpload;
