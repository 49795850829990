import { useEffect, useRef } from "react";
import { classNames } from "../utils";
import UnselectableContentProps from "./UnselectableContent.types";

export default function UnselectableContent({
  id,
  className = "",
  selectable = true,
  children,
}: UnselectableContentProps) {
  const divRef = useRef<HTMLDivElement>(null);

  function disabledEventHandler(e: React.SyntheticEvent | Event) {
    if (!selectable) {
      getSelection()?.empty();
      e.preventDefault();
      return false;
    }
  }

  function disabledSelectionHandler(e: Event) {
    if (!selectable) {
      const root = divRef.current;
      const selection = getSelection();

      if (
        root &&
        selection &&
        selection.type === "Range" &&
        selection.containsNode(root, true)
      ) {
        selection.empty();
        e.preventDefault();
        return false;
      }
    }
  }

  useEffect(() => {
    if (!selectable)
      document.addEventListener("selectionchange", disabledSelectionHandler);

    return () => {
      if (!selectable) {
        document.removeEventListener(
          "selectionchange",
          disabledSelectionHandler
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectable]);

  return (
    <div
      ref={divRef}
      id={id}
      className={classNames(
        className,
        !selectable ? "select-none cursor-default" : ""
      )}
      onClick={disabledEventHandler}
      onFocus={disabledEventHandler}
      onDoubleClick={disabledEventHandler}
      onSelect={disabledEventHandler}
      onCut={disabledEventHandler}
      onCopy={disabledEventHandler}
      onContextMenu={disabledEventHandler}
      onDrag={disabledEventHandler}
      onDrop={disabledEventHandler}
    >
      {children}
    </div>
  );
}
