interface StatsListItemProps {
  category: string;
  value?: string;
}

function StatsListItem({ category, value }: StatsListItemProps) {
  return (
    <div className="flex items-center text-sm leading-5">
      <dt className="grow flex items-center text-gray-500">{category}</dt>
      {value && <dd className="text-gray-900 font-medium">{value}</dd>}
    </div>
  );
}

export default StatsListItem;
