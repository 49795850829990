import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import { PageTransitionProps } from "./PageTransition.types";

export function PageTransition({
  as = Fragment,
  ...props
}: PageTransitionProps) {
  return (
    <Transition
      show
      as={as}
      appear={true}
      enter="transition duration-500"
      enterFrom="opacity-0 translate-y-4"
      enterTo="opacity-100 translate-y-0"
    >
      {props.children}
    </Transition>
  );
}
