import { RadioGroup } from "@headlessui/react";
import {
  Button,
  TabItem,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "../../../_ui-kit";
import Input from "../../../_ui-kit/Input/Input";
import InputGroup, {
  InputGroupErrorDescription,
} from "../../../_ui-kit/InputGroup/InputGroup";
import { SlideOver } from "../../../_ui-kit/SlideOver";
import { classNames } from "../../../_ui-kit/utils";
import {
  ToggleSwitch,
  ToggleSwitchDescription,
  ToggleSwitchGroup,
  ToggleSwitchLabel,
} from "../../../_ui-kit/ToggleSwitch";
import PlatformSelectionItem from "./PlatformSelectionItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Client,
  ClientData,
  ClientObjectLevelPricing,
  ClientPricingPlan,
} from "../../../models/Client";
import SettingsApi from "../../../api/SettingsApi";
import { Platform } from "../../../_ui-kit/SelectPlatform/SelectPlatform.types";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { ConfigureContext } from "../../../contexts/ConfigureContext";
import PricingPlan from "../../../models/PricingPlan";
import Select from "../../../_ui-kit/Select/Select";

interface ClientEditSliderProps {
  openSlideOver: boolean;
  setOpenSlideOver: (open: boolean) => void;
  sliderTitle: string;
  clientsList?: Client[];
  editClient?: Client;
  setEditClient: (client?: Client) => void;
}

const clientTabItems = [
  {
    id: 1,
    name: "Details",
  },
  {
    id: 2,
    name: "Restrictions",
  },
  {
    id: 3,
    name: "Pricing",
  },
];

const maximumObjectsOptions = [
  {
    id: 1000,
    value: 1000,
    label: "1,000",
  },
  {
    id: 2000,
    value: 2000,
    label: "2,000",
  },
  {
    id: 2500,
    value: 2000,
    label: "2,500",
  },
  {
    id: 5000,
    value: 5000,
    label: "5,000",
  },
  {
    id: 10000,
    value: 10000,
    label: "10,000",
  },
  {
    id: 15000,
    value: 15000,
    label: "15,000",
  },
  {
    id: 20000,
    value: 20000,
    label: "20,000",
  },
  {
    id: -1,
    value: -1,
    label: "Unlimited",
  },
];

export default function ClientEditSlider({
  openSlideOver,
  setOpenSlideOver,
  sliderTitle,
  clientsList,
  editClient,
  setEditClient,
}: ClientEditSliderProps) {
  const settingsApi = new SettingsApi();
  const queryClient = useQueryClient();
  const { configureApi, pricingPlansList } = useContext(ConfigureContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedPricingPlan, setSelectedPricingPlan] =
    useState<ClientPricingPlan>();
  const [clientNameExistsError, setClientNameExistsError] = useState(false);

  const addClientMutation = useMutation(
    (newClient: any) => {
      return configureApi.addClient(
        newClient.code,
        newClient.name,
        newClient.clientRestriction
      );
    },
    {
      onError: (error) => {
        closeSlideOver(false);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["users-list"]);
        queryClient.invalidateQueries(["clients-list"]);
        queryClient.invalidateQueries(["clients-list-context"]);
        queryClient.invalidateQueries(["projects-list"]);
        queryClient.invalidateQueries(["projects-list-context"]);
      },
    }
  );

  const updateClientMutation = useMutation(
    (editClient: any) => {
      return configureApi.updateClient(
        editClient.id,
        editClient.code,
        editClient.name,
        editClient.isActive,
        editClient.clientRestriction
      );
    },
    {
      onError: (error) => {
        closeSlideOver(false);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["users-list"]);
        queryClient.invalidateQueries(["clients-list"]);
        queryClient.invalidateQueries(["clients-list-context"]);
        queryClient.invalidateQueries(["projects-list"]);
        queryClient.invalidateQueries(["projects-list-context"]);
      },
    }
  );

  const { data: targetSqlPlatforms } = useQuery(
    ["target-sql-platforms"],
    async () => {
      const result = await settingsApi.getShiftTargetDataPlatformTypes("SQL");
      const platforms: Platform[] = await result.json();
      return platforms;
    }
  );

  const { data: targetETLPlatforms } = useQuery(
    ["target-etl-platforms"],
    async () => {
      const result = await settingsApi.getShiftTargetDataPlatformTypes("ETL");
      const platforms: Platform[] = await result.json();
      return platforms;
    }
  );

  function clientSubmitHandler(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    if (!pricingPlansList || !selectedPricingPlan || clientNameExistsError)
      return;

    setIsSubmitting(true);

    const clientName = formData.get("client-name") as string;
    const clientCode = clientName.replace(" ", "_");
    const clientIsActive = formData.get("client-isactive") === "on";
    const clientStartDate = formData.get("client-start-date") as string;
    const clientEndDate = formData.get("client-end-date") as string;
    const clientTargetSqlPlatforms = formData.getAll(
      "client-target-sql-platforms"
    );
    const clientTargetEtlPlatforms = formData.getAll(
      "client-target-etl-platforms"
    );
    const clientMaximumObjects = formData.get("client-maximum-objects[value]");
    const clientAllowDownload = formData.get("client-allow-download") === "on";

    const pricingFreeTablesViews = selectedPricingPlan.freeTablesViews;
    const pricingFreeAnalyzerReports = selectedPricingPlan.freeAnalyzerReports;
    const pricingPrice = selectedPricingPlan.price;
    const pricingObjectLevelPricing = selectedPricingPlan.objectLevelPricing;

    const addPricingPlanToClient = async (
      response: Response,
      planId: string,
      freeTablesViews: number,
      freeAnalyzerReports: number,
      price: number,
      objectLevelPricing: ClientObjectLevelPricing[]
    ) => {
      try {
        const newClient: ClientData = await response.json();
        const result =
          editClient && editClient.pricingPlan
            ? await configureApi.updateClientPrincingPlan(
                newClient.id,
                planId,
                clientStartDate,
                clientEndDate,
                freeTablesViews.toString(),
                freeAnalyzerReports.toString(),
                price.toString(),
                objectLevelPricing
              )
            : await configureApi.addClientPrincingPlan(
                newClient.id,
                planId,
                clientStartDate,
                clientEndDate
              );
        if (!result.ok) {
          console.error(result.status, result.statusText);
        }
        closeSlideOver(false);
      } catch (error) {
        console.log(error);
        closeSlideOver(false);
      }
    };
    if (editClient) {
      updateClientMutation.mutate(
        {
          id: editClient.id,
          code: clientCode,
          name: clientName,
          isActive: clientIsActive,
          startDate: clientStartDate,
          endDate: clientEndDate,
          clientRestriction: {
            allowedSQLTargets: clientTargetSqlPlatforms,
            allowedETLTargets: clientTargetEtlPlatforms,
            allowDownload: clientAllowDownload,
            maximumObjects: clientMaximumObjects,
          },
        },
        {
          onSuccess: (response) => {
            addPricingPlanToClient(
              response,
              selectedPricingPlan.planId,
              pricingFreeTablesViews,
              pricingFreeAnalyzerReports,
              pricingPrice,
              pricingObjectLevelPricing
            );
          },
        }
      );
    } else {
      addClientMutation.mutate(
        {
          code: clientCode,
          name: clientName,
          startDate: clientStartDate,
          endDate: clientEndDate,
          clientRestriction: {
            allowedSQLTargets: clientTargetSqlPlatforms,
            allowedETLTargets: clientTargetEtlPlatforms,
            allowDownload: clientAllowDownload,
            maximumObjects: clientMaximumObjects,
          },
        },
        {
          onSuccess: (response) => {
            if (
              pricingFreeTablesViews !== undefined &&
              pricingFreeAnalyzerReports !== undefined &&
              pricingPrice !== undefined &&
              pricingObjectLevelPricing
            ) {
              addPricingPlanToClient(
                response,
                selectedPricingPlan.planId,
                pricingFreeTablesViews,
                pricingFreeAnalyzerReports,
                pricingPrice,
                pricingObjectLevelPricing
              );
            }
          },
        }
      );
    }
  }

  function clientNameBlurHandler(e: React.FocusEvent<HTMLInputElement>): void {
    const clientName = e.target.value;
    const clientNameExists = clientsList
      ? clientsList.filter((client) =>
          editClient
            ? client.id !== editClient.id &&
              client.name.toLowerCase() === clientName.toLowerCase()
            : client.name.toLowerCase() === clientName.toLowerCase()
        ).length > 0
      : false;

    if (clientNameExistsError && clientNameExists) return;

    if (clientNameExists) {
      e.target.setCustomValidity("Invalid client name");
    } else {
      e.target.setCustomValidity("");
    }
    setClientNameExistsError(clientNameExists);
  }

  function pricingPlanItemPriceChangeHandler(
    e: ChangeEvent<HTMLInputElement>,
    plan: ClientObjectLevelPricing
  ): void {
    const pricingPlan = structuredClone(selectedPricingPlan);
    if (!pricingPlan) return;

    pricingPlan.objectLevelPricing = pricingPlan.objectLevelPricing.map(
      (item) => {
        if (item.objectType === plan.objectType) {
          item.price = +e.target.value;
        }
        return item;
      }
    );
    setSelectedPricingPlan(pricingPlan);
  }

  function closeSlideOver(close: boolean): void {
    setIsSubmitting(close);
    setOpenSlideOver(close);
    setClientNameExistsError(false);
    setTimeout(() => {
      setEditClient(undefined);
    }, 750);
  }

  function resetToDefaultClickHandler(): void {
    setSelectedPricingPlan(
      structuredClone(
        pricingPlansList?.filter(
          (plan) => plan.planId === selectedPricingPlan?.planId
        )[0] as PricingPlan
      )
    );
  }

  useEffect(() => {
    if (editClient) {
      if (editClient.pricingPlan)
        setSelectedPricingPlan(structuredClone(editClient.pricingPlan));
    } else {
      if (pricingPlansList) setSelectedPricingPlan(pricingPlansList[0]);
    }
  }, [editClient, pricingPlansList]);

  return (
    <SlideOver
      title={sliderTitle}
      show={openSlideOver}
      onClose={isSubmitting ? () => {} : closeSlideOver}
      scroll={false}
    >
      <Tabs>
        <div className="relative border-t shadow-sm">
          <TabList>
            {clientTabItems.map((navItem) => (
              <TabItem key={navItem.id} label={navItem.name} />
            ))}
          </TabList>
        </div>
        <form
          onSubmit={clientSubmitHandler}
          className="h-full min-h-0 grow overflow-y-scroll bg-white"
        >
          <TabPanels>
            <TabPanel unmount={false}>
              <div className="flex flex-col p-6">
                <div className="flex flex-col space-y-8">
                  <InputGroup
                    label="Name"
                    inputId="client-name"
                    className="text-sm"
                  >
                    <Input
                      id="client-name"
                      name="client-name"
                      type="text"
                      autoComplete="off"
                      defaultValue={editClient?.name}
                      isInvalid={clientNameExistsError}
                      onBlur={clientNameBlurHandler}
                      ariaDescribedby={
                        clientNameExistsError ? "client-name-error" : undefined
                      }
                      required={true}
                    />
                    {clientNameExistsError && (
                      <InputGroupErrorDescription
                        id="client-name-error"
                        text="A client with the same name already exists."
                      />
                    )}
                  </InputGroup>
                  {pricingPlansList && selectedPricingPlan && (
                    <RadioGroup
                      value={selectedPricingPlan}
                      onChange={setSelectedPricingPlan}
                      name="client-pricing-plan"
                      by={"planId" as any}
                    >
                      <RadioGroup.Label className="block mb-3 font-medium">
                        Pricing plan
                      </RadioGroup.Label>
                      <div className="-space-y-px rounded-md bg-white shadow-sm">
                        {pricingPlansList.map((plan, index, list) => {
                          const pricing = new PricingPlan(plan);
                          return (
                            <RadioGroup.Option
                              key={pricing.planId}
                              value={pricing}
                              className={({ checked }) =>
                                classNames(
                                  index === 0
                                    ? "rounded-tl-md rounded-tr-md"
                                    : "",
                                  index === list.length - 1
                                    ? "rounded-bl-md rounded-br-md"
                                    : "",
                                  checked
                                    ? "bg-primary-50 border-primary-200 z-10"
                                    : "border-gray-200",
                                  "relative border p-4 flex cursor-pointer focus:outline-none"
                                )
                              }
                            >
                              {({ active, checked }) => (
                                <>
                                  <span
                                    className={classNames(
                                      checked
                                        ? "bg-primary-600 border-transparent"
                                        : "bg-white border-gray-300",
                                      active
                                        ? "ring-2 ring-offset-2 ring-primary-500"
                                        : "",
                                      "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                    )}
                                    aria-hidden="true"
                                  >
                                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                                  </span>
                                  <div className="flex items-center w-full ml-3">
                                    <RadioGroup.Label
                                      as="div"
                                      className={classNames(
                                        checked
                                          ? "text-primary-900"
                                          : "text-gray-900",
                                        "text-sm"
                                      )}
                                    >
                                      <div className="font-medium">
                                        {pricing.planType}
                                      </div>
                                      <div
                                        className={classNames(
                                          checked
                                            ? "text-primary-700"
                                            : "text-gray-500",
                                          "flex mt-0.5 space-x-2 leading-6"
                                        )}
                                      >
                                        <div>
                                          {pricing.getFreeAnalyzerReportsDisplayMessage()}
                                        </div>
                                        <span className="font-extrabold">
                                          &bull;
                                        </span>
                                        <div>
                                          {pricing.getFreeTablesViewsDisplayMessage()}
                                        </div>
                                      </div>
                                    </RadioGroup.Label>
                                    <RadioGroup.Description
                                      as="div"
                                      className={classNames(
                                        checked
                                          ? "text-primary-700"
                                          : "text-gray-500",
                                        "ml-auto text-sm text-right"
                                      )}
                                    >
                                      <div
                                        className={classNames(
                                          checked
                                            ? "text-primary-900"
                                            : "text-gray-900",
                                          "font-medium"
                                        )}
                                      >
                                        ${pricing.price.toLocaleString()}
                                      </div>
                                      <div className="leading-6 capitalize">
                                        {pricing.duration.toLowerCase()}
                                      </div>
                                    </RadioGroup.Description>
                                  </div>
                                </>
                              )}
                            </RadioGroup.Option>
                          );
                        })}
                      </div>
                    </RadioGroup>
                  )}
                  <div className="grid grid-cols-2 gap-4">
                    <InputGroup
                      label="Start date"
                      inputId="client-start-date"
                      className="text-sm"
                    >
                      <Input
                        id="client-start-date"
                        name="client-start-date"
                        type="date"
                        autoComplete="off"
                        required={true}
                        defaultValue={editClient?.pricingPlan?.startDate || ""}
                        className="uppercase"
                      />
                    </InputGroup>
                    <InputGroup
                      label="End date"
                      inputId="client-end-date"
                      className="text-sm"
                    >
                      <Input
                        id="client-end-date"
                        name="client-end-date"
                        type="date"
                        autoComplete="off"
                        required={true}
                        defaultValue={editClient?.pricingPlan?.endDate || ""}
                        className="uppercase"
                      />
                    </InputGroup>
                  </div>
                  {editClient && (
                    <div className="!mt-10 bg-gray-100 rounded-lg">
                      <ToggleSwitchGroup as="div" className="p-4">
                        <ToggleSwitchLabel
                          as="h3"
                          className="font-medium leading-5"
                          passive
                        >
                          Activate client subscription
                        </ToggleSwitchLabel>
                        <div className="mt-2 flex items-start justify-between">
                          <div className="text-sm text-gray-500">
                            <ToggleSwitchDescription>
                              With a valid subscription, active clients are
                              granted access to the platform during their
                              designated time. Remember always to make sure
                              their pricing plan is up to date.
                            </ToggleSwitchDescription>
                          </div>
                          <div className="ml-6 flex flex-shrink-0 items-center">
                            <ToggleSwitch
                              id="client-isactive"
                              name="client-isactive"
                              defaultChecked={editClient.isActive}
                            />
                          </div>
                        </div>
                      </ToggleSwitchGroup>
                    </div>
                  )}
                </div>
              </div>
            </TabPanel>
            <TabPanel unmount={false}>
              <div className="flex flex-col p-6">
                <div className="flex flex-col space-y-8">
                  <div>
                    <label className="block mb-3 font-medium">
                      Target ETL platforms
                    </label>
                    <fieldset className="grid grid-cols-2 gap-3">
                      {targetETLPlatforms &&
                        targetETLPlatforms.map((platform) => {
                          return (
                            <PlatformSelectionItem
                              key={`target-etl-${platform.id}`}
                              platform={platform}
                              allowedSQLTargets={
                                editClient?.clientRestriction?.allowedETLTargets
                              }
                              type="etl"
                            />
                          );
                        })}
                    </fieldset>
                  </div>
                  <div>
                    <label className="block mb-3 font-medium">
                      Target SQL platforms
                    </label>
                    <fieldset className="grid grid-cols-2 gap-3">
                      {targetSqlPlatforms &&
                        targetSqlPlatforms.map((platform) => {
                          return (
                            <PlatformSelectionItem
                              key={`target-sql-${platform.id}`}
                              platform={platform}
                              allowedSQLTargets={
                                editClient?.clientRestriction?.allowedSQLTargets
                              }
                              type="sql"
                            />
                          );
                        })}
                    </fieldset>
                  </div>
                  <div>
                    <h3 className="mb-3 font-medium">Maximum objects limit</h3>
                    <RadioGroup
                      id="client-maximum-objects"
                      name="client-maximum-objects"
                      defaultValue={
                        maximumObjectsOptions.find(
                          (option) =>
                            option.value ===
                            editClient?.clientRestriction?.maximumObjects
                        ) ||
                        maximumObjectsOptions[maximumObjectsOptions.length - 1]
                      }
                    >
                      <RadioGroup.Label className="sr-only">
                        Choose an option
                      </RadioGroup.Label>
                      <div className="grid grid-cols-4 gap-3 sm:grid-cols-6">
                        {maximumObjectsOptions.map((option) => (
                          <RadioGroup.Option
                            key={option.id}
                            value={option}
                            className={({ active, checked }) =>
                              classNames(
                                active
                                  ? "ring-2 ring-offset-2 ring-primary-500"
                                  : "",
                                checked
                                  ? "bg-primary-600 border-transparent text-white hover:bg-primary-700"
                                  : "bg-white border-gray-300 text-gray-900 hover:bg-gray-50",
                                "border rounded-md py-3 px-3 flex items-center justify-center text-sm flex-1 shadow-sm"
                              )
                            }
                          >
                            <RadioGroup.Label as="span">
                              {option.label}
                            </RadioGroup.Label>
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>
                  <div className="!mt-10 bg-gray-100 rounded-lg">
                    <ToggleSwitchGroup as="div" className="p-4">
                      <ToggleSwitchLabel
                        as="h3"
                        className="font-medium leading-5"
                        passive
                      >
                        Allow to copy and download code
                      </ToggleSwitchLabel>
                      <div className="mt-2 flex items-start justify-between">
                        <div className="text-sm text-gray-500">
                          <ToggleSwitchDescription>
                            With this option, the client can include the
                            translated code in their projects by allowing them
                            to copy and download it directly from the platform,
                            providing a more convenient way to make progress.
                          </ToggleSwitchDescription>
                        </div>
                        <div className="ml-6 flex flex-shrink-0 items-center">
                          <ToggleSwitch
                            id="client-allow-download"
                            name="client-allow-download"
                            defaultChecked={
                              editClient?.clientRestriction?.allowDownload
                            }
                          />
                        </div>
                      </div>
                    </ToggleSwitchGroup>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel unmount={false}>
              {selectedPricingPlan &&
                [new PricingPlan(selectedPricingPlan)].map((selected) => {
                  return (
                    <div key={selected.planId} className="flex flex-col p-6">
                      <div className="flex flex-col space-y-6">
                        <div className="flex flex-col space-y-3">
                          <div className="font-medium">
                            Selected pricing plan
                          </div>
                          <div className="relative flex p-4 bg-primary-50 border border-primary-200 rounded-md shadow-sm">
                            <div className="flex items-center w-full">
                              <div className="text-primary-900 text-sm">
                                <div className="font-medium">
                                  {selected.planType}
                                </div>
                                <div className="text-primary-700 flex mt-0.5 space-x-2 leading-6">
                                  <div>
                                    {selected.getFreeAnalyzerReportsDisplayMessage()}
                                  </div>
                                  <span className="font-extrabold">•</span>
                                  <div>
                                    {selected.getFreeTablesViewsDisplayMessage()}
                                  </div>
                                </div>
                              </div>
                              <div className="text-primary-700 ml-auto text-sm text-right">
                                <div className="text-primary-900 font-medium">
                                  ${selected.price.toLocaleString()}
                                </div>
                                <div className="leading-6 capitalize">
                                  {selected.duration.toLowerCase()}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          <InputGroup
                            label="Free analyzer reports"
                            inputId="pricing-plan-free-reports"
                            className="text-sm"
                          >
                            <Select
                              id="pricing-plan-free-reports"
                              name="pricing-plan-free-reports"
                              required={true}
                              onChange={(e) =>
                                setSelectedPricingPlan({
                                  ...selectedPricingPlan,
                                  freeAnalyzerReports: +e.target.value,
                                })
                              }
                              value={selectedPricingPlan.freeAnalyzerReports}
                            >
                              {PricingPlan.freeReportsOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Select>
                          </InputGroup>
                          <InputGroup
                            label="Free tables and views"
                            inputId="pricing-plan-free-tables-views"
                            className="text-sm"
                          >
                            <Select
                              id="pricing-plan-free-tables-views"
                              name="pricing-plan-free-tables-views"
                              required={true}
                              onChange={(e) =>
                                setSelectedPricingPlan({
                                  ...selectedPricingPlan,
                                  freeTablesViews: +e.target.value,
                                })
                              }
                              value={selectedPricingPlan.freeTablesViews}
                            >
                              {PricingPlan.freeTablesViewsOptions.map(
                                (option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                )
                              )}
                            </Select>
                          </InputGroup>
                        </div>
                        <div className="!mt-8 pt-6 border-t-2 border-gray-200">
                          <h3 className="mb-3 font-medium uppercase text-gray-500">
                            Pricing details
                          </h3>
                          <div className="grid grid-cols-3 gap-4">
                            {selected
                              .getBillingObjectLevels()
                              .map((plan, index) => {
                                const id = `client-plan-${plan.objectType}-${index}`;
                                const name = id;
                                return (
                                  <div key={`item-${index}`}>
                                    <InputGroup
                                      label={PricingPlan.getObjectTypeDisplayName(
                                        plan.objectType
                                      )}
                                      inputId={id}
                                      className="text-sm"
                                    >
                                      <span className="absolute inset-y-0 flex items-center pl-2.5 pb-px pointer-events-none">
                                        <FontAwesomeIcon
                                          icon={solid("dollar")}
                                          className="w-3.5 h-3.5 text-gray-500"
                                        />
                                      </span>
                                      <Input
                                        id={id}
                                        name={name}
                                        type="number"
                                        min={0}
                                        step="any"
                                        autoComplete="off"
                                        required={true}
                                        onChange={(e) =>
                                          pricingPlanItemPriceChangeHandler(
                                            e,
                                            plan
                                          )
                                        }
                                        value={plan.price}
                                        placeholder="0.00"
                                        className="!pl-7"
                                      />
                                    </InputGroup>
                                  </div>
                                );
                              })}
                            <div className="pt-6">
                              <Button
                                variant="gray"
                                label="Reset to default"
                                fullWidth
                                soft
                                onClick={resetToDefaultClickHandler}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </TabPanel>
          </TabPanels>
          <div className="p-6">
            <Button
              type="submit"
              label={editClient ? "Save changes" : "Add client"}
              size="lg"
              icon={editClient ? solid("floppy-disk") : solid("plus")}
              isLoading={isSubmitting}
            />
          </div>
        </form>
      </Tabs>
    </SlideOver>
  );
}
