import { ClassAttributes, forwardRef } from "react";
import { classNames } from "../utils";
import { CheckboxProps } from "./Checkbox.types";

const Checkbox = forwardRef(
  (
    {
      label,
      hideLabel = false,
      id,
      name,
      autoComplete,
      className,
      required,
      disabled,
      readOnly,
      onChange,
      checked,
      defaultChecked,
      value,
    }: CheckboxProps,
    ref: ClassAttributes<HTMLInputElement>["ref"]
  ) => {
    const inputProps: CheckboxProps = {
      id,
      name,
      autoComplete,
      className: `h-4 w-4 rounded border-gray-300 ${
        disabled ? "text-gray-500" : "text-primary-700 focus:ring-primary-500"
      }`,
      required,
      disabled,
      readOnly,
      onChange,
      checked,
      defaultChecked,
    };

    if (value) inputProps.value = value;

    return (
      <span
        className={classNames("flex items-center", className ? className : "")}
      >
        <input ref={ref} type="checkbox" {...inputProps} />
        {label && (
          <label
            htmlFor={id}
            className={`${hideLabel ? "sr-only" : "block"} ml-2 text-gray-700`}
          >
            {label}
          </label>
        )}
      </span>
    );
  }
);

export default Checkbox;
