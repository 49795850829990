import { Fragment } from "react";
import { Tab as HeadlessTab } from "@headlessui/react";
import {
  TabItemProps,
  TabListProps,
  TabPanelProps,
  TabPanelsProps,
  TabsProps,
} from "./Tabs.types";
import { classNames } from "../utils";

export function Tabs({
  as = Fragment,
  defaultIndex = 0,
  manual = false,
  ...props
}: TabsProps) {
  return (
    <HeadlessTab.Group as={as} defaultIndex={defaultIndex} {...props} manual>
      {props.children}
    </HeadlessTab.Group>
  );
}

export function TabList({
  as = "div",
  justified = false,
  ...props
}: TabListProps) {
  return (
    <HeadlessTab.List
      as={as}
      className={classNames(
        "flex px-6 border-b border-b-gray-200 space-x-9",
        justified ? "justify-evenly" : "",
        props.className ? props.className : ""
      )}
    >
      {props.children}
    </HeadlessTab.List>
  );
}

export function TabItem({
  as = "button",
  disabled = false,
  ...props
}: TabItemProps) {
  return (
    <HeadlessTab
      className={({ selected }) =>
        classNames(
          "relative whitespace-nowrap py-4 font-medium text-sm tracking-wide uppercase outline-none",
          "after:absolute after:z-10 after:left-0 after:-bottom-0.5 after:rounded-full after:w-full after:h-[.1875rem]",
          selected
            ? "text-gray-900 after:bg-secondary-500 focus:after:bg-secondary-300 after:w-full after:scale-x-100"
            : "text-gray-600 hover:text-gray-900 focus:text-gray-900 after:transition after:bg-gray-300 after:scale-x-0 hover:after:scale-x-100 focus:after:scale-x-100"
        )
      }
      {...props}
    >
      {props.label}
    </HeadlessTab>
  );
}

export function TabPanels({ as = "div", ...props }: TabPanelsProps) {
  return (
    <HeadlessTab.Panels as={as} className={props.className}>
      {props.children}
    </HeadlessTab.Panels>
  );
}

export function TabPanel({ as = "div", unmount=true, ...props }: TabPanelProps) {
  return (
    <HeadlessTab.Panel as={as} className={props.className} unmount={unmount}>
      {props.children}
    </HeadlessTab.Panel>
  );
}
