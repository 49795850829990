import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import { ReactNode } from "react";

interface StatementPanelProps {
  title: ReactNode;
  closeClassName?: string;
  children: ReactNode;
}

function StatementPanel({
  title,
  closeClassName,
  children,
}: StatementPanelProps) {
  return (
    <Disclosure defaultOpen>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={`flex items-center w-full py-3 border-b-2 border-b-gray-300 font-medium ${
              open ? "" : closeClassName ? closeClassName : ""
            }`}
          >
            {title}
            <FontAwesomeIcon
              icon={open ? regular("circle-minus") : regular("circle-plus")}
              className="ml-auto text-gray-400 print:hidden"
            />
          </Disclosure.Button>
          <Disclosure.Panel>{children}</Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default StatementPanel;
