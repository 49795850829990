import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import { TaskFile } from "./TasksList";

interface TaskProgressItemProps {
  file: TaskFile;
  isTranslate: boolean;
}

function TaskProgressItem({ file, isTranslate }: TaskProgressItemProps) {
  const isFinished = file.processed + file.error === file.objectCount;

  function getPercentage(): number {
    return Math.round(((file.processed + file.error) / file.objectCount) * 100);
  }

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="relative w-full py-4 bg-white">
            <div className="grid grid-cols-3 gap-4 text-sm">
              <div className="text-left">
                <div className="flex items-center space-x-3" title={file.name}>
                  <FontAwesomeIcon
                    icon={regular("file-code")}
                    className="h-5 text-gray-500"
                  />
                  <span className="font-medium truncate">{file.name}</span>
                </div>
              </div>
              <div className="text-center">
                <span className="text-gray-500">
                  {(file.processed + file.error).toLocaleString()} /{" "}
                  {file.objectCount.toLocaleString()} objects
                </span>
              </div>
              <div className="text-center pr-10">
                {isFinished && (
                  <>
                    <FontAwesomeIcon
                      icon={solid("check-circle")}
                      size="lg"
                      className="mr-2 text-success-600"
                    />
                    <span className="text-success-700">Completed</span>
                  </>
                )}
                {!isFinished && (
                  <div className="inline-block w-full h-2 rounded-full bg-gray-200 overflow-hidden">
                    <div
                      className={`h-full rounded-full bg-primary-700 ${
                        !isFinished ? "animate-pulse" : ""
                      }`}
                      style={{
                        width: `${getPercentage()}%`,
                      }}
                      role="progressbar"
                      aria-label="File in progress"
                      aria-valuenow={getPercentage()}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  </div>
                )}
              </div>
            </div>
            <span className="absolute right-0 top-3.5 text-gray-500">
              <FontAwesomeIcon
                icon={open ? solid("chevron-down") : solid("chevron-right")}
                size="xs"
              />
            </span>
          </Disclosure.Button>
          <Disclosure.Panel>
            <div className="grid grid-cols-3 gap-4 py-5">
              <div className="h-fit my-auto">
                <div className="uppercase font-medium">{`${file.objectCount.toLocaleString()} objects in total`}</div>
                <dl className="flex flex-col space-y-2 text-sm">
                  <div className="flex items-center space-x-2">
                    <dt className="text-gray-500">Pending:</dt>
                    <dd className="font-medium">
                      {(
                        file.objectCount -
                        (file.processed + file.error)
                      ).toLocaleString()}
                    </dd>
                  </div>
                </dl>
              </div>
              <div className="col-span-2 flex flex-col">
                <div className="mb-2 text-sm text-truncate font-medium">
                  {file.name}
                </div>
                <dl className="flex flex-col space-y-2 text-sm">
                  <div className="flex items-center space-x-2">
                    <dt className="text-gray-500">Processed:</dt>
                    <dd className="font-medium">
                      {(file.processed + file.error).toLocaleString()}
                    </dd>
                  </div>
                  <div className="flex items-center space-x-2">
                    <dt className="text-gray-500">{`${
                      isTranslate ? "Translated:" : "Analyzed:"
                    }`}</dt>
                    <dd className="font-medium">
                      {file.processed.toLocaleString()}
                    </dd>
                  </div>
                  <div className="flex items-center space-x-2">
                    <dt className="text-gray-500">Errors:</dt>
                    <dd className="font-medium">
                      {file.error.toLocaleString()}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default TaskProgressItem;
