import { createContext, useEffect, useState } from "react";
import ConfigureApi from "../api/ConfigureApi";
import {
  clearStoredAuthToken,
  getStoredAuthToken,
  setStoredAuthToken,
} from "../utils/user-storage";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { AuthToken, User } from "../models/User";
import AuthorizationApi from "../api/AuthorizationApi";
import { useQueryClient } from "@tanstack/react-query";

interface UserContextProps {
  authUser?: User;
  setAuthUser: (param: User) => void;
  getAuthUserInfo: (email: string) => Promise<User | undefined>;
  isAuthenticated: boolean;
  setIsAuthenticated: (param: boolean) => void;
  setAuthToken: (token: AuthToken) => void;
  clearAuthToken: VoidFunction;
  signOut: VoidFunction;
}

interface UserContextStateProps {
  children: React.ReactNode;
}

export const UserContext = createContext<UserContextProps>(
  {} as UserContextProps
);

function UserContextState({ children }: UserContextStateProps) {
  const configureApi = new ConfigureApi();
  const queryClient = useQueryClient();
  const [authUser, setAuthUser] = useState<User>();
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!getStoredAuthToken()
  );

  function setAuthToken(newAuthToken: AuthToken): void {
    setStoredAuthToken(newAuthToken);
    setIsAuthenticated(!!newAuthToken);
  }

  function clearAuthToken(): void {
    clearStoredAuthToken();
    setIsAuthenticated(false);
  }

  function signOut(): void {
    clearTimeout(AuthorizationApi.refreshTokenTimeout);
    queryClient.removeQueries();
    clearAuthToken();
  }

  async function getAuthUserInfo(email: string): Promise<User | undefined> {
    try {
      const user = await configureApi.getUserByEmail(email);
      return user;
    } catch (ex) {
      Promise.reject(ex);
    }
  }

  useEffect(() => {
    const authToken = getStoredAuthToken();
    const setCurrentuser = async (userEmail: string): Promise<void> => {
      try {
        const user = await getAuthUserInfo(userEmail);
        setAuthUser(user);
      } catch (ex) {
        console.error(ex);
      }
    };

    if (isAuthenticated && authToken && !authToken.user) {
      try {
        const decoded = jwtDecode<JwtPayload & { upn?: string }>(
          authToken.token
        );
        const email = decoded.upn;
        if (email) {
          setCurrentuser(email);
        }
      } catch (ex) {
        console.error(ex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <UserContext.Provider
      value={{
        authUser,
        setAuthUser,
        getAuthUserInfo,
        isAuthenticated,
        setIsAuthenticated,
        setAuthToken,
        clearAuthToken,
        signOut,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserContextState;
