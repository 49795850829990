import { ReactNode } from "react";
import { Navigate, Route } from "react-router-dom";
import SettingsContextState from "../contexts/SettingsContext";
import LibraryFiles from "../pages/upload/LibraryFiles";
import LibraryUpload from "../pages/upload/LibraryUpload";
import ProjectRoute from "./components/ProjectRoute";
import RestrictedRoute from "./components/RestrictedRoute";

function UploadRoutes(): ReactNode {
  return (
    <>
      <Route path="/" element={<Navigate to="upload/libraries" />} />
      <Route
        path="upload/libraries"
        element={
          <RestrictedRoute>
            <ProjectRoute pageTitle="Upload">
              <SettingsContextState>
                <LibraryUpload />
              </SettingsContextState>
            </ProjectRoute>
          </RestrictedRoute>
        }
      />
      <Route
        path="upload/library-files/:libraryId"
        element={
          <RestrictedRoute>
            <LibraryFiles />
          </RestrictedRoute>
        }
      />
    </>
  );
}

export default UploadRoutes;
