import { useQuery, useQueryClient } from "@tanstack/react-query";
import AnalysisReportResults from "../analyze/AnalysisReportResults";
import AnalyzeDashboard from "./AnalyzeDashboard";
import TasksApi from "../../api/TasksApi";
import { useContext, useEffect, useState } from "react";
import { ProjectContext } from "../../contexts/ProjectContext";
import { useLocation, useParams } from "react-router-dom";
import { TaskData } from "../translate/components/TasksList";
import { PageLoaderSpinner } from "../../_ui-kit/PageLoderSpinner/PageLoaderSpinner";
import { MasterPage } from "../../_ui-kit/MasterPage/MasterPage";
import AnalysisRationalization from "./AnalysisRationalization";
import { UserContext } from "../../contexts/UserContext";
import { ClientContext } from "../../contexts/ClientContext";

interface SqlAnalyzerReport {
  id: string;
  reference: string;
  source: string;
  target: string;
  processingTime: number;
  stats: {
    lines: number;
    objects: number;
    exceptions?: number;
    exceptionCategories?: number;
    translationPercentage: number;
    slaMet: boolean;
  };
  statements: {
    type: string;
    statementCount: number;
    lineCount: number;
    objectPercentage: number;
    linePercentage: number;
  }[];
  exceptionCategories?: {
    category: string;
    count: number;
  }[];
  dateCreated: string;
  createdBy: string;
}

interface EtlAnalyzerReport {
  reference: string;
  stats: {
    lines: number;
    files: number;
    controlFlows: number;
    controlFlowActivities: number;
    dataFlows: number;
    dataFlowServer: number;
    dataFlowSteps: number;
    sources: number;
    sinks: number;
    exceptions: number;
    exceptionTypes: number;
    slaMet: boolean;
  };
  sourceSinkList: EtlAnalyzerCategory[];
  controlFlowSteps: EtlAnalyzerCategory[];
  dataFlowSteps: EtlAnalyzerCategory[];
  exceptions: EtlAnalyzerCategory[];
  dateCreated: string;
  createdBy: string;
  sourcePlatform: string;
  taskId: string;
  time: number;
  dashboardData: {
    etlSummary: {
      automationPercentage: number;
      simplificationPercentage: number;
      visualizeObjectsCount: number;
    };
    patternDistribution: {
      category: string;
      count: number;
      percentage: number;
    }[];
    embeddedCodeSummary: {
      category: string;
      scriptTaskAttributes: {
        name: string;
        count: number;
      }[];
    }[];
    eltTargetJobsDistribution: {
      category: string;
      description: string;
      details: ETLTargetJobsDistributionDetails;
    }[];
  };
}

interface EtlAnalyzerCategory {
  name: string;
  count: number;
}

interface ETLTargetJobsDistributionDetails {
  egress: number;
  ieTransform: number;
  ingest: number;
  ingestEgress: number;
  transform: number;
  untouched: number;
}

function AnalyzeResults() {
  const tasksApi = new TasksApi();
  const queryClient = useQueryClient();
  const location = useLocation();
  const locationState = location.state;
  const initial: TaskData | undefined = locationState?.task;
  const { authUser } = useContext(UserContext);
  const { currentClient } = useContext(ClientContext);
  const { currentProject } = useContext(ProjectContext);
  const { taskId } = useParams();
  const [refetchInterval, setRefetchInterval] = useState(1000);
  const [isSqlReport, setIsSqlReport] = useState<boolean>();

  const { data: currentTask, isLoading: isLoadingCurrentTask } = useQuery(
    [
      `task${!currentProject || !taskId ? "-invalid" : ""}`,
      {
        projectId: currentProject?.uuid,
        taskId,
      },
    ],
    async () => {
      const projectId = currentProject?.uuid || "";
      const id = taskId || "";
      const response = await tasksApi.getTask(projectId, id);
      const task: TaskData = await response.json();
      return task;
    },
    {
      staleTime: 0,
      placeholderData: initial,
      refetchInterval: refetchInterval,
      enabled: !!currentProject && !!taskId,
      onSuccess: (data) => {
        queryClient.removeQueries(["task-invalid"]);
        if (
          data.processed !== undefined &&
          data.processed + data.errorCount === data.size
        )
          setRefetchInterval(0);
      },
      onError: (ex) => {
        console.log(ex);
        setRefetchInterval(0);
      },
    }
  );

  const {
    data: sqlAnalyzerReport,
    isInitialLoading: isLoadingSqlAnalyzerReport,
  } = useQuery(
    [
      `sql-analyzer-report${!currentProject || !taskId ? "-invalid" : ""}`,
      {
        projectId: currentProject?.uuid,
        taskId: currentTask?.uuid,
      },
    ],
    async () => {
      const projectId = currentProject?.uuid || "";
      const id = currentTask?.uuid || "";
      const response = await tasksApi.getAnalyzerReport(projectId, id);
      const tasks: SqlAnalyzerReport = await response.json();
      return tasks;
    },
    {
      enabled: !!currentProject && !!currentTask && isSqlReport === true,
      onSuccess: () => {
        queryClient.removeQueries(["sql-analyzer-report-invalid"]);
      },
      onError: (ex) => {
        console.log(ex);
      },
    }
  );

  const {
    data: etlAnalyzerReport,
    isInitialLoading: isLoadingEtlAnalyzerReport,
  } = useQuery(
    [
      `etl-analyzer-report${!currentProject || !taskId ? "-invalid" : ""}`,
      {
        projectId: currentProject?.uuid,
        taskId: currentTask?.uuid,
      },
    ],
    async () => {
      const projectId = currentProject?.uuid || "";
      const id = currentTask?.uuid || "";
      const response = await tasksApi.getEtlAnalyzerReport(projectId, id);
      const tasks: EtlAnalyzerReport = await response.json();
      return tasks;
    },
    {
      enabled: !!currentProject && !!currentTask && isSqlReport === false,
      onSuccess: () => {
        queryClient.removeQueries(["etl-analyzer-report-invalid"]);
      },
      onError: (ex) => {
        console.log(ex);
      },
    }
  );

  useEffect(() => {
    if (currentTask) {
      setIsSqlReport(currentTask.sourceType === "SQL");
    }
  }, [currentTask]);

  return (
    <MasterPage heading="Results" useBackButton>
      {authUser &&
        currentClient &&
        currentProject &&
        currentTask &&
        !isLoadingCurrentTask && (
          <>
            {currentTask.sourceType === "ETL" &&
              etlAnalyzerReport &&
              !location.pathname.includes("/report-results/migrate") &&
              !location.pathname.includes("/report-results/rationalize") && (
                <AnalyzeDashboard
                  authUser={authUser}
                  currentClient={currentClient}
                  currentProject={currentProject}
                  currentTask={currentTask}
                  etlAnalyzerReport={etlAnalyzerReport}
                />
              )}
            {location.pathname.includes("/report-results/rationalize") &&
              etlAnalyzerReport && (
                <AnalysisRationalization
                  authUser={authUser}
                  currentClient={currentClient}
                  currentProject={currentProject}
                  currentTask={currentTask}
                  etlAnalyzerReport={etlAnalyzerReport}
                />
              )}
            {((currentTask.sourceType === "SQL" && sqlAnalyzerReport) ||
              (location.pathname.includes("/report-results/migrate") &&
                etlAnalyzerReport)) && (
              <AnalysisReportResults
                currentTask={currentTask}
                sqlAnalyzerReport={sqlAnalyzerReport}
                etlAnalyzerReport={etlAnalyzerReport}
              />
            )}
          </>
        )}
      {((isLoadingCurrentTask && !currentTask) ||
        (isLoadingSqlAnalyzerReport && !sqlAnalyzerReport) ||
        (isLoadingEtlAnalyzerReport && !etlAnalyzerReport)) && (
        <PageLoaderSpinner />
      )}
    </MasterPage>
  );
}

export type {
  SqlAnalyzerReport,
  EtlAnalyzerReport,
  ETLTargetJobsDistributionDetails,
};
export default AnalyzeResults;
