import { ReactNode } from "react";
import { Route } from "react-router-dom";
import RestrictedRoute from "./components/RestrictedRoute";
import Analyze from "../pages/analyze/Analyze";
import AnalyzeWavePlanner from "../pages/analyze/WavePlanner";
import AnalyzeDataFlow from "../pages/analyze/DataFlow";
import AnalizeWaveLineage from "../pages/analyze/WaveLineage";
import AnalizeScopeOverview from "../pages/analyze/ScopeOverview";
import AnalizeSequenceJobsOverview from "../pages/analyze/SequenceJobsOverview";
import AnalizeParallelJobsOverview from "../pages/analyze/ParallelJobsOverview";
import AnalizeDataOverview from "../pages/analyze/DataOverview";
import DataPlatforms from "../pages/analyze/DataPlatforms";
import Code from "../pages/analyze/Code";
import AnalysisList from "../pages/analyze/AnalysisList";
import AnalysisProgress from "../pages/analyze/AnalysisProgress";
import AnalyzeResults from "../pages/analyze/AnalyzeResults";
import { isCrawlerCore, isShiftCore } from "../env";
import ProjectRoute from "./components/ProjectRoute";
import SettingsContextState from "../contexts/SettingsContext";

function AnalyzeRoutes(): ReactNode {
  return (
    <>
      {isCrawlerCore() && (
        <>
          <Route
            path="analyze"
            element={
              <RestrictedRoute>
                <Analyze />
              </RestrictedRoute>
            }
          >
            <Route path="data-overview" element={<AnalizeDataOverview />} />
            <Route path="wave-planner" element={<AnalyzeWavePlanner />} />
            <Route path="data-platforms" element={<DataPlatforms />} />
            <Route path="code" element={<Code />} />
          </Route>
          <Route
            path="analyze/data-overview/scope-overview"
            element={
              <RestrictedRoute>
                <AnalizeScopeOverview />
              </RestrictedRoute>
            }
          />
          <Route
            path="analyze/data-overview/sequence-jobs"
            element={
              <RestrictedRoute>
                <AnalizeSequenceJobsOverview />
              </RestrictedRoute>
            }
          />
          <Route
            path="analyze/data-overview/parallel-jobs"
            element={
              <RestrictedRoute>
                <AnalizeParallelJobsOverview />
              </RestrictedRoute>
            }
          />
          <Route
            path="analyze/data-overview/data-flow/:type"
            element={
              <RestrictedRoute>
                <AnalyzeDataFlow />
              </RestrictedRoute>
            }
          />
          {[
            ":resourceType/:resourceId",
            ":waveId/:resourceType/:resourceId",
          ].map((path, index) => (
            <Route
              key={index}
              path={`analyze/wave-lineage/${path}`}
              element={
                <RestrictedRoute>
                  <AnalizeWaveLineage />
                </RestrictedRoute>
              }
            />
          ))}
        </>
      )}
      {isShiftCore() && (
        <>
          <Route
            path="analyze/list"
            element={
              <RestrictedRoute>
                <ProjectRoute pageTitle="Analyze">
                  <AnalysisList />
                </ProjectRoute>
              </RestrictedRoute>
            }
          />
          <Route
            path="analyze/progress/:taskId"
            element={
              <RestrictedRoute>
                <AnalysisProgress />
              </RestrictedRoute>
            }
          />
          {[
            "analyze/report-results/:taskId",
            "analyze/report-results/migrate/:taskId",
            "analyze/report-results/rationalize/:taskId",
          ].map((path, index) => (
            <Route
              path={path}
              element={
                <RestrictedRoute>
                  <SettingsContextState>
                    <AnalyzeResults />
                  </SettingsContextState>
                </RestrictedRoute>
              }
              key={`route-${index}`}
            />
          ))}
        </>
      )}
    </>
  );
}

export default AnalyzeRoutes;
