import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Button } from "../../../_ui-kit";
import { useState, MouseEvent } from "react";

interface CopyCodeToClipboardButtonProps {
  clipboard: string[];
}

export default function CopyCodeToClipboardButton({
  clipboard,
}: CopyCodeToClipboardButtonProps) {
  const [icon, setIcon] = useState(solid("copy"));
  const [label, setLabel] = useState("Copy");
  const [disabled, setDisabled] = useState(false);

  function copyToClipboardClickHandler(e: MouseEvent<HTMLButtonElement>): void {
    const btnIcon = icon;
    const btnText = label;
    const text = clipboard.join("");
    navigator.clipboard.writeText(text);
    setIcon(solid("check-double"));
    setLabel("Copied!");
    setDisabled(true);
    setTimeout(() => {
      setIcon(btnIcon);
      setLabel(btnText);
      setDisabled(false);
    }, 2000);
  }

  return (
    <Button
      variant="white"
      label={label}
      size="xs"
      icon={icon}
      onClick={copyToClipboardClickHandler}
      disabled={disabled}
    />
  );
}
