import BaseApi from "./BaseApi";

export default class AnalyzeApi extends BaseApi {
  apiGetLineage = `${this.baseUrl}/wp/{{projectId}}/lineage`;
  apiGetJobLineageSummary = `${this.baseUrl}/wp/{{projectId}}/joblineagesummary`;
  apiGetTableLineageSummary = `${this.baseUrl}/wp/{{projectId}}/tablelineagesummary`;
  apiGetJobLineage = `${this.baseUrl}/wp/{{projectId}}/joblineage`;
  apiGetApplicationWavePreview = `${this.baseUrl}/wp/{{projectId}}/wave/joblineage/application/{{applicationId}}`;
  apiGetTableWavePreview = `${this.baseUrl}/wp/{{projectId}}/wave/joblineage/table/{{tableId}}`;
  apiAddApplicationWave = this.apiGetApplicationWavePreview;
  apiAddTableWave = this.apiGetTableWavePreview;
  apiGetWavePlanSummary = `${this.baseUrl}/wp/{{projectId}}/summary`;
  apiGetWavePlanSummaryDetails = `${this.baseUrl}/wp/{{projectId}}/wave`;
  apiGetWaveMetadata = `${this.baseUrl}/wp/{{projectId}}/wave/{{waveId}}/metadata`;
  apiRemoveLastWave = `${this.baseUrl}/wp/{{projectId}}/wave/recent`;
  apiRemoveAllWaves = `${this.baseUrl}/wp/{{projectId}}/wave`;
  apiDownloadWaves = `${this.baseUrl}/wp/{{projectId}}/wave/download`;
  apiGetProjectCategories = `${this.baseUrl}/dashboard/{{projectId}}/category`;
  apiGetProjectSubcategories = `${this.baseUrl}/dashboard/{{projectId}}/subcategory`;
  apiGetApplicationJobSummary = `${this.baseUrl}/dashboard/{{projectId}}/applicationjobsummary`;
  apiGetEtlPlatformSummaryFromProject = `${this.baseUrl}/dashboard/{{projectId}}/etlplatforms`;
  apiGetParallelJobSummary = `${this.baseUrl}/dashboard/{{projectId}}/paralleljobsummary`;
  apiGetSequenceJobSummary = `${this.baseUrl}/dashboard/{{projectId}}/sequencejobsummary`;

  getJobLineageSummary(
    projectId: string,
    type: number,
    applicationId?: string,
    schedulerId?: string,
    jobId?: string,
    tableId?: string
  ): Promise<Response> {
    const queryString: any = {
      type: type,
    };
    if (applicationId && applicationId !== "all")
      queryString.applicationId = applicationId;
    if (schedulerId && schedulerId !== "all")
      queryString.schedulerId = schedulerId;
    if (jobId && jobId !== "all") queryString.jobId = jobId;
    else if (tableId && tableId !== "all") queryString.tableId = tableId;
    const promise = this.doFetch(
      `${this.apiGetJobLineageSummary.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}${
        this.isObjEmpty(queryString)
          ? ""
          : `?${this.objToQueryString(queryString)}`
      }`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getTableLineageSummary(
    projectId: string,
    type: number,
    applicationId?: string,
    jobId?: string,
    tableId?: string
  ): Promise<Response> {
    const queryString: any = {
      type: type,
    };
    if (applicationId) queryString.applicationId = applicationId;
    if (jobId) queryString.jobId = jobId;
    else if (tableId) queryString.tableId = tableId;
    const promise = this.doFetch(
      `${this.apiGetTableLineageSummary.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}${
        this.isObjEmpty(queryString)
          ? ""
          : `?${this.objToQueryString(queryString)}`
      }`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getTableLineage(
    projectId: string,
    tableId?: string,
    jobId?: string
  ): Promise<Response> {
    const queryString: any = {};
    if (tableId && tableId !== "all") queryString.tableId = tableId;
    if (jobId && jobId !== "all") queryString.jobId = jobId;
    const promise = this.doFetch(
      `${this.apiGetLineage.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}${
        this.isObjEmpty(queryString)
          ? ""
          : `?${this.objToQueryString(queryString)}`
      }`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getJobLineage(
    projectId: string,
    applicationId: string,
    schedulerId?: string,
    jobId?: string,
    tableId?: string
  ): Promise<Response> {
    const queryString: any = {};
    if (applicationId !== "all") queryString.applicationId = applicationId;
    if (schedulerId && schedulerId !== "all")
      queryString.schedulerId = schedulerId;
    if (jobId && jobId !== "all") queryString.jobId = jobId;
    if (tableId && tableId !== "all") queryString.tableId = tableId;
    const promise = this.doFetch(
      `${this.apiGetJobLineage.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}${
        this.isObjEmpty(queryString)
          ? ""
          : `?${this.objToQueryString(queryString)}`
      }`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getApplicationWavePreview(
    projectId: string,
    applicationId: string
  ): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetApplicationWavePreview
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{applicationId}}", encodeURIComponent(applicationId))}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getTableWavePreview(projectId: string, tableId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetTableWavePreview
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{tableId}}", encodeURIComponent(tableId))}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  addApplicationToWavePlan(
    projectId: string,
    applicationId: string
  ): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiAddApplicationWave
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{applicationId}}", encodeURIComponent(applicationId))}`,
      {
        method: "POST",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  addTableToWavePlan(projectId: string, tableId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiAddTableWave
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{tableId}}", encodeURIComponent(tableId))}`,
      {
        method: "POST",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getWavePlanSummary(projectId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetWavePlanSummary.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getWavePlanSummaryDetails(projectId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetWavePlanSummaryDetails.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getWaveMetadata(projectId: string, waveId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetWaveMetadata
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{waveId}}", encodeURIComponent(waveId))}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  removeLastWave(projectId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiRemoveLastWave.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}`,
      {
        method: "DELETE",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  removeAllWaves(projectId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiRemoveAllWaves.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}`,
      {
        method: "DELETE",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  downloadWaves(projectId: string, waveId?: string): Promise<Response> {
    const queryString: any = {};
    if (waveId) queryString.waveId = waveId;

    const promise = this.doFetch(
      `${this.apiDownloadWaves.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}${
        this.isObjEmpty(queryString)
          ? ""
          : `?${this.objToQueryString(queryString)}`
      }`,
      {
        method: "GET",
        headers: this.getAuthOctetStreamHeaders(),
      }
    );
    return promise;
  }

  getProjectCategories(projectId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetProjectCategories.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getProjectSubCategories(projectId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetProjectSubcategories.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getEtlPlatformsJobSummary(
    projectId: string,
    etlplatform?: string
  ): Promise<Response> {
    const queryString: any = {};
    if (etlplatform) queryString.etlplatform = etlplatform;

    const promise = this.doFetch(
      `${this.apiGetApplicationJobSummary.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}${
        this.isObjEmpty(queryString)
          ? ""
          : `?${this.objToQueryString(queryString)}`
      }`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getEtlPlatformSummaryFromProject(projectId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetEtlPlatformSummaryFromProject.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getParallelJobSummary(projectId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetParallelJobSummary.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getSequenceJobSummary(projectId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetSequenceJobSummary.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }
}
