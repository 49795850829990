import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseButtonProps } from "./CloseButton.types";

export default function CloseButton({ onClose }: CloseButtonProps) {
  return (
    <button
      type="button"
      className="flex rounded-sm bg-white p-0.5 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
      onClick={() => onClose(false)}
    >
      <span className="sr-only">Close panel</span>
      <FontAwesomeIcon
        icon={regular("xmark")}
        className="w-6 h-6"
        aria-hidden={true}
      />
    </button>
  );
}
