import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure, Transition } from "@headlessui/react";
import { CollapsibleListItemProps } from "./CollapsibleListItem.types";

export function CollapsibleListItem({
  title,
  subTitle,
  last = false,
  ...props
}: CollapsibleListItemProps) {
  return (
    <Disclosure as="div" className="bg-white">
      {({ open }) => (
        <>
          <Disclosure.Button className="flex items-start w-full pl-6 pt-4 text-left hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500">
            <FontAwesomeIcon
              icon={props.icon}
              size="lg"
              className="mr-4 my-0.5 text-gray-400"
              aria-hidden={true}
            />
            <div
              className={`min-w-0 flex items-center justify-between grow text-base pr-6 pb-4 ${
                last ? "" : "border-b"
              }`}
            >
              <span className="mr-6 leading-6 text-sm text-gray-700 font-medium truncate">
                {title}
              </span>
              {subTitle && (
                <span className="ml-auto mr-8 text-sm text-gray-500 whitespace-nowrap">
                  {subTitle}
                </span>
              )}
              <FontAwesomeIcon
                icon={solid("chevron-right")}
                rotation={open ? 90 : undefined}
                size="xs"
                className="transition-transform duration-100 ease-in text-gray-500"
                aria-hidden={true}
              />
            </div>
          </Disclosure.Button>
          <Transition
            as="div"
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="flex items-center justify-between pl-14 pr-6 text-sm border-b">
              {props.children}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
