import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { Button } from "../../_ui-kit";
import { PageTransition } from "../../_ui-kit/PageTransition";
import { ConfigureContext } from "../../contexts/ConfigureContext";
import { ClientPricingPlan } from "../../models/Client";
import PricingPlansListRow, {
  PricingPlansListRowSkeleton,
} from "./components/PricingPlansListRow";
import PricingPlanEditSlider from "./components/PricingPlanEditSlider";
import Modal, { ModalContent, ModalFooter } from "../../_ui-kit/Modal/Modal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import SettingsApi from "../../api/SettingsApi";
import { AppError } from "../../App";
import { Notification, showNotification } from "../../_ui-kit/Notification";

type SortDirection = "desc" | "asc";

export default function PricingPlans() {
  const settingsApi = new SettingsApi();
  const queryClient = useQueryClient();
  const [openSlideOver, setOpenSlideOver] = useState(false);
  const [sliderTitle, setSliderTitle] = useState("");
  const { pricingPlansList } = useContext(ConfigureContext);
  const [plansListQuery, setPlansListQuery] = useState("");
  const [sortDirection, setSortDirection] = useState<SortDirection>("desc");
  const [editPricingPlan, setEditPricingPlan] = useState<ClientPricingPlan>();
  const [deletePricingPlan, setDeletePricingPlan] =
    useState<ClientPricingPlan>();
  const [updatePlan, setUpdatePlan] = useState(false);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    useState(false);
  const [isDeletingpricingPlan, setIsDeletingPricingPlan] = useState(false);
  const [pageErrorsList, setPageErrorsList] = useState<AppError[]>([]);

  const filteredPlansList =
    plansListQuery === ""
      ? pricingPlansList
      : pricingPlansList?.filter((item) => {
          return item.planType
            .toLowerCase()
            .includes(plansListQuery.toLowerCase());
        });

  const deletePricingPlanMutation = useMutation(
    (currentPricingPlan: any) => {
      return settingsApi.deletePricingPlan(currentPricingPlan.planId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["pricing-plans-list"]);
        queryClient.invalidateQueries(["users-list"]);
        queryClient.invalidateQueries(["clients-list"]);
        queryClient.invalidateQueries(["clients-list-context"]);
        queryClient.invalidateQueries(["projects-list"]);
        queryClient.invalidateQueries(["projects-list-context"]);
        setIsDeletingPricingPlan(false);
        closeDeletePricingPlanDialog(false);
      },
      onError: (error: any) => {
        setIsDeletingPricingPlan(false);
        closeDeletePricingPlanDialog(false);
        setPageErrorsList((prev) => [
          ...prev,
          {
            id: Math.random().toString(),
            error: "Error deliting pricing plan",
            description: error,
          },
        ]);
      },
    }
  );

  function sortPricingPlans(a: ClientPricingPlan, b: ClientPricingPlan) {
    if (sortDirection === "desc") {
      if (a.planType < b.planType) return -1;
      if (a.planType > b.planType) return 1;
    } else {
      if (a.planType > b.planType) return -1;
      if (a.planType < b.planType) return 1;
    }
    return 0;
  }

  function sortPricingPlansClickHandler(): void {
    setSortDirection(sortDirection === "desc" ? "asc" : "desc");
  }

  function addPricingPlanClickHandler(): void {
    setSliderTitle("New pricing plan");
    setUpdatePlan(false);
    setOpenSlideOver(true);
  }

  function closeDeletePricingPlanDialog(close: boolean): void {
    setOpenDeleteConfirmationDialog(close);
  }

  function editPricingPlanClickHandler(pricingPlan: ClientPricingPlan): void {
    if (pricingPlansList) {
      setSliderTitle("Edit pricing plan");
      setUpdatePlan(true);
      setEditPricingPlan(pricingPlan);
      setOpenSlideOver(true);
    }
  }

  function deletePricingPlanClickHandler(pricingPlan: ClientPricingPlan): void {
    setDeletePricingPlan(pricingPlan);
    setOpenDeleteConfirmationDialog(true);
  }

  function deleteClickHandler(): void {
    const deletePlan = (planId: string) => {
      deletePricingPlanMutation.mutate({
        planId,
      });
    };
    if (deletePricingPlan) {
      setIsDeletingPricingPlan(true);
      deletePlan(deletePricingPlan.planId);
    }
  }

  return (
    <PageTransition>
      <div id="content" className="px-6 py-9 xl:max-w-5xl xl:mx-auto">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <h2 className="flex items-center text-lg text-gray-900 font-medium">
              Pricing plans
              {!pricingPlansList && (
                <span className="inline-flex px-3 ml-1 text-gray-300">
                  <FontAwesomeIcon
                    icon={solid("spinner")}
                    spin={true}
                    className="w-6 h-6"
                    aria-hidden={true}
                  />
                </span>
              )}
              {pricingPlansList && (
                <span className="rounded-full bg-gray-200 px-3 py-1 ml-3 text-sm text-gray-700 font-bold">
                  {pricingPlansList.length}
                </span>
              )}
            </h2>
            <Button
              label="Add plan"
              icon={solid("plus")}
              onClick={addPricingPlanClickHandler}
            />
          </div>
          <div className="flex items-center space-x-4">
            <div>
              <label htmlFor="searchPricingPlans" className="sr-only">
                Search plans
              </label>
              <div className="flex rounded-md shadow-sm">
                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3.5">
                    <FontAwesomeIcon
                      icon={regular("search")}
                      className="h-3.5 w-3.5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    id="searchPricingPlans"
                    className="block w-full rounded-none rounded-l-md border-gray-300 pl-9 focus:border-primary-500 focus:ring-primary-500 text-sm"
                    placeholder="Search"
                    onChange={(event) => setPlansListQuery(event.target.value)}
                    disabled={!pricingPlansList}
                  />
                </div>
                <button
                  type="button"
                  className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                  onClick={sortPricingPlansClickHandler}
                  disabled={!pricingPlansList}
                >
                  <FontAwesomeIcon
                    icon={
                      sortDirection === "desc"
                        ? solid("arrow-down-short-wide")
                        : solid("arrow-up-short-wide")
                    }
                    className="h-4 w-4 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>Sort</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-col">
          <div className="overflow-x-auto -mx-1">
            <div className="inline-block min-w-full py-2 align-middle px-1">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 text-left text-sm font-medium pr-3 pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-medium"
                      >
                        Cycle
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-medium"
                      >
                        Access fee
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-medium"
                      >
                        Created date
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-medium"
                      >
                        Created user
                      </th>
                      <th scope="col" className="relative py-3.5 pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {filteredPlansList &&
                      filteredPlansList
                        .sort(sortPricingPlans)
                        .map((plan) => (
                          <PricingPlansListRow
                            key={plan.planId}
                            pricingPlan={plan}
                            editPricingPlanClickHandler={
                              editPricingPlanClickHandler
                            }
                            deletePricingPlanClickHandler={
                              deletePricingPlanClickHandler
                            }
                          />
                        ))}
                    {!pricingPlansList &&
                      [...Array(5)].map((_r, i) => (
                        <PricingPlansListRowSkeleton key={`skeleton-${i}`} />
                      ))}
                    {filteredPlansList && filteredPlansList.length < 1 && (
                      <tr>
                        <td
                          className="px-6 py-4 text-sm text-gray-500"
                          colSpan={6}
                        >
                          No plans found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <PricingPlanEditSlider
          openSlideOver={openSlideOver}
          setOpenSlideOver={setOpenSlideOver}
          sliderTitle={sliderTitle}
          editPricingPlan={editPricingPlan}
          setEditPricingPlan={setEditPricingPlan}
          update={updatePlan}
        />
        <Modal
          title="Delete confirmation"
          size="lg"
          show={openDeleteConfirmationDialog}
          onClose={
            isDeletingpricingPlan ? () => {} : closeDeletePricingPlanDialog
          }
        >
          <ModalContent>
            <div className="flex items-start">
              <div className="w-10 h-10 mr-4 flex items-center justify-center shrink-0 bg-danger-100 rounded-full text-danger-600">
                <FontAwesomeIcon
                  icon={regular("triangle-exclamation")}
                  size="lg"
                />
              </div>
              <div>
                <p className="text-gray-500">
                  Are you sure you want to delete{" "}
                  <strong className="text-gray-900">
                    {deletePricingPlan?.planType}
                  </strong>{" "}
                  plan? All of your data will be permanently removed from our
                  servers forever. This action cannot be undone.
                </p>
              </div>
            </div>
          </ModalContent>
          <ModalFooter>
            <div className="flex space-x-4 pl-14">
              <Button
                variant="danger"
                type="submit"
                label="Delete pricing plan"
                icon={solid("trash-alt")}
                isLoading={isDeletingpricingPlan}
                onClick={deleteClickHandler}
              />
              <Button
                label="Cancel"
                icon={solid("xmark-circle")}
                variant="white"
                disabled={isDeletingpricingPlan}
                onClick={() => closeDeletePricingPlanDialog(false)}
              />
            </div>
          </ModalFooter>
        </Modal>
        {pageErrorsList.length > 0 &&
          pageErrorsList.map((error, index, list) => {
            return showNotification(
              <Notification
                key={`error.${error.id}`}
                variant="danger"
                title={error.error}
                subTitle={error.description}
                onClose={() =>
                  setPageErrorsList(
                    list.filter((_listItem, newIndex) => newIndex !== index)
                  )
                }
              />
            );
          })}
      </div>
    </PageTransition>
  );
}
