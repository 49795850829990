import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import TasksApi from "../../../api/TasksApi";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { UserContext } from "../../../contexts/UserContext";
import TaskListRow, { TasksListRowSkeleton } from "./TasksListRow";

enum TaskStatus {
  CREATED = "CREATED",
  SUBMITTED = "SUBMITTED",
  ANALYZING = "ANALYZING",
  TRANSLATING = "TRANSLATING",
  TESTING = "TESTING",
  FINISHED = "FINISHED",
  CANCELLED = "CANCELLED",
  ERROR = "ERROR",
}

interface TaskData {
  id: number;
  dateCreated: string;
  lastUpdated: string;
  createdBy: string;
  lastUpdatedBy: string;
  name: string;
  projectId: number;
  sourceType: "SQL" | "ETL";
  projectPlatformId: string;
  projectPlatformName: string;
  sourcePlatformName: string;
  sourcePlatformDescription: string;
  targetPlatformId: number;
  targetPlatformName: string;
  targetPlatformDescription: string;
  taskStatus: TaskStatus;
  size: number;
  processed?: number;
  errorCount: number;
  uuid: string;
  analyzed: boolean;
  translated: boolean;
  tested: boolean;
  slaMet: boolean;
  analyzeSlaMet: boolean;
  etlDuration?: {
    analysisDuration: number;
    translationDuration: number;
  };
  embeddedSourcePlatformDescription?: string;
  embeddedSourcePlatformName?: string;
  embeddedTargetPlatformDescription?: string;
  embeddedTargetPlatformName?: string;
  fileProgressList?: TaskFile[];
}

interface TaskFile {
  id: number;
  name: string;
  objectCount: number;
  processed: number;
  error: number;
}

interface TaskErrors {
  errors: ErrorDetail[];
  fileName: string;
  objectId: string;
}

interface ErrorDetail {
  error: string;
  lineNo: number;
}

interface TaskTranslateErrors {
  taskId: string;
  analyzeExceptionDetailsList: TaskTranslateError[];
  translationErrorList: TaskTranslateError[];
  missingObjectErrorList: TaskTranslateError[];
  testingErrorList: TaskTranslateError[];
}

interface TaskTranslateError {
  fileName: string;
  count: number;
  errorContent: string;
  errorType:
    | "TRANSLATION_ERROR"
    | "INLINE_EXCEPTION"
    | "TESTING_ERROR"
    | "MISSING_OBJ";
}

type SortDirection = "desc" | "asc";

function TasksList() {
  const tasksApi = new TasksApi();
  const queryClient = useQueryClient();
  const location = useLocation();
  const isTranslate = location.pathname.includes("/translate");
  const [tasksListQuery, setTasksListQuery] = useState("");
  const [sortDirection, setSortDirection] = useState<SortDirection>("desc");
  const [refetchInterval, setRefetchInterval] = useState(1000);
  const { currentProject } = useContext(ProjectContext);
  const { authUser } = useContext(UserContext);

  const { data: tasksList, isLoading: isLoadingTasks } = useQuery(
    [
      `tasks-list${!currentProject ? "-invalid" : ""}`,
      {
        projectId: currentProject?.uuid,
      },
    ],
    async () => {
      const projectId = currentProject?.uuid || "";
      const response = await tasksApi.getTasks(projectId);
      const tasks: TaskData[] = await response.json();
      return tasks;
    },
    {
      staleTime: 0,
      refetchInterval: refetchInterval,
      enabled: !!currentProject,
      select: (tasks) => {
        return tasks.filter((task) => {
          if (isTranslate) {
            if (task.analyzed) {
              if (task.translated) {
                if (task.taskStatus !== TaskStatus.CANCELLED) return true;
              } else {
                if (task.taskStatus === TaskStatus.SUBMITTED) return true;
                if (task.taskStatus === TaskStatus.TRANSLATING) return true;
              }
            }
          } else {
            if (task.analyzed) {
              if (task.taskStatus !== TaskStatus.CANCELLED) return true;
            } else {
              if (!task.translated) {
                if (task.taskStatus === TaskStatus.SUBMITTED) return true;
                if (task.taskStatus === TaskStatus.ANALYZING) return true;
              }
            }
          }
          return false;
        });
      },
      onSuccess: () => {
        queryClient.removeQueries(["tasks-list-invalid"]);
      },
      onError: (ex) => {
        console.log(ex);
        setRefetchInterval(0);
      },
    }
  );

  const filteredTasksList =
    tasksListQuery === ""
      ? tasksList
      : tasksList?.filter((item) => {
          return (
            item.projectPlatformName
              .toLowerCase()
              .includes(tasksListQuery.toLowerCase()) ||
            item.uuid === tasksListQuery
          );
        });

  function sortTasksClickHandler(): void {
    setSortDirection(sortDirection === "desc" ? "asc" : "desc");
  }

  function sortTasks(a: TaskData, b: TaskData) {
    if (sortDirection === "asc") {
      if (a.dateCreated < b.dateCreated) return -1;
      if (a.dateCreated > b.dateCreated) return 1;
    } else {
      if (a.dateCreated > b.dateCreated) return -1;
      if (a.dateCreated < b.dateCreated) return 1;
    }
    return 0;
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <h2 className="flex items-center text-lg text-gray-900 font-medium">
            {isTranslate ? "Translations list" : "Analysis list"}
            {isLoadingTasks && !tasksList && (
              <span className="inline-flex px-3 ml-1 text-gray-300">
                <FontAwesomeIcon
                  icon={solid("spinner")}
                  spin={true}
                  className="w-6 h-6"
                  aria-hidden={true}
                />
              </span>
            )}
            {tasksList && (
              <span className="rounded-full bg-gray-200 px-3 py-1 ml-3 text-sm text-gray-700 font-bold">
                {tasksList?.length}
              </span>
            )}
          </h2>
        </div>
        <div>
          <label htmlFor="searchTasks" className="sr-only">
            Search tasks
          </label>
          <div className="flex rounded-md shadow-sm">
            <div className="relative flex flex-grow items-stretch focus-within:z-10">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3.5">
                <FontAwesomeIcon
                  icon={regular("search")}
                  className="h-3.5 w-3.5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="text"
                id="searchTasks"
                className="block w-full rounded-none rounded-l-md border-gray-300 pl-9 focus:border-primary-500 focus:ring-primary-500 text-sm"
                placeholder="Search"
                onChange={(event) => setTasksListQuery(event.target.value)}
                disabled={!tasksList}
              />
            </div>
            <button
              type="button"
              className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
              onClick={sortTasksClickHandler}
              disabled={!tasksList}
            >
              <FontAwesomeIcon
                icon={
                  sortDirection === "desc"
                    ? solid("arrow-down-short-wide")
                    : solid("arrow-up-short-wide")
                }
                className="h-4 w-4 text-gray-400"
                aria-hidden="true"
              />
              <span>Sort</span>
            </button>
          </div>
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        <div className="-mx-1">
          <div className="inline-block min-w-full py-2 align-middle px-1">
            <div className="bg-white rounded-lg shadow ring-1 ring-black ring-opacity-5">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pr-3 pl-6 bg-gray-50 rounded-tl-lg text-left text-sm font-medium"
                    >
                      Library
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 bg-gray-50 text-center text-sm font-medium"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 bg-gray-50 text-left text-sm font-medium"
                    >
                      Platforms
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 bg-gray-50 text-left text-sm font-medium"
                    >
                      Create user
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 bg-gray-50 text-left text-sm font-medium"
                    >
                      Created date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 bg-gray-50 text-center text-sm font-medium"
                    >
                      Result
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 bg-gray-50 text-left text-sm font-medium"
                    >
                      Status
                    </th>
                    {authUser?.isAdmin() && (
                      <th
                        scope="col"
                        className="py-3.5 pr-6 bg-gray-50 rounded-tr-lg text-left text-sm font-medium"
                      >
                        <span className="sr-only">Actions</span>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {currentProject &&
                    filteredTasksList &&
                    filteredTasksList
                      .sort(sortTasks)
                      .map((task, taskIndex, taskList) => {
                        let isSubmitted;
                        let inProgress;
                        let isFinished;
                        let hasError;
                        let status = "";
                        let path = "";

                        if (isTranslate) {
                          isSubmitted =
                            task.analyzed &&
                            task.taskStatus === TaskStatus.SUBMITTED;
                          inProgress =
                            task.taskStatus === TaskStatus.TRANSLATING;
                          isFinished = task.translated;
                          hasError =
                            task.translated &&
                            task.taskStatus === TaskStatus.ERROR;
                          if (isFinished)
                            path = `/translate/report-summary/${task.uuid}`;
                          else path = `/translate/progress/${task.uuid}`;
                        } else {
                          isSubmitted =
                            !task.analyzed &&
                            task.taskStatus === TaskStatus.SUBMITTED;
                          inProgress = task.taskStatus === TaskStatus.ANALYZING;
                          isFinished = task.analyzed;
                          hasError =
                            task.analyzed &&
                            !task.translated &&
                            task.taskStatus === TaskStatus.ERROR;
                          if (isFinished)
                            path = `/analyze/report-results/${task.uuid}`;
                          else path = `/analyze/progress/${task.uuid}`;
                        }

                        if (isSubmitted) status = "Queued";
                        else if (inProgress) status = "In progress";
                        else if (hasError) status = "Error";
                        else if (isFinished) status = "Completed";

                        return (
                          <TaskListRow
                            key={task.uuid}
                            path={path}
                            task={task}
                            status={status}
                            isSubmitted={isSubmitted}
                            isTranslate={isTranslate}
                            isFinished={isFinished}
                            hasError={hasError}
                            isAdmin={authUser?.isAdmin() || false}
                            project={currentProject}
                            isLast={taskList.length - 1 === taskIndex}
                          />
                        );
                      })}
                  {isLoadingTasks &&
                    !tasksList &&
                    [...Array(5)].map((_r, i) => (
                      <TasksListRowSkeleton
                        key={`skeleton-${i}`}
                        isAdmin={authUser?.isAdmin() || false}
                      />
                    ))}
                  {filteredTasksList && filteredTasksList.length < 1 && (
                    <tr>
                      <td
                        className="px-6 py-5 text-sm leading-8 text-gray-500"
                        colSpan={authUser?.isAdmin() ? 7 : 6}
                      >
                        No tasks found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export type { TaskData, TaskFile, TaskErrors, TaskTranslateErrors };
export { TaskStatus };
export default TasksList;
