import { ReactComponent as AppLogoCrawler } from "../../../images/brand/logo-crawler.svg";
import { ReactComponent as HeroImage } from "../../../images/brand/hero-crawler.svg";

function HeroCrawler() {
  return (
    <div className="flex flex-col justify-between h-full pb-4 3xl:pb-5 4xl:pb-8 bg-white border-r border-r-gray-200 shadow-md overflow-y-scroll">
      <div className="flex items-start justify-between px-12 pt-12">
        <div className="flex flex-col max-w-sm 3xl:max-w-md 4xl:max-w-2xl mr-12 space-y-4 4xl:space-y-8">
          <h1 className="text-5xl 3xl:text-6xl 4xl:text-7xl text-gray-900 font-bold tracking-wide">
            Automate your migration to the cloud.
          </h1>
          <p className="text-lg 3xl:text-xl 4xl:text-3xl 4xl:leading-10 text-gray-500 3xl:tracking-wide">
            Scan, plan and complete your migration faster. Take a deep dive into
            your legacy systems with{" "}
            <strong className="text-gray-700 font-medium">Crawler360</strong>.
          </p>
        </div>
        <AppLogoCrawler
          title="Crawler360"
          role="img"
          className="flex-shrink-0 w-28 3xl:w-32 4xl:w-44 h-auto"
        />
      </div>
      <HeroImage className="w-full h-auto shrink-0" />
    </div>
  );
}

export default HeroCrawler;
