import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export function getPropertyDisplayName(prop: string): string {
  switch (prop.toLocaleLowerCase()) {
    case "jobs":
      return "Jobs";
    case "steps":
      return "Steps";
    case "controlflows":
      return "Control flows";
    case "dataflows":
      return "Data flows";
    case "sources":
      return "Sources";
    case "sinks":
      return "Sinks";
    default:
      return "-";
  }
}

export function getPropertyIcon(prop: string): IconDefinition {
  switch (prop.toLocaleLowerCase()) {
    case "jobs":
      return regular("diagram-cells");
    case "steps":
      return regular("bring-forward");
    case "controlflows":
      return regular("diagram-next");
    case "dataflows":
      return regular("diagram-predecessor");
    case "sources":
      return solid("database");
    case "sinks":
      return solid("database");
    default:
      return solid("empty-set");
  }
}

export function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}