import { ChartOptions } from "chart.js";
import fullConfig from "../full-config";
import {
  BarChartProps,
  HorizontalBarChartProps,
  DoughnutChartProps,
} from "./Charts.types";
import {
  Chart as Charts,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import {
  Bar,
  Doughnut,
  getDatasetAtEvent,
  getElementAtEvent,
  getElementsAtEvent,
} from "react-chartjs-2";
import { useEffect, useRef, useState } from "react";

const fontFamilyMono = getComputedStyle(
  document.documentElement
).getPropertyValue("--font-family-monospace");
const fontFamilySans = getComputedStyle(
  document.documentElement
).getPropertyValue("--font-family-sans-serif");
const fontWeight: any = fullConfig.theme?.fontWeight;
const colors: any = fullConfig.theme?.colors;

Charts.defaults.color = colors?.neutral["500"];
Charts.defaults.font.family = fontFamilyMono;
Charts.defaults.font.size = 10;
Charts.defaults.font.weight = fontWeight["bold"];
Charts.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
);

const colorPalettes = {
  brand: [
    colors.sky["700"],
    colors.sky["600"],
    colors.sky["500"],
    colors.sky["300"],
    colors.sky["200"],
    colors.orange["600"],
    colors.orange["500"],
    colors.orange["400"],
    colors.orange["300"],
    colors.orange["200"],
  ],
  vibrant: [
    colors.neutral["200"],
    colors.neutral["300"],
    colors.neutral["500"],
    colors.neutral["700"],
    colors.neutral["900"],
    colors.neutral["200"],
    colors.neutral["400"],
    colors.neutral["500"],
    colors.neutral["700"],
    colors.neutral["900"],
  ],
  neon: [
    colors.neutral["200"],
    colors.neutral["300"],
    colors.neutral["500"],
    colors.neutral["700"],
    colors.neutral["900"],
    colors.neutral["200"],
    colors.neutral["400"],
    colors.neutral["500"],
    colors.neutral["700"],
    colors.neutral["900"],
  ],
  fancy: [
    colors.neutral["200"],
    colors.neutral["300"],
    colors.neutral["500"],
    colors.neutral["700"],
    colors.neutral["900"],
    colors.neutral["200"],
    colors.neutral["400"],
    colors.neutral["500"],
    colors.neutral["700"],
    colors.neutral["900"],
  ],
  primary: [
    colors.sky["300"],
    colors.sky["400"],
    colors.sky["500"],
    colors.sky["600"],
    colors.sky["700"],
    colors.neutral["400"],
    colors.neutral["500"],
    colors.neutral["600"],
    colors.neutral["700"],
    colors.neutral["800"],
  ],
  secondary: [
    colors.orange["50"],
    colors.orange["100"],
    colors.orange["200"],
    colors.orange["300"],
    colors.orange["400"],
    colors.orange["500"],
    colors.orange["600"],
    colors.orange["700"],
    colors.orange["800"],
    colors.orange["900"],
  ],
  grayscale: [
    colors.neutral["50"],
    colors.neutral["100"],
    colors.neutral["200"],
    colors.neutral["300"],
    colors.neutral["400"],
    colors.neutral["500"],
    colors.neutral["600"],
    colors.neutral["700"],
    colors.neutral["800"],
    colors.neutral["900"],
  ],
  success: [
    colors.emerald["50"],
    colors.emerald["100"],
    colors.emerald["200"],
    colors.emerald["300"],
    colors.emerald["400"],
    colors.emerald["500"],
    colors.emerald["600"],
    colors.emerald["700"],
    colors.emerald["800"],
    colors.emerald["900"],
  ],
  danger: [
    colors.rose["50"],
    colors.rose["100"],
    colors.rose["200"],
    colors.rose["300"],
    colors.rose["400"],
    colors.rose["500"],
    colors.rose["600"],
    colors.rose["700"],
    colors.rose["800"],
    colors.rose["900"],
  ],
  warning: [
    colors.amber["50"],
    colors.amber["100"],
    colors.amber["200"],
    colors.amber["300"],
    colors.amber["400"],
    colors.amber["500"],
    colors.amber["600"],
    colors.amber["700"],
    colors.amber["800"],
    colors.amber["900"],
  ],
  info: [
    colors.blue["50"],
    colors.blue["100"],
    colors.blue["200"],
    colors.blue["300"],
    colors.blue["400"],
    colors.blue["500"],
    colors.blue["600"],
    colors.blue["700"],
    colors.blue["800"],
    colors.blue["900"],
  ],
};

const barChartOptions: ChartOptions<"bar"> = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
        drawTicks: false,
      },
      border: {
        color: colors.neutral["200"],
      },
      ticks: {
        align: "center" as const,
        padding: 6,
      },
    },
    y: {
      stacked: true,
      grid: {
        drawTicks: false,
        color: colors.neutral["200"],
      },
      border: {
        display: false,
        dash: [4, 4],
      },
      ticks: {
        display: false,
      },
    },
  },
};

const horizontalBarChartOptions: ChartOptions<"bar"> = {
  layout: {
    padding: {
      left: -24,
    },
  },
  maintainAspectRatio: false,
  indexAxis: "y",
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        drawTicks: false,
        color: colors.neutral["200"],
      },
      border: {
        display: false,
        dash: [4, 4],
      },
      ticks: {
        align: "center" as const,
        padding: 4,
      },
    },
    y: {
      grid: {
        display: false,
        drawTicks: false,
      },
      border: {
        color: colors.neutral["200"],
      },
      ticks: {
        mirror: true,
        padding: 16,
        z: 1,
        color: colors.neutral["900"],
        font: {
          family: fontFamilySans,
          weight: fontWeight["medium"],
        },
      },
    },
  },
};

const doughnutChartOptions: ChartOptions<"doughnut"> = {
  plugins: {
    legend: {
      display: false,
    },
  },
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
};

function BarChart({
  options = barChartOptions,
  data,
  children,
}: BarChartProps) {
  const [labels, setLabels] = useState<any[]>([]);
  const chartRef = useRef();

  function legendClickHandler(
    e: React.MouseEvent<HTMLDivElement>,
    itemIndex: number
  ): void {
    const chart: any | undefined = chartRef.current;
    if (chart) {
      setLabels(
        labels.map((label: any, index: number) => {
          let hidden = label.hidden;
          if (index === itemIndex) hidden = !hidden;
          return {
            ...label,
            hidden,
          };
        })
      );
      chart.setDatasetVisibility(itemIndex, !chart.isDatasetVisible(itemIndex));
      chart.update();
    }
  }

  useEffect(() => {
    setLabels(
      data.datasets.map((set: any) => {
        return {
          name: set.label,
          backgroundColor: set.backgroundColor,
          hidden: false,
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="flex items-center space-x-6">
      <div className="shrink w-2/3">
        <Bar ref={chartRef} options={options} data={data} />
      </div>
      <div className="w-1/3">
        {children}
        {data &&
          labels.map((label, index) => {
            return (
              <div
                key={`${label.name.replace(" ", "")}_${index}`}
                className="flex items-center space-x-2 hover:bg-gray-100 hover:text-gray-900 active:bg-gray-200 px-2 py-1.5 rounded cursor-pointer"
                onClick={(e) => legendClickHandler(e, index)}
                aria-checked={label.hidden}
              >
                <span
                  className="w-2.5 h-2.5 shrink-0"
                  style={{
                    backgroundColor: label.backgroundColor,
                  }}
                ></span>
                <span
                  className={`${label.hidden ? "line-through" : ""} text-xs`}
                >
                  {label.name}
                </span>
              </div>
            );
          })}
      </div>
    </div>
  );
}

function HorizontalBarChart({
  options = horizontalBarChartOptions,
  data,
}: HorizontalBarChartProps) {
  return <Bar options={options} data={data} />;
}

function DoughnutChart({
  options = doughnutChartOptions,
  title = "Total",
  size = "md",
  data,
}: DoughnutChartProps) {
  const [labels, setLabels] = useState<any[]>([]);
  const chartRef = useRef();
  options.cutout = size === "sm" ? 88 : size === "lg" ? 108 : 98;

  function legendClickHandler(
    e: React.MouseEvent<HTMLDivElement>,
    itemIndex: number
  ): void {
    const chart: any | undefined = chartRef.current;
    if (chart) {
      setLabels(
        labels.map((label: any, index: number) => {
          let hidden = label.hidden;
          if (index === itemIndex) hidden = !hidden;
          return {
            ...label,
            hidden,
          };
        })
      );
      chart.toggleDataVisibility(itemIndex);
      chart.update();
    }
  }

  function onChartClickHandler(e: React.MouseEvent<HTMLCanvasElement>) {
    const chart: any | undefined = chartRef.current;
    if (chart) {
      console.log(getDatasetAtEvent(chart, e));
      console.log(getElementAtEvent(chart, e));
      console.log(getElementsAtEvent(chart, e));
    }
  }

  useEffect(() => {
    setLabels(
      data.labels.map((label: string, index: number) => {
        return {
          name: label,
          backgroundColor: data.datasets[0].backgroundColor[index],
          hidden: false,
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="flex items-center space-x-6">
      <div
        className={`relative shrink ${
          size === "sm" ? "w-56" : size === "lg" ? "w-72" : "w-64"
        }`}
      >
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <span
            className={`mt-2.5 mb-0.5 ${
              size === "sm"
                ? "text-3xl"
                : size === "lg"
                ? "text-5xl"
                : "text-4xl"
            } font-bold`}
          >
            {data.datasets[0].data
              .reduce((a: number, b: number) => a + b)
              .toLocaleString()}
          </span>
          <span className="text-gray-500 mb-3">{title}</span>
        </div>
        <div className="relative">
          <Doughnut
            ref={chartRef}
            options={options}
            data={data}
            onClick={onChartClickHandler}
          />
        </div>
      </div>
      <div className="grow">
        {labels.map((label: any, index: number) => {
          return (
            <div
              key={`${label.name.replace(" ", "")}_${index}`}
              className="flex items-center space-x-2 hover:bg-gray-100 hover:text-gray-900 active:bg-gray-200 px-2 py-1.5 rounded cursor-pointer"
              aria-checked={data.datasets[0].hidden}
              onClick={(e) => legendClickHandler(e, index)}
            >
              <span
                className="w-2.5 h-2.5 shrink-0"
                style={{
                  backgroundColor: label.backgroundColor,
                }}
              ></span>
              <span className={`${label.hidden ? "line-through" : ""} text-xs`}>
                {label.name}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export {
  Charts,
  colorPalettes as chartColorPalettes,
  BarChart,
  HorizontalBarChart,
  DoughnutChart,
};
