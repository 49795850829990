import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ChangeEvent, useContext, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { Button } from "../../../_ui-kit";
import { SearchBox } from "../../../_ui-kit/SearchBox";
import { VendorAsset } from "../../../_ui-kit/VendorCard";
import StatementPanel from "./StatementPanel";
import { TaskData, TaskStatus } from "./TasksList";
import TaskProgressItem from "./TaskProgressItem";
import "../../../styles/progress-bar.css";
import TasksApi from "../../../api/TasksApi";
import { PageLoaderSpinner } from "../../../_ui-kit/PageLoderSpinner";

function TaskProgress() {
  const location = useLocation();
  const locationState = location.state;
  const isTranslate = location.pathname.includes("/translate");
  const initial: TaskData | undefined = locationState?.task;
  const tasksApi = new TasksApi();
  const queryClient = useQueryClient();
  const [filesListQuery, setFilesListQuery] = useState("");
  const [refetchInterval, setRefetchInterval] = useState(1000);
  const [isSqlReport, setIsSqlReport] = useState<boolean>();
  const { taskId } = useParams();
  const { currentProject } = useContext(ProjectContext);

  const { data: currentTask, isLoading: isLoadingCurrentTask } = useQuery(
    [
      `task${!currentProject || !taskId ? "-invalid" : ""}`,
      {
        projectId: currentProject?.uuid,
        taskId,
      },
    ],
    async () => {
      const projectId = currentProject?.uuid || "";
      const id = taskId || "";
      const response = await tasksApi.getTask(projectId, id);
      const task: TaskData = await response.json();
      return task;
    },
    {
      staleTime: 0,
      placeholderData: initial,
      refetchInterval: refetchInterval,
      enabled: !!currentProject && !!taskId,
      onSuccess: (data) => {
        queryClient.removeQueries(["task-invalid"]);
        setIsSqlReport(data.sourceType.toLowerCase() === "sql");
        if (
          data.processed !== undefined &&
          data.processed + data.errorCount === data.size
        )
          setRefetchInterval(0);
      },
      onError: (ex) => {
        console.log(ex);
        setRefetchInterval(0);
      },
    }
  );

  const filteredFilesList =
    filesListQuery === ""
      ? currentTask?.fileProgressList
      : currentTask?.fileProgressList?.filter((file) => {
          return file.name.toLowerCase().includes(filesListQuery.toLowerCase());
        });

  function getPercentage(): number {
    if (currentTask && currentTask.processed !== undefined)
      return Math.round(
        ((currentTask.processed + currentTask.errorCount) / currentTask.size) *
          100
      );
    return 0;
  }

  function searchFilterChangeHandler(e: ChangeEvent<HTMLInputElement>) {
    setFilesListQuery(e.target.value);
  }

  return (
    <>
      {currentTask && (
        <>
          <h2 className="text-lg font-medium mb-5">
            {isTranslate ? "Translation progress" : "Analysis progress"}
          </h2>
          <div className="flex flex-col space-y-6">
            <div className="shrink-0 bg-white rounded-lg shadow">
              <div className="flex items-center px-6 py-5">
                <span className="mr-5 text-lg font-medium">
                  {currentTask.projectPlatformName}
                </span>
                <div className="flex items-center space-x-3 mr-auto">
                  <VendorAsset
                    platform={currentTask.sourcePlatformName}
                    type="icon"
                  />
                  {(isSqlReport || isTranslate) && (
                    <>
                      <FontAwesomeIcon
                        icon={solid("arrow-right")}
                        className="text-gray-400"
                        size="sm"
                      />
                      <VendorAsset
                        platform={currentTask.targetPlatformName}
                        type="icon"
                      />
                    </>
                  )}
                </div>
                <div className="bg-gray-100 rounded px-2 py-1 uppercase text-xs font-medium text-gray-600 ml-6">
                  {currentTask.sourceType}
                </div>
              </div>
              <div className="bg-gray-50 border-t-2 border-gray-200 rounded-b-lg px-6 pt-4 pb-6">
                <div className="flex justify-between mb-2 text-sm text-gray-500">
                  <span>{`${
                    !currentTask.processed
                      ? 0 + currentTask.errorCount
                      : (
                          currentTask.processed + currentTask.errorCount
                        ).toLocaleString()
                  } objects`}</span>
                  <span>
                    {currentTask.taskStatus === TaskStatus.FINISHED
                      ? "Completed"
                      : currentTask.taskStatus === TaskStatus.ERROR
                      ? "Error"
                      : currentTask.taskStatus === TaskStatus.SUBMITTED
                      ? "Queued"
                      : currentTask.processed === undefined ||
                        currentTask.processed + currentTask.errorCount === 0
                      ? "Loading"
                      : "Processing"}
                  </span>
                  <span>{`${currentTask.size.toLocaleString()} objects`}</span>
                </div>
                {(currentTask.processed === undefined ||
                  currentTask.processed + currentTask.errorCount === 0) && (
                  <div className="progress-bar">
                    <div className="progress-bar-value"></div>
                  </div>
                )}
                {currentTask.processed !== undefined &&
                  currentTask.processed + currentTask.errorCount > 0 && (
                    <div className="h-2 rounded-full bg-gray-200 overflow-hidden">
                      <div
                        className={`h-full rounded-full ${
                          currentTask.taskStatus === TaskStatus.ERROR
                            ? "bg-danger-700"
                            : "bg-primary-700"
                        } ${
                          currentTask.processed + currentTask.errorCount !==
                          currentTask.size
                            ? "animate-pulse"
                            : ""
                        }`}
                        style={{
                          width: `${getPercentage()}%`,
                        }}
                        role="progressbar"
                        aria-label={`${
                          isTranslate ? "Translation" : "Analysis"
                        } in progress`}
                        aria-valuenow={getPercentage()}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    </div>
                  )}
              </div>
            </div>
            {currentTask.fileProgressList &&
              currentTask.fileProgressList.length > 0 && (
                <div className="bg-white rounded-lg shadow px-6 pt-6 pb-2">
                  <SearchBox
                    id="file-name-search"
                    placeholder="Search files"
                    onChange={searchFilterChangeHandler}
                  />
                  <div className="mt-3">
                    <StatementPanel
                      title={
                        <div className="text-primary-700 font-medium">
                          <span>
                            {isTranslate ? "Translating" : "Analyzing"}
                          </span>
                          <FontAwesomeIcon
                            icon={solid("angle-double-right")}
                            size="xs"
                            className="mx-3 text-primary-500"
                          />
                          {`${getPercentage()}%`}
                        </div>
                      }
                      closeClassName="mb-4"
                    >
                      <div className="d-flex flex-col divide-y divide-gray-200">
                        {filteredFilesList &&
                          filteredFilesList.map((file) => {
                            return (
                              <TaskProgressItem
                                key={file.id}
                                file={file}
                                isTranslate={isTranslate}
                              />
                            );
                          })}
                      </div>
                    </StatementPanel>
                  </div>
                </div>
              )}
          </div>
          {currentTask.processed !== undefined &&
            currentTask.processed + currentTask.errorCount ===
              currentTask.size && (
              <div className="flex items-center justify-between mt-6">
                <Button
                  href={
                    isTranslate
                      ? `/translate/report-summary/${currentTask.uuid}`
                      : `/analyze/report-results/${currentTask.uuid}`
                  }
                  variant={
                    currentTask.taskStatus === TaskStatus.ERROR
                      ? "danger"
                      : "primary"
                  }
                  label="Continue"
                  icon={solid("arrow-right")}
                  trailingIcon
                />
              </div>
            )}
        </>
      )}
      {isLoadingCurrentTask && !currentTask && <PageLoaderSpinner />}
    </>
  );
}

export default TaskProgress;
