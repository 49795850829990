import { ReactNode } from "react";
import { Route, Navigate } from "react-router-dom";
import RestrictedRoute from "./components/RestrictedRoute";
import Discover from "../pages/discover/Discover";
import DiscoverEtlPlatforms from "../pages/discover/EtlPlatforms";
import DiscoverDataPlatforms from "../pages/discover/DataPlatforms";
import OrchestrationJob from "../pages/discover/OrchestrationJob";
import DiscoverCode from "../pages/discover/Code";

function DiscoverRoutes(): ReactNode {
  return (
    <>
      <Route path="/" element={<Navigate to="discover/etl-platforms" />} />
      <Route
        path="discover"
        element={
          <RestrictedRoute>
            <Discover />
          </RestrictedRoute>
        }
      >
        <Route path="etl-platforms" element={<DiscoverEtlPlatforms />} />
        <Route path="data-platforms" element={<DiscoverDataPlatforms />} />
        <Route path="orchestration-job" element={<OrchestrationJob />} />
        <Route path="code" element={<DiscoverCode />} />
      </Route>
    </>
  );
}

export default DiscoverRoutes;
