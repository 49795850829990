import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu } from "@headlessui/react";
import { ButtonProps } from "./Button.types";
import { forwardRef } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export const Button = forwardRef(function (
  {
    usage = "button",
    variant = "primary",
    soft = false,
    radius = "regular",
    size = "md",
    isLoading = false,
    hideLabel = false,
    fullWidth = false,
    disabled = false,
    trailingIcon = false,
    external = false,
    uppercase = false,
    outline = false,
    icon,
    loadingText = "Loading...",
    type = "button",
    role,
    label,
    badge,
    href,
    onClick,
    underline = false,
  }: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement | null>
) {
  let buttonClassName = [
    "inline-flex",
    "items-center",
    "border",
    "font-medium",
    "focus:outline-none",
    "focus:ring-2",
    "focus:ring-offset-2",
    "select-none",
  ];

  let iconClassName: String[] = [];

  if (fullWidth) buttonClassName.push("w-full");

  switch (variant) {
    case "primary":
      if (outline) {
        buttonClassName.push("border-primary-700");
        buttonClassName.push("text-primary-700");
        buttonClassName.push("hover:bg-primary-700");
        buttonClassName.push("hover:text-white");
        buttonClassName.push("shadow-sm");
      } else if (soft) {
        buttonClassName.push("bg-primary-100");
        buttonClassName.push("hover:bg-primary-200");
        buttonClassName.push("text-primary-700");
        buttonClassName.push("border-transparent");
      } else {
        buttonClassName.push("bg-primary-700");
        buttonClassName.push("hover:bg-primary-600");
        buttonClassName.push("text-white");
        buttonClassName.push("shadow-sm");
        buttonClassName.push("border-transparent");
        iconClassName.push("text-white");
      }
      buttonClassName.push("focus:ring-primary-500");
      break;
    case "secondary":
      if (outline) {
        buttonClassName.push("border-secondary-700");
        buttonClassName.push("text-secondary-700");
        buttonClassName.push("hover:bg-secondary-700");
        buttonClassName.push("hover:text-white");
        buttonClassName.push("shadow-sm");
      } else if (soft) {
        buttonClassName.push("bg-secondary-100");
        buttonClassName.push("hover:bg-secondary-200");
        buttonClassName.push("text-secondary-700");
      } else {
        buttonClassName.push("bg-secondary-700");
        buttonClassName.push("hover:bg-secondary-600");
        buttonClassName.push("text-white");
        buttonClassName.push("shadow-sm");
      }
      buttonClassName.push("focus:ring-secondary-500");
      break;
    case "danger":
      if (outline) {
        buttonClassName.push("border-danger-700");
        buttonClassName.push("text-danger-700");
        buttonClassName.push("hover:bg-danger-700");
        buttonClassName.push("hover:text-white");
        buttonClassName.push("shadow-sm");
      } else if (soft) {
        buttonClassName.push("bg-danger-100");
        buttonClassName.push("hover:bg-danger-200");
        buttonClassName.push("text-danger-700");
        buttonClassName.push("border-transparent");
      } else {
        buttonClassName.push("bg-danger-700");
        buttonClassName.push("hover:bg-danger-600");
        buttonClassName.push("text-white");
        buttonClassName.push("shadow-sm");
        buttonClassName.push("border-transparent");
        iconClassName.push("text-white");
      }
      buttonClassName.push("focus:ring-danger-500");
      break;
    case "warning":
      if (outline) {
        buttonClassName.push("border-warning-700");
        buttonClassName.push("text-warning-700");
        buttonClassName.push("hover:bg-warning-700");
        buttonClassName.push("hover:text-white");
        buttonClassName.push("shadow-sm");
      } else if (soft) {
        buttonClassName.push("bg-warning-100");
        buttonClassName.push("hover:bg-warning-200");
        buttonClassName.push("text-warning-700");
        buttonClassName.push("border-transparent");
      } else {
        buttonClassName.push("bg-warning-700");
        buttonClassName.push("hover:bg-warning-600");
        buttonClassName.push("text-white");
        buttonClassName.push("shadow-sm");
        buttonClassName.push("border-transparent");
        iconClassName.push("text-white");
      }
      buttonClassName.push("focus:ring-warning-500");
      break;
    case "success":
      if (outline) {
        buttonClassName.push("border-success-700");
        buttonClassName.push("text-success-700");
        buttonClassName.push("hover:bg-success-700");
        buttonClassName.push("hover:text-white");
        buttonClassName.push("shadow-sm");
      } else if (soft) {
        buttonClassName.push("bg-success-100");
        buttonClassName.push("hover:bg-success-200");
        buttonClassName.push("text-success-700");
        buttonClassName.push("border-transparent");
      } else {
        buttonClassName.push("bg-success-700");
        buttonClassName.push("hover:bg-success-600");
        buttonClassName.push("text-white");
        buttonClassName.push("shadow-sm");
        buttonClassName.push("border-transparent");
        iconClassName.push("text-white");
      }
      buttonClassName.push("focus:ring-success-500");
      break;
    case "info":
      if (outline) {
        buttonClassName.push("border-info-700");
        buttonClassName.push("text-info-700");
        buttonClassName.push("hover:bg-info-700");
        buttonClassName.push("hover:text-white");
        buttonClassName.push("shadow-sm");
      } else if (soft) {
        buttonClassName.push("bg-info-100");
        buttonClassName.push("hover:bg-info-200");
        buttonClassName.push("text-info-700");
        buttonClassName.push("border-transparent");
      } else {
        buttonClassName.push("bg-info-700");
        buttonClassName.push("hover:bg-info-600");
        buttonClassName.push("text-white");
        buttonClassName.push("shadow-sm");
        buttonClassName.push("border-transparent");
        iconClassName.push("text-white");
      }
      buttonClassName.push("focus:ring-info-500");
      break;
    case "white":
      buttonClassName.push("bg-white");
      buttonClassName.push("border-gray-300");
      buttonClassName.push("text-gray-700");
      buttonClassName.push("hover:bg-gray-50");
      buttonClassName.push("focus:ring-primary-500");
      buttonClassName.push("shadow-sm");
      iconClassName.push("text-gray-500");
      break;
    case "gray":
      if (soft) {
        buttonClassName.push("bg-gray-100");
        buttonClassName.push("hover:bg-gray-200");
        buttonClassName.push("text-gray-700");
      } else {
        buttonClassName.push("bg-gray-600");
        buttonClassName.push("hover:bg-gray-500");
        buttonClassName.push("text-white");
        buttonClassName.push("shadow-sm");
      }
      buttonClassName.push("focus:ring-gray-500");
      buttonClassName.push("border-transparent");
      if (!soft) iconClassName.push("text-white");
      else iconClassName.push("text-gray-500");
  }

  switch (size) {
    case "xs":
      if (radius === "pill") {
        buttonClassName.push("px-3");
        buttonClassName.push("py-1.5");
        buttonClassName.push("text-xs");
      } else if (radius === "circular") {
        buttonClassName.push("p-1");
      } else {
        buttonClassName.push("px-2.5");
        buttonClassName.push("py-1.5");
        buttonClassName.push("text-xs");
      }
      iconClassName.push("h-3.5");
      iconClassName.push("w-3.5");
      if (!hideLabel) {
        if (trailingIcon) iconClassName.push("ml-2");
        else iconClassName.push("mr-2");
      }
      break;
    case "sm":
      if (radius === "pill") {
        buttonClassName.push("px-3.5");
        buttonClassName.push("py-2");
        buttonClassName.push("leading-4");
        buttonClassName.push("text-xs");
      } else if (radius === "circular") {
        buttonClassName.push("p-1.5");
      } else {
        buttonClassName.push("px-3");
        buttonClassName.push("py-2");
        buttonClassName.push("leading-4");
        buttonClassName.push("text-sm");
      }
      iconClassName.push("h-3.5");
      iconClassName.push("w-3.5");
      if (!hideLabel) {
        if (trailingIcon) iconClassName.push("ml-2");
        else iconClassName.push("mr-2");
      }
      break;
    case "md":
      if (radius === "pill") {
        buttonClassName.push("px-4");
        buttonClassName.push("py-2");
      } else if (radius === "circular") {
        buttonClassName.push("p-2");
      } else {
        buttonClassName.push("px-4");
        buttonClassName.push("py-2");
        buttonClassName.push("text-sm");
        iconClassName.push("h-4");
        iconClassName.push("w-4");
      }
      if (!hideLabel) {
        if (trailingIcon) iconClassName.push("ml-2");
        else iconClassName.push("mr-2");
      }
      break;
    case "lg":
      if (radius === "pill") {
        buttonClassName.push("px-5");
        buttonClassName.push("py-2");
        buttonClassName.push("text-base");
      } else if (radius === "circular") {
        buttonClassName.push("p-2");
      } else {
        buttonClassName.push("px-4");
        buttonClassName.push("py-2");
        buttonClassName.push("text-base");
      }
      iconClassName.push("h-5");
      iconClassName.push("w-5");
      if (!hideLabel) {
        if (trailingIcon) iconClassName.push("ml-3");
        else iconClassName.push("mr-3");
      }
      break;
    case "xl":
      if (radius === "pill") {
        buttonClassName.push("px-7");
        buttonClassName.push("py-3");
        buttonClassName.push("text-base");
      } else if (radius === "circular") {
        buttonClassName.push("p-3");
      } else {
        buttonClassName.push("px-6");
        buttonClassName.push("py-3");
        buttonClassName.push("text-base");
      }
      iconClassName.push("h-5");
      iconClassName.push("w-5");
      if (!hideLabel) {
        if (trailingIcon) iconClassName.push("ml-3");
        else iconClassName.push("mr-3");
      }
  }

  switch (radius) {
    case "regular":
      if (size === "xs") buttonClassName.push("rounded");
      else buttonClassName.push("rounded-md");
      break;
    case "pill":
    case "circular":
      buttonClassName.push("rounded-full");
  }

  if (trailingIcon) iconClassName.push("order-last");

  if (disabled || isLoading) {
    buttonClassName.push("pointer-events-none");
    buttonClassName.push("opacity-60");
  }

  if (isLoading) {
    iconClassName.push("animate-spin");
  }

  if (underline) {
    buttonClassName.push("hover:underline");
  }

  const buttonContent = (
    <span className="flex items-center mx-auto">
      {icon && (
        <FontAwesomeIcon
          icon={isLoading ? solid("spinner-third") : icon}
          className={iconClassName.join(" ")}
          aria-hidden={true}
          fixedWidth
        />
      )}
      <span className={hideLabel ? "sr-only" : uppercase ? "uppercase" : ""}>
        {isLoading && !icon ? loadingText : label}
      </span>
      {badge && <span className={`font-bold ml-3`}>{badge}</span>}
    </span>
  );

  if (href) {
    let url = href;
    let externalProps;

    if (external) {
      url = new URL(href).toString();
      externalProps = {
        target: "_blank",
        rel: "noreferrer",
      };

      return (
        <a
          href={url}
          {...externalProps}
          className={buttonClassName.join(" ")}
          role={role}
        >
          {buttonContent}
        </a>
      );
    }

    return (
      <Link to={url} className={buttonClassName.join(" ")} role={role}>
        {buttonContent}
      </Link>
    );
  }

  if (usage === "dropdown")
    return (
      <Menu.Button className={buttonClassName.join(" ")} role={role}>
        {buttonContent}
      </Menu.Button>
    );

  return (
    <button
      type={type}
      className={buttonClassName.join(" ")}
      onClick={onClick}
      disabled={disabled || isLoading}
      ref={ref}
      role={role}
    >
      {buttonContent}
    </button>
  );
});
