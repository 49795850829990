import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { UserContext } from "../../../contexts/UserContext";
import { getFormattedDate, getProcessingTimeText } from "../../../utils/common";
import { VendorAsset } from "../../../_ui-kit/VendorCard";
import { TaskData, TaskErrors, TaskStatus } from "./TasksList";
import { TaskObjectSummary } from "../TranslateReportSummary";
import Modal, { ModalContent } from "../../../_ui-kit/Modal/Modal";
import { Button } from "../../../_ui-kit";

interface ReportHeaderProps {
  isTranslate: boolean;
  reportProcessingTime?: number;
  reportDateCreated?: string;
  task: TaskData;
  taskErrors?: TaskErrors[];
  translateObjectSummary?: TaskObjectSummary;
}

function ReportHeader({
  isTranslate,
  task,
  taskErrors,
  reportProcessingTime = 0,
  reportDateCreated = "",
  translateObjectSummary,
}: ReportHeaderProps) {
  const { authUser } = useContext(UserContext);
  const [showTasksErrorsModal, setShowTasksErrorsModal] = useState(false);

  function closeTaskErrorsModal(close: boolean): void {
    setShowTasksErrorsModal(close);
  }

  return (
    <>
      {((!isTranslate && !task.analyzeSlaMet) ||
        (isTranslate &&
          task.taskStatus === TaskStatus.FINISHED &&
          task.tested &&
          !task.slaMet)) && (
        <div className="relative px-5 py-4 mb-10 bg-warning-100 border border-warning-300 rounded-md print:hidden">
          <div className="flex items-center justify-center text-warning-700 text-sm">
            <FontAwesomeIcon
              icon={solid("hexagon-exclamation")}
              className="w-6 h-6 text-warning-500"
            />
            <span className="ml-3 font-medium">Warning:</span>
            <span className="ml-2">Too many files were found with errors.</span>
            {taskErrors && taskErrors.length > 0 && (
              <div className="ml-auto">
                <Button
                  label={`View files (${taskErrors.length.toLocaleString()})`}
                  variant="warning"
                  size="xs"
                  onClick={() => setShowTasksErrorsModal(true)}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="grid grid-cols-3 gap-6 py-4 border-y border-gray-200 text-sm">
        <div
          className="flex items-center space-x-2"
          title={task.projectPlatformName}
        >
          <FontAwesomeIcon
            icon={regular("memo-circle-info")}
            className="text-base text-gray-400"
          />
          <span className="font-medium text-gray-900 truncate">
            {task.projectPlatformName}
          </span>
        </div>
        {((isTranslate && !task.tested) || !isTranslate) && (
          <div className="flex items-center justify-center space-x-2">
            <FontAwesomeIcon
              icon={regular("clock")}
              className="text-base text-gray-400"
            />
            <span className="font-medium text-gray-900">
              {getProcessingTimeText(
                isTranslate
                  ? translateObjectSummary?.translationTime
                  : reportProcessingTime
              )}
            </span>
          </div>
        )}
        {isTranslate && task.tested && translateObjectSummary && (
          <div className="flex flex-col space-y-1.5 -my-1.5">
            {[
              {
                time: translateObjectSummary.translationTime,
                type: "Translate" as const,
              },
              {
                time: translateObjectSummary.testTime,
                type: "Test" as const,
              },
            ].map((stat, index) => {
              return (
                <div
                  key={`time-${stat.type}`}
                  className="flex items-center justify-center space-x-1.5 text-xs leading-none"
                >
                  <FontAwesomeIcon
                    icon={index > 0 ? regular("clock-eight") : regular("clock")}
                    className="text-base text-gray-400 w-3.5 h-3.5"
                  />
                  <div className="flex items-center space-x-1 font-medium text-gray-900">
                    <span>{getProcessingTimeText(stat.time)}</span>
                    <FontAwesomeIcon
                      icon={solid("angles-right")}
                      size="xs"
                      className="text-gray-400"
                    />
                    <span>{stat.type}</span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className="flex items-center justify-end space-x-2">
          <FontAwesomeIcon
            icon={regular("calendar-lines")}
            className="text-base text-gray-400"
          />
          <span className="font-medium text-gray-900">
            {isTranslate
              ? `Updated on ${getFormattedDate(task.lastUpdated)}`
              : `Created on ${getFormattedDate(reportDateCreated)}`}
          </span>
        </div>
      </div>
      <div className="py-6 mb-10 border-b-2 border-gray-300">
        <div className="flex items-center justify-center space-x-12">
          <VendorAsset platform={task.sourcePlatformName} type="logo" />
          {(task.sourceType === "SQL" || isTranslate) && (
            <>
              <div className="flex flex-col space-y-2">
                <FontAwesomeIcon
                  icon={solid("arrow-right")}
                  className="text-gray-400"
                />
              </div>
              <VendorAsset platform={task.targetPlatformName} type="logo" />
            </>
          )}
        </div>
      </div>
      {((!isTranslate && !task.analyzeSlaMet) ||
        (isTranslate &&
          task.taskStatus === TaskStatus.FINISHED &&
          task.tested &&
          !task.slaMet)) && (
        <div className="flex pl-5 pr-6 py-6 mb-8 space-x-4 bg-info-50 border-l-4 border-info-500 print:hidden">
          <FontAwesomeIcon
            icon={solid("info-circle")}
            size="xl"
            className="text-info-400"
          />
          <div>
            <h3 className="mb-1 text-info-700 font-medium">
              We'll get back to you very soon
            </h3>
            <p className="text-sm text-info-700">
              Our engineers are hard at work to get the code translation process
              back on track, and we are doing our best to ensure that it meets
              the expected results. We apologize for any delays or
              inconveniences this may have caused and appreciate your patience
              as we strive to resolve this issue as quickly as possible.
            </p>
          </div>
        </div>
      )}
      <Modal
        title="File with errors"
        size="2xl"
        show={showTasksErrorsModal}
        onClose={closeTaskErrorsModal}
      >
        <ModalContent>
          {taskErrors && taskErrors.length > 0 && (
            <ul className="relative border border-gray-200 rounded-md divide-y">
              {taskErrors.map((taskError, index) => {
                return (
                  <li
                    key={`${taskError.objectId}_${index}`}
                    className="group flex space-x-4 px-5 py-3 first:rounded-t-md last:rounded-b-md border-gray-200 hover:bg-gray-50"
                  >
                    <FontAwesomeIcon
                      icon={regular("file-circle-exclamation")}
                      size="2x"
                      className="mt-0.5 text-gray-400 group-hover:text-gray-600"
                    />
                    <div className="flex flex-col space-y-1 overflow-hidden">
                      <div
                        className="text-sm truncate text-gray-700 group-hover:text-gray-900 leading-4 font-medium"
                        title={taskError.fileName}
                      >
                        {taskError.fileName}
                      </div>
                      <div className="text-sm">
                        {authUser?.isAdmin() && (
                          <ol className="space-y-2 pt-1 font-mono">
                            {taskError.errors.map((error, index) => {
                              return (
                                <li
                                  key={`${taskError.objectId}_${index}`}
                                  className="flex flex-col text-xs text-gray-500 group-hover:text-gray-900"
                                >
                                  <span className="font-bold whitespace-nowrap">
                                    Line {error.lineNo}
                                  </span>
                                  <span>{error.error}</span>
                                </li>
                              );
                            })}
                          </ol>
                        )}
                        {!authUser?.isAdmin() && (
                          <span className="text-gray-500 group-hover:text-gray-900">
                            Unsuported expressions. Please contact support for
                            addtional information.
                          </span>
                        )}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

export default ReportHeader;
