import { useQuery, useQueryClient } from "@tanstack/react-query";
import { MasterPage } from "../../_ui-kit/MasterPage";
import { PageTransition } from "../../_ui-kit/PageTransition";
import SettingsApi from "../../api/SettingsApi";
import { useContext, useEffect, useState } from "react";
import { ClientContext } from "../../contexts/ClientContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ProjectContext } from "../../contexts/ProjectContext";
import StatementSection from "./components/StatementSection";
import { getFormattedDate, getFormattedDateShort } from "../../utils/common";
import { classNames } from "../../_ui-kit/utils";
import { PageLoaderSpinner } from "../../_ui-kit/PageLoderSpinner";
import { PagePrintFormat } from "../../_ui-kit/PagePrintFormat";
import { Button } from "../../_ui-kit";
import SettingsContextState from "../../contexts/SettingsContext";
import PricingPlan from "../../models/PricingPlan";

interface BillingStatement {
  id: string;
  client: string;
  project: string;
  library: string;
  sourceType: "ETL" | "SQL";
  sourcePlatform: string;
  targetPlatform: string;
  pricingItem: StatementTypes;
  start: string;
  end: string;
  processingTimeSeconds: number;
  objectCount: number;
  objectCost: number;
}

interface BillintStatementGroup {
  groupType: StatementTypes;
  groupeName: string;
  stateMents: BillingStatement[];
  totalCount: number;
  totalCosts: number;
}

type StatementTypes =
  | "Analyzer Report"
  | "Table"
  | "View"
  | "Procedure / Function"
  | "ETL Job";

function AccountBilling() {
  const settingsApi = new SettingsApi();
  const queryClient = useQueryClient();
  const { currentClient } = useContext(ClientContext);
  const { currentProject } = useContext(ProjectContext);
  const [last12MonthsDates, setLast12MonthsDates] = useState<string[]>([]);
  const [statementDate, setStatementDate] = useState("");

  const { data: billintStatementGroups, isLoading: isLoadingBilling } =
    useQuery(
      [
        `billing-statement${
          !currentClient || !statementDate ? "-invalid" : ""
        }`,
        { clientId: currentClient?.id, statementDate },
      ],
      async () => {
        if (currentClient && statementDate) {
          const clientId = currentClient.id;
          const result = await settingsApi.getUsageDetails(
            clientId,
            getFirstDayDate(statementDate),
            getLastDayDate(statementDate)
          );
          const statements: BillingStatement[] = await result.json();
          const groups: BillintStatementGroup[] = [
            "Analyzer Report",
            "Table",
            "View",
            "Procedure / Function",
            "ETL Job",
          ].map((item) => {
            const filteredStatements = statements?.filter(
              (statement) => statement.pricingItem === item
            );
            return {
              groupType: item as StatementTypes,
              groupeName: item,
              stateMents: filteredStatements || [],
              totalCount:
                filteredStatements?.reduce(
                  (acc, statement) => acc + statement.objectCount,
                  0
                ) || 0,
              totalCosts:
                filteredStatements?.reduce(
                  (acc, statement) => acc + statement.objectCost,
                  0
                ) || 0,
            };
          });
          return groups;
        }
      },
      {
        enabled: !!currentClient && !!statementDate,
        onSuccess: () => {
          queryClient.removeQueries(["billing-statement-invalid"]);
        },
      }
    );

  function getFirstDayDate(date: string) {
    const day = new Date(date);
    const firstDay = new Date(day.getFullYear(), day.getMonth(), 1);
    return `${firstDay.getFullYear()}-${
      firstDay.getMonth() + 1
    }-${firstDay.getDate()}`;
  }

  function getLastDayDate(date: string) {
    const day = new Date(date);
    const lastDay = new Date(day.getFullYear(), day.getMonth() + 1, 0);
    return `${lastDay.getFullYear()}-${
      lastDay.getMonth() + 1
    }-${lastDay.getDate()}`;
  }

  function getFullDate(date: string) {
    const dateSplit = date.split("-");
    return getFormattedDate(
      new Date(+dateSplit[0], +dateSplit[1] - 1, +dateSplit[2]).toDateString()
    );
  }

  function statementDateChangeHandler(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    setStatementDate(event.target.value);
  }

  useEffect(() => {
    const dates = Array.from(Array(12).keys()).map((item) => {
      const date = new Date();
      date.setDate(1);
      date.setMonth(date.getMonth() - item);
      return date.toDateString();
    });
    setLast12MonthsDates(dates);
    queryClient.removeQueries(["billing-statement"]);
  }, [queryClient]);

  useEffect(() => {
    if (last12MonthsDates.length > 0) {
      setStatementDate(last12MonthsDates[0]);
    }
  }, [last12MonthsDates]);

  return (
    <SettingsContextState>
      <MasterPage heading="Billing" useBackButton>
        {currentClient && currentProject && statementDate && (
          <PagePrintFormat>
            <PageTransition>
              <div
                id="content"
                className="px-6 py-10 bg-white border-b print:px-0"
              >
                <div className="flex items-start justify-between mb-10">
                  <div className="flex flex-col">
                    <div className="flex items-center space-x-4">
                      <h2 className="text-lg text-gray-900 font-medium">
                        Current statement
                      </h2>
                      <div className="flex items-center">
                        <label
                          htmlFor="statement-date"
                          className="sr-only text-sm leading-6 text-gray-900"
                        >
                          Statement date
                        </label>
                        <div className="relative mr-4 rounded-md shadow-sm">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <FontAwesomeIcon
                              icon={regular("calendar")}
                              className="w-4 h-4 text-gray-400"
                            />
                          </div>
                          <select
                            id="statement-date"
                            className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 text-sm leading-6"
                            onChange={statementDateChangeHandler}
                          >
                            {last12MonthsDates.map((date) => {
                              return (
                                <option key={date} value={date}>
                                  {getFormattedDateShort(date)}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="print:hidden">
                          <Button
                            label="Print"
                            size="sm"
                            icon={solid("print")}
                            onClick={() => window.print()}
                            outline
                          />
                        </div>
                      </div>
                    </div>
                    <p className="mt-4 text-sm text-gray-700">
                      For tasks created between{" "}
                      <time
                        dateTime={getFirstDayDate(statementDate)}
                        className="underline"
                      >
                        {getFullDate(getFirstDayDate(statementDate))}
                      </time>{" "}
                      and{" "}
                      <time
                        dateTime={getLastDayDate(statementDate)}
                        className="underline"
                      >
                        {getFullDate(getLastDayDate(statementDate))}
                      </time>
                      .
                    </p>
                    <div className="max-w-md mt-5 px-4 py-3 border border-gray-200 rounded-lg">
                      <h3 className="mb-2 text-sm font-bold uppercase">
                        Pricing plan
                      </h3>
                      <dl className="-mx-3 -my-1 flex flex-wrap items-center text-xs">
                        {currentClient.pricingPlan &&
                          new PricingPlan(currentClient.pricingPlan)
                            .getBillingObjectLevels()
                            .map((item, index) => {
                              return (
                                <div
                                  key={`${item.objectType}-index`}
                                  className="flex items-center space-x-2 px-3 py-1"
                                >
                                  <dt className="text-gray-500">{`${PricingPlan.getObjectTypeDisplayName(
                                    item.objectType
                                  )}:`}</dt>
                                  <dd className="font-medium">{`$${item.price?.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}`}</dd>
                                </div>
                              );
                            })}
                      </dl>
                    </div>
                  </div>
                  {!isLoadingBilling &&
                    billintStatementGroups &&
                    ["billingTotals"].map((id) => {
                      const translatedItems =
                        billintStatementGroups
                          .filter(
                            (group) => group.groupType !== "Analyzer Report"
                          )
                          .reduce((acc, group) => acc + group.totalCosts, 0) ||
                        0;
                      const analyzerReports =
                        billintStatementGroups
                          .filter(
                            (group) => group.groupType === "Analyzer Report"
                          )
                          .reduce((acc, group) => acc + group.totalCosts, 0) ||
                        0;
                      const monthlyAccess =
                        currentClient.pricingPlan?.price || 0;
                      const total = translatedItems + monthlyAccess;

                      return (
                        <div
                          key={id}
                          className="flex flex-col space-y-2.5 px-5 py-4 bg-gray-100 rounded-lg"
                        >
                          <div className="flex items-center space-x-24 text-gray-500">
                            <span className="grow">Analyzer Reports</span>
                            <span>{`$${analyzerReports.toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}`}</span>
                          </div>
                          <div className="flex items-center space-x-24 text-gray-500">
                            <span className="grow">Access Fee</span>
                            <div>{`$${monthlyAccess.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}`}</div>
                          </div>
                          <div className="flex items-center space-x-24 text-gray-500">
                            <span className="grow">Translated Items</span>
                            <span>{`$${translatedItems.toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}`}</span>
                          </div>
                          <div className="flex items-center space-x-24 !mt-4 pt-4 border-t border-gray-200 text-xl text-gray-900 font-medium">
                            <span className="grow">Total</span>
                            <div className="text-gray-900">
                              {`$${total.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {isLoadingBilling && (
                  <div className="py-5">
                    <PageLoaderSpinner />
                  </div>
                )}
                {!isLoadingBilling && billintStatementGroups && (
                  <>
                    <div className="-mx-6 border-t border-t-gray-200">
                      <dl className="mx-auto grid max-w-7xl grid-cols-4 px-2">
                        {billintStatementGroups
                          .filter(
                            (group) => group.groupType !== "Analyzer Report"
                          )
                          .map((group, index) => (
                            <div
                              key={group.groupeName}
                              className={classNames(
                                index % 2 === 1
                                  ? "border-l"
                                  : index === 2
                                  ? "border-l"
                                  : "",
                                "flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-gray-200 py-10 px-8"
                              )}
                            >
                              <dt className="text-sm font-medium leading-6 text-gray-500">
                                {group.groupeName}
                              </dt>
                              <dd className="px-2 py-0.5 bg-primary-50 rounded-md tracking-wide text-primary-700 text-xs leading-5 font-bold print:hidden">
                                {group.totalCount.toLocaleString()}
                              </dd>
                              <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900 print:text-2xl">
                                {`$${group.totalCosts.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}`}
                              </dd>
                            </div>
                          ))}
                      </dl>
                    </div>
                    <div className="flex flex-col print:space-y-12">
                      {billintStatementGroups?.map((group) => {
                        return (
                          <StatementSection
                            key={group.groupeName}
                            statements={group.stateMents}
                            title={group.groupeName}
                            totalCount={group.totalCount}
                            totalCosts={group.totalCosts}
                          />
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </PageTransition>
          </PagePrintFormat>
        )}
      </MasterPage>
    </SettingsContextState>
  );
}

export type { BillingStatement };
export default AccountBilling;
