import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import AuthorizationApi from "../../api/AuthorizationApi";
import { UserContext } from "../../contexts/UserContext";
import { Button } from "../../_ui-kit";
import Alert from "../../_ui-kit/Alert/Alert";
import { AuthToken, User } from "../../models/User";
import { AuthRouteLocationState } from "../../routes/components/AuthorizationRoute";
import { encode } from "base-64";
import { MasterPage } from "../../_ui-kit/MasterPage";
import InputGroup, {
  InputGroupErrorDescription,
} from "../../_ui-kit/InputGroup/InputGroup";
import Input from "../../_ui-kit/Input/Input";
import Checkbox from "../../_ui-kit/Checkbox/Checkbox";
import MicrosoftSignOn from "./components/MicrosoftSignOn";

interface SingInProps {
  authApi: AuthorizationApi;
}

const rememberEmail = "userEmail";

function userNameBlurHandler(
  e: ChangeEvent<HTMLInputElement>,
  setEmailAddressRequiredError: (param: boolean) => void,
  setEmailAddressFormatError: (param: boolean) => void
): void {
  const user = e.target.value;
  const formatValidation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
    user
  );
  if (!user) {
    setEmailAddressRequiredError(true);
    setEmailAddressFormatError(false);
  } else if (!formatValidation) {
    setEmailAddressFormatError(true);
    setEmailAddressRequiredError(false);
  } else {
    setEmailAddressFormatError(false);
    setEmailAddressRequiredError(false);
  }
}

function passwordBlurHandler(
  e: ChangeEvent<HTMLInputElement>,
  setPasswordRequiredError: (param: boolean) => void
): void {
  const password = e.target.value;
  if (!password) {
    setPasswordRequiredError(true);
  } else {
    setPasswordRequiredError(false);
  }
}

function SignIn({ authApi }: SingInProps) {
  const { setAuthToken, clearAuthToken, getAuthUserInfo, setAuthUser } =
    useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [userEmail, setUserEmail] = useState(searchParams.get("user"));
  const [token, setToken] = useState(searchParams.get("token"));
  const [refreshToken, setRefreshToken] = useState(searchParams.get("refresh"));
  const [refreshTokenExpiry, setRefreshTokenExpiry] = useState(
    searchParams.get("expire")
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [info, setInfo] = useState("");
  const [savedUserName] = useState(localStorage.getItem(rememberEmail));
  const [emailAddressRequiredError, setEmailAddressRequiredError] =
    useState(false);
  const [emailAddressFormatError, setEmailAddressFormatError] = useState(false);
  const [passwordRequiredError, setPasswordRequiredError] = useState(false);
  const [rememberMe, setRememberMe] = useState(savedUserName !== "");
  const navigate = useNavigate();
  const locationState = useLocation().state as AuthRouteLocationState;
  const origin = locationState?.from?.location?.pathname || "/";

  function handleSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");
    setInfo("");

    const signInUser = async () => {
      try {
        const formData = new FormData(e.currentTarget);
        const emailAddress = formData.get("signin-username") as string;
        const password = formData.get("signin-password") as string;

        const response = await authApi.signIn(emailAddress, encode(password));
        const authToken: AuthToken = await response.json();
        setAuthToken(authToken);
        redirentFromSignIn(authToken, emailAddress);
        setIsSubmitting(false);
      } catch (ex) {
        setIsSubmitting(false);
        setError("Error authenticating user account.");
      }
    };

    signInUser();
  }

  function redirentFromSignIn(authToken: AuthToken, emailAddress: string) {
    if (authToken && authToken.user) {
      const authUser = new User(authToken.user);
      if (!authUser.isAdmin() && authUser.userClients.length === 0) {
        setError("No client space assigned in the platform.");
        clearAuthToken();
      } else if (!authUser.isActive) {
        setError("Your user account is not active.");
        clearAuthToken();
      } else {
        if (rememberMe) {
          localStorage.setItem(rememberEmail, emailAddress);
        } else {
          localStorage.removeItem(rememberEmail);
        }
        authApi.initRefreshToken();
        if (
          authUser.isAdmin() ||
          (!authUser.isAdmin() && authUser.userClients.length > 0)
        )
          navigate(origin, { replace: true });
        else navigate("/configure/users-administration", { replace: true });
      }
    }
  }

  useEffect(() => {
    if (userEmail && token && refreshToken && refreshTokenExpiry) {
      let authToken: AuthToken = {
        token,
        refreshToken,
        refreshTokenExpiry,
      };

      const loadAuthUser = async (username: string): Promise<void> => {
        const userInfo = await getAuthUserInfo(username);
        if (userInfo) {
          authToken.user = userInfo;
          setAuthToken(authToken);
          setAuthUser(userInfo);
          redirentFromSignIn(authToken, userEmail);
        } else {
          searchParams.delete("user");
          searchParams.delete("token");
          searchParams.delete("refresh");
          searchParams.delete("expire");
          setSearchParams(searchParams);
          setUserEmail(null);
          setToken(null);
          setRefreshToken(null);
          setRefreshTokenExpiry(null);
          setError("Error getting user account.");
        }
      };
      setAuthToken(authToken);
      loadAuthUser(userEmail);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setError(locationState?.from?.error || "");
    setInfo(locationState?.from?.info || "");
    window.history.replaceState(null, "");
  }, [locationState]);

  if (userEmail && token && refreshToken && refreshTokenExpiry) {
    return null;
  }

  return (
    <MasterPage type="signin" heading="Sign In">
      {error && (
        <Alert variant="danger" className="mb-8" show={!!error} dismissible>
          <p className="text-sm font-medium">{error}</p>
        </Alert>
      )}
      {info && (
        <Alert className="mb-8" show={!!info} dismissible>
          <p className="text-sm font-medium">{info}</p>
        </Alert>
      )}
      <h2 className="mb-1 text-4xl font-bold text-gray-900">Welcome back!</h2>
      <p className="mb-6 text-sm text-gray-600">
        Enter your credentials below to get started.
      </p>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col space-y-5 text-left"
      >
        <InputGroup label="Email address" inputId="signin-username">
          <Input
            type="email"
            id="signin-username"
            name="signin-username"
            size="lg"
            autoComplete="username"
            defaultValue={savedUserName || ""}
            isInvalid={emailAddressRequiredError || emailAddressFormatError}
            onBlur={(e) =>
              userNameBlurHandler(
                e,
                setEmailAddressRequiredError,
                setEmailAddressFormatError
              )
            }
            ariaDescribedby={
              emailAddressRequiredError
                ? "signin-username-required-error"
                : emailAddressFormatError
                ? "signin-username-format-error"
                : undefined
            }
            required
          />
          {emailAddressRequiredError && (
            <InputGroupErrorDescription
              id="signin-username-required-error"
              text="Your email address is required."
            />
          )}
          {emailAddressFormatError && (
            <InputGroupErrorDescription
              id="signin-username-format-error"
              text="Your email address is invalid."
            />
          )}
        </InputGroup>
        <InputGroup label="Password" inputId="signin-password">
          <Input
            id="signin-password"
            name="signin-password"
            size="lg"
            autoComplete="current-password"
            isInvalid={passwordRequiredError}
            onBlur={(e) => passwordBlurHandler(e, setPasswordRequiredError)}
            ariaDescribedby={
              passwordRequiredError
                ? "signin-password-required-error"
                : undefined
            }
            isPasswordInput
            required
          />
          {passwordRequiredError && (
            <InputGroupErrorDescription
              id="signin-password-required-error"
              text="Your password is required."
              showIcon={false}
            />
          )}
        </InputGroup>
        <div className="flex justify-between">
          <Checkbox
            id="remember-me"
            name="remember-me"
            label="Remember me"
            className="text-sm"
            defaultChecked={rememberMe}
            onChange={(event) => setRememberMe(event.target.checked)}
          />
          <Link
            className="text-sm text-primary-700 hover:underline focus:underline outline-none"
            to="/forgot-password"
          >
            Forgot your password?
          </Link>
        </div>
        <Button
          label="Sign in"
          size="lg"
          type="submit"
          isLoading={isSubmitting}
        />
      </form>
      <div className="relative my-4 text-xs before:absolute before:inset-x-0 before:top-2 before:h-px before:bg-gray-300">
        <span className="relative px-2 bg-gray-100 text-gray-600 font-medium">
          or
        </span>
      </div>
      <MicrosoftSignOn />
      <div className="mt-6 px-6 4xl:px-14 text-xs leading-5 text-gray-600">
        By signing in to SHIFT&trade; Cloud, you agree to Next Pathway's{" "}
        <a
          className="mr-0.5 text-primary-700 hover:underline focus:underline outline-none"
          href="https://www.nextpathway.com/hubfs/shift-cloud-ui-web-assets/legal-public-files/Terms%20of%20Use%20-%20SHIFT%20Cloud%20.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Use
        </a>{" "}
        and{" "}
        <a
          className="ml-0.5 text-primary-700 hover:underline focus:underline outline-none"
          href="https://www.nextpathway.com/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        .
      </div>
    </MasterPage>
  );
}

export { userNameBlurHandler, passwordBlurHandler };
export default SignIn;
