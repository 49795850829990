import { ChangeEvent, InputHTMLAttributes, useState } from "react";
import Input from "../../../_ui-kit/Input/Input";
import InputGroup, {
  InputGroupErrorDescription,
} from "../../../_ui-kit/InputGroup/InputGroup";

interface SystemSettingInputGroupProps {
  label: string;
  inputId: string;
  defaultValue?: InputHTMLAttributes<HTMLInputElement>["defaultValue"];
}

function SystemSettingInputGroup({
  label,
  inputId,
  defaultValue,
}: SystemSettingInputGroupProps) {
  const inputDescriptionId = `${inputId}-required-error`;
  const requiredErrorDescription = "This field is required.";
  const [inputHasRequiredError, setInputHasRequiredError] = useState(false);

  function onBlurHandler(e: ChangeEvent<HTMLInputElement>): void {
    const value = e.target.value;
    if (!value) {
      setInputHasRequiredError(true);
    } else {
      setInputHasRequiredError(false);
    }
  }

  return (
    <InputGroup label={label} inputId={inputId} className="grow text-sm">
      <Input
        id={inputId}
        name={inputId}
        type="text"
        required={true}
        defaultValue={defaultValue}
        isInvalid={inputHasRequiredError}
        onBlur={onBlurHandler}
        ariaDescribedby={inputHasRequiredError ? inputDescriptionId : undefined}
      />
      {inputHasRequiredError && (
        <InputGroupErrorDescription
          id={inputDescriptionId}
          text={requiredErrorDescription}
        />
      )}
    </InputGroup>
  );
}

export default SystemSettingInputGroup;
