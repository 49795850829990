import { ReactComponent as AppLogo } from "../../images/brand/logo-crawler.svg";
import { faArrowUpRightFromSquare as fasArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "../../_ui-kit";

function ComingSoon() {
  return (
    <>
      <div className="bg-white min-h-full py-24 grid place-items-center px-8">
        <div className="max-w-max mx-auto">
          <div className="flex items-start">
            <div className="py-1">
              <AppLogo
                title="Next Pathway Studio"
                role="img"
                width={114}
                height={68}
              />
            </div>
            <div className="ml-6">
              <div className="border-l border-gray-200 pl-6">
                <h1 className="font-black text-gray-600 tracking-tight text-5xl">
                  Coming soon
                </h1>
                <p className="mt-1 text-base text-gray-500">
                  We are launching before you know it. Stay tuned for more
                  updates.
                </p>
              </div>
              <div className="mt-10 flex space-x-3 border-l border-transparent pl-6">
                <Button
                  label="Visit nextpathway.com"
                  icon={fasArrowUpRightFromSquare}
                  trailingIcon={true}
                  href="https://www.nextpathway.com/"
                />
                <Button
                  label="Contact us"
                  icon={fasArrowUpRightFromSquare}
                  trailingIcon={true}
                  soft={true}
                  href="https://www.nextpathway.com/contact/"
                  external={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ComingSoon;
