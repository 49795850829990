import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import {
  ChangeEvent,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import UploadApi from "../../../api/UploadApi";
import { ClientContext } from "../../../contexts/ClientContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { UserContext } from "../../../contexts/UserContext";
import { getFormattedDate } from "../../../utils/common";
import { Button } from "../../../_ui-kit/Button";
import { classNames } from "../../../_ui-kit/utils";
import { VendorAsset } from "../../../_ui-kit/VendorCard";
import { downloadLibraryFiles, Library, LibraryAction } from "../LibraryUpload";

interface LibraryCardProps {
  disabled?: boolean;
  library: Library;
  setCurrentLibrary: (param: Library) => void;
  setLibraryAction: (param: LibraryAction) => void;
}

interface LibraryUploadFileItem {
  file: File;
  error?: string;
}

enum ProjectPlatformStatus {
  CREATED = "CREATED",
  UPLOADING = "UPLOADING",
  UPLOADED = "UPLOADED",
  IMPORTING = "IMPORTING",
  IMPORTED = "IMPORTED",
  ERROR = "ERROR",
}

const libraryOptions = [
  {
    id: "view-files",
    icon: solid("files"),
    label: "View files",
    important: false,
  },
  {
    id: "download-files",
    icon: solid("folder-arrow-down"),
    label: "Download files",
    important: false,
  },
  {
    id: "delete",
    icon: solid("trash-alt"),
    label: "Delete library",
    important: true,
  },
];

function LibraryCard({
  library,
  disabled = false,
  setLibraryAction,
  setCurrentLibrary,
}: LibraryCardProps) {
  const uploadApi = new UploadApi();
  const navigate = useNavigate();
  const { authUser } = useContext(UserContext);
  const { currentClient } = useContext(ClientContext);
  const { currentProject } = useContext(ProjectContext);
  const [filesToUpload, setFilesToUpload] = useState<LibraryUploadFileItem[]>(
    []
  );
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [isImportingFiles, setIsImportingFiles] = useState(false);
  const [isDownloadingSourceCode, setIsDownloadingSourceCode] = useState(false);
  const libraryFileUploadRef = useRef<HTMLInputElement>(null);
  const hasError =
    library.projectPlatformStatus === ProjectPlatformStatus.ERROR;

  const cardIcon = (
    <VendorAsset
      type="icon"
      platform={library.platformName}
      className="w-12 h-12"
    />
  );
  const statusText =
    library.projectPlatformStatus === ProjectPlatformStatus.UPLOADING
      ? `Uploading - ${getPercentage()}%`
      : library.projectPlatformStatus === ProjectPlatformStatus.UPLOADED
      ? "Ready to pre-process"
      : hasError && library.uploadedCount === library.fileCount
      ? "Pre-process error"
      : hasError && library.uploadedCount < library.fileCount
      ? "Upload error"
      : "Pre-processing";

  function getFilesToUpload(files: FileList): LibraryUploadFileItem[] {
    const items: LibraryUploadFileItem[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (file) {
        const invalidFormat = false; // file.type !== "text/csv";
        const fileTooBig = file.size > 1048576000; // 100MB
        const hasError = invalidFormat || fileTooBig;

        items.push({
          file: file,
          error: hasError
            ? invalidFormat
              ? "Invalid file format"
              : "File size is too big"
            : undefined,
        });
      }
    }
    return items;
  }

  function libraryFileUploadClickHandler(): void {
    const input = libraryFileUploadRef.current;

    if (input) {
      input.value = "";
      input.click();
    }
  }

  function libraryUploadChangeHandler(e: ChangeEvent<HTMLInputElement>): void {
    const filesList = e.target.files;
    if (filesList) setFilesToUpload(getFilesToUpload(filesList));
  }

  function libraryImportClickHandler(): void {
    const importLibrary = async (projectId: string) => {
      try {
        const response = await uploadApi.importLibraryFiles(
          projectId,
          library.uuid
        );
        if (!response.ok) {
          console.log(response.status, response.statusText);
          setIsImportingFiles(false);
        }
      } catch (ex) {
        console.log(ex);
      }
    };

    if (currentProject) {
      setIsImportingFiles(true);
      importLibrary(currentProject.uuid);
    }
  }

  function libraryMenuItemClickHandler(
    e: React.MouseEvent<HTMLButtonElement>,
    option: string
  ): void {
    switch (option) {
      case "view-files":
        viewFiles();
        break;
      case "download-files":
        e.preventDefault();
        e.stopPropagation();
        downloadFiles();
        break;
      case "delete":
        deleteLibrary();
        break;
    }
  }

  function analyzeClickHandler(): void {
    setCurrentLibrary(library);
    setLibraryAction("analyze");
  }

  function viewErrorsClickHandler(): void {
    setCurrentLibrary(library);
    setLibraryAction("view-errors");
  }

  function viewFiles(): void {
    navigate(`/upload/library-files/${library.uuid}`);
  }

  function deleteLibrary(): void {
    setCurrentLibrary(library);
    setLibraryAction("delete");
  }

  function downloadFiles(): void {
    downloadLibraryFiles(
      setIsDownloadingSourceCode,
      currentProject,
      library.uuid,
      library
    );
  }

  function getPercentage(): number {
    return Math.round((library.uploadedCount / library.fileCount) * 100);
  }

  useEffect(() => {
    if (
      filesToUpload.length > 0 &&
      filesToUpload.every((file) => !file.error)
    ) {
      const uploadLibraryFiles = async (
        projectId: string,
        filesToUpload: LibraryUploadFileItem[]
      ): Promise<void> => {
        try {
          const response = await uploadApi.uploadLibraryFiles(
            projectId,
            library.uuid,
            filesToUpload.map((upload) => upload.file)
          );
          if (!response.ok) {
            console.log(response.status, response.statusText);
            setIsUploadingFiles(false);
          }
        } catch (ex) {
          console.log(ex);
        }
      };

      if (currentProject && filesToUpload.length > 0) {
        setIsUploadingFiles(true);
        uploadLibraryFiles(currentProject.uuid, filesToUpload);
        setFilesToUpload([]);
      }
    } else {
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesToUpload]);

  return (
    <div className="group flex flex-col min-w-full h-64 ring-1 ring-black ring-opacity-5 bg-white shadow-sm rounded-lg max-w-md overflow-hidden">
      {library.projectPlatformStatus !== ProjectPlatformStatus.IMPORTED && (
        <>
          <div className="relative flex flex-col justify-center items-center h-full">
            {cardIcon}
            {library.projectPlatformStatus !==
              ProjectPlatformStatus.CREATED && (
              <div
                className={`absolute top-6 right-6 px-3 py-1 rounded-full text-xs ${
                  library.projectPlatformStatus ===
                  ProjectPlatformStatus.UPLOADED
                    ? "bg-success-100 text-success-700"
                    : hasError
                    ? "bg-danger-100 text-danger-700"
                    : "bg-gray-100 text-gray-600"
                }`}
              >
                <span>{statusText}</span>
              </div>
            )}
            <div className="font-medium mt-4">{library.platformName}</div>
            <div className="text-sm text-gray-500">{library.name}</div>
            {!authUser?.isReviewer() &&
              library.projectPlatformStatus === ProjectPlatformStatus.CREATED &&
              currentClient &&
              currentClient.isActive && (
                <div className="flex space-x-4 mt-4">
                  <Button
                    label="Delete"
                    icon={solid("trash-alt")}
                    variant="danger"
                    onClick={deleteLibrary}
                    disabled={isUploadingFiles}
                    outline
                  />
                  <div>
                    <input
                      ref={libraryFileUploadRef}
                      onChange={libraryUploadChangeHandler}
                      id="libraryFileUpload"
                      type="file"
                      disabled={isUploadingFiles}
                      multiple
                      hidden
                    />
                    <Button
                      label="Upload"
                      icon={solid("cloud-arrow-up")}
                      variant="primary"
                      onClick={libraryFileUploadClickHandler}
                      isLoading={isUploadingFiles}
                      loadingText="Uploading..."
                      outline
                    />
                  </div>
                </div>
              )}
            {!authUser?.isReviewer() &&
              library.projectPlatformStatus === ProjectPlatformStatus.ERROR && (
                <div className="flex space-x-4 mt-4">
                  <Button
                    label="Delete"
                    icon={solid("trash-alt")}
                    variant="danger"
                    onClick={deleteLibrary}
                    outline
                  />
                  {((library.errors && library.errors.length > 0) ||
                    (library.validationErrors &&
                      library.validationErrors.length > 0)) && (
                    <Button
                      label="View errors"
                      icon={solid("circle-exclamation")}
                      variant="white"
                      onClick={viewErrorsClickHandler}
                    />
                  )}
                </div>
              )}
            {library.projectPlatformStatus ===
              ProjectPlatformStatus.UPLOADING && (
              <div className="w-72 mt-2">
                <div className="flex justify-between mb-1.5 text-xs">
                  <span className="font-medium">{`${library.uploadedCount.toLocaleString()} files`}</span>
                  <span className="text-primary-700 font-medium">
                    {`${library.fileCount.toLocaleString()} files`}
                  </span>
                </div>
                <div className="h-2 rounded-full bg-gray-200 mb-2 overflow-hidden">
                  <div
                    className="h-full rounded-full bg-primary-600 animate-pulse"
                    style={{
                      width: `${getPercentage()}%`,
                    }}
                    role="progressbar"
                    aria-label="Files upload progress"
                    aria-valuenow={getPercentage()}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
                <div className="leading-none text-xs text-center text-gray-500">
                  loading files...
                </div>
              </div>
            )}
            {(library.projectPlatformStatus ===
              ProjectPlatformStatus.UPLOADED ||
              library.projectPlatformStatus ===
                ProjectPlatformStatus.IMPORTING) &&
              currentClient &&
              currentClient.isActive &&
              !authUser?.isReviewer() && (
                <div className="flex space-x-4 mt-4">
                  <Button
                    label="Delete"
                    icon={solid("trash-alt")}
                    variant="danger"
                    onClick={deleteLibrary}
                    disabled={
                      authUser?.isAdmin()
                        ? undefined
                        : library.projectPlatformStatus ===
                            ProjectPlatformStatus.IMPORTING || isImportingFiles
                    }
                    outline
                  />
                  <Button
                    label="Pre-process"
                    icon={solid("file-dashed-line")}
                    variant="white"
                    onClick={libraryImportClickHandler}
                    isLoading={
                      library.projectPlatformStatus ===
                        ProjectPlatformStatus.IMPORTING || isImportingFiles
                    }
                    loadingText={`Pre-processing ${library.fileCount.toLocaleString()} files...`}
                  />
                </div>
              )}
          </div>
        </>
      )}
      {library.projectPlatformStatus === ProjectPlatformStatus.IMPORTED && (
        <>
          <div className="flex items-center space-x-4 px-6 py-5 border-b border-gray-200">
            {cardIcon}
            <div className="flex flex-col w-full mr-auto overflow-hidden">
              <div className="w-full font-medium text-gray-900 truncate">
                {library.platformName}
              </div>
              <div className="text-sm text-gray-500 truncate">
                {library.name}
              </div>
            </div>
            <div className="group-hover:hidden bg-gray-100 rounded px-2 py-1 uppercase text-xs font-medium text-gray-600">
              {library.sourceType}
            </div>
            <Menu as="div" className="relative hidden group-hover:block">
              <Menu.Button className="flex group-hover:opacity-100 opacity-0 px-2.5 py-2 rounded-md bg-gray-50 hover:bg-gray-100 border border-transparent text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                <span className="sr-only">Menu</span>
                <FontAwesomeIcon icon={solid("ellipsis-vertical")} />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 py-1 flex flex-col rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-40 focus:outline-none">
                  {libraryOptions.map((option) => {
                    const isDownloading =
                      option.id === "download-files" && isDownloadingSourceCode;

                    if (authUser?.isReviewer()) {
                      if (option.id === "delete") return null;
                      if (option.id === "download-files") return null;
                    }

                    if (
                      option.id === "view-files" &&
                      library.sourceType === "ETL"
                    )
                      return null;

                    return (
                      <Menu.Item key={option.id}>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active
                                ? `bg-gray-100 ${
                                    option.important
                                      ? "text-danger-900"
                                      : "text-gray-900"
                                  }`
                                : `${
                                    option.important
                                      ? "text-danger-700"
                                      : "text-gray-700"
                                  }`,
                              "flex items-center w-full text-left px-4 py-2 text-sm disabled:pointer-events-none disabled:opacity-60"
                            )}
                            onClick={(e) =>
                              libraryMenuItemClickHandler(e, option.id)
                            }
                            disabled={isDownloading}
                          >
                            <FontAwesomeIcon
                              icon={
                                isDownloading
                                  ? solid("spinner-third")
                                  : option.icon
                              }
                              className={`mr-2.5 ${
                                isDownloading ? "animate-spin" : ""
                              } ${
                                active
                                  ? `${
                                      option.important
                                        ? "text-danger-700"
                                        : "text-gray-500"
                                    }`
                                  : `${
                                      option.important
                                        ? "text-danger-600"
                                        : "text-gray-400"
                                    }`
                              }`}
                              aria-hidden={true}
                              fixedWidth
                            />
                            {option.label}
                          </button>
                        )}
                      </Menu.Item>
                    );
                  })}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className="p-6 mb-auto">
            <dl className="grid grid-cols-3 divide-x -mx-4">
              <div className="text-sm space-y-1 px-4">
                <dt className="text-gray-500 whitespace-nowrap">
                  Object count
                </dt>
                <dd className="font-medium text-gray-900 truncate">
                  {library.objectCount.toLocaleString()}
                </dd>
              </div>
              <div className="text-sm space-y-1 px-4">
                <dt className="text-gray-500 whitespace-nowrap">
                  Created date
                </dt>
                <dd className="font-medium text-gray-900 truncate">
                  {getFormattedDate(library.dateCreated)}
                </dd>
              </div>
              <div className="text-sm space-y-1 px-4">
                <dt className="text-gray-500 whitespace-nowrap">
                  Created user
                </dt>
                <dd className="font-medium text-gray-900 truncate">
                  {library.creatorName}
                </dd>
              </div>
            </dl>
          </div>
          {authUser && (
            <div className="px-4 py-4 bg-gray-50 border-t border-gray-200">
              {!authUser.isReviewer() &&
                currentClient &&
                currentClient.isActive && (
                  <Button
                    label="Analyze"
                    variant="white"
                    icon={solid("square-plus")}
                    onClick={analyzeClickHandler}
                    fullWidth
                  />
                )}
              {(authUser.isReviewer() ||
                !(currentClient && currentClient.isActive)) && (
                <Button
                  label="View files"
                  variant="white"
                  icon={solid("files")}
                  onClick={() => viewFiles()}
                  fullWidth
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}

function LibraryCardSkeleton() {
  return (
    <div
      className="border border-gray-200 shadow-sm rounded-lg p-6 max-w-md w-full bg-white"
      aria-hidden={true}
    >
      <div className="animate-pulse flex flex-col">
        <div className="flex items-center space-x-4 mb-5">
          <div className="rounded bg-gray-200 h-12 w-12"></div>
          <div className="flex flex-col space-y-2">
            <div className="w-40 h-4 bg-gray-200 rounded"></div>
            <div className="w-20 h-3 bg-gray-200 rounded"></div>
          </div>
          <div className="rounded bg-gray-200 h-5 w-9 !ml-auto"></div>
        </div>
        <div className="h-0.5 bg-gray-200 rounded mb-5"></div>
        <div className="grid grid-cols-3 gap-x-4 gap-y-4 mb-4 pb-1">
          <div className="h-12 bg-gray-200 rounded"></div>
          <div className="h-12 bg-gray-200 rounded"></div>
          <div className="h-12 bg-gray-200 rounded"></div>
        </div>
        <div className="h-12 bg-gray-200 rounded"></div>
      </div>
    </div>
  );
}

export { LibraryCardSkeleton };
export default LibraryCard;
