import debounce from "lodash.debounce";
import { SearchBoxProps } from "./SearchBox.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass as farMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { ChangeEvent, useMemo } from "react";

export function SearchBox({
  label = "Search for entries",
  placeholder = "Find entries",
  ...props
}: SearchBoxProps) {
  const changeEventHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) props.onChange(e);
  };

  const debouncedChangeEventHandler = useMemo(
    () => debounce(changeEventHandler, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      <label htmlFor={props.id} className="sr-only">
        {label}
      </label>
      <div className="relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-px mb-px pl-3 flex items-center pointer-events-none">
          <FontAwesomeIcon
            icon={farMagnifyingGlass}
            className="w-4 h-4 text-gray-500"
            aria-hidden={true}
          />
        </div>
        <input
          type="text"
          id={props.id}
          autoComplete="off"
          autoCapitalize="off"
          autoCorrect="off"
          spellCheck={false}
          className="focus:ring-primary-500 focus:border-primary-500 placeholder:text-gray-400 block w-full pl-9 text-sm text-gray-900 border-gray-300 rounded-md"
          placeholder={placeholder}
          onChange={debouncedChangeEventHandler}
        />
      </div>
    </div>
  );
}
