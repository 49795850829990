type UploadType =
  | "error"
  | "missing"
  | "etl"
  | "category"
  | "dependency"
  | "ddl";

interface ProjectData {
  clientId: string;
  clientName: string;
  createdBy: number;
  createdUsername: string;
  dateCreated: string;
  id: number;
  lastUpdated: string;
  name: string;
  uuid: string;
  userIds: string[];
}

interface ProjectCollections {
  collectionId: string;
  size: number;
}

interface ProjectCollectionInProgress {
  projectId: string;
  collectionId: string;
  collectionName: string;
  size: number;
  processed: number;
  status: number;
  type: UploadType;
  imported: boolean;
}

class Project {
  clientId: string;
  clientName: string;
  createdBy: number;
  createdUsername: string;
  dateCreated: string;
  id: number;
  lastUpdated: string;
  name: string;
  uuid: string;
  userIds: string[];

  static uploadState = {
    COMPLETE: 1,
    UPLOADING: 2,
    ERROR: 3,
    IMPORTING: 4,
  };

  constructor(data: ProjectData) {
    this.clientId = data.clientId;
    this.clientName = data.clientName;
    this.createdBy = data.createdBy;
    this.createdUsername = data.createdUsername;
    this.dateCreated = data.dateCreated;
    this.id = data.id;
    this.lastUpdated = data.lastUpdated;
    this.name = data.name;
    this.uuid = data.uuid;
    this.userIds = data.userIds;
  }
}

export type {
  ProjectData,
  UploadType,
  ProjectCollections as Collections,
  ProjectCollectionInProgress,
};
export { Project };
