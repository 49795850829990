import { MasterPageProps } from "./MasterPage.types";
import { MainNavigation } from "../MainNavigation";
import { AppBar, AppBarWidget } from "../AppBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Link, useNavigate } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import {
  ChangeEvent,
  Fragment,
  MutableRefObject,
  useContext,
  useEffect,
  useState,
} from "react";
import { ProjectContext } from "../../contexts/ProjectContext";
import { ClientContext } from "../../contexts/ClientContext";
import { Button } from "../Button";
import _ from "lodash";
import ConfigureApi from "../../api/ConfigureApi";
import { Project } from "../../models/Project";
import DataUploadContextProvider from "../../contexts/DataUploadContext";
import Modal, { ModalContent, ModalFooter } from "../Modal/Modal";
import { UserContext } from "../../contexts/UserContext";
import { useQueryClient } from "@tanstack/react-query";
import { isCrawlerCore, isShiftCore } from "../../env";
import HeroCrawler from "../../pages/authorization/components/HeroCrawler";
import HeroShiftCloud from "../../pages/authorization/components/HeroShiftCloud";

export function MasterPage({
  type = "regular",
  useBackButton = false,
  heading = "Page",
  onBackClick,
  noAppBarElevation = false,
  children,
}: MasterPageProps) {
  const configureApi = new ConfigureApi();
  const navigate = useNavigate();
  const { authUser } = useContext(UserContext);
  const { currentClient } = useContext(ClientContext);
  const { currentProject, setCurrentProject, projectsListInContext } =
    useContext(ProjectContext);
  const [newProjectName, setNewProjectName] = useState("");
  const [openCreateProject, setOpenCreateProject] = useState(false);
  const [isCreatingProject, setIsCreatingProject] = useState(false);
  const [projectsListQuery, setProjectsListQuery] = useState("");
  const filteredProjectsList =
    projectsListQuery === ""
      ? projectsListInContext
      : projectsListInContext?.filter((item) => {
          return item.name
            .toLowerCase()
            .includes(projectsListQuery.toLowerCase());
        });
  const queryClient = useQueryClient();

  if (useBackButton && !onBackClick) onBackClick = () => navigate(-1);

  function setCurrentProjectBtnClickHandler(
    projectId: string,
    close: (
      focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null>
    ) => void
  ): void {
    const newCurrentProject = projectsListInContext?.find(
      (project) => project.uuid === projectId
    );

    if (newCurrentProject) setCurrentProject(newCurrentProject);
    close();
  }

  function openCreateProjectClickHandler(
    close: (
      focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null>
    ) => void
  ): void {
    setOpenCreateProject(true);
    close();
  }

  function projectsFilterChangeHandler(e: ChangeEvent<HTMLInputElement>) {
    setProjectsListQuery(e.target.value);
  }

  function newProjectNameChangeHandler(e: ChangeEvent<HTMLInputElement>) {
    setNewProjectName(e.target.value);
  }

  function createProjectClickHandler(): void {
    const createNewProject = async (
      clientId: string,
      name: string
    ): Promise<void> => {
      const response = await configureApi.addProject(clientId, name, []);
      const newProject: Project = await response.json();
      setIsCreatingProject(false);
      queryClient.invalidateQueries(["projects-list"]);
      queryClient.invalidateQueries(["projects-list-context"]);
      setCurrentProject(newProject);
      setOpenCreateProject(false);
      if (isCrawlerCore()) navigate("/configure/data-upload");
      else navigate("/upload/libraries");
    };

    if (currentClient && newProjectName) {
      setIsCreatingProject(true);
      createNewProject(currentClient.id, newProjectName);
    }
  }

  useEffect(() => {
    const { REACT_APP_APP_TITLE } = process.env;
    const title = _.unescape(
      `${REACT_APP_APP_TITLE} ${String.fromCharCode(8226)} ${heading}`
    );
    document.title = title;
  }, [heading]);

  switch (type) {
    case "regular":
      return (
        <>
          <a
            href="#main-content"
            className="sr-only focus:not-sr-only !absolute left-2 top-2.5 z-50 bg-primary-100 rounded-md outline-none text-primary-700 !px-4 !py-2.5"
          >
            <span className="text-sm uppercase font-medium underline mr-3">
              Skip to main content
            </span>
            <FontAwesomeIcon
              icon={solid("arrow-down")}
              size="sm"
              aria-hidden={true}
            />
          </a>
          <div className="relative z-0 min-w-[1200px] h-full flex print:min-w-0">
            <MainNavigation />
            <div
              className={`flex flex-col w-full h-full print:pl-0 ${
                isCrawlerCore() ? "pl-36" : "pl-32"
              }`}
            >
              {currentClient && !currentClient.isActive && (
                <div className="bg-warning-100 border-b border-warning-200">
                  <div className="mx-auto max-w-7xl py-2 px-8">
                    <div className="flex items-center justify-center space-x-2">
                      <FontAwesomeIcon
                        icon={solid("triangle-exclamation")}
                        className="text-warning-500"
                        size="sm"
                      />
                      <p className="text-xs font-medium text-warning-700">
                        The current client is inactive.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <AppBar
                heading={heading}
                onBackClick={onBackClick}
                noElevation={noAppBarElevation}
              >
                {currentProject && (
                  <DataUploadContextProvider>
                    <AppBarWidget className="flex items-center space-x-6">
                      <Popover className="relative max-w-[12rem] 2xl:max-w-xs 3xl:max-w-md">
                        {({ open }) => {
                          if (!open) {
                            _.delay(() => setProjectsListQuery(""), 100);
                          }
                          return (
                            <>
                              <Popover.Button className="group relative flex items-center w-full rounded-full bg-white hover:bg-gray-50 border border-gray-300 py-2 pl-4 pr-9 text-left shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 text-sm">
                                <span className="absolute -top-px -left-px rounded-full flex py-2.5 px-4 border border-gray-300 bg-gray-100 group-hover:bg-transparent shadow-sm">
                                  <FontAwesomeIcon
                                    icon={regular("folder-open")}
                                    aria-hidden={true}
                                    className="w-4 h-4 text-gray-500 ml-0.5"
                                  />
                                </span>
                                <span className="block ml-12 truncate">
                                  {currentProject?.name}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                                  <FontAwesomeIcon
                                    icon={solid("chevron-down")}
                                    aria-hidden={true}
                                    className="w-2.5 h-2.5"
                                  />
                                </span>
                              </Popover.Button>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Popover.Panel
                                  focus
                                  className="absolute right-0 z-40 mt-2 min-w-full w-72 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm overflow-hidden"
                                >
                                  {({ close }) => (
                                    <>
                                      <div className="min-w-0 flex flex-col grow space-y-0.5 px-4 pt-2 pb-3 border-b">
                                        <p className="text-xs leading-5 text-gray-500 tracking-wide font-bold uppercase">
                                          Current client space
                                        </p>
                                        <p className="text-sm text-primary-700 font-medium truncate">
                                          {currentClient?.name}
                                        </p>
                                      </div>
                                      {projectsListInContext &&
                                        projectsListInContext.length > 1 && (
                                          <div className="px-4 my-4">
                                            <label
                                              htmlFor="quickProjectSearch"
                                              className="sr-only"
                                            >
                                              Project search
                                            </label>
                                            <input
                                              type="text"
                                              id="quickProjectSearch"
                                              className="block w-full rounded-md border-gray-300 px-4 focus:border-primary-500 focus:ring-primary-500 text-sm"
                                              placeholder={`Search from ${projectsListInContext.length} projects...`}
                                              value={projectsListQuery}
                                              onChange={
                                                projectsFilterChangeHandler
                                              }
                                            />
                                          </div>
                                        )}
                                      {!projectsListQuery && (
                                        <>
                                          <h3 className="px-4 mt-3 mb-2 uppercase tracking-wide font-bold text-gray-500 text-xs">
                                            Current project
                                          </h3>
                                          <div className="flex items-center justify-between px-4 mb-3">
                                            <div className="flex items-center pr-4">
                                              <FontAwesomeIcon
                                                icon={regular("folder-open")}
                                                className="w-4 h-4 mr-3 text-primary-600"
                                              />
                                              <span className="font-medium text-primary-700">
                                                {currentProject?.name}
                                              </span>
                                            </div>
                                            <span className="flex items-center text-primary-600">
                                              <span className="text-base">
                                                <span className="sr-only">
                                                  Selected
                                                </span>
                                                <FontAwesomeIcon
                                                  icon={solid("check")}
                                                  aria-hidden={true}
                                                />
                                              </span>
                                            </span>
                                          </div>
                                        </>
                                      )}
                                      {projectsListInContext &&
                                        projectsListInContext.length > 1 && (
                                          <>
                                            {!projectsListQuery && (
                                              <h3 className="pt-3.5 mx-4 mb-1 border-t border-gray-200 uppercase tracking-wide font-bold text-gray-500 text-xs">
                                                Recent projects
                                              </h3>
                                            )}
                                            <ul className="mb-2">
                                              {filteredProjectsList &&
                                                filteredProjectsList
                                                  .filter(
                                                    (project) =>
                                                      project.id !==
                                                      currentProject?.id
                                                  )
                                                  .slice(0, 5)
                                                  .map((project) => (
                                                    <li
                                                      key={project.id}
                                                      className="select-none"
                                                    >
                                                      <button
                                                        className="group flex items-center w-full py-2 pl-4 pr-9 text-gray-900 hover:bg-primary-50 hover:text-primary-700 focus:bg-primary-50 focus:text-primary-700 outline-none"
                                                        onClick={() =>
                                                          setCurrentProjectBtnClickHandler(
                                                            project.uuid,
                                                            close
                                                          )
                                                        }
                                                      >
                                                        <FontAwesomeIcon
                                                          icon={regular(
                                                            "folder"
                                                          )}
                                                          className="w-4 h-4 mr-3 text-gray-400 group-hover:text-primary-600 group-focus:text-primary-600"
                                                        />
                                                        <span className="truncate">
                                                          {project.name}
                                                        </span>
                                                      </button>
                                                    </li>
                                                  ))}
                                            </ul>
                                            {filteredProjectsList &&
                                              filteredProjectsList.length <
                                                1 && (
                                                <div className="px-4 mb-4">
                                                  Nothing found.
                                                </div>
                                              )}
                                          </>
                                        )}
                                      {(authUser?.isAdmin() ||
                                        authUser?.isOwner()) && (
                                        <div className="flex items-center justify-between space-x-4 bg-gray-50 -mb-1 px-4 py-3.5">
                                          {currentClient &&
                                            currentClient.isActive && (
                                              <button
                                                className="flex items-center space-x-2 text-primary-700 hover:text-primary-600 focus:text-primary-600 focus:underline outline-none"
                                                onClick={() =>
                                                  openCreateProjectClickHandler(
                                                    close
                                                  )
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  icon={solid("plus-circle")}
                                                />
                                                <span>Create new</span>
                                              </button>
                                            )}
                                          <Link
                                            to={"/configure/projects-setup"}
                                            className="flex items-center space-x-2 text-primary-700 hover:text-primary-600 focus:text-primary-600 focus:underline outline-none"
                                            onClick={() => close()}
                                          >
                                            <FontAwesomeIcon
                                              icon={solid("folders")}
                                            />
                                            <span>View all projects</span>
                                          </Link>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </Popover.Panel>
                              </Transition>
                            </>
                          );
                        }}
                      </Popover>
                    </AppBarWidget>
                  </DataUploadContextProvider>
                )}
              </AppBar>
              <main id="main-content" className="h-full grow">
                {children}
              </main>
            </div>
          </div>
          {(authUser?.isAdmin() || authUser?.isOwner()) && (
            <Modal
              size="sm"
              title="New project"
              show={openCreateProject}
              onClose={setOpenCreateProject}
            >
              <ModalContent>
                <div className="text-sm font-medium text-primary-700">
                  Current client space: {currentClient?.name}
                </div>
                <div className="mt-2">
                  <label htmlFor="projectName" className="sr-only">
                    Project name
                  </label>
                  <input
                    type="text"
                    id="projectName"
                    maxLength={30}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                    placeholder="Enter a brief project name"
                    onChange={newProjectNameChangeHandler}
                    autoComplete="off"
                    autoCapitalize="off"
                    autoCorrect="off"
                    spellCheck={false}
                  />
                </div>
              </ModalContent>
              <ModalFooter>
                <div className="flex space-x-4">
                  <Button
                    label="Create"
                    icon={solid("check-circle")}
                    onClick={createProjectClickHandler}
                    isLoading={isCreatingProject}
                  />
                  <Button
                    label="Cancel"
                    icon={solid("xmark-circle")}
                    variant="white"
                    onClick={() => setOpenCreateProject(false)}
                  />
                </div>
              </ModalFooter>
            </Modal>
          )}
        </>
      );
    case "signin":
      return (
        <div className="flex min-w-[1200px] h-full bg-gray-100">
          <div className="shrink-0 w-1/2 max-w-3xl 3xl:max-w-4xl 4xl:max-w-6xl h-full pr-2">
            {isCrawlerCore() && <HeroCrawler />}
            {isShiftCore() && <HeroShiftCloud />}
          </div>
          <div className="flex flex-col items-center justify-center text-center w-full -ml-2">
            <div className="flex flex-col w-full max-w-sm 4xl:max-w-md">
              {children}
            </div>
          </div>
        </div>
      );
    case "error":
      return <div className="bg-white"></div>;
  }
}
