import { ReactNode } from "react";
import { Route } from "react-router-dom";
import RestrictedRoute from "./components/RestrictedRoute";
import Configure from "../pages/configure/Configure";
import UsersAdministration from "../pages/configure/UsersAdministration";
import ClientManagement from "../pages/configure/ClientManagement";
import ProjectsSetup from "../pages/configure/ProjectsSetup";
import DataUpload from "../pages/configure/DataUpload";
import DataUploadContextProvider from "../contexts/DataUploadContext";
import { isShiftCore, isCrawlerCore } from "../env";
import SystemSettings from "../pages/configure/SystemSettings";
import SettingsContextState from "../contexts/SettingsContext";
import PricingPlans from "../pages/configure/PricingPlans";

function ConfigureRoutes(): ReactNode {
  return (
    <>
      <Route
        path="configure"
        element={
          <RestrictedRoute>
            <Configure />
          </RestrictedRoute>
        }
      >
        <Route path="users-administration" element={<UsersAdministration />} />
        <Route path="client-management" element={<ClientManagement />} />
        <Route path="projects-setup" element={<ProjectsSetup />} />
        {isShiftCore() && (
          <>
            <Route
              path="system-settings"
              element={
                <SettingsContextState>
                  <SystemSettings />
                </SettingsContextState>
              }
            />
            <Route
              path="pricing-plans"
              element={
                <SettingsContextState>
                  <PricingPlans />
                </SettingsContextState>
              }
            />
          </>
        )}
        {isCrawlerCore() && (
          <Route
            path="data-upload"
            element={
              <DataUploadContextProvider>
                <DataUpload />
              </DataUploadContextProvider>
            }
          />
        )}
      </Route>
    </>
  );
}

export default ConfigureRoutes;
