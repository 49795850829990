import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../_ui-kit";
import { PageTransition } from "../../_ui-kit/PageTransition";

const analysisPages = [
  {
    id: 1,
    title: "Scope overview",
    subTitle: "ETL and data platform scope",
    path: "scope-overview",
    cta: "Review scope",
    icon: regular("chart-pie"),
  },
  {
    id: 2,
    title: "Sequence jobs",
    subTitle: "Full sequence jobs overview",
    path: "sequence-jobs",
    cta: "Inspect insights",
    icon: regular("diagram-successor"),
  },
  {
    id: 3,
    title: "Parallel jobs",
    subTitle: "Brief parallel jobs overview",
    path: "parallel-jobs",
    cta: "View overview",
    icon: regular("diagram-cells"),
  },
  {
    id: 4,
    title: "Jobs data flow",
    subTitle: "Lineage for job dependencies",
    path: "data-flow/job",
    cta: "Explore lineage",
    icon: regular("list-tree"),
  },
  {
    id: 5,
    title: "Tables data flow",
    subTitle: "Lineage for table dependencies",
    path: "data-flow/table",
    cta: "Explore lineage",
    icon: regular("table-tree"),
  },
];

function AnalysisOverview() {
  return (
    <PageTransition>
      <div id="content" className="p-6 2xl:max-w-7xl 2xl:mx-auto 2xl:px-0">
        <h2 className="mb-6 text-lg font-medium">Generated analysis</h2>
        <ul className="grid grid-cols-4 gap-6 mb-4">
          {analysisPages.map((analysis) => (
            <li
              key={analysis.id}
              className="flex flex-col items-center overflow-hidden bg-white shadow rounded-lg"
            >
              <div className="relative py-8 w-full">
                <div className="flex items-center justify-center w-24 h-24 mx-auto rounded-lg bg-primary-100">
                  <FontAwesomeIcon
                    icon={analysis.icon}
                    size="3x"
                    className="block mx-auto text-primary-600"
                    aria-hidden={true}
                  />
                </div>
              </div>
              <div className="flex flex-col items-center w-full px-6 pt-4 pb-7 space-y-4 border-t-2 border-t-gray-100 bg-gray-50">
                <div className="text-center">
                  <div className="text-lg font-medium">{analysis.title}</div>
                  <div className="text-sm text-gray-500">
                    {analysis.subTitle}
                  </div>
                </div>
                <Button
                  label={analysis.cta}
                  icon={solid("arrow-right")}
                  href={analysis.path}
                  size="lg"
                  trailingIcon
                  fullWidth
                  outline
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </PageTransition>
  );
}

export default AnalysisOverview;
