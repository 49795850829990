import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageTransition } from "../../_ui-kit/PageTransition";

const fileCategories = [
  {
    id: 1,
    type: "All files",
    averageSize: "24.03 mb",
    count: 2640,
    icon: regular("files"),
    primary: false,
  },
  {
    id: 2,
    type: "ETL files",
    averageSize: "8.84 mb",
    count: 27,
    icon: regular("sensor-cloud"),
    primary: true,
  },
  {
    id: 3,
    type: "SQL files",
    averageSize: "2.00 mb",
    count: 565,
    icon: regular("file-code"),
    primary: true,
  },
  {
    id: 4,
    type: "KSH files",
    averageSize: "1.07 mb",
    count: 425,
    icon: regular("rectangle-terminal"),
    primary: true,
  },
];

function Code() {
  return (
    <PageTransition>
      <div id="content" className="p-6 2xl:max-w-7xl 2xl:mx-auto 2xl:px-0">
        <h2 className="mb-6 text-lg font-medium">Discovered files</h2>
        <div className="grid grid-cols-4 gap-6">
          {fileCategories.map((category) => (
            <div
              key={category.id}
              className="flex flex-col items-center overflow-hidden bg-white rounded-lg shadow"
            >
              <div className="relative w-full">
                <span className="absolute top-4 right-6 text-gray-500 text-sm uppercase">
                  {category.averageSize}
                </span>
                <FontAwesomeIcon
                  icon={category.icon}
                  size="6x"
                  className={`block mx-auto pt-12 pb-6 ${
                    category.primary ? "text-primary-600" : "text-gray-500"
                  }`}
                  aria-hidden={true}
                />
              </div>
              <div className="flex flex-col items-center w-full p-6 bg-gray-50">
                <span className="text-4xl font-bold mb-1">
                  {category.count}
                </span>
                <span className="text-gray-500 text-xl">{category.type}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </PageTransition>
  );
}

export default Code;
