function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

function getArrayFromString(text: string): string[] {
  if (!text) return [];
  if (!text.includes(",")) return [text];
  return text.split(",");
}

function getFormattedDate(date?: string): string {
  if (!date) return "";

  const dateFormat = new Intl.DateTimeFormat("en-CA", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  return dateFormat.format(new Date(date));
}

function getFormattedDateTime(date: string): string {
  const dateFormat = new Intl.DateTimeFormat("en-CA", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  return dateFormat.format(new Date(date));
}

function getFormattedDateShort(date: string) {
  if (!date) return "";

  const dateFormat = new Intl.DateTimeFormat("en-CA", {
    year: "numeric",
    month: "long",
  });

  return dateFormat.format(new Date(date));
}

function millisToMinutesAndSeconds(millis: number) {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (+seconds < 10 ? "0" : "") + seconds;
}

function getProcessingTimeText(time?: number) {
  if (time) {
    let seconds = time * 1000;
    let processingLabel = `${
      seconds < 60000 ? "seconds" : seconds < 6000000 ? "minutes" : "hours"
    }`;
    return `${new Date(seconds)
      .toISOString()
      .slice(11, 19)} ${processingLabel}`;
  }
  return "Time unavailable";
}

export {
  formatBytes,
  getArrayFromString,
  getFormattedDate,
  getFormattedDateTime,
  getFormattedDateShort,
  millisToMinutesAndSeconds,
  getProcessingTimeText,
};
