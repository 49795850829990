import { MainNavigationProps } from "./MainNavigation.types";
import { useLocation, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ReactComponent as CrawlerLogo } from "../../images/brand/logo-crawler.svg";
import { ReactComponent as ShiftLogo } from "../../images/brand/logo-shift.svg";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { isCrawlerCore, isShiftCore } from "../../env";
import Modal, { ModalContent } from "../Modal/Modal";

const navigation = [
  {
    id: 2,
    module: "analyze",
    moduleName: "Analyze",
    path: isCrawlerCore() ? "/analyze/data-overview" : "/analyze/list",
    icon: solid("chart-mixed"),
    primary: true,
  },
];

if (isCrawlerCore()) {
  navigation.push({
    id: 1,
    module: "discover",
    moduleName: "Discover",
    path: "/discover/etl-platforms",
    icon: regular("folder-magnifying-glass"),
    primary: true,
  });
}

if (isShiftCore()) {
  navigation.push({
    id: 1,
    module: "upload",
    moduleName: "Upload",
    path: "/upload/libraries",
    icon: regular("folder-arrow-up"),
    primary: true,
  });
  navigation.push({
    id: 3,
    module: "translate",
    moduleName: "Translate",
    path: "/translate/list",
    icon: regular("sliders"),
    primary: true,
  });
}

export function MainNavigation({ ...props }: MainNavigationProps) {
  const { authUser } = useContext(UserContext);
  const location = useLocation();
  const [navLiks, setNavLinks] = useState(navigation);
  const [openSupportModal, setOpenSupportModal] = useState(false);

  function supportClickHandler(): void {
    setOpenSupportModal(true);
  }

  useEffect(() => {
    if (
      authUser &&
      (authUser.isAdmin() || authUser.isOwner() || authUser.isEditor())
    ) {
      if (navLiks.every((link) => link.id !== 4)) {
        const configureNavLink = {
          id: 4,
          module: "configure",
          moduleName: "Configure",
          path: "/configure/users-administration",
          icon: regular("gear"),
          primary: true,
        };
        if (isShiftCore()) {
          if (!authUser.isEditor()) setNavLinks([...navLiks, configureNavLink]);
        } else {
          if (authUser.isEditor())
            configureNavLink.path = "/configure/data-upload";
          setNavLinks([...navLiks, configureNavLink]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  return (
    <>
      <aside
        className={`flex flex-col ${
          isCrawlerCore() ? "w-36" : "w-32"
        } fixed z-20 inset-y-0 print:hidden`}
      >
        <div className="flex-1 flex flex-col items-center min-h-0 pt-6 border-r border-gray-200 bg-white">
          {isCrawlerCore() && (
            <CrawlerLogo
              title="Crawler"
              role="img"
              className="flex-shrink-0 mb-6"
            />
          )}
          {isShiftCore() && (
            <ShiftLogo
              title="Shift&trade; Cloud"
              role="img"
              className="flex-shrink-0 mb-6"
            />
          )}
          <nav
            id="main-nav"
            className={`w-full ${
              isCrawlerCore() ? "px-5 space-y-2" : "px-3 space-y-2"
            } mb-auto`}
          >
            {navLiks
              .sort(function (a, b) {
                return a.id - b.id;
              })
              .map((navItem) => {
                const isActive = location.pathname
                  .toUpperCase()
                  .includes(navItem.module.toUpperCase());
                return (
                  <NavLink
                    key={navItem.id}
                    to={navItem.path}
                    className={`group flex flex-col items-center ${
                      isCrawlerCore() ? "p-4" : "p-3"
                    } rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 ${
                      isActive ? "bg-gray-100" : "hover:bg-gray-50"
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={navItem.icon}
                      className={`${
                        isCrawlerCore() ? "w-6 h-6" : "w-6 h-6"
                      } mb-2 ${
                        isActive
                          ? "text-gray-500"
                          : "text-gray-400 group-hover:text-gray-500"
                      }`}
                      aria-hidden={true}
                    />
                    <span
                      className={`${
                        isCrawlerCore() ? "text-sm" : "text-sm"
                      } font-medium ${
                        isActive
                          ? "text-gray-700"
                          : "text-gray-600 group-hover:text-gray-700"
                      }`}
                    >
                      {navItem.moduleName}
                    </span>
                  </NavLink>
                );
              })}
          </nav>
          <div
            className={`flex flex-col w-full mt-8 ${
              isCrawlerCore() ? "px-5" : "px-3"
            } py-4 border-t border-gray-200`}
          >
            <button
              className={`group flex flex-col items-center ${
                isCrawlerCore() ? "p-4" : "p-3"
              } rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
              onClick={supportClickHandler}
            >
              <FontAwesomeIcon
                icon={regular("life-ring")}
                className="w-6 h-6 mb-2 text-gray-400 group-hover:text-gray-500"
                aria-hidden={true}
              />
              <span className="text-sm font-medium text-gray-600 group-hover:text-gray-700">
                Support
              </span>
            </button>
          </div>
        </div>
      </aside>
      <Modal
        title={"Support"}
        onClose={setOpenSupportModal}
        show={openSupportModal}
        size="lg"
      >
        <ModalContent>
          <p className="mb-4">
            Are you in need of assistance? Don't worry, we've got you covered.
            Our dedicated support team is here to help guide and answer any
            questions or concerns you may have. Reach out to us at any time by
            emailing{" "}
            <a
              className="text-primary-700 font-medium hover:underline"
              href="mailto:shiftcloud-support@nextpathway.com"
            >
              shiftcloud-support@nextpathway.com
            </a>{" "}
            and we'll respond as soon as possible.
          </p>
          <p>
            We can provide guidance on everything from a specific issue to
            general recommendations for getting the best performance out of our
            products and services. Whatever the case may be, feel free to reach
            out to us anytime with your questions and concerns!
          </p>
          <hr className="border-gray-200 my-6" />
          <p className="mb-4">
            For more information on how to use SHIFT&trade; Cloud, please refer
            to our user guide below.
          </p>
          <a
            className="flex items-center px-5 py-4 bg-gray-50 rounded text-primary-700 font-medium hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            href="https://www.nextpathway.com/hubfs/shift-cloud-ui-web-assets/support/SHIFTCloud-USER-GUIDE-V1.8.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={regular("file-pdf")} size="lg" />
            <span className="ml-3">User guide</span>
            <span className="ml-auto text-xs font-normal">Version 1.0</span>
          </a>
        </ModalContent>
      </Modal>
    </>
  );
}
