import { MasterPage } from "../../_ui-kit/MasterPage";
import type { ChartData } from "chart.js";
import { PageTransition } from "../../_ui-kit/PageTransition";
import { useContext, useEffect, useState } from "react";
import {
  DoughnutChart,
  chartColorPalettes,
} from "../../_ui-kit/Charts/Charts";
import { ProjectContext } from "../../contexts/ProjectContext";
import { VendorAsset } from "../../_ui-kit/VendorCard";
import AnalyzeApi from "../../api/AnalyzeApi";

interface EtlPlatform {
  etlplatform: string;
  summary: EtlPlatformSummary[];
}

interface EtlPlatformSummary {
  name: string;
  jobType: "Parallel" | "Sequence";
  totalJobs: number;
}

function getJobsByApplicationChartData(
  etlPlaforms: EtlPlatformSummary[],
  jobType: "Parallel" | "Sequence"
): ChartData<"doughnut"> {
  const labels: string[] = [];
  const data: number[] = [];
  const colors: string[] = [];
  const isSequence = jobType === "Sequence";

  etlPlaforms
    .filter((platform) => platform.jobType === jobType)
    .forEach((plaform) => {
      labels.push(plaform.name);
      data.push(plaform.totalJobs);
      colors.push(
        isSequence
          ? chartColorPalettes.primary[colors.length]
          : chartColorPalettes.secondary[colors.length]
      );
    });

  return {
    labels,
    datasets: [
      {
        label: isSequence ? "Sequence jobs" : "Parallel jobs",
        data,
        backgroundColor: colors,
      },
    ],
  };
}

function ScopeOverview() {
  const analyzeApi = new AnalyzeApi();
  const { currentProject } = useContext(ProjectContext);
  const [jobApplicationSummaryList, setJobApplicationSummaryList] = useState<
    EtlPlatform[]
  >([]);

  useEffect(() => {
    async function getEtlPlatforms(projectId: string): Promise<void> {
      const result = await analyzeApi.getEtlPlatformsJobSummary(projectId);
      const categories: EtlPlatform[] = await result.json();
      setJobApplicationSummaryList(categories);
    }

    if (currentProject) {
      getEtlPlatforms(currentProject.uuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  return (
    <MasterPage heading="Scope Overview" useBackButton>
      <PageTransition>
        <div id="content" className="p-6 2xl:max-w-7xl 2xl:mx-auto 2xl:px-0">
          <h2 className="mb-6 text-xl font-medium">ETL platforms</h2>
          <div className="flex flex-col w-full space-y-6 pb-6">
            {jobApplicationSummaryList.map((etl) => {
              return (
                <div key={etl.etlplatform} className="flex">
                  <div className="shrink-0 w-72 mr-6">
                    <div className="pb-4 mb-4 border-b">
                      <VendorAsset platform="DataStage" type="logo" />
                    </div>
                    <h2 className="leading-none mb-6 text-lg">
                      {etl.etlplatform}
                    </h2>
                  </div>
                  <div className="grow grid grid-cols-2 gap-6">
                    <div className="col-span-1 flex flex-col bg-white rounded-lg overflow-hidden shadow">
                      <div className="p-6">
                        <DoughnutChart
                          data={getJobsByApplicationChartData(
                            etl.summary,
                            "Sequence"
                          )}
                          title="Squence jobs"
                        />
                      </div>
                      <div className="px-5 py-4 bg-gray-50 font-medium text-gray-500">
                        Sequence jobs by application
                      </div>
                    </div>
                    <div className="col-span-1 flex flex-col bg-white rounded-lg overflow-hidden shadow">
                      <div className="p-6">
                        <DoughnutChart
                          data={getJobsByApplicationChartData(
                            etl.summary,
                            "Parallel"
                          )}
                          title="Parallel jobs"
                        />
                      </div>
                      <div className="px-5 py-4 bg-gray-50 font-medium text-gray-500">
                        Parallel jobs by application
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </PageTransition>
    </MasterPage>
  );
}

export default ScopeOverview;
