import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import _ from "lodash";
import { Fragment, ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import NotificationProps from "./Notification.types";

const notificationContainerId = "notifications-container";

function Notification({
  variant = "info",
  title,
  subTitle,
  hideIcon = false,
  show = true,
  onClose,
}: NotificationProps) {
  const [showNotification, setShowNotification] = useState(false);
  let icon;
  let iconClassName;

  switch (variant) {
    case "info":
      iconClassName = "text-info-600";
      icon = regular("circle-info");
      break;
    case "success":
      iconClassName = "text-success-600";
      icon = regular("check-circle");
      break;
    case "danger":
      iconClassName = "text-danger-600";
      icon = regular("xmark-circle");
      break;
    case "warning":
      iconClassName = "text-warning-600";
      icon = regular("circle-exclamation");
      break;
  }

  function closeNotification(): void {
    setShowNotification(false);
    _.delay(() => {
      onClose?.();
    }, 300);
  }

  useEffect(() => {
    setShowNotification(show);
  }, [show]);

  return (
    <Transition
      show={showNotification}
      as={Fragment}
      enter="transform ease-out duration-300 delay-150"
      enterFrom="opacity-0 translate-x-12"
      enterTo="opacity-100 translate-x-0"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="relative pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="p-4">
          <div className="flex items-start">
            {!hideIcon && (
              <div className="flex-shrink-0 mr-3">
                <FontAwesomeIcon
                  icon={icon}
                  className={`h-5 w-5 ${iconClassName}`}
                />
              </div>
            )}
            <div className="w-0 flex-1 pt-px">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              {subTitle && (
                <p className="mt-0.5 text-sm text-gray-500">{subTitle}</p>
              )}
            </div>
            <div className="ml-4 mt-0.5 flex flex-shrink-0">
              <button
                type="button"
                className="inline-flex rounded-sm bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                onClick={closeNotification}
              >
                <span className="sr-only">Close</span>
                <FontAwesomeIcon icon={regular("xmark")} className="h-4 w-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}

function NotificationsContainer() {
  return (
    <div
      id="notifications-container-root"
      className="fixed inset-0 z-30 p-6 pointer-events-none select-none"
      aria-live="assertive"
    >
      <div
        id={notificationContainerId}
        className="flex flex-col items-end space-y-4"
      ></div>
    </div>
  );
}

function showNotification(notification: ReactNode) {
  const container =
    document.getElementById(notificationContainerId) || document.body;

  return createPortal(notification, container);
}

export { Notification, NotificationsContainer, showNotification };
