import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FormEvent, useContext, useState } from "react";
import SettingsApi from "../../api/SettingsApi";
import { AppError } from "../../App";
import { UserContext } from "../../contexts/UserContext";
import { Button } from "../../_ui-kit/Button";
import Checkbox from "../../_ui-kit/Checkbox/Checkbox";
import { MasterPage } from "../../_ui-kit/MasterPage";
import { showNotification, Notification } from "../../_ui-kit/Notification";
import { PageLoaderSpinner } from "../../_ui-kit/PageLoderSpinner";
import { PageTransition } from "../../_ui-kit/PageTransition";

interface UserPreferencesData {
  userEmail: string;
  enableSplitRqEmail: boolean;
  enableSplitRsEmail: boolean;
  enableAnalyzeRqEmail: boolean;
  enableAnalyzeRsEmail: boolean;
  enableTranslateRqEmail: boolean;
  enableTranslateRsEmail: boolean;
  enableTestRqEmail: boolean;
  enableTestRsEmail: boolean;
}

function UserPreferences() {
  const settingsApi = new SettingsApi();
  const queryClient = useQueryClient();
  const { authUser } = useContext(UserContext);
  const [isUpdatingPreferences, setIsUpdatingPreferences] = useState(false);
  const [pageErrorsList, setPageErrorsList] = useState<AppError[]>([]);

  const { data: userPreferences, isLoading } = useQuery(
    [
      `user-preferences${!authUser ? "-invalid" : ""}`,
      { userEmail: authUser?.email },
    ],
    async () => {
      const result = await settingsApi.getUserPreferences();
      const preferences: UserPreferencesData = await result.json();
      return preferences;
    },
    {
      enabled: !!authUser,
      staleTime: 0,
      onSuccess: () => {
        queryClient.removeQueries(["user-preferences-invalid"]);
      },
    }
  );

  const updateUserPreferencesMutation = useMutation(
    (preferences: UserPreferencesData) => {
      return settingsApi.updateUserPreferences(
        preferences.userEmail,
        preferences.enableSplitRqEmail,
        preferences.enableSplitRsEmail,
        preferences.enableAnalyzeRqEmail,
        preferences.enableAnalyzeRsEmail,
        preferences.enableTranslateRqEmail,
        preferences.enableTranslateRsEmail,
        preferences.enableTestRqEmail,
        preferences.enableTestRsEmail
      );
    },
    {
      onSettled: () => {
        setIsUpdatingPreferences(false);
      },
      onError: (error: any) => {
        setPageErrorsList((prev) => [
          ...prev,
          {
            id: Math.random().toString(),
            error: "Error updating user preferences",
            description: error,
          },
        ]);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["user-preferences"]);
      },
    }
  );

  function getPreferenceName(preference: string): string {
    switch (preference) {
      case "enableSplitRqEmail":
        return "Code pre-processing starts";
      case "enableSplitRsEmail":
        return "Code pre-processing ends";
      case "enableAnalyzeRqEmail":
        return "Code analysis starts";
      case "enableAnalyzeRsEmail":
        return "Code analysis ends";
      case "enableTranslateRqEmail":
        return "Code translation starts";
      case "enableTranslateRsEmail":
        return "Code translation ends";
      case "enableTestRqEmail":
        return "Code testing starts";
      case "enableTestRsEmail":
        return "Code testing ends";
      default:
        return preference;
    }
  }

  function getPreferenceDescription(preference: string): string {
    switch (preference) {
      case "enableSplitRqEmail":
        return "Receive an email notification before code pre-processing.";
      case "enableSplitRsEmail":
        return "Receive an email notification after code pre-processing.";
      case "enableAnalyzeRqEmail":
        return "Receive an email notification before code analyze.";
      case "enableAnalyzeRsEmail":
        return "Receive an email notification after code analyze.";
      case "enableTranslateRqEmail":
        return "Receive an email notification code before translate.";
      case "enableTranslateRsEmail":
        return "Receive an email notification code after translate.";
      case "enableTestRqEmail":
        return "Receive an email notification before translated code testing.";
      case "enableTestRsEmail":
        return "Receive an email notification after translated code testing.";
      default:
        return preference;
    }
  }

  function submitUpdateUserPreferences(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const fields: any = formData.entries();

    if (userPreferences) {
      let newPreferences: UserPreferencesData = {
        userEmail: userPreferences.userEmail,
        enableSplitRqEmail: false,
        enableSplitRsEmail: false,
        enableAnalyzeRqEmail: false,
        enableAnalyzeRsEmail: false,
        enableTranslateRqEmail: false,
        enableTranslateRsEmail: false,
        enableTestRqEmail: false,
        enableTestRsEmail: false,
      };
      setIsUpdatingPreferences(true);
      for (const pair of fields) {
        const property: string = pair[0].split("-")[1];
        const value: string = pair[1];
        newPreferences = {
          ...newPreferences,
          [property]: value === "on",
        };
      }
      updateUserPreferencesMutation.mutate(newPreferences);
    }
  }

  return (
    <MasterPage heading="User preferences" useBackButton>
      {userPreferences && (
        <PageTransition>
          <div id="content" className="px-6 py-10 xl:max-w-5xl xl:mx-auto">
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-1">
                <h2 className="text-lg font-medium leading-6 text-gray-900">
                  Platform notifications
                </h2>
                <p className="mt-1 text-sm text-gray-600">
                  Stay connected as you create your code notification pipelines!
                  Get timely email alerts to keep up with progress.
                </p>
              </div>
              <div className="col-span-2">
                <form onSubmit={submitUpdateUserPreferences}>
                  <div className="overflow-hidden shadow rounded-md">
                    <div className="bg-white p-6">
                      <h2 className="flex items-center justify-between pb-3 mb-6 border-b-2 border-gray-200 text-gray-900 font-medium">
                        Email preferences
                        <span className="text-sm text-gray-500 font-normal">
                          <FontAwesomeIcon icon={solid("envelope")} />
                          <span className="ml-2">
                            {userPreferences.userEmail}
                          </span>
                        </span>
                      </h2>
                      <div className="flex flex-col space-y-3">
                        {Object.entries(userPreferences).map(([key, value]) => {
                          if (key === "userEmail") return null;
                          return (
                            <div key={key} className="flex flex-col text-sm">
                              <Checkbox
                                id={`notification-${key}`}
                                name={`notification-${key}`}
                                label={getPreferenceName(key)}
                                className="space-x-3 space-y-0.5 font-medium text-gray-900"
                                defaultChecked={value}
                              />
                              <div className="ml-7 mt-1 text-gray-500">
                                {getPreferenceDescription(key)}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="flex justify-end space-x-4 py-4 px-6 bg-gray-50 ">
                      <Button
                        type="reset"
                        label="Reset"
                        icon={solid("rotate")}
                        variant="white"
                      />
                      <Button
                        type="submit"
                        label="Save"
                        icon={solid("floppy-disk")}
                        isLoading={isUpdatingPreferences}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </PageTransition>
      )}
      {!userPreferences && isLoading && <PageLoaderSpinner />}
      {pageErrorsList.length > 0 &&
        pageErrorsList.map((error, index, list) => {
          return showNotification(
            <Notification
              key={`error.${error.id}`}
              variant="danger"
              title={error.error}
              subTitle={error.description}
              onClose={() =>
                setPageErrorsList(
                  list.filter((_listItem, newIndex) => newIndex !== index)
                )
              }
            />
          );
        })}
    </MasterPage>
  );
}

export default UserPreferences;
