import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UploadApi from "../../api/UploadApi";
import { ClientContext } from "../../contexts/ClientContext";
import { ProjectContext } from "../../contexts/ProjectContext";
import { Button } from "../../_ui-kit";
import { MasterPage } from "../../_ui-kit/MasterPage";
import { PageTransition } from "../../_ui-kit/PageTransition";
import { VendorAsset } from "../../_ui-kit/VendorCard";
import CodeView from "./components/CodeView";
import { downloadLibraryFiles, Library } from "./LibraryUpload";
import { UserContext } from "../../contexts/UserContext";
import CopyCodeToClipboardButton from "../translate/components/CopyCodeToClipboardButton";

interface LibraryFile {
  id: number;
  projectPlatformId: number;
  fileName: string;
  split: boolean;
  objectCount: number;
}

interface LibInfo {
  platformName: string;
  name: string;
  sourceType: string;
}

function LibraryFiles() {
  const uploadApi = new UploadApi();
  const queryClient = useQueryClient();
  const { libraryId } = useParams();
  const { authUser } = useContext(UserContext);
  const { currentClient } = useContext(ClientContext);
  const { currentProject } = useContext(ProjectContext);
  const [isDownloadingSourceCode, setIsDownloadingSourceCode] = useState(false);
  const [selectedFile, setSelectedFile] = useState<LibraryFile>();
  const [currentLibrary, setCurrentLibrary] = useState<LibInfo>();
  const [objCount, setObjCount] = useState(0);
  const [isSqlLibrary, setIsSqlLibrary] = useState<boolean>();
  const [clipboard, setClipboard] = useState<string[]>([]);

  const { data: libraryFiles } = useQuery(
    [
      `library-files${!currentProject || !libraryId ? "-invalid" : ""}`,
      { projectId: currentProject?.uuid, libraryId },
    ],
    async () => {
      const projectId = currentProject?.uuid;
      if (projectId) {
        const library = libraryId || "";
        const result = await uploadApi.getLibraryFiles(projectId, library);
        const files: LibraryFile[] = await result.json();
        return files;
      }
      return [];
    },
    {
      enabled: !!currentProject && !!libraryId,
      onSuccess: () => {
        queryClient.removeQueries(["library-files-invalid"]);
      },
    }
  );

  const getLibInfo = async () => {
    const projectId = currentProject?.uuid;
    const library = libraryId || "";
    if (projectId) {
      const response = await uploadApi.getLibrary(projectId, library);
      const lib: Library = await response.json();
      setIsSqlLibrary(lib.sourceType === "SQL");
      setCurrentLibrary(lib);
    }
  };

  function downloadSourceCodeClickHandler(): void {
    downloadLibraryFiles(
      setIsDownloadingSourceCode,
      currentProject,
      libraryId,
      currentLibrary
    );
  }

  useEffect(() => {
    if (libraryFiles && libraryFiles.length > 0) {
      setSelectedFile(libraryFiles[0]);
      getLibInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libraryFiles]);

  return (
    <MasterPage heading="Library" useBackButton>
      <PageTransition>
        <div id="content" className="h-full">
          <div className="flex h-full">
            <div className="shrink-0 px-6 py-7">
              <div className="w-80 flex flex-col bg-white rounded-lg shadow">
                {currentLibrary && (
                  <div className="flex items-center space-x-4 px-6 py-5 border-b border-gray-200">
                    <VendorAsset
                      type="icon"
                      platform={currentLibrary.platformName}
                      className="w-12 h-12"
                    />
                    <div className="flex flex-col min-w-0 w-full mr-auto">
                      <div
                        className="font-medium text-gray-900 truncate"
                        title={currentLibrary.platformName}
                      >
                        {currentLibrary.platformName}
                      </div>
                      <div
                        className="text-sm text-gray-500 truncate"
                        title={currentLibrary.name}
                      >
                        {currentLibrary.name}
                      </div>
                    </div>
                    <span className="bg-gray-100 rounded px-2 py-1 uppercase text-xs font-medium text-gray-600">
                      {currentLibrary.sourceType}
                    </span>
                  </div>
                )}
                <div className="py-2">
                  <h2 className="px-6 py-2 text-sm font-medium uppercase text-gray-500">
                    Library files
                    <span className="ml-2">
                      ({libraryFiles?.length.toLocaleString()})
                    </span>
                  </h2>
                  <div className="max-h-44 overflow-y-auto">
                    {libraryFiles &&
                      libraryFiles.map((file, idx) => {
                        const isCurrent = file.id === selectedFile?.id;
                        return (
                          <button
                            className={`group flex items-center w-full text-left px-6 py-2 text-sm ${
                              isCurrent
                                ? "text-primary-700 hover:text-primary-900 bg-primary-50"
                                : "text-gray-700 hover:text-gray-900 hover:bg-gray-100"
                            }`}
                            title={file.fileName}
                            key={`${file.id}_${idx}`}
                            onClick={() => {
                              setSelectedFile(libraryFiles[idx]);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={regular("file")}
                              size="lg"
                              className={`${
                                isCurrent
                                  ? "text-primary-600 group-hover:text-primary-800"
                                  : "text-gray-400 group-hover:text-gray-500"
                              }`}
                            />
                            <span className="ml-2.5 truncate">
                              {file.fileName}
                            </span>
                            {isCurrent && (
                              <FontAwesomeIcon
                                icon={solid("check")}
                                className="ml-auto"
                              />
                            )}
                          </button>
                        );
                      })}
                  </div>
                </div>
              </div>
              {(authUser?.isAdmin() ||
                currentClient?.clientRestriction?.allowDownload) && (
                <div className="mt-6">
                  <Button
                    label="Download files"
                    icon={solid("cloud-arrow-down")}
                    onClick={downloadSourceCodeClickHandler}
                    isLoading={isDownloadingSourceCode}
                    fullWidth
                  />
                </div>
              )}
            </div>
            <div className="flex flex-col w-full min-h-full h-fit border-l border-gray-200 bg-white overflow-hidden">
              <div className="flex items-center shrink-0 bg-gray-50 px-6 py-3 text-sm border-b-2 border-b-gray-200">
                <FontAwesomeIcon
                  icon={regular("file")}
                  className="w-4 h-4 text-primary-600"
                />
                <span className="font-medium ml-2">
                  {selectedFile?.fileName}
                </span>
                <span className="pl-4 ml-4 mr-auto border-l border-gray-300 text-gray-500">
                  {objCount.toLocaleString()} /{" "}
                  {selectedFile?.objectCount.toLocaleString()} objects
                </span>
                {(authUser?.isAdmin() ||
                  currentClient?.clientRestriction?.allowDownload) && (
                  <CopyCodeToClipboardButton clipboard={clipboard} />
                )}
                {!authUser?.isAdmin() &&
                  !currentClient?.clientRestriction?.allowDownload && (
                    <div className="flex p-2.5 bg-white text-gray-500 rounded-full shadow-sm ring-1 ring-black ring-opacity-5">
                      <FontAwesomeIcon
                        icon={solid("lock")}
                        className="w-3.5 h-3.5"
                      />
                    </div>
                  )}
              </div>
              {selectedFile && currentProject && libraryId && (
                <CodeView
                  selectedFile={selectedFile}
                  objCount={objCount}
                  setObjCount={setObjCount}
                  projectId={currentProject.uuid || ""}
                  libId={libraryId || ""}
                  fileId={selectedFile.id || -1}
                  language={isSqlLibrary ? "sql" : "xml"}
                  clipboard={clipboard}
                  setClipboard={setClipboard}
                />
              )}
            </div>
          </div>
        </div>
      </PageTransition>
    </MasterPage>
  );
}

export type { LibraryFile, LibInfo };
export default LibraryFiles;
