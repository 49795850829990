import { RefObject, useRef } from "react";
import { Client } from "../../../models/Client";
import { getFormattedDate } from "../../../utils/common";

interface ClientsListRowProps {
  client: Client;
  editClientClickHandler: (
    param: string,
    ref: RefObject<HTMLButtonElement>
  ) => void;
}

function ClientsListRow({
  client,
  editClientClickHandler,
}: ClientsListRowProps) {
  const editBtn = useRef<HTMLButtonElement>(null);

  return (
    <tr>
      <td className="whitespace-nowrap py-4 pr-3 text-sm pl-6 font-medium">
        {client.name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {getFormattedDate(client.dateCreated)}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {client.projectCount}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <span
          className={`inline-flex rounded-full px-3 py-1 text-xs ${
            client.isActive
              ? "bg-success-100 text-success-700"
              : "bg-gray-100 text-gray-600"
          }`}
        >
          {client.isActive ? "Active" : "Inactive"}
        </span>
      </td>
      <td className="relative whitespace-nowrap py-4 pl-3 text-right text-sm font-medium pr-6">
        <button
          ref={editBtn}
          className="px-1 text-primary-700 rounded-sm hover:text-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:pointer-events-none disabled:opacity-60"
          onClick={() => editClientClickHandler(client.id, editBtn)}
        >
          Edit
          <span className="sr-only">, {client.name}</span>
        </button>
      </td>
    </tr>
  );
}

function ClientsListRowSkeleton() {
  return (
    <tr>
      <td className="py-4 pr-3 pl-6 leading-6">
        <span className="inline-block rounded bg-gray-200 w-40 h-4 align-middle animate-pulse" />
      </td>
      <td className="px-3 py-4 leading-6">
        <span className="inline-block rounded bg-gray-200 w-20 h-4 align-middle animate-pulse" />
      </td>
      <td className="px-3 py-4 leading-6">
        <span className="inline-block rounded bg-gray-200 w-10 h-4 align-middle animate-pulse" />
      </td>
      <td className="px-3 py-4 leading-6">
        <span className="inline-block rounded bg-gray-200 w-16 h-4 align-middle animate-pulse" />
      </td>
      <td className="py-4 pl-3 pr-6 text-right leading-6">
        <span className="inline-block rounded bg-gray-200 w-8 h-4 align-middle animate-pulse" />
      </td>
    </tr>
  );
}

export { ClientsListRowSkeleton };
export default ClientsListRow;
