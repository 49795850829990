import { CommandPaletteProps } from "./CommandPalette.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass as farMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";

export function CommandPalette({
  placeholder = "Quick search...",
  ...props
}: CommandPaletteProps) {
  return (
    <>
      <button
        type="button"
        className="group flex items-center w-full pl-3 pr-4 py-2 border border-gray-300 shadow-sm text-sm rounded-md text-gray-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
      >
        <FontAwesomeIcon
          icon={farMagnifyingGlass}
          aria-hidden={true}
          className="w-4 h-4 mb-px mr-2 text-gray-400"
        />
        {placeholder}
        <div className="flex ml-auto space-x-1 text-gray-500">
          {["⌘", "K"].map((value, index) => (
            <kbd
              key={index}
              className={`w-5 h-5 leading-5 bg-gray-100 group-hover:bg-gray-200 rounded font-sans ${
                value !== "⌘" ? "text-xs" : ""
              }`}
            >
              {value}
            </kbd>
          ))}
        </div>
      </button>
    </>
  );
}
