import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RadioGroup } from "@headlessui/react";
import { useContext, useEffect, useState } from "react";
import { Button } from "../../_ui-kit";
import Input from "../../_ui-kit/Input/Input";
import InputGroup from "../../_ui-kit/InputGroup/InputGroup";
import { PageTransition } from "../../_ui-kit/PageTransition";
import { SlideOver } from "../../_ui-kit/SlideOver";
import { classNames } from "../../_ui-kit/utils";
import { User } from "../../models/User";
import { ConfigureContext } from "../../contexts/ConfigureContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ConfigurationManager from "../../_ui-kit/ConfigurationManager/ConfigurationManager";
import { Client } from "../../models/Client";
import { getArrayFromString } from "../../utils/common";
import { UserContext } from "../../contexts/UserContext";
import UsersListRow, { UsersListRowSkeleton } from "./components/UsersListRow";
import {
  ToggleSwitch,
  ToggleSwitchDescription,
  ToggleSwitchGroup,
  ToggleSwitchLabel,
} from "../../_ui-kit/ToggleSwitch";

type SortDirection = "desc" | "asc";

interface RolesListItem {
  id: string;
  name: string;
  description: string;
}

const rolesList: RolesListItem[] = [
  {
    id: User.userRoles.ADMIN,
    name: "Admin",
    description: "Full access to all clients and projects in the platform",
  },
  {
    id: User.userRoles.OWNER,
    name: "Owner",
    description: "Access to modify and view all projects within a client",
  },
  {
    id: User.userRoles.EDITOR,
    name: "Editor",
    description: "Access to modify and view assigned projects within a client",
  },
  {
    id: User.userRoles.REVIEWER,
    name: "Reviewer",
    description: "Limited Access to view assigned projects within a client",
  },
];

const providerList = [
  { id: "BASIC", name: "Next Pathway" },
  { id: "MICROSOFT", name: "Microsoft" },
];

function UsersAdministration() {
  const { authUser } = useContext(UserContext);
  const { configureApi, usersList, clientsList } = useContext(ConfigureContext);
  const [openSlideOver, setOpenSlideOver] = useState(false);
  const [sliderTitle, setSliderTitle] = useState("");
  const [usersListQuery, setUsersListQuery] = useState("");
  const [sortDirection, setSortDirection] = useState<SortDirection>("desc");
  const [editUser, setEditUser] = useState<User>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedRole, setSelectedRole] = useState<RolesListItem>();
  const [assignedClients, setAssignedClients] = useState<Client[]>([]);
  const filteredRolesList = rolesList.filter(
    (role) =>
      authUser?.isAdmin() ||
      (authUser?.isOwner() && role.name !== "Admin" && role.name !== "Owner")
  );
  const filteredUsersList =
    usersListQuery === ""
      ? usersList
      : usersList?.filter((item) => {
          return item.fullName
            .toLowerCase()
            .includes(usersListQuery.toLowerCase());
        });

  const queryClient = useQueryClient();

  const addUserMutation = useMutation(
    (newUser: any) => {
      return configureApi.addUser(
        newUser.fullName,
        newUser.email,
        newUser.role,
        newUser.isActive,
        newUser.userClients,
        newUser.provider
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users-list"]);
        queryClient.invalidateQueries(["clients-list"]);
        queryClient.invalidateQueries(["clients-list-context"]);
        queryClient.invalidateQueries(["projects-list"]);
        queryClient.invalidateQueries(["projects-list-context"]);
      },
    }
  );

  const updateUserMutation = useMutation(
    (currentUser: any) => {
      return configureApi.updateUser(
        currentUser.id,
        currentUser.fullName,
        currentUser.email,
        currentUser.role,
        currentUser.isActive,
        currentUser.userClients,
        currentUser.provider
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users-list"]);
        queryClient.invalidateQueries(["clients-list"]);
        queryClient.invalidateQueries(["clients-list-context"]);
        queryClient.invalidateQueries(["projects-list"]);
        queryClient.invalidateQueries(["projects-list-context"]);
      },
    }
  );

  function sortUsers(a: User, b: User) {
    if (sortDirection === "desc") {
      if (a.fullName < b.fullName) return -1;
      if (a.fullName > b.fullName) return 1;
    } else {
      if (a.fullName > b.fullName) return -1;
      if (a.fullName < b.fullName) return 1;
    }
    return 0;
  }

  function sortUsersClickHandler(): void {
    setSortDirection(sortDirection === "desc" ? "asc" : "desc");
  }

  function addUserClickHandler(): void {
    setSliderTitle("New user");
    setOpenSlideOver(true);
  }

  function editUserClickHandler(userId: number): void {
    if (usersList) {
      setSliderTitle("Edit user");
      setEditUser(usersList.filter((user) => user.id === userId)[0]);
      setOpenSlideOver(true);
    }
  }

  function handleSubmitHandler(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData(e.currentTarget);
    const isCurrentSameAsEdit = editUser?.id === authUser?.id;
    const userFullName = formData.get("user-fullname");
    const userEmail = editUser ? editUser.email : formData.get("user-email");
    const userRole = formData.get("user-role[id]");
    const userIsActive =
      !isCurrentSameAsEdit && editUser
        ? formData.get("user-isactive") === "on"
        : true;
    var userClients: string[] = [];
    if (authUser?.isOwner()) {
      userClients = authUser.userClients;
    } else if (authUser?.isAdmin()) {
      userClients = getArrayFromString(
        formData.get("user-clients[selected]") as string
      );
    }
    const userProvider = formData.get("user-provider");

    if (editUser && selectedRole) {
      updateUserMutation.mutate(
        {
          id: editUser.id,
          fullName: userFullName,
          email: userEmail,
          role: userRole,
          isActive: userIsActive,
          userClients: userClients,
          provider: userProvider,
        },
        {
          onSettled: () => {
            closeSlideOver(false);
          },
        }
      );
    } else {
      addUserMutation.mutate(
        {
          fullName: userFullName,
          email: userEmail,
          role: userRole,
          isActive: userIsActive,
          userClients: userClients,
          provider: userProvider,
        },
        {
          onSettled: () => {
            closeSlideOver(false);
          },
        }
      );
    }
  }

  function closeSlideOver(close: boolean): void {
    setIsSubmitting(close);
    setOpenSlideOver(close);
    setTimeout(() => {
      setSelectedRole(undefined);
      setEditUser(undefined);
      setAssignedClients([]);
    }, 750);
  }

  useEffect(() => {
    if (editUser) {
      setSelectedRole(
        filteredRolesList.filter((role) => role.id === editUser.role)[0]
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editUser]);

  useEffect(() => {
    if (openSlideOver && !editUser) {
      if (clientsList && clientsList.length === 1) {
        setAssignedClients([clientsList[0]]);
      }
      setSelectedRole(filteredRolesList[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSlideOver]);

  return (
    <PageTransition>
      <div id="content" className="px-6 py-9 xl:max-w-5xl xl:mx-auto">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <h2 className="flex items-center text-lg text-gray-900 font-medium">
              Users
              {!usersList && (
                <span className="inline-flex px-3 ml-1 text-gray-300">
                  <FontAwesomeIcon
                    icon={solid("spinner")}
                    spin={true}
                    className="w-6 h-6"
                    aria-hidden={true}
                  />
                </span>
              )}
              {usersList && (
                <span className="rounded-full bg-gray-200 px-3 py-1 ml-3 text-sm text-gray-700 font-bold">
                  {usersList.length}
                </span>
              )}
            </h2>
            <Button
              label="Add user"
              icon={solid("plus")}
              onClick={addUserClickHandler}
            />
          </div>
          <div>
            <label htmlFor="searchUsers" className="sr-only">
              Search users
            </label>
            <div className="flex rounded-md shadow-sm">
              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3.5">
                  <FontAwesomeIcon
                    icon={regular("search")}
                    className="h-3.5 w-3.5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  id="searchUsers"
                  className="block w-full rounded-none rounded-l-md border-gray-300 pl-9 focus:border-primary-500 focus:ring-primary-500 text-sm"
                  placeholder="Search"
                  onChange={(event) => setUsersListQuery(event.target.value)}
                  disabled={!usersList}
                />
              </div>
              <button
                type="button"
                className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                onClick={sortUsersClickHandler}
                disabled={!usersList}
              >
                <FontAwesomeIcon
                  icon={
                    sortDirection === "desc"
                      ? solid("arrow-down-short-wide")
                      : solid("arrow-up-short-wide")
                  }
                  className="h-4 w-4 text-gray-400"
                  aria-hidden="true"
                />
                <span>Sort</span>
              </button>
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-col">
          <div className="overflow-x-auto -mx-1">
            <div className="inline-block min-w-full py-2 align-middle px-1">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 text-left text-sm font-medium pr-3 pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-medium"
                      >
                        Clients
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-medium"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-medium"
                      >
                        Role
                      </th>
                      <th scope="col" className="relative py-3.5 pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {filteredUsersList &&
                      filteredUsersList
                        .sort(sortUsers)
                        .map((user) => (
                          <UsersListRow
                            key={user.id}
                            user={user}
                            editUserClickHandler={editUserClickHandler}
                          />
                        ))}
                    {!usersList &&
                      [...Array(5)].map((_r, i) => (
                        <UsersListRowSkeleton key={`skeleton-${i}`} />
                      ))}
                    {filteredUsersList && filteredUsersList.length < 1 && (
                      <tr>
                        <td
                          className="px-6 py-5 text-sm leading-8 text-gray-500"
                          colSpan={5}
                        >
                          No users found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <SlideOver
          title={sliderTitle}
          show={openSlideOver}
          onClose={isSubmitting ? () => {} : closeSlideOver}
          scroll={false}
        >
          <div className="h-full border-t border-t-gray-200 bg-white overflow-y-auto">
            <div className="flex flex-col px-6 py-5">
              <form onSubmit={handleSubmitHandler}>
                <div className="flex flex-col space-y-8">
                  <InputGroup
                    label="Full name"
                    inputId="user-fullname"
                    className="text-sm"
                  >
                    <Input
                      id="user-fullname"
                      name="user-fullname"
                      type="text"
                      autoComplete="name"
                      required={true}
                      defaultValue={editUser?.fullName}
                    />
                  </InputGroup>
                  <InputGroup
                    label="Email"
                    inputId="user-email"
                    className="text-sm"
                  >
                    <Input
                      id="user-email"
                      name="user-email"
                      type="email"
                      autoComplete="email"
                      defaultValue={editUser?.email}
                      required={editUser ? false : true}
                      disabled={editUser ? true : false}
                    />
                  </InputGroup>
                  <RadioGroup
                    value={selectedRole || null}
                    name="user-role"
                    onChange={setSelectedRole}
                    disabled={
                      editUser?.id === authUser?.id && authUser?.isAdmin()
                    }
                  >
                    <RadioGroup.Label className="block mb-3 font-medium">
                      Roles
                    </RadioGroup.Label>
                    <div className="-space-y-px rounded-md bg-white shadow-sm">
                      {filteredRolesList.map((role, index, list) => (
                        <RadioGroup.Option
                          key={role.id}
                          value={role}
                          className={({ checked }) =>
                            classNames(
                              index === 0 ? "rounded-tl-md rounded-tr-md" : "",
                              index === list.length - 1
                                ? "rounded-bl-md rounded-br-md"
                                : "",
                              checked
                                ? "bg-primary-50 border-primary-200 z-10"
                                : "border-gray-200",
                              "relative border p-4 flex cursor-pointer focus:outline-none"
                            )
                          }
                        >
                          {({ active, checked }) => (
                            <>
                              <span
                                className={classNames(
                                  checked
                                    ? "bg-primary-600 border-transparent"
                                    : "bg-white border-gray-300",
                                  active
                                    ? "ring-2 ring-offset-2 ring-primary-500"
                                    : "",
                                  "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                )}
                                aria-hidden="true"
                              >
                                <span className="rounded-full bg-white w-1.5 h-1.5" />
                              </span>
                              <span className="ml-3 flex flex-col">
                                <RadioGroup.Label
                                  as="span"
                                  className={classNames(
                                    checked
                                      ? "text-primary-900"
                                      : "text-gray-900",
                                    "block text-sm font-medium"
                                  )}
                                >
                                  {role.name}
                                </RadioGroup.Label>
                                <RadioGroup.Description
                                  as="span"
                                  className={classNames(
                                    checked
                                      ? "text-primary-700"
                                      : "text-gray-500",
                                    "block text-sm"
                                  )}
                                >
                                  {role.description}
                                </RadioGroup.Description>
                              </span>
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                  {editUser && editUser.id !== authUser?.id && (
                    <div className="!mt-10 bg-gray-100 rounded-lg">
                      <ToggleSwitchGroup as="div" className="p-4">
                        <ToggleSwitchLabel
                          as="h3"
                          className="font-medium leading-5"
                          passive
                        >
                          Activate user account
                        </ToggleSwitchLabel>
                        <div className="mt-2 flex items-start justify-between">
                          <div className="text-sm text-gray-500">
                            <ToggleSwitchDescription>
                              Activating user accounts is essential for allowing
                              them access to the platform so they can start
                              their migration process. Only active users will
                              have access to the system.
                            </ToggleSwitchDescription>
                          </div>
                          <div className="ml-6 flex flex-shrink-0 items-center">
                            <ToggleSwitch
                              id="user-isactive"
                              name="user-isactive"
                              defaultChecked={editUser.isActive}
                            />
                          </div>
                        </div>
                      </ToggleSwitchGroup>
                    </div>
                  )}
                  {selectedRole?.name !== "Admin" && (
                    <ConfigurationManager
                      id={"user-clients"}
                      label="Assigned client spaces"
                      description="Search by client name"
                      placeholder={"Name"}
                      listItems={clientsList || []}
                      selectedItems={assignedClients}
                      setSelectedItems={setAssignedClients}
                      defaultValue={editUser?.userClients || []}
                      className="text-base"
                      readonly={
                        clientsList &&
                        clientsList.length === 1 &&
                        assignedClients.length === 1
                      }
                    />
                  )}
                  <InputGroup
                    label="Provider"
                    inputId="user-provider"
                    className="text-sm"
                  >
                    <select
                      className="mt-1 block w-full pl-3 pr-10 py-2 rounded-md shadow-sm text-sm border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                      id="user-provider"
                      name="user-provider"
                      defaultValue={editUser?.provider}
                      required
                    >
                      {providerList.map((provider) => (
                        <option key={provider.id} value={provider.id}>
                          {provider.name}
                        </option>
                      ))}
                    </select>
                  </InputGroup>
                </div>
                <div className="self-start mt-8">
                  <Button
                    type="submit"
                    label={editUser ? "Save changes" : "Add user"}
                    size="lg"
                    icon={editUser ? solid("floppy-disk") : solid("plus")}
                    isLoading={isSubmitting}
                  />
                </div>
              </form>
            </div>
          </div>
        </SlideOver>
      </div>
    </PageTransition>
  );
}

export default UsersAdministration;
