const { REACT_APP_API_URL, REACT_APP_API_VERSION, REACT_APP_CORE_TYPE } =
  process.env;

exports.getApiUrl = function () {
  if (REACT_APP_API_URL) return REACT_APP_API_URL;
  return "/";
};

exports.getApiVersion = function () {
  if (REACT_APP_API_VERSION) return +REACT_APP_API_VERSION;
  return -1;
};

exports.isCrawlerCore = function () {
  return REACT_APP_CORE_TYPE?.toUpperCase() === "CRAWLER";
};

exports.isShiftCore = function () {
  return REACT_APP_CORE_TYPE?.toUpperCase() === "SHIFT";
};
