import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { redirect } from "react-router-dom";
import SettingsApi from "../../api/SettingsApi";
import { UserContext } from "../../contexts/UserContext";
import { MasterPage } from "../../_ui-kit/MasterPage";
import { PageLoaderSpinner } from "../../_ui-kit/PageLoderSpinner";
import { PageTransition } from "../../_ui-kit/PageTransition";

interface HealthCheckStatus {
  database: number;
  runners: number;
  kafka: number;
  ui: number;
}

function HealthCheck() {
  const settingsApi = new SettingsApi();
  const { authUser } = useContext(UserContext);

  if (!authUser?.isAdmin()) redirect("/");

  const { data: healthCheckStatus, isLoading } = useQuery(
    ["heatlh-check"],
    async () => {
      const result = await settingsApi.getHealthCheckStatus();
      const status: HealthCheckStatus = await result.json();
      return status;
    },
    { staleTime: 0 }
  );

  function getStatusDescription(status: string): string {
    switch (status) {
      case "database":
        return "SHIFT Cloud database.";
      case "runners":
        return "SHIFT Cloud Analysis and Translation engines.";
      case "kafka":
        return "SHIFT Cloud messaging system for the runners.";
      case "ui":
        return "SHIFT Cloud user interface services.";
      default:
        return status;
    }
  }

  return (
    <MasterPage heading="Health check" useBackButton>
      {healthCheckStatus && (
        <PageTransition>
          <div id="content" className="px-6 py-10 xl:max-w-5xl xl:mx-auto">
            <h2 className="mb-4 text-lg text-gray-900 font-medium">
              System status
            </h2>
            <div className="bg-white shadow ring-1 ring-black ring-opacity-5 rounded-lg">
              <div className="px-6 py-4">
                <div className="max-w-lg flex flex-col divide-y divide-gray-300 mx-auto">
                  {Object.entries(healthCheckStatus).map(([key, value]) => {
                    return (
                      <div
                        key={key}
                        className="flex items-center justify-between py-4"
                      >
                        <div className="space-y-1">
                          <div className="flex items-center space-x-3 text-lg font-medium leading-none">
                            <span className="uppercase">{key}</span>
                            {key.toLocaleLowerCase() === "runners" && (
                              <>
                                <FontAwesomeIcon
                                  icon={solid("arrow-right")}
                                  className="text-gray-400"
                                  size="sm"
                                />
                                <span
                                  className={
                                    value > 0
                                      ? "text-success-600"
                                      : "text-danger-600"
                                  }
                                >
                                  {value} Active
                                </span>
                              </>
                            )}
                          </div>
                          <div className="text-gray-500">
                            {getStatusDescription(key)}
                          </div>
                        </div>
                        <FontAwesomeIcon
                          icon={
                            value > 0
                              ? solid("circle-check")
                              : solid("circle-xmark")
                          }
                          size="xl"
                          className={
                            value > 0 ? "text-success-600" : "text-danger-600"
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </PageTransition>
      )}
      {!healthCheckStatus && isLoading && <PageLoaderSpinner />}
    </MasterPage>
  );
}

export default HealthCheck;
