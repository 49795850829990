import { Switch as HeadlessSwitch } from "@headlessui/react";
import { classNames } from "../utils";
import {
  ToggleSwitchDescriptionProps,
  ToggleSwitchGroupProps,
  ToggleSwitchLabelProps,
  ToggleSwitchProps,
} from "./ToggleSwitch.types";

function ToggleSwitch({
  defaultChecked,
  checked,
  onChange,
  as,
  id,
  name,
  label,
  className,
  refName,
}: ToggleSwitchProps) {
  return (
    <HeadlessSwitch
      as={as}
      id={id}
      refName={refName}
      name={name}
      defaultChecked={defaultChecked}
      checked={checked}
      onChange={onChange}
    >
      {({ checked }) => (
        <div
          className={classNames(
            "relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2",
            checked ? "bg-primary-600" : "bg-gray-200",
            className ? className : ""
          )}
        >
          {label && <span className="sr-only">{label}</span>}
          <span
            aria-hidden="true"
            className={classNames(
              checked ? "translate-x-5" : "translate-x-0",
              "inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            )}
          />
        </div>
      )}
    </HeadlessSwitch>
  );
}

function ToggleSwitchGroup({
  as,
  className,
  refName,
  children,
}: ToggleSwitchGroupProps) {
  return (
    <HeadlessSwitch.Group as={as} className={className} refName={refName}>
      {children}
    </HeadlessSwitch.Group>
  );
}

function ToggleSwitchLabel({
  as,
  className,
  refName,
  passive,
  children,
}: ToggleSwitchLabelProps) {
  return (
    <HeadlessSwitch.Label
      as={as}
      className={className}
      refName={refName}
      passive={passive}
    >
      {children}
    </HeadlessSwitch.Label>
  );
}

function ToggleSwitchDescription({
  as,
  className,
  refName,
  children,
}: ToggleSwitchDescriptionProps) {
  return (
    <HeadlessSwitch.Description as={as} className={className} refName={refName}>
      {children}
    </HeadlessSwitch.Description>
  );
}

export {
  ToggleSwitch,
  ToggleSwitchGroup,
  ToggleSwitchLabel,
  ToggleSwitchDescription,
};
