import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AuthorizationApi from "../../api/AuthorizationApi";
import { AuthRouteLocationState } from "../../routes/components/AuthorizationRoute";
import { MasterPage } from "../../_ui-kit/MasterPage";
import { PageLoaderSpinner } from "../../_ui-kit/PageLoderSpinner";

interface SingInProps {
  authApi: AuthorizationApi;
}

export default function UserWelcome({ authApi }: SingInProps) {
  const navigate = useNavigate();
  const locationState = useLocation().state as AuthRouteLocationState;
  const [userEmail, setUserEmail] = useState("");
  const [instructionsSent, setInstructionsSent] = useState(false);
  const [searchParams] = useSearchParams();

  function requestPasswordSetup(email: string): void {
    const sendPassword = async () => {
      try {
        await authApi.requestPasswordReset(email, false);
        setInstructionsSent(true);
      } catch (ex: any) {
        navigate("/sign-in", {
          state: {
            from: {
              error: "Error sending password setup instructions.",
            },
          },
          replace: true,
        });
        console.log(ex);
      }
    };

    sendPassword();
  }

  useEffect(() => {
    window.history.replaceState(null, "");
  }, [locationState]);

  useEffect(() => {
    setUserEmail(searchParams.get("email") || "-");
  }, [searchParams]);

  useEffect(() => {
    const isValid =
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userEmail) ||
      userEmail === "";

    if (userEmail === "-" || !isValid) {
      navigate("/sign-in", {
        state: {
          from: {
            error: "Error validating email address.",
          },
        },
        replace: true,
      });
    } else if (userEmail) {
      requestPasswordSetup(userEmail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail]);

  return (
    <MasterPage type="signin" heading="Welcome">
      {instructionsSent && (
        <>
          <h2 className="mb-3 text-4xl font-bold text-gray-900">
            Welcome to SHIFT&trade; Cloud!
          </h2>
          <p className="text-gray-600">
            Your password setup instructions have been sent to{" "}
            <strong className="font-medium text-gray-900">{userEmail}</strong>.
            Keep an eye on your inbox because we'll send notifications as you
            work through your code notification pipelines.
          </p>
        </>
      )}
      {!instructionsSent && <PageLoaderSpinner />}
    </MasterPage>
  );
}
