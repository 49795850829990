import { useEffect, useState } from "react";
import { TempBarChartProps } from "./BarChart.types";

function sumAggregates(
  exclusive: number,
  readingOthers: number,
  writingOthers: number,
  readingWritingOthers: number
): number {
  return exclusive + readingOthers + writingOthers + readingWritingOthers;
}

export function TempBarChart({
  exclusive,
  readingOthers,
  writingOthers,
  readingWritingOthers,
}: TempBarChartProps) {
  const [total, setTotal] = useState(
    sumAggregates(exclusive, readingOthers, writingOthers, readingWritingOthers)
  );

  function getPercentage(count: number): number {
    return (count / total) * 100;
  }

  useEffect(() => {
    setTotal(
      sumAggregates(
        exclusive,
        readingOthers,
        writingOthers,
        readingWritingOthers
      )
    );
  }, [exclusive, readingOthers, writingOthers, readingWritingOthers]);

  return (
    <>
      <div className="bg-gray-300 px-0.5">
        <span className="sr-only">Jobs distribution</span>
        <div className="flex h-12 py-2 bg-white">
          <div
            className="bg-secondary-300"
            style={{ width: `${getPercentage(exclusive)}%` }}
          ></div>
          <div
            className="bg-secondary-500"
            style={{ width: `${getPercentage(readingOthers)}%` }}
          ></div>
          <div
            className="bg-secondary-700"
            style={{ width: `${getPercentage(writingOthers)}%` }}
          ></div>
          <div
            className="bg-secondary-900"
            style={{ width: `${getPercentage(readingWritingOthers)}%` }}
          ></div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-x-12 gap-y-3 mt-4 px-4 py-3 bg-gray-100 rounded text-sm">
        <div className="flex items-center justify-between">
          <div className="flex items-center grow min-w-0 mr-3 text-gray-500">
            <div className="w-2.5 h-2.5 mr-2 bg-secondary-300 rounded-full"></div>
            <span className="truncate grow">Exclusive jobs</span>
          </div>
          <div className="font-medium">{exclusive}</div>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center grow min-w-0 mr-3 text-gray-500">
            <div className="w-2.5 h-2.5 mr-2 bg-secondary-500 rounded-full"></div>
            <span className="truncate grow">Reading other</span>
          </div>
          <div className="font-medium">{readingOthers}</div>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center grow min-w-0 mr-3 text-gray-500">
            <div className="w-2.5 h-2.5 mr-2 bg-secondary-700 rounded-full"></div>
            <span className="truncate grow">Writing other</span>
          </div>
          <div className="font-medium">{writingOthers}</div>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center grow min-w-0 mr-3 text-gray-500">
            <div className="w-2.5 h-2.5 mr-2 bg-secondary-900 rounded-full"></div>
            <span className="truncate grow">Reading &amp; writing other</span>
          </div>
          <div className="font-medium">{readingWritingOthers}</div>
        </div>
      </div>
    </>
  );
}
