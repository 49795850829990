import { useQuery, useQueryClient } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useState } from "react";
import ConfigureApi from "../api/ConfigureApi";
import { Project } from "../models/Project";
import { ClientContext } from "./ClientContext";
import { UserContext } from "./UserContext";

interface ProjectContextProps {
  isLoadingProjectListInContext: boolean;
  projectsListInContext?: Project[];
  currentProject?: Project;
  setCurrentProject: (param: Project) => void;
}

interface ProjectContextStateProps {
  children: React.ReactNode;
}

export const ProjectContext = createContext<ProjectContextProps>(
  {} as ProjectContextProps
);

function ProjectContextState({ children }: ProjectContextStateProps) {
  const configureApi = new ConfigureApi();
  const { currentClient } = useContext(ClientContext);
  const { authUser } = useContext(UserContext);
  const [currentProject, setCurrentProject] = useState<Project>();
  const queryClient = useQueryClient();
  const {
    data: projectsListInContext,
    isLoading: isLoadingProjectListInContext,
  } = useQuery(
    [
      `projects-list-context${!currentClient ? "-invalid" : ""}`,
      {
        clientId: currentClient?.id,
      },
    ],
    async () => {
      const clientId = currentClient?.id || "";
      var result;
      if (authUser?.isAdmin() || authUser?.isOwner()) {
        result = await configureApi.getProjectsByClient(clientId);
      } else {
        result = await configureApi.getProjects();
      }
      const projects: Project[] = await result.json();

      projects.sort(
        (a, b) => +new Date(a.dateCreated) - +new Date(b.dateCreated)
      );

      if (authUser?.isEditor() || authUser?.isReviewer()) {
        return projects.filter(
          (project) => project.clientId === currentClient?.id
        );
      }

      return projects;
    },
    {
      enabled: !!currentClient,
      onError: (ex) => {
        console.error(ex);
      },
      onSuccess: () => {
        queryClient.removeQueries(["projects-list-context-invalid"]);
      },
    }
  );

  useEffect(() => {
    if (projectsListInContext) {
      if (currentProject) {
        const selected = projectsListInContext.find(
          (project) => project.uuid === currentProject.uuid
        );
        if (!selected) setCurrentProject(projectsListInContext[0]);
      } else setCurrentProject(projectsListInContext[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsListInContext, currentClient]);

  return (
    <ProjectContext.Provider
      value={{
        isLoadingProjectListInContext,
        projectsListInContext,
        currentProject,
        setCurrentProject,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
}

export default ProjectContextState;
