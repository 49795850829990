import { classNames } from "../../../_ui-kit/utils";

interface ChartLegendProps {
  category: string;
  count?: string;
  percentage?: number;
  indicatorColor?: string;
}

function ChartLegend({
  category,
  count = "0",
  percentage = 0,
  indicatorColor = "bg-gray-600",
}: ChartLegendProps) {
  return (
    <div className="flex items-center text-xs leading-5">
      <dt className="grow flex items-center space-x-2 text-gray-500">
        <span
          className={classNames("w-2 h-2 rounded-full", indicatorColor)}
          role="presentation"
          aria-hidden={true}
        />
        <span>
          <span className="font-medium">{count}</span> {category}
        </span>
      </dt>
      <dd className="text-gray-900 font-bold">{percentage}%</dd>
    </div>
  );
}

export default ChartLegend;
