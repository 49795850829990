import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faBan as fasBan } from "@fortawesome/pro-solid-svg-icons";
import { faSpinnerThird as fasSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu } from "@headlessui/react";
import { useEffect, useState } from "react";
import TasksApi from "../../../api/TasksApi";
import { classNames } from "../../../_ui-kit/utils";
import { TaskStatus } from "./TasksList";

interface TaskListRowMenuItemProps {
  isTranslate: boolean;
  actionId: string;
  actionLabel: string;
  actionIcon: IconDefinition;
  actionIsImportant: boolean;
  projectId: string;
  taskId: string;
  taskStatus: TaskStatus;
  isAdmin: boolean;
}

function TaskListRowMenuItem({
  isTranslate,
  actionId,
  actionLabel,
  actionIcon,
  actionIsImportant,
  projectId,
  taskId,
  taskStatus,
  isAdmin,
}: TaskListRowMenuItemProps) {
  const tasksApi = new TasksApi();
  const [errorDownloadingTaskLog, setErrorDownloadingTaskLog] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [icon, setIcon] = useState(actionIcon);
  const [label, setLabel] = useState(actionLabel);

  function taskActionMenuItemClickHandler(
    e: React.MouseEvent<HTMLButtonElement>,
    action: string
  ): void {
    e.stopPropagation();
    e.preventDefault();

    switch (action) {
      case "download-logs":
        downloadLogs();
        break;
      case "cancel-task":
        cancelTask();
        break;
      case "delete-task":
        deleteTask();
        break;
    }
  }

  function downloadLogs(): void {
    const downloadLogs = async (
      projectId: string,
      taskId: string,
      type: "analyze" | "translate"
    ) => {
      try {
        const response = await tasksApi.downloadTaskLogs(
          projectId,
          taskId,
          type
        );
        if (response.ok) {
          const result = await response.blob();
          let url = URL.createObjectURL(result);
          let anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = `${
            isTranslate ? "Translate" : "Analyze"
          } task log.zip`;
          anchor.style.display = "none";
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          URL.revokeObjectURL(url);
        } else {
          console.log(response.status, response.statusText);
        }
        setIsLoading(false);
      } catch (ex) {
        console.log(ex);
        setIsLoading(false);
        setErrorDownloadingTaskLog(true);
      }
    };

    setIsLoading(true);
    downloadLogs(projectId, taskId, isTranslate ? "translate" : "analyze");
  }

  function cancelTask(): void {
    const cancel = async () => {
      try {
        const response = await tasksApi.cancelTask(projectId, taskId);
        if (!response.ok) {
          setIsLoading(false);
          console.log(response.status, response.statusText);
        }
      } catch (ex) {
        console.log(ex);
        setIsLoading(false);
      }
    };

    setIsLoading(true);
    cancel();
  }

  function deleteTask(): void {
    const deleteTask = async () => {
      try {
        const response = await tasksApi.deleteTask(projectId, taskId, isAdmin);
        if (!response.ok) {
          setIsLoading(false);
          console.log(response.status, response.statusText);
        }
      } catch (ex) {
        console.log(ex);
        setIsLoading(false);
      }
    };

    setIsLoading(true);
    deleteTask();
  }

  useEffect(() => {
    if (isLoading) {
      setIsDisabled(true);
      setIcon(fasSpinnerThird);
    } else {
      setIsDisabled(false);
      setIcon(actionIcon);
    }

    if (actionId === "cancel-task") {
      if (
        (!isTranslate && taskStatus === TaskStatus.SUBMITTED) ||
        (!isTranslate && taskStatus === TaskStatus.ANALYZING) ||
        (isTranslate && taskStatus === TaskStatus.TRANSLATING) ||
        (isTranslate && taskStatus === TaskStatus.TESTING)
      )
        setIsVisible(true);
      else setIsVisible(false);
    } else if (actionId === "download-logs") {
      if (errorDownloadingTaskLog) {
        setIsDisabled(true);
        setIcon(fasBan);
        setLabel("Unavailable");
      }
    }
  }, [
    isTranslate,
    taskStatus,
    actionId,
    actionIcon,
    actionLabel,
    isLoading,
    isVisible,
    errorDownloadingTaskLog,
  ]);

  if (isVisible)
    return (
      <Menu.Item disabled={isDisabled}>
        {({ active, disabled }) => (
          <button
            className={classNames(
              active && !disabled
                ? `bg-gray-100 ${
                    actionIsImportant ? "text-danger-900" : "text-gray-900"
                  }`
                : `${actionIsImportant ? "text-danger-700" : "text-gray-700"}`,
              "flex items-center w-full text-left px-4 py-2 text-sm disabled:pointer-events-none disabled:opacity-60"
            )}
            onClick={(e) => taskActionMenuItemClickHandler(e, actionId)}
            disabled={disabled}
          >
            <FontAwesomeIcon
              icon={icon}
              className={`mr-2.5 ${
                active
                  ? `${actionIsImportant ? "text-danger-700" : "text-gray-500"}`
                  : `${actionIsImportant ? "text-danger-600" : "text-gray-400"}`
              } ${isLoading ? "animate-spin" : ""}`}
              aria-hidden={true}
              fixedWidth
            />
            {label}
          </button>
        )}
      </Menu.Item>
    );
  else return null;
}

export default TaskListRowMenuItem;
