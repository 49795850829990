import BaseApi from "./BaseApi";

export default class DiscoverApi extends BaseApi {
  apiGetETLPlatforms = `${this.baseUrl}/clients/{{clientId}}/{{projectId}}/etlplatforms`;
  apiAddETLPlatforms = `${this.baseUrl}/{{projectId}}/etlplatforms`;
  apiGetETLPlatformsSummary = `${this.baseUrl}/clients/{{clientId}}/{{projectId}}/etlplatforms/summary`;
  apiGetDataPlatformsSummary = `${this.baseUrl}/clients/{{clientId}}/{{projectId}}/dataplatforms/summary`;
  apiGetMigratingDataWarehouses = `${this.baseUrl}/{{projectId}}/etlplatforms/{{etlId}}/datawarehouses`;
  apiGetMigratingDataWarehousesAggregates = `${this.baseUrl}/{{projectId}}/etlplatforms/{{etlId}}/aggregates`;
  apiGetMigratingControlFLows = `${this.baseUrl}/{{projectId}}/etlplatforms/{{etlId}}/controlflows/summary`;
  apiGetMigratingDataFlows = `${this.baseUrl}/{{projectId}}/etlplatforms/{{etlId}}/dataflows/summary`;
  apiGetMigratingSources = `${this.baseUrl}/{{projectId}}/etlplatforms/{{etlId}}/datasources/sources`;
  apiGetMigratingSinks = `${this.baseUrl}/{{projectId}}/etlplatforms/{{etlId}}/datasources/sinks`;

  addETLPlatform(
    clientId: string,
    projectId: string,
    etlPlatformType: string,
    folder: string,
    name: string,
    repository: string
  ) {
    const promise = this.doFetch(
      `${this.apiAddETLPlatforms.replace(
        "{{projectId}}",
        encodeURIComponent(projectId)
      )}`,
      {
        method: "POST",
        headers: this.getAuthApplicationJsonHeaders(),
        body: JSON.stringify({
          clientId,
          etlPlatformType,
          folder,
          name,
          repository,
        }),
      }
    );
    return promise;
  }

  getETLPlatforms(clientId: string, projectId: string): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetETLPlatforms
        .replace("{{clientId}}", encodeURIComponent(clientId))
        .replace("{{projectId}}", encodeURIComponent(projectId))}?pageSize=50`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getETLPlatformsSummary(
    clientId: string,
    projectId: string
  ): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetETLPlatformsSummary
        .replace("{{clientId}}", encodeURIComponent(clientId))
        .replace("{{projectId}}", encodeURIComponent(projectId))}?pageSize=50`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getDataPlatformsSummary(
    clientId: string,
    projectId: string
  ): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetDataPlatformsSummary
        .replace("{{clientId}}", encodeURIComponent(clientId))
        .replace("{{projectId}}", encodeURIComponent(projectId))}?pageSize=50`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getMigratingDataWarehouses(
    projectId: string,
    etlId: string
  ): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetMigratingDataWarehouses
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{etlId}}", encodeURIComponent(etlId))}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getMigratingDataWarehouseAggregates(
    projectId: string,
    etlId: string,
    migratingdw: string
  ): Promise<Response> {
    const promise = this.doFetch(
      `${this.apiGetMigratingDataWarehousesAggregates
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace(
          "{{etlId}}",
          encodeURIComponent(etlId)
        )}?migratingdw=${migratingdw}`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getMigratingControlFlows(
    projectId: string,
    etlId: string,
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortDirection: string,
    searchFilter: string | null,
    migratingWarehouse: string
  ): Promise<Response> {
    const queryString: any = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortDirection: sortDirection,
      migratingWarehouse: migratingWarehouse,
    };
    if (searchFilter) queryString.searchFilter = searchFilter;
    const promise = this.doFetch(
      `${this.apiGetMigratingControlFLows
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{etlId}}", encodeURIComponent(etlId))}${
        this.isObjEmpty(queryString)
          ? ""
          : `?${this.objToQueryString(queryString)}`
      }`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getMigratingDataFlows(
    projectId: string,
    etlId: string,
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortDirection: string,
    searchFilter: string | null,
    migratingWarehouse: string
  ): Promise<Response> {
    const queryString: any = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortDirection: sortDirection,
      migratingWarehouse: migratingWarehouse,
    };
    if (searchFilter) queryString.searchFilter = searchFilter;
    const promise = this.doFetch(
      `${this.apiGetMigratingDataFlows
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{etlId}}", encodeURIComponent(etlId))}${
        this.isObjEmpty(queryString)
          ? ""
          : `?${this.objToQueryString(queryString)}`
      }`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getMigratingSources(
    projectId: string,
    etlId: string,
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortDirection: string,
    searchFilter: string | null,
    migratingWarehouse: string
  ): Promise<Response> {
    const queryString: any = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortDirection: sortDirection,
      migratingWarehouse: migratingWarehouse,
    };
    if (searchFilter) queryString.searchFilter = searchFilter;
    const promise = this.doFetch(
      `${this.apiGetMigratingSources
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{etlId}}", encodeURIComponent(etlId))}${
        this.isObjEmpty(queryString)
          ? ""
          : `?${this.objToQueryString(queryString)}`
      }`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }

  getMigratingSinks(
    projectId: string,
    etlId: string,
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortDirection: string,
    searchFilter: string | null,
    migratingWarehouse: string
  ): Promise<Response> {
    const queryString: any = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortDirection: sortDirection,
      migratingWarehouse: migratingWarehouse,
    };
    if (searchFilter) queryString.searchFilter = searchFilter;
    const promise = this.doFetch(
      `${this.apiGetMigratingSinks
        .replace("{{projectId}}", encodeURIComponent(projectId))
        .replace("{{etlId}}", encodeURIComponent(etlId))}${
        this.isObjEmpty(queryString)
          ? ""
          : `?${this.objToQueryString(queryString)}`
      }`,
      {
        method: "GET",
        headers: this.getAuthApplicationJsonHeaders(),
      }
    );
    return promise;
  }
}
