import { classNames } from "../utils";
import SelectProps from "./Select.types";

export default function Select({
  id,
  name,
  className = "",
  children,
  ariaDescribedby,
  autoComplete,
  disabled,
  multiple,
  required,
  size,
  value,
  onChange,
  onBlur,
  isInvalid,
  hasAddon,
}: SelectProps) {
  const defaultClassName =
    "block w-full pl-3 pr-10 py-2 rounded-md shadow-sm text-sm border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500";

  return (
    <select
      id={id}
      name={name}
      className={classNames(defaultClassName, className)}
      aria-describedby={ariaDescribedby}
      autoComplete={autoComplete}
      disabled={disabled}
      required={required}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      multiple={multiple}
    >
      {children}
    </select>
  );
}
