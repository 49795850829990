import { MasterPage } from "../../_ui-kit/MasterPage";
import type { ChartData } from "chart.js";
import { PageTransition } from "../../_ui-kit/PageTransition";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Fragment, useContext, useEffect, useState } from "react";
import {
  BarChart,
  HorizontalBarChart,
  DoughnutChart,
  chartColorPalettes,
} from "../../_ui-kit/Charts/Charts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Combobox, Transition } from "@headlessui/react";
import { ProjectContext } from "../../contexts/ProjectContext";
import AnalyzeApi from "../../api/AnalyzeApi";

export interface Category {
  id: number;
  name: string;
  totalConnections: number;
  totalJobs: number;
}

export interface SubCategory {
  application: string;
  category: string;
  subCateory: string;
  totalConnections: number;
  totalJobs: number;
}

function getSubCategoryChartData(
  subCategories: SubCategory[]
): ChartData<"bar"> {
  const labels: string[] = [];
  const groups: string[] = [];
  let datasets: any[] = [];

  for (let subCat of subCategories) {
    if (!labels.includes(subCat.application)) {
      labels.push(subCat.application);
    }
    if (!groups.includes(subCat.subCateory)) {
      groups.push(subCat.subCateory);
    }
  }

  const getData = (key: string): number[] => {
    let list: number[] = [];
    labels.forEach((app) => {
      let subCats = subCategories.filter((sub) => sub.subCateory === key);
      let ele = subCats.find((res) => {
        return res.application === app;
      });
      list.push(ele ? ele.totalJobs : 0);
    });
    return list;
  };

  groups.forEach((subCat, index) => {
    datasets.push({
      label: subCat,
      data: getData(subCat),
      backgroundColor: chartColorPalettes.primary[index],
    });
  });

  return {
    labels,
    datasets,
  };
}

function getConnectionsByTransformationTypeChartData(
  subCategories: SubCategory[]
): ChartData<"bar"> {
  const labels: string[] = [];
  const data: number[] = [];
  const colors: string[] = [];

  subCategories.forEach((subcategory) => {
    let exists = false;
    for (let index = 0; index < labels.length; index++) {
      if (labels[index] === subcategory.subCateory) {
        data[index] += subcategory.totalConnections;
        exists = true;
        break;
      }
    }
    if (!exists) {
      labels.push(subcategory.subCateory);
      data.push(subcategory.totalConnections);
      colors.push(chartColorPalettes.primary[colors.length]);
    } else {
      exists = false;
    }
  });

  return {
    labels,
    datasets: [
      {
        label: "Connections",
        data,
        backgroundColor: colors,
      },
    ],
  };
}

function getConnectionsBySubcategoryChartData(
  subCategories: SubCategory[]
): ChartData<"doughnut"> {
  const labels: string[] = [];
  const data: number[] = [];
  const colors: string[] = [];

  subCategories.forEach((subcategory) => {
    let exists = false;
    for (let index = 0; index < labels.length; index++) {
      if (labels[index] === subcategory.application) {
        data[index] += subcategory.totalConnections;
        exists = true;
        break;
      }
    }
    if (!exists) {
      labels.push(subcategory.application);
      data.push(subcategory.totalConnections);
      colors.push(chartColorPalettes.secondary[colors.length]);
    } else {
      exists = false;
    }
  });

  return {
    labels,
    datasets: [
      {
        label: "Connections",
        data,
        backgroundColor: colors,
      },
    ],
  };
}

function ParallelJobsOverview() {
  const analyzeApi = new AnalyzeApi();
  const { currentProject } = useContext(ProjectContext);
  const [categoriesList, setCategoriesList] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [subCategoriesList, setSubCategoriesList] = useState<SubCategory[]>([]);
  const [selectedSubCategoriesList, setSelectedSubCategoriesList] = useState<
    SubCategory[]
  >([]);
  const [barCharData, setBarCharData] = useState<ChartData<"bar"> | null>(null);
  const [doughnutChartData, setDoughnutChartData] =
    useState<ChartData<"doughnut"> | null>(null);
  const [horizontalBarCharData, setHorizontalBarCharData] =
    useState<ChartData<"bar"> | null>(null);

  const [categoriesListQuery, setCategoriesListQuery] = useState("");
  const filteredCategoriesList =
    categoriesListQuery === ""
      ? categoriesList
      : categoriesList.filter((item) => {
          return item.name
            .toLowerCase()
            .includes(categoriesListQuery.toLowerCase());
        });

  useEffect(() => {
    async function getProjectCategories(projectId: string): Promise<void> {
      const result = await analyzeApi.getProjectCategories(projectId);
      const categories: Category[] = await result.json();
      setCategoriesList(categories);
      setSelectedCategory(categories[0]);
    }

    async function getProjectSubCategories(projectId: string): Promise<void> {
      const result = await analyzeApi.getProjectSubCategories(projectId);
      const subCategories: SubCategory[] = await result.json();
      setSubCategoriesList(subCategories);
    }

    if (currentProject) {
      getProjectCategories(currentProject.uuid);
      getProjectSubCategories(currentProject.uuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  useEffect(() => {
    if (selectedCategory && subCategoriesList.length > 0) {
      const subCategories: SubCategory[] = [];
      subCategoriesList.forEach((sub) => {
        if (selectedCategory.name === sub.category) subCategories.push(sub);
      });
      setSelectedSubCategoriesList(subCategories);
    }
  }, [selectedCategory, subCategoriesList]);

  useEffect(() => {
    if (selectedSubCategoriesList.length > 0) {
      setBarCharData(getSubCategoryChartData(selectedSubCategoriesList));
      setHorizontalBarCharData(
        getConnectionsByTransformationTypeChartData(selectedSubCategoriesList)
      );
      setDoughnutChartData(
        getConnectionsBySubcategoryChartData(selectedSubCategoriesList)
      );
    }
  }, [selectedSubCategoriesList]);

  return (
    <MasterPage heading="Parallel jobs" useBackButton>
      <PageTransition>
        <div id="content" className="p-6 2xl:max-w-7xl 2xl:mx-auto 2xl:px-0">
          <h2 className="mt-2 mb-6 text-xl font-medium">
            High level parallel jobs analysis
          </h2>
          <div className="grid grid-cols-4 gap-6">
            <div className="col-span-full flex flex-col p-6 bg-white rounded-lg shadow">
              {categoriesList && (
                <div className="mb-8">
                  <Combobox
                    value={selectedCategory}
                    onChange={setSelectedCategory}
                  >
                    <div className="relative">
                      <div className="relative w-full">
                        <Combobox.Input
                          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-4 pr-12 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                          displayValue={(category: Category) => category?.name}
                          onChange={(event) =>
                            setCategoriesListQuery(event.target.value)
                          }
                          spellCheck={false}
                          autoComplete="off"
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                          <span className="pointer-events-none relative inset-y-0 right-0 flex flex-col justify-center items-center px-2 text-gray-500">
                            <FontAwesomeIcon
                              icon={solid("sort-up")}
                              className="w-2.5 h-2.5"
                              aria-hidden={true}
                            />
                            <FontAwesomeIcon
                              icon={solid("sort-down")}
                              className="w-2.5 h-2.5 -mt-1.5"
                              aria-hidden={true}
                            />
                          </span>
                        </Combobox.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setCategoriesListQuery("")}
                      >
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {filteredCategoriesList.length === 0 &&
                          categoriesListQuery !== "" ? (
                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                              Nothing found.
                            </div>
                          ) : (
                            filteredCategoriesList.map((category) => (
                              <Combobox.Option
                                key={category.id}
                                className={({ active, selected }) =>
                                  `relative cursor-default select-none flex py-2 pl-4 pr-9 text-sm ${
                                    selected && !active
                                      ? "bg-primary-100 text-primary-700"
                                      : active
                                      ? "bg-primary-50 text-primary-700"
                                      : "bg-white text-gray-900"
                                  }`
                                }
                                value={category}
                              >
                                {({ active, selected }) => (
                                  <>
                                    <span
                                      className={`block truncate mr-3 ${
                                        selected ? "font-medium" : "font-normal"
                                      }`}
                                    >
                                      {category.name}
                                    </span>
                                    <span
                                      className={`ml-auto mr-3 ${
                                        active || selected
                                          ? "text-primary-600"
                                          : "text-gray-500"
                                      }`}
                                    >
                                      {category.totalJobs} Jobs
                                    </span>
                                    {selected ? (
                                      <span
                                        className={`absolute inset-y-0 right-0 flex items-center pr-4 text-primary-600`}
                                      >
                                        <span className="text-base">
                                          <span className="sr-only">
                                            Selected
                                          </span>
                                          <FontAwesomeIcon
                                            icon={solid("check")}
                                            aria-hidden={true}
                                          />
                                        </span>
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Combobox.Option>
                            ))
                          )}
                        </Combobox.Options>
                      </Transition>
                    </div>
                  </Combobox>
                </div>
              )}
              {barCharData && (
                <BarChart data={barCharData}>
                  <div className="text-xl font-bold mb-1 px-2">
                    {selectedCategory?.totalJobs.toLocaleString()} Jobs
                  </div>
                </BarChart>
              )}
            </div>
            <div className="col-span-2 h-fit flex flex-col px-6 py-5 bg-white rounded-lg shadow">
              <h3 className="mb-7 text-lg">Connections by application</h3>
              {doughnutChartData && (
                <div className="px-1 pb-3">
                  <DoughnutChart
                    data={doughnutChartData}
                    title="Total connections"
                    size="lg"
                  />
                </div>
              )}
            </div>
            <div className="col-span-2 flex flex-col px-6 py-5 bg-white rounded-lg shadow">
              <h3 className="mb-1 text-lg">Connections by transformation</h3>
              {horizontalBarCharData && (
                <div className="relative grow -mx-1.5">
                  <HorizontalBarChart data={horizontalBarCharData} />
                </div>
              )}
            </div>
          </div>
        </div>
      </PageTransition>
    </MasterPage>
  );
}

export default ParallelJobsOverview;
