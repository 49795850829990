import { useEffect, useState } from "react";
import { PageTransition } from "../../_ui-kit/PageTransition";
import { TaskData } from "../translate/components/TasksList";
import {
  ETLTargetJobsDistributionDetails,
  EtlAnalyzerReport,
} from "./AnalyzeResults";
import AnalyzeTaskHeading from "./components/AnalyzeTaskHeading";
import { Platform } from "../../_ui-kit/SelectPlatform/SelectPlatform.types";
import LinkedBox from "./components/LinkedBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { RingChart } from "../../_ui-kit/RingChart";
import ChartLegend from "./components/ChartLegend";
import StatsListItem from "./components/StatsListItem";
import { Project } from "../../models/Project";
import { User } from "../../models/User";
import { Client } from "../../models/Client";
import { TranslateTaskButtonModal } from "./components/TranslateTaskButtonModal";
import { VendorAsset } from "../../_ui-kit/VendorCard";

interface AnalysisRationalizationProps {
  authUser: User;
  currentClient: Client;
  currentProject: Project;
  currentTask: TaskData;
  etlAnalyzerReport: EtlAnalyzerReport;
}

function AnalysisRationalization({
  authUser,
  currentClient,
  currentProject,
  currentTask,
  etlAnalyzerReport,
}: AnalysisRationalizationProps) {
  const [etlTargetPlatforms, setEtlTargetPlatforms] = useState<Platform[]>([]);
  const [selectedPlatform, setSelectedPlatform] = useState<Platform>();
  const [eltTargetJobsDistribution, setEltTargetJobsDistribution] =
    useState<ETLTargetJobsDistributionDetails>();
  const totalJobs =
    etlAnalyzerReport.stats.controlFlows +
    etlAnalyzerReport.stats.dataFlows +
    etlAnalyzerReport.stats.dataFlowServer;

  function findPatternDistribution(category: string) {
    return etlAnalyzerReport.dashboardData.patternDistribution.find(
      (pattern) => pattern.category === category
    );
  }

  useEffect(() => {
    if (etlAnalyzerReport) {
      setEtlTargetPlatforms(
        etlAnalyzerReport.dashboardData.eltTargetJobsDistribution.map(
          (platform) => {
            return {
              id: platform.category,
              name: platform.category,
              description: platform.description,
            };
          }
        )
      );
    }
  }, [etlAnalyzerReport]);

  useEffect(() => {
    if (currentTask && etlTargetPlatforms.length > 0) {
      setSelectedPlatform(
        etlTargetPlatforms.filter(
          (platform) =>
            platform.name.toLocaleUpperCase() ===
            currentTask.embeddedSourcePlatformName?.toUpperCase()
        )[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [etlTargetPlatforms]);

  useEffect(() => {
    if (selectedPlatform) {
      setEltTargetJobsDistribution(
        etlAnalyzerReport.dashboardData.eltTargetJobsDistribution.find(
          (platform) => platform.category === selectedPlatform.id
        )?.details
      );
    }
  }, [selectedPlatform, etlAnalyzerReport]);

  return (
    <PageTransition>
      <div id="content" className="max-w-3xl mx-auto py-8">
        <div className="flex items-center justify-between space-x-3 mb-6">
          <h2 className="text-lg text-gray-900 font-medium">
            Jobs rationalization
          </h2>
          <TranslateTaskButtonModal
            authUser={authUser}
            currentClient={currentClient}
            currentProject={currentProject}
            currentTask={currentTask}
          />
        </div>
        <div className="flex flex-col space-y-6">
          <div className="shrink-0 bg-white rounded-lg shadow">
            <AnalyzeTaskHeading
              currentTask={currentTask}
              isSqlReport={currentTask.sourceType === "SQL"}
            />
          </div>
          {eltTargetJobsDistribution && (
            <div className="shrink-0 bg-white rounded-lg shadow">
              <div className="px-6 py-5">
                <div className="flex items-center">
                  <h3 className="text-lg font-medium text-gray-900">
                    Modernization opportunities for
                  </h3>
                  <div className="flex items-center space-x-4 ml-4">
                    {selectedPlatform && (
                      <div className="flex space-x-3 p-2 border border-gray-200 rounded-md">
                        <VendorAsset
                          platform={selectedPlatform.name}
                          type="icon"
                        />
                        <span className="pr-1 font-medium text-gray-700">
                          {selectedPlatform.description}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center mt-5 pt-4 pb-1 border-t border-gray-200">
                  <div className="relative z-10 w-64">
                    <h4 className="relative text-xs leading-none text-info-900 text-center font-bold tracking-wide before:absolute before:left-0 before:top-1.5 before:w-full before:h-px before:bg-info-100">
                      <span className="relative bg-white px-2">
                        E&nbsp;T&nbsp;L
                      </span>
                    </h4>
                    <dl className="flex flex-col space-y-4 mt-4">
                      <LinkedBox
                        title="Extract, Load"
                        subTitle={(
                          eltTargetJobsDistribution.ingestEgress +
                          eltTargetJobsDistribution.ingest +
                          eltTargetJobsDistribution.egress
                        ).toLocaleString()}
                        hideLeftHandle
                      />
                      <LinkedBox
                        title="Extract, Transform, Load"
                        subTitle={eltTargetJobsDistribution.ieTransform.toLocaleString()}
                        hideLeftHandle
                      />
                      <LinkedBox
                        title="Transform"
                        subTitle={eltTargetJobsDistribution.transform.toLocaleString()}
                        hideLeftHandle
                      />
                    </dl>
                  </div>
                  <div className="relative z-0 grow self-stretch flex flex-col items-center justify-center">
                    <div
                      className="absolute top-[68px] inset-x-0 h-px border-b-[1.5px] border-dashed border-info-300 rotate-[9deg]"
                      aria-hidden={true}
                    />
                    <div
                      className="absolute top-[100px] inset-x-0 h-px border-b-[1.5px] border-dashed border-info-300 rotate-[-6deg]"
                      aria-hidden={true}
                    />
                    <div className="relative -top-16 -mt-2 flex flex-col items-center justify-center text-center">
                      <div className="mb-1 text-lg leading-6 font-bold text-gray-900 ">
                        {`${(
                          eltTargetJobsDistribution.ingest +
                          eltTargetJobsDistribution.egress +
                          eltTargetJobsDistribution.ingestEgress +
                          eltTargetJobsDistribution.transform +
                          eltTargetJobsDistribution.ieTransform
                        ).toLocaleString()} / ${etlAnalyzerReport.stats.dataFlows.toLocaleString()}`}
                      </div>
                      <div className="text-xs leading-none font-medium uppercase text-gray-500">
                        Data Flows
                      </div>
                    </div>
                    <FontAwesomeIcon
                      icon={solid("arrow-right-long")}
                      className="relative w-4 text-info-700"
                      aria-hidden={true}
                    />
                    <div
                      className="absolute top-[130px] inset-x-0 h-px border-b-[1.5px] border-dashed border-info-300 rotate-[9deg]"
                      aria-hidden={true}
                    />
                    <div
                      className="absolute top-[162px] inset-x-0 h-px border-b-[1.5px] border-dashed border-info-300 rotate-[-6deg]"
                      aria-hidden={true}
                    />
                  </div>
                  <div className="relative z-10 w-60 mt-3">
                    <h4 className="relative text-xs leading-none text-info-900 text-center font-bold tracking-widest before:absolute before:left-0 before:top-1.5 before:w-full before:h-px before:bg-info-100">
                      <span className="relative bg-white px-2">EL&nbsp;T</span>
                    </h4>
                    <dl className="flex flex-col space-y-4 mt-4">
                      <LinkedBox
                        title="Extract, Load"
                        subTitle={(
                          eltTargetJobsDistribution.ingestEgress +
                          eltTargetJobsDistribution.ingest +
                          eltTargetJobsDistribution.egress +
                          eltTargetJobsDistribution.ieTransform
                        ).toLocaleString()}
                        hideRightHandle
                      />
                      <LinkedBox
                        title="Transform"
                        subTitle={(
                          eltTargetJobsDistribution.ieTransform +
                          eltTargetJobsDistribution.transform
                        ).toLocaleString()}
                        hideRightHandle
                      />
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="bg-white rounded-lg shadow">
            <div className="p-6">
              <h2 className="mb-6 text-xl font-medium text-gray-900">
                Total job analysis
              </h2>
              <div className="flex items-stretch space-x-8">
                <div className="shrink-0 w-3/5 flex flex-col space-y-8">
                  <div>
                    {[etlAnalyzerReport].map((report) => {
                      const redundantJobs =
                        findPatternDistribution("Redundant Jobs");
                      const orphanJobs =
                        findPatternDistribution("Orphaned Jobs");
                      const ingestEgressOnly =
                        findPatternDistribution("Ingest/Egress Only");
                      const egressOnly = findPatternDistribution("Egress Only");
                      const ingestOnly = findPatternDistribution("Ingest Only");

                      return (
                        <div
                          key="notRequiredJobs"
                          className="flex items-center space-x-5"
                        >
                          <div className="relative shrink-0 w-48 h-48">
                            <div className="absolute inset-0 flex items-center justify-center">
                              <RingChart
                                size={192}
                                title="Redundant jobs chart"
                                progress={redundantJobs?.percentage || 0}
                                variant="warning"
                              ></RingChart>
                            </div>
                            <div className="absolute inset-0 flex items-center justify-center">
                              <RingChart
                                size={152}
                                title="Orphan jobs chart"
                                progress={orphanJobs?.percentage || 0}
                                variant="success"
                              ></RingChart>
                            </div>
                            <div className="absolute inset-0 flex items-center justify-center">
                              <RingChart
                                size={112}
                                title="Extract, Load chart"
                                progress={
                                  (ingestEgressOnly?.percentage || 0) +
                                  (egressOnly?.percentage || 0) +
                                  (ingestOnly?.percentage || 0)
                                }
                                variant="info"
                              >
                                <div className="absolute inset-0 flex flex-col items-center justify-center">
                                  <span className="mb-1 text-xl leading-6 text-gray-900 font-bold">
                                    {totalJobs.toLocaleString()}
                                  </span>
                                  <span className="uppercase text-gray-500 text-xs leading-none font-medium">
                                    Total jobs
                                  </span>
                                </div>
                              </RingChart>
                            </div>
                          </div>
                          <div className="pr-1">
                            <h3 className="mb-1 text-2xl text-gray-900 font-medium">
                              {`${etlAnalyzerReport.dashboardData.etlSummary.simplificationPercentage}%`}{" "}
                              Simplification
                            </h3>
                            <h4 className="mb-3 text-gray-500 text-lg leading-6">
                              Potentially no longer required jobs
                            </h4>
                            <dl className="flex flex-col space-y-1 pt-3 border-t-2 border-gray-200">
                              <ChartLegend
                                category="Redundant jobs"
                                indicatorColor="bg-warning-600"
                                count={redundantJobs?.count.toLocaleString()}
                                percentage={redundantJobs?.percentage}
                              />
                              <ChartLegend
                                category="Orphan jobs"
                                indicatorColor="bg-success-600"
                                count={orphanJobs?.count.toLocaleString()}
                                percentage={orphanJobs?.percentage}
                              />
                              <ChartLegend
                                category="Extract, Load"
                                indicatorColor="bg-info-600"
                                count={(
                                  (ingestEgressOnly?.count || 0) +
                                  (egressOnly?.count || 0) +
                                  (ingestOnly?.count || 0)
                                ).toLocaleString()}
                                percentage={
                                  (ingestEgressOnly?.percentage || 0) +
                                  (egressOnly?.percentage || 0) +
                                  (ingestOnly?.percentage || 0)
                                }
                              />
                            </dl>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    {[etlAnalyzerReport].map((report) => {
                      const data = [
                        {
                          title: "Extract only",
                          description: `${findPatternDistribution(
                            "Egress Only"
                          )?.count.toLocaleString()} Jobs`,
                        },
                        {
                          title: "Load only",
                          description: `${findPatternDistribution(
                            "Ingest Only"
                          )?.count.toLocaleString()} Jobs`,
                        },
                        {
                          title: "E / L Only",
                          description: `${findPatternDistribution(
                            "Ingest/Egress Only"
                          )?.count.toLocaleString()} Jobs`,
                        },
                        {
                          title: "E / L Hot spot",
                          description: `${findPatternDistribution(
                            "I/E Hot Spot"
                          )?.count.toLocaleString()} Jobs`,
                        },
                      ];
                      return (
                        <div key="additional">
                          <h3 className="mb-3 text-gray-900 text-lg font-medium">
                            Additional distribution details
                          </h3>
                          <dl className="flex -mx-3 divide-x">
                            {data.map((item) => {
                              return (
                                <div
                                  className="flex flex-col grow space-y-0.5 px-3"
                                  key={item.title}
                                >
                                  <dt className="text-xs text-gray-500 uppercase">
                                    {item.title}
                                  </dt>
                                  <dd className="text-sm text-gray-900 font-medium">
                                    {item.description}
                                  </dd>
                                </div>
                              );
                            })}
                          </dl>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="flex-grow flex flex-col space-y-8 pl-8 border-l border-gray-200">
                  <div>
                    <h3 className="mb-2 text-gray-900 text-lg font-medium">
                      Top 5 patterns
                    </h3>
                    {[etlAnalyzerReport].map((report) => {
                      const topControlflow = findPatternDistribution(
                        "Top 5 Control Flow Patterns"
                      );
                      const topDataFlow = findPatternDistribution(
                        "Top 5 Data Flow Patterns"
                      );
                      const topDataFlowServer = findPatternDistribution(
                        "Top 5 Data Flow Server Patterns"
                      );
                      const totalPatterns =
                        (topControlflow?.count || 0) +
                        (topDataFlow?.count || 0) +
                        (topDataFlowServer?.count || 0);
                      const patternsPercentage = +(
                        (totalPatterns / totalJobs) *
                        100
                      ).toFixed(0);

                      return (
                        <div key="patterns">
                          <div className="px-4 pt-3 pb-4 mb-4 bg-gray-100 rounded">
                            <h4
                              id="efficiency-heading"
                              className="flex items-center justify-between mb-1.5 text-sm text-gray-900"
                            >
                              <span className="font-bold">{`${patternsPercentage}%`}</span>
                              <span className="text-xs font-medium text-info-700">
                                Testing efficiency savings
                              </span>
                            </h4>
                            <div className="w-full h-2.5 rounded-full bg-gray-200 overflow-hidden">
                              <div
                                className={`h-full bg-info-600 rounded-full`}
                                style={{
                                  width: `${patternsPercentage}%`,
                                }}
                                role="meter"
                                aria-labelledby="efficiency-heading"
                                aria-valuenow={patternsPercentage}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                          <dl className="flex flex-col space-y-2">
                            <StatsListItem
                              category="Control flow"
                              value={`${topControlflow?.count.toLocaleString()} / ${
                                etlAnalyzerReport.stats.controlFlows
                              } Jobs`}
                            />
                            <StatsListItem
                              category="Data flow"
                              value={`${topDataFlow?.count.toLocaleString()} / ${
                                etlAnalyzerReport.stats.dataFlows
                              } Jobs`}
                            />
                            {etlAnalyzerReport.sourcePlatform.toLowerCase() ===
                              "datastage" && (
                              <StatsListItem
                                category="Data flow server"
                                value={`${topDataFlowServer?.count.toLocaleString()} / ${
                                  etlAnalyzerReport.stats.dataFlowServer
                                } Jobs`}
                              />
                            )}
                            <div className="mt-3 pt-3 border-t border-gray-200">
                              <StatsListItem
                                category="Total patterns"
                                value={`${totalPatterns.toLocaleString()} / ${totalJobs.toLocaleString()} Jobs`}
                              />
                            </div>
                          </dl>
                        </div>
                      );
                    })}
                  </div>
                  {etlAnalyzerReport.dashboardData.embeddedCodeSummary.length >
                    0 && (
                    <div>
                      <h3 className="mb-2 text-gray-900 text-lg font-medium">
                        Embedded code analysis
                      </h3>
                      {[etlAnalyzerReport].map((report) => {
                        return (
                          <div
                            key="embeddedCodeSummary"
                            className="flex flex-col space-y-4"
                          >
                            {report.dashboardData.embeddedCodeSummary.map(
                              (embeddedCode) => {
                                return (
                                  <div
                                    className="flex flex-col space-y-2"
                                    key={embeddedCode.category}
                                  >
                                    <h4 className="flex items-center space-x-2.5 font-medium text-sm text-primary-700">
                                      <FontAwesomeIcon
                                        icon={solid("rectangle-code")}
                                        className="w-4 h-4 text-primary-600"
                                      />
                                      <span>{embeddedCode.category}</span>
                                    </h4>
                                    <dl className="grid grid-cols-2 gap-3 pt-2.5 border-t border-gray-200">
                                      {embeddedCode.scriptTaskAttributes.map(
                                        (scriptTaskAttribute) => {
                                          return (
                                            <div
                                              className="flex flex-col space-y-0.5"
                                              key={scriptTaskAttribute.name}
                                            >
                                              <dt className="text-xs text-gray-500 uppercase">
                                                {scriptTaskAttribute.name}
                                              </dt>
                                              <dd className="text-sm text-gray-900 font-medium">
                                                {scriptTaskAttribute.count.toLocaleString()}
                                              </dd>
                                            </div>
                                          );
                                        }
                                      )}
                                    </dl>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageTransition>
  );
}

export default AnalysisRationalization;
