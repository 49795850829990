import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useState } from "react";
import ConfigureApi from "../api/ConfigureApi";
import { isCrawlerCore } from "../env";
import { ProjectCollectionInProgress } from "../models/Project";
import { ClientContext } from "./ClientContext";
import { ProjectContext } from "./ProjectContext";

interface DataUploadContextProviderProps {
  children: React.ReactNode;
}

interface DataUploadContextProps {
  projectUploadInProgress: boolean;
  projectCollectionsInProgress: ProjectCollectionInProgress[];
  setProjectCollectionsInProgress: (
    param: ProjectCollectionInProgress[]
  ) => void;
}

export const DataUploadContext = createContext<DataUploadContextProps>(
  {} as DataUploadContextProps
);

function DataUploadContextProvider({
  children,
}: DataUploadContextProviderProps) {
  const configureApi = new ConfigureApi();
  const { currentClient } = useContext(ClientContext);
  const { currentProject } = useContext(ProjectContext);
  const [projectUploadInProgress, setProjectUploadInProgress] = useState(false);
  const [projectCollectionsInProgress, setProjectCollectionsInProgress] =
    useState<ProjectCollectionInProgress[]>([]);
  const [refetchInterval, setRefetchInterval] = useState(1000);

  const { data: uploadInProgress } = useQuery(
    [
      "upload-in-progress",
      { clientId: currentClient?.id, projectId: currentProject?.uuid },
    ],
    async () => {
      if (currentProject) {
        const result = await configureApi.getCsvProgressCollections(
          currentProject.uuid
        );
        const collectionsInProgress: ProjectCollectionInProgress[] =
          await result.json();
        return collectionsInProgress;
      } else return null;
    },
    {
      refetchInterval: refetchInterval,
      enabled: isCrawlerCore(),
      onError: (ex) => {
        console.error(ex);
        setRefetchInterval(0);
      },
    }
  );

  useEffect(() => {
    if (uploadInProgress) {
      const filtered = uploadInProgress.filter((data) => !data.imported);
      setProjectCollectionsInProgress(uploadInProgress);
      setProjectUploadInProgress(filtered.length > 0);
    }
  }, [uploadInProgress]);

  return (
    <DataUploadContext.Provider
      value={{
        projectUploadInProgress,
        projectCollectionsInProgress,
        setProjectCollectionsInProgress,
      }}
    >
      {children}
    </DataUploadContext.Provider>
  );
}

export default DataUploadContextProvider;
