import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Combobox, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { Button } from "../Button";
import { ConfigurationManagerProps } from "./ConfigurationManager.types";

export default function ConfigurationManager({
  id,
  label,
  description,
  placeholder,
  className,
  listItems,
  selectedItems,
  setSelectedItems,
  defaultValue,
  searchKey = "name",
  readonly,
}: ConfigurationManagerProps) {
  const [selectedItemsIds, setSelectedItemsIds] = useState<string[]>([]);
  const [selecteListItem, setSelectedListItem] = useState<{} | null>(null);
  const [listItemsQuery, setListItemsQuery] = useState("");
  const filteredListItems =
    listItemsQuery === ""
      ? listItems
      : listItems.filter((item) => {
          return item[searchKey]
            .toLowerCase()
            .includes(listItemsQuery.toLowerCase());
        });

  function getId(item: any): string {
    return item.uuid ? item.uuid : item.id;
  }

  function getTitle(item: any): string {
    return item.fullName ? item.fullName : item.name;
  }

  function getSubtitle(item: any): string {
    return item.role
      ? item.role.toLowerCase()
      : item.clientName
      ? item.clientName
      : `${item.projectCount} Projects`;
  }

  function assignButtonClickHandler(): void {
    setSelectedItems([...selectedItems, selecteListItem]);
    setSelectedListItem(null);
  }

  function unAssignButtonClickHandler(itemId: string): void {
    setSelectedItems(selectedItems.filter((item) => getId(item) !== itemId));
  }

  useEffect(() => {
    if (defaultValue.length > 0) {
      setSelectedItems(
        listItems.filter((item) => defaultValue.includes(getId(item)))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedItemsIds(selectedItems.map((item: any) => getId(item)));
  }, [selectedItems]);

  return (
    <div className={`${className ? className : ""}`}>
      <input
        id={`${id}[selected]`}
        name={`${id}[selected]`}
        type="hidden"
        value={selectedItemsIds}
      />
      <div className="space-y-3">
        <label
          htmlFor={!readonly ? id : undefined}
          className="block font-medium text-gray-700"
        >
          {label}
        </label>
        <p id={`${id}-description`} className="sr-only">
          {description}
        </p>
        {!readonly && (
          <div className="flex">
            <div className="relative w-full">
              <Combobox value={selecteListItem} onChange={setSelectedListItem}>
                <div className="relative">
                  <Combobox.Input
                    id={id}
                    aria-describedby={`${id}-description`}
                    className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-12 text-sm text-gray-900 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                    displayValue={(item: any) => (item ? getTitle(item) : "")}
                    onChange={(event) => setListItemsQuery(event.target.value)}
                    spellCheck={false}
                    placeholder={placeholder}
                    autoComplete="off"
                  />
                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-1.5 focus:outline-none">
                    <span className="pointer-events-none relative inset-y-0 right-0 flex flex-col justify-center items-center px-2 text-gray-500">
                      <FontAwesomeIcon
                        icon={solid("sort-up")}
                        className="w-2.5 h-2.5"
                        aria-hidden={true}
                      />
                      <FontAwesomeIcon
                        icon={solid("sort-down")}
                        className="w-2.5 h-2.5 -mt-1.5"
                        aria-hidden={true}
                      />
                    </span>
                  </Combobox.Button>
                </div>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  afterLeave={() => setListItemsQuery("")}
                >
                  <Combobox.Options className="absolute z-10 mt-2 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {filteredListItems.length === 0 ? (
                      <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                        Nothing found.
                      </div>
                    ) : (
                      filteredListItems.slice(0, 50).map((item) => {
                        const disabled = selectedItemsIds.includes(item.id);
                        return (
                          <Combobox.Option
                            key={getId(item)}
                            className={({ active, selected }) =>
                              `relative cursor-default select-none flex py-2 pl-4 pr-9 text-sm ${
                                selected && !active
                                  ? "bg-primary-100 text-primary-700"
                                  : active
                                  ? "bg-primary-50 text-primary-700"
                                  : "bg-white text-gray-700"
                              }`
                            }
                            disabled={disabled}
                            value={item}
                          >
                            {({ active, selected }) => (
                              <>
                                <span
                                  className={`block truncate mr-3 ${
                                    disabled ? "text-gray-400" : ""
                                  } ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {getTitle(item)}
                                </span>
                                {!disabled && (
                                  <span
                                    className={`whitespace-nowrap ml-auto mr-3 ${
                                      active || selected
                                        ? "text-primary-600"
                                        : "text-gray-500"
                                    }`}
                                  >
                                    <span className="capitalize">
                                      {getSubtitle(item)}
                                    </span>
                                  </span>
                                )}
                                {disabled && (
                                  <span className="ml-auto mr-3 text-gray-300">
                                    Assigned
                                  </span>
                                )}
                                {selected && (
                                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary-600">
                                    <span className="text-base">
                                      <span className="sr-only">Selected</span>
                                      <FontAwesomeIcon
                                        icon={solid("check")}
                                        aria-hidden={true}
                                      />
                                    </span>
                                  </span>
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        );
                      })
                    )}
                  </Combobox.Options>
                </Transition>
              </Combobox>
            </div>
            <div className="ml-3">
              <Button
                variant="white"
                label="Assign"
                onClick={
                  !selecteListItem ? undefined : assignButtonClickHandler
                }
                disabled={!selecteListItem}
              />
            </div>
          </div>
        )}
      </div>
      {selectedItems.length > 0 && (
        <div className="mt-2 border-b border-gray-200">
          <ul className="divide-y divide-gray-200">
            {selectedItems.map((item) => {
              return (
                <li key={getId(item)} className="flex py-4">
                  <span className="inline-flex shrink-0 h-10 w-10 items-center justify-center rounded-full bg-gray-500">
                    <span className="leading-none text-white uppercase tracking-wide">
                      {getTitle(item).substring(0, 2)}
                    </span>
                  </span>
                  <div className="w-full flex items-center justify-between ml-3">
                    <div className="flex flex-col">
                      <span className="text-sm font-medium text-gray-900">
                        {getTitle(item)}
                      </span>
                      <span className="text-sm text-gray-500">
                        <span className="capitalize">{getSubtitle(item)}</span>
                      </span>
                    </div>
                    {!readonly && (
                      <button
                        type="button"
                        className="flex rounded-sm bg-white p-0.5 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                        onClick={() => unAssignButtonClickHandler(item.id)}
                      >
                        <span className="sr-only">Remove item</span>
                        <FontAwesomeIcon
                          icon={solid("xmark")}
                          className="w-4 h-4"
                          aria-hidden={true}
                        />
                      </button>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
