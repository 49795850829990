import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useContext, useEffect, useState } from "react";
import { useNodesState, useEdgesState, addEdge } from "react-flow-renderer";
import { Lineage, getTranslatedLineageData } from "../../_ui-kit/Lineage";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Combobox, Disclosure, Transition } from "@headlessui/react";
import {
  LineageLevel,
  LineageResource,
  LineageResourceSummary,
} from "../../_ui-kit/Lineage/Lineage.types";
import { Button } from "../../_ui-kit";
import { VendorAsset } from "../../_ui-kit/VendorCard";
import { MasterPage } from "../../_ui-kit/MasterPage";
import { ProjectContext } from "../../contexts/ProjectContext";
import { useParams } from "react-router-dom";
import AnalyzeApi from "../../api/AnalyzeApi";

function DataFlow() {
  const analyzeApi = new AnalyzeApi();
  const { type } = useParams();
  const isTableLineage = type === "table";
  const [showFilters, setShowFilters] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isLoadingLineage, setIsLoadingLineage] = useState(false);
  const { currentProject } = useContext(ProjectContext);
  const [applicationsList, setApplicationsList] = useState<
    LineageResourceSummary[]
  >([]);
  const [selectedApplication, setSelectedApplication] =
    useState<LineageResourceSummary | null>(null);
  const [schedulersList, setSchedulersList] = useState<
    LineageResourceSummary[]
  >([]);
  const [selectedScheduler, setSelectedScheduler] =
    useState<LineageResourceSummary | null>(null);
  const [jobsList, setJobsList] = useState<LineageResourceSummary[]>([]);
  const [selectedJob, setSelectedJob] = useState<LineageResourceSummary | null>(
    null
  );
  const [tablesList, setTablesList] = useState<LineageResourceSummary[]>([]);
  const [selectedTable, setSelectedTable] =
    useState<LineageResourceSummary | null>(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const onConnect = (params: any) => setEdges((els) => addEdge(params, els));
  const [isLineageTooLarge, setIsLineageTooLarge] = useState(false);
  const [applicationsListQuery, setApplicationsListQuery] = useState("");
  const filteredApplicationsList =
    applicationsListQuery === ""
      ? applicationsList
      : applicationsList.filter((item) => {
          return item.name
            .toLowerCase()
            .includes(applicationsListQuery.toLowerCase());
        });

  const [schedulersListQuery, setSchedulersListQuery] = useState("");
  const filteredSchedulersList =
    schedulersListQuery === ""
      ? schedulersList
      : schedulersList.filter((item) => {
          return item.name
            .toLowerCase()
            .includes(schedulersListQuery.toLowerCase());
        });

  const [jobsListQuery, setJobsListQuery] = useState("");
  const filteredJobsList =
    jobsListQuery === ""
      ? jobsList
      : jobsList.filter((item) => {
          return item.name.toLowerCase().includes(jobsListQuery.toLowerCase());
        });

  const [tablesListQuery, setTablesListQuery] = useState("");
  const filteredTablesList =
    tablesListQuery === ""
      ? tablesList
      : tablesList.filter((item) => {
          return item.name
            .toLowerCase()
            .includes(tablesListQuery.toLowerCase());
        });

  async function getTables(
    projectId: string,
    applicationId: string
  ): Promise<void> {
    const result = isTableLineage
      ? await analyzeApi.getTableLineageSummary(projectId, 3)
      : await analyzeApi.getJobLineageSummary(
          projectId,
          3,
          applicationId,
          selectedScheduler?.id,
          selectedJob?.id
        );
    const tables: LineageResourceSummary[] = await result.json();
    tables.unshift({
      id: "all",
      name: "All tables",
      type: "table",
      platform: "",
      totalApplications: 0,
      totalSchedulers: 0,
      totalJobs: 0,
      totalTables: 0,
      totalDependencies: 0,
    });
    setTablesList(tables);
    setSelectedTable(isTableLineage ? tables[1] : tables[0]);
    setIsLoadingData(false);
  }

  function visualizeLineageClickHandler() {
    async function getJobLineage(projectId: string): Promise<void> {
      if (selectedApplication) {
        let result;
        if (isTableLineage && selectedTable) {
          result = await analyzeApi.getTableLineage(
            projectId,
            selectedTable.id
          );
        } else {
          result = await analyzeApi.getJobLineage(
            projectId,
            selectedApplication.id,
            selectedScheduler?.id,
            selectedJob?.id,
            selectedTable?.id
          );
        }
        const lineage: LineageLevel[] = await result.json();
        const { nodes, edges } = getTranslatedLineageData(lineage, true);
        if (isTableLineage && selectedTable) {
          setNodes(nodes);
          setEdges(edges);
        } else {
          if (edges.length < 350) {
            setNodes(nodes);
            setEdges(edges);
          } else {
            setNodes([]);
            setEdges([]);
            setIsLineageTooLarge(true);
          }
        }
        setIsLoadingLineage(false);
      }
    }

    if (
      currentProject &&
      selectedApplication &&
      (selectedScheduler || selectedJob || selectedTable)
    ) {
      setIsLineageTooLarge(false);
      setIsLoadingLineage(true);
      getJobLineage(currentProject.uuid);
    }
  }

  useEffect(() => {
    setShowFilters(true);
  }, []);

  useEffect(() => {
    async function getApplications(projectId: string): Promise<void> {
      const result = isTableLineage
        ? await analyzeApi.getTableLineageSummary(projectId, 0)
        : await analyzeApi.getJobLineageSummary(projectId, 0);
      const applications: LineageResourceSummary[] = await result.json();
      setApplicationsList(applications);
      setSelectedApplication(applications[0]);
    }

    if (currentProject) {
      setIsLoadingData(true);
      getApplications(currentProject.uuid);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  useEffect(() => {
    async function getSchedulers(
      projectId: string,
      applicationId: string
    ): Promise<void> {
      const result = await analyzeApi.getJobLineageSummary(
        projectId,
        1,
        applicationId
      );
      const schedulers: LineageResourceSummary[] = await result.json();
      schedulers.unshift({
        id: "all",
        name: "All schedulers",
        type: "scheduler",
        platform: "",
        totalApplications: 0,
        totalSchedulers: 0,
        totalJobs: 0,
        totalTables: 0,
        totalDependencies: 0,
      });
      setSchedulersList(schedulers);
      setSelectedScheduler(schedulers[0]);
    }

    if (currentProject && selectedApplication) {
      setIsLoadingData(true);
      if (isTableLineage) {
        getTables(currentProject.uuid, selectedApplication.id);
      } else {
        getSchedulers(currentProject.uuid, selectedApplication.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApplication]);

  useEffect(() => {
    async function getJobs(
      projectId: string,
      applicationId: string
    ): Promise<void> {
      const result = await analyzeApi.getJobLineageSummary(
        projectId,
        2,
        applicationId,
        selectedScheduler?.id
      );
      const jobs: LineageResourceSummary[] = await result.json();
      jobs.unshift({
        id: "all",
        name: "All jobs",
        type: "job",
        platform: "",
        totalApplications: 0,
        totalSchedulers: 0,
        totalJobs: 0,
        totalTables: 0,
        totalDependencies: 0,
      });
      setJobsList(jobs);
      setSelectedJob(jobs[0]);
    }

    if (currentProject && selectedApplication) {
      setIsLoadingData(true);
      getJobs(currentProject.uuid, selectedApplication.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScheduler]);

  useEffect(() => {
    if (currentProject && selectedApplication) {
      setIsLoadingData(true);
      getTables(currentProject.uuid, selectedApplication.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJob]);

  return (
    <MasterPage heading="Data flow" useBackButton>
      <div className="relative flex flex-col h-full overflow-hidden bg-gray-50">
        <div className="min-h-[24rem] grow">
          <Lineage
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
          >
            <Transition
              as={Fragment}
              enter="transition delay-150 duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              show={showFilters}
              appear
            >
              <Disclosure
                as="div"
                className="absolute left-6 top-6 z-20 w-96 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5"
              >
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={`flex items-center w-full px-5 py-4 ${
                        open ? "rounded-t-md bg-gray-50" : "rounded-md"
                      } focus:outline-none focus-visible:ring focus-visible:ring-primary-500`}
                    >
                      <FontAwesomeIcon
                        icon={solid("bars-filter")}
                        className="w-5 h-5 -mt-px mr-3 text-gray-500"
                        aria-hidden={true}
                      />
                      <span className="flex items-baseline space-x-2">
                        <span className="text-sm font-medium uppercase">
                          Filter
                        </span>
                        {!open && (
                          <span className="text-xs tracking-wide text-gray-500">
                            {isTableLineage
                              ? "Application, Table"
                              : "Application, Scheduler, Job, Table"}
                          </span>
                        )}
                      </span>
                      <FontAwesomeIcon
                        icon={solid("caret-right")}
                        className="transition-transform ml-auto text-gray-400"
                        aria-hidden={true}
                        rotation={open ? 90 : undefined}
                        size="sm"
                      />
                    </Disclosure.Button>
                    <Transition
                      as={Fragment}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                      show={open}
                    >
                      <Disclosure.Panel className="relative flex flex-col w-full px-5 pt-4 pb-6 space-y-4 border-t border-gray-200 rounded-b-md">
                        <div className="relative flex flex-col space-y-1">
                          <label className="block w-24 text-sm text-gray-500">
                            Application
                          </label>
                          <Combobox
                            value={selectedApplication}
                            onChange={setSelectedApplication}
                            disabled={isLoadingData}
                          >
                            <div className="relative w-full">
                              <Combobox.Input
                                className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-12 text-sm text-gray-900 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                                displayValue={(
                                  application: LineageResourceSummary
                                ) => (application ? application.name : "")}
                                onChange={(event) =>
                                  setApplicationsListQuery(event.target.value)
                                }
                                spellCheck={false}
                                autoComplete="off"
                              />
                              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-1.5 focus:outline-none">
                                <span className="pointer-events-none relative inset-y-0 right-0 flex flex-col justify-center items-center px-2 text-gray-500">
                                  <FontAwesomeIcon
                                    icon={solid("sort-up")}
                                    className="w-2.5 h-2.5"
                                    aria-hidden={true}
                                  />
                                  <FontAwesomeIcon
                                    icon={solid("sort-down")}
                                    className="w-2.5 h-2.5 -mt-1.5"
                                    aria-hidden={true}
                                  />
                                </span>
                              </Combobox.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                              afterLeave={() => setApplicationsListQuery("")}
                            >
                              <Combobox.Options className="absolute z-10 top-16 left-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 divide-y text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {filteredApplicationsList.length === 0 &&
                                applicationsListQuery !== "" ? (
                                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Nothing found.
                                  </div>
                                ) : (
                                  filteredApplicationsList
                                    .slice(0, 50)
                                    .map((application) => (
                                      <Combobox.Option
                                        key={application.id}
                                        className={({ active, selected }) =>
                                          `relative cursor-default select-none flex items-center py-2.5 px-4 border-gray-200 text-sm ${
                                            selected && !active
                                              ? "bg-primary-100 text-primary-700"
                                              : active
                                              ? "bg-primary-50 text-primary-700"
                                              : "bg-white text-gray-700"
                                          }`
                                        }
                                        value={application}
                                      >
                                        <div className="flex flex-col flex-grow flex-shrink w-full min-w-0">
                                          <span className="font-medium truncate">
                                            {application.name}
                                          </span>
                                          {!isTableLineage &&
                                            application.id !== "all" && (
                                              <div className="flex items-center space-x-2 mt-1 text-xs opacity-70">
                                                <div className="flex space-x-1">
                                                  <span className="font-medium">
                                                    {application.totalSchedulers.toLocaleString()}
                                                  </span>
                                                  <span>Schedulers</span>
                                                </div>
                                                <FontAwesomeIcon
                                                  icon={solid("circle")}
                                                  className="w-1 h-1 opacity-30"
                                                  aria-hidden={true}
                                                />
                                                <div className="flex space-x-1">
                                                  <span className="font-medium">
                                                    {application.totalJobs.toLocaleString()}
                                                  </span>
                                                  <span>Jobs</span>
                                                </div>
                                                <FontAwesomeIcon
                                                  icon={solid("circle")}
                                                  className="w-1 h-1 opacity-30"
                                                  aria-hidden={true}
                                                />
                                                <div className="flex space-x-1">
                                                  <span className="font-medium">
                                                    {application.totalTables.toLocaleString()}
                                                  </span>
                                                  <span>Tables</span>
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                      </Combobox.Option>
                                    ))
                                )}
                              </Combobox.Options>
                            </Transition>
                          </Combobox>
                        </div>
                        {!isTableLineage && (
                          <div className="relative flex flex-col space-y-1">
                            <label className="block w-24 text-sm text-gray-500">
                              Scheduler
                            </label>
                            <Combobox
                              value={selectedScheduler}
                              onChange={setSelectedScheduler}
                              disabled={isLoadingData}
                            >
                              <div className="relative w-full">
                                <Combobox.Input
                                  className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-12 text-sm text-gray-900 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                                  displayValue={(scheduler: LineageResource) =>
                                    scheduler ? scheduler.name : ""
                                  }
                                  onChange={(event) =>
                                    setSchedulersListQuery(event.target.value)
                                  }
                                  spellCheck={false}
                                  autoComplete="off"
                                />
                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-1.5 focus:outline-none">
                                  <span className="pointer-events-none relative inset-y-0 right-0 flex flex-col justify-center items-center px-2 text-gray-500">
                                    <FontAwesomeIcon
                                      icon={solid("sort-up")}
                                      className="w-2.5 h-2.5"
                                      aria-hidden={true}
                                    />
                                    <FontAwesomeIcon
                                      icon={solid("sort-down")}
                                      className="w-2.5 h-2.5 -mt-1.5"
                                      aria-hidden={true}
                                    />
                                  </span>
                                </Combobox.Button>
                              </div>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                afterLeave={() => setSchedulersListQuery("")}
                              >
                                <Combobox.Options className="absolute z-10 top-16 left-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 divide-y text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  {filteredSchedulersList.length === 0 &&
                                  schedulersListQuery !== "" ? (
                                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                      Nothing found.
                                    </div>
                                  ) : (
                                    filteredSchedulersList
                                      .slice(0, 50)
                                      .map((scheduler) => (
                                        <Combobox.Option
                                          key={scheduler.id}
                                          className={({ active, selected }) =>
                                            `relative cursor-default select-none flex items-center py-2.5 px-4 border-gray-200 text-sm ${
                                              selected && !active
                                                ? "bg-primary-100 text-primary-700"
                                                : active
                                                ? "bg-primary-50 text-primary-700"
                                                : "bg-white text-gray-700"
                                            }`
                                          }
                                          value={scheduler}
                                        >
                                          <div className="flex flex-col flex-grow flex-shrink w-full min-w-0">
                                            <span className="font-medium truncate">
                                              {scheduler.name}
                                            </span>
                                            {scheduler.id !== "all" && (
                                              <div className="flex items-center space-x-2 mt-1 text-xs opacity-70">
                                                <div className="flex space-x-1">
                                                  <span className="font-medium">
                                                    {scheduler.totalJobs.toLocaleString()}
                                                  </span>
                                                  <span>Jobs</span>
                                                </div>
                                                <FontAwesomeIcon
                                                  icon={solid("circle")}
                                                  className="w-1 h-1 opacity-30"
                                                  aria-hidden={true}
                                                />
                                                <div className="flex space-x-1">
                                                  <span className="font-medium">
                                                    {scheduler.totalTables.toLocaleString()}
                                                  </span>
                                                  <span>Tables</span>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </Combobox.Option>
                                      ))
                                  )}
                                </Combobox.Options>
                              </Transition>
                            </Combobox>
                          </div>
                        )}
                        {!isTableLineage && (
                          <div className="relative flex flex-col space-y-1">
                            <label className="block w-24 text-sm text-gray-500">
                              Job
                            </label>
                            <Combobox
                              value={selectedJob}
                              onChange={setSelectedJob}
                              disabled={isLoadingData}
                            >
                              <div className="relative w-full">
                                <Combobox.Input
                                  className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-12 text-sm text-gray-900 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                                  displayValue={(job: LineageResource) =>
                                    job ? job.name : ""
                                  }
                                  onChange={(event) =>
                                    setJobsListQuery(event.target.value)
                                  }
                                  spellCheck={false}
                                  autoComplete="off"
                                />
                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-1.5 focus:outline-none">
                                  <span className="pointer-events-none relative inset-y-0 right-0 flex flex-col justify-center items-center px-2 text-gray-500">
                                    <FontAwesomeIcon
                                      icon={solid("sort-up")}
                                      className="w-2.5 h-2.5"
                                      aria-hidden={true}
                                    />
                                    <FontAwesomeIcon
                                      icon={solid("sort-down")}
                                      className="w-2.5 h-2.5 -mt-1.5"
                                      aria-hidden={true}
                                    />
                                  </span>
                                </Combobox.Button>
                              </div>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                afterLeave={() => setJobsListQuery("")}
                              >
                                <Combobox.Options className="absolute z-10 top-16 left-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 divide-y text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  {filteredJobsList.length === 0 &&
                                  jobsListQuery !== "" ? (
                                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                      Nothing found.
                                    </div>
                                  ) : (
                                    filteredJobsList.slice(0, 50).map((job) => (
                                      <Combobox.Option
                                        key={job.id}
                                        className={({ active, selected }) =>
                                          `relative cursor-default select-none flex items-center py-2.5 px-4 border-gray-200 text-sm ${
                                            selected && !active
                                              ? "bg-primary-100 text-primary-700"
                                              : active
                                              ? "bg-primary-50 text-primary-700"
                                              : "bg-white text-gray-700"
                                          }`
                                        }
                                        value={job}
                                      >
                                        <div className="flex flex-col flex-grow flex-shrink w-full min-w-0">
                                          <span className="font-medium truncate">
                                            {job.name}
                                          </span>
                                          {job.id !== "all" && (
                                            <div className="flex items-center space-x-2 mt-1 text-xs opacity-70">
                                              <div className="flex space-x-1">
                                                <span className="font-medium">
                                                  {job.totalSchedulers.toLocaleString()}
                                                </span>
                                                <span>Schedulers</span>
                                              </div>
                                              <FontAwesomeIcon
                                                icon={solid("circle")}
                                                className="w-1 h-1 opacity-30"
                                                aria-hidden={true}
                                              />
                                              <div className="flex space-x-1">
                                                <span className="font-medium">
                                                  {job.totalJobs.toLocaleString()}
                                                </span>
                                                <span>Jobs</span>
                                              </div>
                                              <FontAwesomeIcon
                                                icon={solid("circle")}
                                                className="w-1 h-1 opacity-30"
                                                aria-hidden={true}
                                              />
                                              <div className="flex space-x-1">
                                                <span className="font-medium">
                                                  {job.totalTables.toLocaleString()}
                                                </span>
                                                <span>Tables</span>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        {job.id !== "all" && (
                                          <div className="ml-4">
                                            <VendorAsset
                                              type="icon"
                                              platform={job.platform}
                                            />
                                          </div>
                                        )}
                                      </Combobox.Option>
                                    ))
                                  )}
                                </Combobox.Options>
                              </Transition>
                            </Combobox>
                          </div>
                        )}
                        <div className="relative flex flex-col space-y-1">
                          <label className="block w-24 text-sm text-gray-500">
                            Table
                          </label>
                          <Combobox
                            value={selectedTable}
                            onChange={setSelectedTable}
                            disabled={isLoadingData}
                          >
                            <div className="relative w-full">
                              <Combobox.Input
                                className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-12 text-sm text-gray-900 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                                displayValue={(table: LineageResource) =>
                                  table ? table.name : ""
                                }
                                onChange={(event) =>
                                  setTablesListQuery(event.target.value)
                                }
                                spellCheck={false}
                                autoComplete="off"
                              />
                              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-1.5 focus:outline-none">
                                <span className="pointer-events-none relative inset-y-0 right-0 flex flex-col justify-center items-center px-2 text-gray-500">
                                  <FontAwesomeIcon
                                    icon={solid("sort-up")}
                                    className="w-2.5 h-2.5"
                                    aria-hidden={true}
                                  />
                                  <FontAwesomeIcon
                                    icon={solid("sort-down")}
                                    className="w-2.5 h-2.5 -mt-1.5"
                                    aria-hidden={true}
                                  />
                                </span>
                              </Combobox.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                              afterLeave={() => setTablesListQuery("")}
                            >
                              <Combobox.Options className="absolute z-10 top-16 left-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 divide-y text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {filteredTablesList.length === 0 &&
                                tablesListQuery !== "" ? (
                                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Nothing found.
                                  </div>
                                ) : (
                                  filteredTablesList
                                    .slice(0, 50)
                                    .map((table) => (
                                      <Combobox.Option
                                        key={table.id}
                                        className={({ active, selected }) =>
                                          `${
                                            table.id === "all" && isTableLineage
                                              ? "hidden"
                                              : "flex"
                                          } relative cursor-default select-none items-center py-2.5 px-4 border-gray-200 text-sm ${
                                            selected && !active
                                              ? "bg-primary-100 text-primary-700"
                                              : active
                                              ? "bg-primary-50 text-primary-700"
                                              : "bg-white text-gray-700"
                                          }`
                                        }
                                        value={table}
                                      >
                                        <div className="flex flex-col flex-grow flex-shrink w-full min-w-0">
                                          <span className="font-medium truncate">
                                            {table.name}
                                          </span>
                                          {!isTableLineage &&
                                            table.id !== "all" && (
                                              <div className="flex items-center space-x-2 mt-1 text-xs opacity-70">
                                                <div className="flex space-x-1">
                                                  <span className="font-medium">
                                                    {table.totalSchedulers.toLocaleString()}
                                                  </span>
                                                  <span>Schedulers</span>
                                                </div>
                                                <FontAwesomeIcon
                                                  icon={solid("circle")}
                                                  className="w-1 h-1 opacity-30"
                                                  aria-hidden={true}
                                                />
                                                <div className="flex space-x-1">
                                                  <span className="font-medium">
                                                    {table.totalJobs.toLocaleString()}
                                                  </span>
                                                  <span>Jobs</span>
                                                </div>
                                              </div>
                                            )}
                                          {isTableLineage && (
                                            <div className="mt-1 text-xs opacity-70">
                                              {table.totalConnections}{" "}
                                              Connections
                                            </div>
                                          )}
                                        </div>
                                        {table.id !== "all" && (
                                          <div className="ml-4">
                                            <VendorAsset
                                              type="icon"
                                              platform={table.platform}
                                            />
                                          </div>
                                        )}
                                      </Combobox.Option>
                                    ))
                                )}
                              </Combobox.Options>
                            </Transition>
                          </Combobox>
                        </div>
                        <div className="!mt-6">
                          <Button
                            label="Visualize lineage"
                            fullWidth
                            icon={solid("arrow-right-to-arc")}
                            onClick={visualizeLineageClickHandler}
                            disabled={isLoadingData}
                            isLoading={isLoadingLineage}
                            trailingIcon
                          />
                        </div>
                        {isLoadingData && (
                          <div className="absolute inset-0 flex flex-col items-center justify-start !m-0 bg-white/70 text-primary-600 rounded-b-md">
                            <div className="animate-pulse flex w-full h-1 -mt-px bg-gradient-to-r from-primary-600 to-primary-500"></div>
                          </div>
                        )}
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            </Transition>
            <Transition
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
              show={isLineageTooLarge}
            >
              <div className="absolute z-10 inset-0 flex items-center justify-center">
                <div className="bg-gray-50 max-w-xs px-2 py-3 mb-12">
                  <div className="text-center">
                    <h3 className="text-lg font-medium text-gray-900">
                      Too large to visualize
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Add filters like specific jobs or tables to reduce the
                        size of the generated lineage.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </Lineage>
        </div>
      </div>
    </MasterPage>
  );
}

export default DataFlow;
